import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'

import { setCookie } from '@/utils/general/cookie'
import { ILineageIdInitialState } from './type'
import { X_LINEAGE_ID } from '@/constants/browser'

const initialState: ILineageIdInitialState = {
    id: ''
}

const lineageIdSlice = createSlice({
    name: 'lineageId',
    initialState,
    reducers: {
        setLineageId: state => {
            const id = uuid()
            state.id = id
            setCookie({ name: X_LINEAGE_ID, value: id })
        }
    }
})

const { actions, reducer } = lineageIdSlice || {}
const { setLineageId } = actions || {}

export { setLineageId }

export default reducer
