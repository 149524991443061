import { Container } from '@cleartrip/ct-design-components'
import componentGenerator from '@/utils/widget/componentGenerator'

import { isEmpty } from '@/utils/general/browserHelper'
import { errorTypeWidgetResponse } from '@/mocks/errorMock'
import { useAppDispatch } from '@/redux/store/hooks'
import { fireLogger } from '@/utils/general/logger'
import { DEFAULT_ERROR_MOCK_CODE } from '@/constants/components/common'

interface IErrorFallbacl {
    statusCode?: number
    errorLog?: unknown
    footer?: boolean
}

const ErrorFallback: React.FC<IErrorFallbacl> = ({ statusCode = DEFAULT_ERROR_MOCK_CODE, errorLog, footer }) => {
    const dispatcher = useAppDispatch()

    if (!isEmpty(errorLog)) {
        //TODO: Log the error on the basis of client and server both condition
        fireLogger('errorFallback: ', {
            message: `Error fallback Error ${errorLog}`
        })
    }

    let errorResponse = errorTypeWidgetResponse[statusCode]

    if (isEmpty(errorResponse)) {
        errorResponse = errorTypeWidgetResponse[DEFAULT_ERROR_MOCK_CODE]
    }

    return (
        <Container className={`flex flex-middle flex-center ${footer ? '' : 'min-h-100vh'}`}>
            {componentGenerator({ slot: errorResponse, dispatcher, pageName: '' })}
        </Container>
    )
}

export default ErrorFallback
