import { TypographyColorType } from '@cleartrip/ct-design-components'
import { ComponentMapper, IComponent } from '../widget'

export interface IImage {
    url: string
    alt: string
    caption?: string
    index?: number
    category?: string
}

export interface IIcon {
    iconId: string
    height?: string
    width?: string
    color?: string
}

export enum OFFER_CARD_SIZE {
    SM = 'sm',
    MD = 'md',
    FW = 'fw'
}

export interface IText {
    text: string
}

export type IconType = IComponent<IIcon, ComponentMapper.ICON>

export type ButtonType = IComponent<{ text: string }>

export interface IButton {
    label: string
    disabled?: boolean
    loading?: boolean
}

export interface IDialogActionItem {
    label: string
    color: TypographyColorType
}

export interface IDialogAction {
    alignment: 'column' | 'row' // TODO: Implement row logic
    list: IComponent<IDialogActionItem>[]
}
