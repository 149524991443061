import { IRoomAllocation } from '@/types/network/results'

export const ADULTS = 'adults'
export const CHILDREN = 'children'
export const CHILD_AGE = 'ca'

export const STAR_RATING_MAPPER: { [key: string]: string } = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five'
}

export const REVERSE_STAR_RATING_MAPPER: { [key: string]: string } = {
    one: '1',
    two: '2',
    three: '3',
    four: '4',
    five: '5'
}

export enum SORT_FILTER_TYPES {
    TOP_RATED = 'TA_RATING',
    PRICE_HIGH_TO_LOW = 'PRICE_HIGH_TO_LOW',
    PRICE_LOW_TO_HIGH = 'PRICE_LOW_TO_HIGH'
}

export const SORT_BY_FILTER_VALUES: Record<string, string> = {
    topRated: SORT_FILTER_TYPES.TOP_RATED,
    priceHighToLow: SORT_FILTER_TYPES.PRICE_HIGH_TO_LOW,
    priceLowToHigh: SORT_FILTER_TYPES.PRICE_LOW_TO_HIGH
}

export const REVERSE_TA_MAPPER: Record<string, string> = {
    FourPointFivePlus: '4.5',
    FourPlus: '4',
    threePointFivePlus: '3.5',
    threePlus: '3'
}

export const TA_URL_MAPPER: Record<string, string> = {
    FourPointFivePlus: '4.5-5',
    FourPlus: '4-5',
    threePointFivePlus: '3.5-5',
    threePlus: '3-5'
}

export const TA_VALUE_URL_QUERY_MAPPER: Record<string, string> = {
    '4.5': '4.5-5',
    '4': '4-5',
    '3.5': '3.5-5',
    '3': '3-5'
}

export const DEFAULT_ROOM_ALLOCATION: IRoomAllocation[] = [
    {
        adults: {
            count: 2,
            metadata: []
        },
        children: {
            count: 0,
            metadata: []
        }
    }
]

export const amenitiesPreFix = 'am'
