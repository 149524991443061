import { APP_NAME_FOR_ERROR_LOG } from '@/constants/network'
import { isServer } from '@/utils/general'

/**
 *
 * @param message message for logger and will run on server
 * @param loggerObj :- object values for logger
 */
export const fireLogger = (message: string, loggerObj: Record<string, unknown>) => {
    if (isServer()) {
        const prePareLog = Object.assign(
            {
                errorTitle: message,
                appType: APP_NAME_FOR_ERROR_LOG,
                severity: 'ERR',
                timestamp: Date.now()
            },
            loggerObj
        )

        /**
         * Kibana will pick the below json format from pod and upload.
         * It should always be JSON format. i.e {key: value }
         */
        console.log(JSON.stringify(prePareLog))
    }
}
