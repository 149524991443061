import React from 'react'

export default function StarOutline({ color = '#1A1A1A', ...props }) {
    return (
        <div className="pl-2 flex justify-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M7.42997 21C7.12997 21 6.81997 20.9 6.53997 20.69C5.99997 20.3 5.77997 19.72 5.91997 19.06L6.92997 14.48L3.53997 11.4C3.04997 10.94 2.88997 10.34 3.08997 9.72C3.28997 9.09 3.77997 8.71 4.44997 8.65L8.85997 8.26L10.6 3.96C10.85 3.34 11.35 3 12 3C12.65 3 13.15 3.34 13.4 3.96L15.14 8.24L19.55 8.63C20.22 8.69 20.7 9.07 20.91 9.7C21.11 10.33 20.96 10.92 20.47 11.38L17.07 14.47L18.08 19.05C18.22 19.72 18 20.3 17.46 20.69C16.91 21.09 16.31 21.11 15.76 20.74L12 18.33L8.22997 20.75C7.96997 20.92 7.69997 21.01 7.42997 21.01V21ZM12 4C11.75 4 11.63 4.09 11.53 4.34L9.66997 8.9C9.59997 9.07 9.43997 9.19 9.24997 9.21L4.53997 9.63C4.26997 9.65 4.12997 9.76 4.04997 10.01C3.96997 10.27 4.01997 10.46 4.21997 10.65L7.81997 13.92C7.94997 14.04 8.00997 14.22 7.96997 14.4L6.89997 19.26C6.83997 19.53 6.89997 19.71 7.12997 19.87C7.36997 20.05 7.51997 20.01 7.68997 19.9L11.74 17.3C11.91 17.19 12.12 17.19 12.28 17.3L16.32 19.89C16.49 20.01 16.65 20.04 16.89 19.86C17.11 19.7 17.18 19.52 17.12 19.24L16.05 14.38C16.01 14.2 16.07 14.02 16.2 13.9L19.81 10.62C20.01 10.43 20.06 10.24 19.98 9.98C19.9 9.73 19.76 9.62 19.49 9.6L14.77 9.18C14.58 9.16 14.42 9.04 14.35 8.87L12.49 4.31C12.39 4.06 12.26 3.97 12.02 3.97L12 4Z"
                    fill={color}
                />
            </svg>
        </div>
    )
}
