import { CURRENCY, CURRENCYLOCAL } from '@/constants/currency'
import { FASTUI_DOMAIN, IMAGE_DOMAIN, SEO_IMAGE_DOMAIN } from '@/constants/cloudinary'
import { onlyNumbersRegex } from '@/constants/regex'
import { RAVEN_PAGE_NAME } from '@/analytics/raven/ravenConstants'
import { getCookie } from './cookie'
import { STATSIG_AB_KEY } from '../experiment/statsig'
import { isEmpty } from './browserHelper'
import { BaggageInfoType, MealInfo } from '@/components/itinerary/TravellerInfo/types'

export const constructImageUrl = (url: string, transformations?: string, defaultBucket = true): string => {
    const defaultTransformation = transformations || 'w_300,h_300,fl_progressive,e_sharpen:80,c_fill'

    url = url?.replace('https://s3.ap-south-1.amazonaws.com/ct-flight-images-devqa/', '')

    if (defaultBucket) {
        return `${IMAGE_DOMAIN(defaultTransformation)}/places/flights/${url}`
    } else {
        return `${FASTUI_DOMAIN}/raw/upload/${defaultTransformation}${url}`
    }
}

export const constructflightSEOImageUrl = (image: string) => `${SEO_IMAGE_DOMAIN}/places/flights/${image}`

export const formatCurrency = (value: string | number, currencyCode?: string) => {
    if (!value) return value

    if (typeof value === 'string') {
        value = value.replace(/,/g, '')
    }

    if (typeof value === 'number') {
        value = value?.toString()
    }

    const formatted = parseInt(value, 10).toLocaleString(CURRENCYLOCAL, {
        style: 'currency',
        currency: currencyCode || CURRENCY,
        minimumFractionDigits: 0
    })
    return formatted
}

export const isServer = () => {
    return typeof window === 'undefined' || !window
}

type NestedArray<T> = Array<NestedArray<T> | T>

export function flatten<T>(input: NestedArray<T>): T[] {
    return flatten(input.map(x => (Array.isArray(x) ? flatten(x) : [x])))
}

export const validateMobileNumber = (mobile: string, minLength: number, maxLength: number) => {
    if (mobile && mobile.length >= minLength && mobile.length <= maxLength && onlyNumbersRegex.test(mobile)) {
        return true
    }
    return false
}

// Needed to be any for now
// unknown is giving error
// unkown can't be used to index

/**
 *
 * @param fieldArray  Array of string
 * @param objectToTraverse  Object from which value needs to be extracted
 * @returns Return field value in a nested object
 */
export const getNestedField = (fieldArray: any, objectToTraverse: any) => {
    const reducerFunction = (accumalator: any, current: any) => {
        return accumalator && accumalator[current] ? accumalator[current] : null
    }
    return fieldArray.reduce(reducerFunction, objectToTraverse)
}

/**
 *
 * @param str  A string that needs to be transformed into a sentence Case
 * @returns  A sentence case string
 */
export const toSentenceCase = (str: string) => str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1)

export const parseJSON = <T extends Record<string, unknown>>(jsonString = ''): T => {
    try {
        return JSON.parse(jsonString)
    } catch (error) {
        return {} as T
    }
}

export const checkPageName = () => {
    if (!isServer()) {
        const currentURL = window.location.href
        const urlPattern = /\/(flights\/?)?$/
        if (urlPattern.test(currentURL)) return RAVEN_PAGE_NAME.FlightSHOME
        else return 'NA'
    } else {
        return 'NA'
    }
}

export const capitalizeFirstLetter = (payload: string) => {
    if (payload && isNaN(Number(payload))) {
        return payload
            .split(' ')
            .map(item => item.charAt(0).toUpperCase() + item.slice(1))
            .join('')
    }

    return payload
}

export const replacePlaceholders = (str: string, placeholderMap: any) => {
    const placeholderRegex = /\{(\w+)\}/g

    return str.replace(placeholderRegex, (match, placeholderName) => {
        const value = placeholderMap[placeholderName]
        return value !== undefined ? value : match
    })
}

export const getFormattedTime = (totalSeconds, stringFormat = false, stringFormatToMin = false) => {
    if (typeof totalSeconds !== 'string') {
        let seconds = totalSeconds
        const days = Math.floor(seconds / 86400)
        seconds %= 86400
        const hours = Math.floor(seconds / 3600)
        seconds %= 3600
        const minutes = Math.floor(seconds / 60)
        seconds %= 60
        if (stringFormatToMin) {
            let hr = 0
            if (days > 0 || hours > 0) {
                if (days > 0) {
                    hr += days * 24
                }
                if (hours > 0) {
                    hr += hours
                }
                if (minutes > 30) {
                    hr += 1
                }
                return `${hr}h`
            }
            return `${minutes}m`
        } else if (stringFormat) {
            return `${days > 0 ? `${days}d ` : ''}${hours > 0 ? `${hours}h ` : ''}${
                minutes > 0 ? `${minutes}m` : hours > 0 ? '0m' : ''
            }`
        }
        return { days, hours, minutes, seconds }
    } else {
        return totalSeconds
    }
}

export function deepclone(item: string | number | Date | BaggageInfoType[] | MealInfo[]) {
    if (!item) {
        return item
    } // null, undefined values check

    var types = [Number, String, Boolean],
        result: string | number | boolean | Date | BaggageInfoType[] | MealInfo[]

    // normalizing primitives if someone did new String('aaa'), or new Number('444');
    types.forEach(function (type) {
        if (item instanceof type) {
            result = type(item)
        }
    })

    if (typeof result == 'undefined') {
        if (Object.prototype.toString.call(item) === '[object Array]') {
            result = []
            item.forEach(function (child: any, index: string | number, array: any) {
                result[index] = deepclone(child)
            })
        } else if (typeof item == 'object') {
            // testing that this is DOM
            if (item.nodeType && typeof item.cloneNode == 'function') {
                result = item.cloneNode(true)
            } else if (!item.prototype) {
                // check that this is a literal
                if (item instanceof Date) {
                    result = new Date(item)
                } else {
                    // it is an object literal
                    result = {}
                    for (var i in item) {
                        result[i] = deepclone(item[i])
                    }
                }
            } else {
                // depending what you would like here,
                // just keep the reference, or create new object
                if (false && item.constructor) {
                    // would not advice to do that, reason? Read below
                    result = new item.constructor()
                } else {
                    result = item
                }
            }
        } else {
            result = item
        }
    }

    return result
}


export const replacePlaceHolderText = (text = '', placeholders = [], replaceClass = '') => {
    let formattedText = text
    if (!isEmpty(text) && !isEmpty(placeholders)) {
        formattedText = placeholders.reduce((desc, ele, index) => {
            const { description = '', title = '' } = ele
            return desc.replace(`${title}`, `<span class=${replaceClass}>${description}</span>`)
        }, text)
    }
    return formattedText
}

export const getJourneyLevelData = (ancillaryData: any) => {
    const journeyData: any = []
    const { tripAncillariesResponse = [] } = ancillaryData || {}
    const journeyDataObj = tripAncillariesResponse?.filter((tripAncillary:any) => {
        return tripAncillary?.fareSubType === 'RETAIL'
    })
    if (isEmpty(journeyDataObj)) return journeyData
    const { ancillaryDetails = {} as any } = journeyDataObj[0] || {}
    const { journeyAncillaries = [] } = ancillaryDetails || {}
    return journeyAncillaries
}

export const getFreeSeatsAndMealsData = (journeyLevelAncillaryData: any) => {
    let isSeatFree = false
    let isMealFree = false
    try {
        journeyLevelAncillaryData.forEach((journey: any) => {
            journey.benefitInfos.forEach((tripBenefits: any) => {
                tripBenefits.benefits.forEach((benefit: any) => {
                    if (benefit?.supplyBenefitType === 'SEAT' && !benefit?.details?.chargeable) {
                        isSeatFree = true
                    } else if (benefit?.supplyBenefitType === 'MEAL' && !benefit?.details?.chargeable) {
                        isMealFree = true
                    }
                })
            })
        })
    } catch (e) { }
    return { isSeatFree, isMealFree }
}
