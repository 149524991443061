import { SVGProps } from 'react'

export const InfoWarning = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask id="mask0_9407_59030" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_9407_59030)">
                <path
                    d="M12 16.5C12.15 16.5 12.271 16.4543 12.363 16.363C12.4543 16.271 12.5 16.15 12.5 16V11.475C12.5 11.3417 12.45 11.229 12.35 11.137C12.25 11.0457 12.1333 11 12 11C11.85 11 11.729 11.05 11.637 11.15C11.5457 11.25 11.5 11.3667 11.5 11.5V16.025C11.5 16.1583 11.55 16.271 11.65 16.363C11.75 16.4543 11.8667 16.5 12 16.5ZM12 9.575C12.1667 9.575 12.3127 9.51667 12.438 9.4C12.5627 9.28333 12.625 9.13333 12.625 8.95C12.625 8.78333 12.5627 8.64167 12.438 8.525C12.3127 8.40833 12.1667 8.35 12 8.35C11.8333 8.35 11.6873 8.40833 11.562 8.525C11.4373 8.64167 11.375 8.78333 11.375 8.95C11.375 9.13333 11.4373 9.28333 11.562 9.4C11.6873 9.51667 11.8333 9.575 12 9.575ZM12 21C10.75 21 9.575 20.7667 8.475 20.3C7.375 19.8333 6.421 19.196 5.613 18.388C4.80433 17.5793 4.16667 16.625 3.7 15.525C3.23333 14.425 3 13.25 3 12C3 10.75 3.23333 9.575 3.7 8.475C4.16667 7.375 4.80433 6.42067 5.613 5.612C6.421 4.804 7.375 4.16667 8.475 3.7C9.575 3.23333 10.75 3 12 3C13.25 3 14.425 3.23333 15.525 3.7C16.625 4.16667 17.5793 4.804 18.388 5.612C19.196 6.42067 19.8333 7.375 20.3 8.475C20.7667 9.575 21 10.75 21 12C21 13.25 20.7667 14.425 20.3 15.525C19.8333 16.625 19.196 17.5793 18.388 18.388C17.5793 19.196 16.625 19.8333 15.525 20.3C14.425 20.7667 13.25 21 12 21ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z"
                    fill="#DF9641"
                />
            </g>
        </svg>
    )
}
