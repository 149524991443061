import * as React from 'react'

export const SearchIconRed: React.FC<React.SVGProps<SVGSVGElement>> = props => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clip-path="url(#clip0_4567_299350)">
            <mask
                id="mask0_4567_299350"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4567_299350)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.61133 10.1749C4.61133 6.99329 7.19056 4.41406 10.3722 4.41406C13.5538 4.41406 16.1331 6.99329 16.1331 10.1749C16.1331 13.3566 13.5538 15.9358 10.3722 15.9358C7.19056 15.9358 4.61133 13.3566 4.61133 10.1749ZM10.3722 3.41406C6.63827 3.41406 3.61133 6.44101 3.61133 10.1749C3.61133 13.9089 6.63827 16.9358 10.3722 16.9358C11.9734 16.9358 13.4445 16.3792 14.6027 15.4489L19.5736 20.4198C19.7689 20.6151 20.0855 20.6151 20.2807 20.4198C20.476 20.2246 20.476 19.908 20.2807 19.7127L15.3346 14.7666C16.4509 13.5609 17.1331 11.9476 17.1331 10.1749C17.1331 6.44101 14.1061 3.41406 10.3722 3.41406Z"
                    fill="#FF4F17"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_4567_299350">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
