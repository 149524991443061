export const AlertInfo: React.FC<React.SVGProps<SVGSVGElement>> = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
            <mask
                id="mask0_2592_95568"
                style={{ maskType: 'alpha' }}
                width="24"
                height="24"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
            </mask>
            <g mask="url(#mask0_2592_95568)">
                <path
                    fill="#DF9641"
                    d="M12 16.5c.15 0 .271-.046.363-.137A.493.493 0 0012.5 16v-4.525a.442.442 0 00-.15-.338A.503.503 0 0012 11a.47.47 0 00-.363.15.503.503 0 00-.137.35v4.525c0 .133.05.246.15.338.1.091.217.137.35.137zm0-6.925a.623.623 0 00.438-.175.589.589 0 00.187-.45.56.56 0 00-.187-.425A.623.623 0 0012 8.35a.623.623 0 00-.438.175.56.56 0 00-.187.425c0 .183.062.333.187.45a.623.623 0 00.438.175zM12 21a8.93 8.93 0 01-3.525-.7 8.937 8.937 0 01-2.862-1.912A8.94 8.94 0 013.7 15.525 8.931 8.931 0 013 12c0-1.25.233-2.425.7-3.525a8.94 8.94 0 011.913-2.863A8.936 8.936 0 018.475 3.7C9.575 3.233 10.75 3 12 3s2.425.233 3.525.7a8.948 8.948 0 012.863 1.912A8.948 8.948 0 0120.3 8.475c.467 1.1.7 2.275.7 3.525a8.93 8.93 0 01-.7 3.525 8.949 8.949 0 01-1.912 2.863 8.949 8.949 0 01-2.863 1.912A8.93 8.93 0 0112 21zm0-1c2.217 0 4.104-.779 5.663-2.337C19.221 16.104 20 14.217 20 12s-.779-4.104-2.337-5.663C16.104 4.779 14.217 4 12 4s-4.104.779-5.662 2.337C4.779 7.896 4 9.783 4 12s.78 4.104 2.338 5.663C7.896 19.221 9.783 20 12 20z"
                ></path>
            </g>
        </svg>
    )
}
