import React from 'react'

export const Search: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill = '#B3B3B3', ...restProps }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <path
                d="M20 20L16.5463 16.5463M18.2222 11.1111C18.2222 15.0385 15.0385 18.2222 11.1111 18.2222C7.18375 18.2222 4 15.0385 4 11.1111C4 7.18375 7.18375 4 11.1111 4C15.0385 4 18.2222 7.18375 18.2222 11.1111Z"
                stroke={fill}
                stroke-linecap="round"
            />
        </svg>
    )
}
