import { getCookie, setCookie } from '../general/cookie'
import { STAGSIG_EXPERIMENT_VALUES, STATSIG_EXPERIMENT_NAMES } from '@/analytics/statsig/type'
import { GetServerSidePropsContext } from 'next/types'
import { isServer } from '../general'
import { isEmpty } from '../general/browserHelper'
import { DEFAULT_STATSIG_EXPERIMENTS } from '@/analytics/statsig/statsigConfig'
export const STATSIG_AB_KEY = 'ct_statsig_experiments'
export const STATSIG_OVERRIDE_KEY = 'h_override_statsig'

export const getStatsigExperimentsFromClientSideCookie = (): Record<string, string> => {
    const experimentsObject = getCookie(STATSIG_AB_KEY)
    if (experimentsObject) {
        return JSON.parse(experimentsObject)
    }
    return {}
}

// TODO: DIscuss with team, Add logic if required experiemnts already exists then don't change the A/B time

export const formStatsigExperimentObjectAndUpdateValues = ({
    existingExperimentObject = {},
    latestExperimentValues = {},
    shouldOverride = false
}: {
    existingExperimentObject?: STAGSIG_EXPERIMENT_VALUES | Record<string, string>
    latestExperimentValues: STAGSIG_EXPERIMENT_VALUES | Record<string, string>
    shouldOverride?: boolean
}) => {
    if (!isServer() && isEmpty(existingExperimentObject)) {
        existingExperimentObject = getStatsigExperimentsFromClientSideCookie()
    }
    /**
     * Statsig always returns same experiemt values if userid falls in certain bucket,
     * so even if we refetch values from statis and update with new response,
     * it will always return same values
     */
    if (!shouldOverride) {
        const statsigExperiments = {
            ...existingExperimentObject,
            ...latestExperimentValues
        }
        return setCookie({ name: STATSIG_AB_KEY, value: JSON.stringify(statsigExperiments), expiryInDay: 1 })
    } else {
        const statsigExperiments = {
            ...latestExperimentValues,
            ...existingExperimentObject
        }
        return setCookie({ name: STATSIG_AB_KEY, value: JSON.stringify(statsigExperiments), expiryInDay: 1 })
    }
}

export const getStatsigCookieForServerSide = (context: GetServerSidePropsContext) => {
    const cookies = context.req?.cookies
    const statsigCookie = cookies?.[STATSIG_AB_KEY]
    let parsedStatsigCookie = JSON.parse(statsigCookie || '{}')

    // We'll be using DEFAULT_STATSIG_EXPERIMENTS for default value for some experiment which is 100% i.e. that doesn't require statisg call
    parsedStatsigCookie = {
        ...DEFAULT_STATSIG_EXPERIMENTS,
        ...parsedStatsigCookie
    }
    return parsedStatsigCookie
}
