export enum INCLUSIONS {
    FREECANCELLATION = 'freeCancellation',
    FREEBREAKFAST = 'freeBreakfast'
}

export const DOCUMENTS_IDS = {
    SHEET_HEADER_ID: 'SHEET_HEADER'
}

export const DETAIL_PAGES = {
    DETAIL: 'details',
    GALLERY: 'gallery',
    AMENITIES: 'amenties',
    RULES: 'propertyRules',
    ABOUT_PROPERTY: 'ABOUT_PROPERTY'
}

export const HEADERS_HEIGHT = 128
export const ROOM_FILTER_HEIGHT = 80
