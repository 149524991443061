import { useAppDispatch, useAppSelector } from '@/redux/store/hooks'
import { closeModal, getModals } from '@/redux/slices/modalSlice'
import componentRenderer from '@/utils/widget/componentRenderer'
import { Modal } from './Modal'

const GlobalModalComponent = () => {
    const modals = useAppSelector(getModals)
    const dispatch = useAppDispatch()

    const onClose = () => {
        dispatch(closeModal())
    }

    return (
        <>
            {modals.map((modal, index) => {
                const { data, ownProps } = modal || {}
                const { pageHeaderSlotNodes, slotNodes } = componentRenderer({
                    data,
                    dispatcher: dispatch,
                    ownProps
                })
                return (
                    <Modal key={`modal-${index}`} onClose={onClose}>
                        {pageHeaderSlotNodes}
                        {slotNodes}
                    </Modal>
                )
            })}
        </>
    )
}
export default GlobalModalComponent
