import React, { FC } from 'react'

export const Cross: FC<React.SVGProps<SVGSVGElement>> = ({ stroke = '#1A1A1A', ...rest }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M18 6L12 12M12 12L6 18M12 12L6 6M12 12L18 18"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
