import React, { SVGProps } from 'react'

export const CalendarCross = (props: SVGProps<SVGSVGElement>) => {
    const { height = 20, width = 20, ...rest } = props || {}
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 20 20" {...rest}>
            <mask
                id="mask0_6882_23653"
                style={{ maskType: 'alpha' }}
                width="20"
                height="20"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
            </mask>
            <g mask="url(#mask0_6882_23653)">
                <path
                    fill="#C83232"
                    d="M10 12.875L8.373 14.5a.376.376 0 01-.28.135.354.354 0 01-.303-.135.375.375 0 01-.146-.291c0-.111.049-.209.146-.292l1.625-1.625-1.625-1.625a.377.377 0 01-.135-.282.353.353 0 01.135-.301.375.375 0 01.292-.146c.11 0 .208.048.291.146L10 11.709l1.625-1.625a.377.377 0 01.282-.135.353.353 0 01.302.135c.097.083.146.18.146.291a.375.375 0 01-.146.292l-1.625 1.625 1.625 1.625c.083.07.128.163.135.282a.353.353 0 01-.135.301.375.375 0 01-.292.146.375.375 0 01-.292-.146L10 12.875zM4.686 17.5a1.314 1.314 0 01-1.354-1.354V5.521c0-.389.128-.712.386-.968.256-.257.579-.386.968-.386h1.458V2.75c0-.125.045-.229.135-.312a.461.461 0 01.324-.125.424.424 0 01.437.437v1.417h5.98V2.729a.41.41 0 01.113-.302.41.41 0 01.303-.114c.111 0 .208.038.292.114a.392.392 0 01.125.302v1.438h1.458a1.314 1.314 0 011.354 1.354v10.625a1.315 1.315 0 01-1.354 1.354H4.687zm0-.833h10.625a.508.508 0 00.375-.146.507.507 0 00.146-.375V8.854H4.166v7.292c0 .153.049.278.146.375a.508.508 0 00.375.146zm-.52-8.646h11.666v-2.5a.507.507 0 00-.146-.375.508.508 0 00-.375-.146H4.687a.508.508 0 00-.375.146.508.508 0 00-.146.375v2.5z"
                ></path>
            </g>
        </svg>
    )
}
