import StatsigNode from 'statsig-node'
import { STATSIG_ENVIRONMENT_TIER, STATSIG_EXPERIMENT_AB_KEYS, getStatsigUserConfig } from './statsigConfig'

import { STAGSIG_EXPERIMENT_VALUES, STATSIG_EXPERIMENT_NAMES_TYPE } from './type'
import { PAGE_NAME_TYPE } from '@/constants/pages/common'

class StatsigManager {
    static statsigInstance: typeof StatsigNode
    constructor() {
        StatsigManager.initializeStastigInstance()
    }

    static async initializeStastigInstance() {
        const statsigToken = process.env.STATSIG_SERVER_KEY || ''
        if (!StatsigManager.statsigInstance) {
            try {
                StatsigManager.statsigInstance = StatsigNode

                await StatsigManager.statsigInstance.initialize(statsigToken, {
                    environment: { tier: STATSIG_ENVIRONMENT_TIER },
                    rulesetsSyncIntervalMs: 1000 * 60 * 10 // sync the values in the 10 minute.
                })
            } catch (err) {
                console.log('Failed to initialise statsig', err)
            }
        }
    }

    private async getStatsigAbValue(
        abKey: string,
        userId: string,
        customConfig?: Record<string, string | number | boolean | Array<string> | undefined>
    ) {
        if (!StatsigManager.statsigInstance) {
            await StatsigManager.initializeStastigInstance()
        }
        return StatsigManager.statsigInstance.getExperimentSync(getStatsigUserConfig(userId, customConfig), abKey)
    }
    /**
     *
     * @param userId
     * @param pageName
     * @returns Statsig experiment object
     *
     * NOTE!: Cookie updation logic is added in getHeadersForSSR because of server side
     */
    async getAllStatigAbValues(
        userId: string,
        pageName: PAGE_NAME_TYPE,
        customConfig?: Record<string, string | number | boolean | Array<string> | undefined>
    ) {
        const abValues = {}

        const experimentPayload = STATSIG_EXPERIMENT_AB_KEYS[pageName]

        for (const abValueKey of Object.keys(experimentPayload)) {
            const {
                experimentName = '',
                experimentValue = '',
                defaultValue
            } = experimentPayload[abValueKey as STATSIG_EXPERIMENT_NAMES_TYPE] || {}
            const abConfig = await this.getStatsigAbValue(experimentName, userId, customConfig)
            const abValue = abConfig.get(experimentValue, defaultValue)
            Object.assign(abValues, { [abValueKey]: abValue })
        }

        return abValues as STAGSIG_EXPERIMENT_VALUES
    }
}

// const StatsigManagerInstance = new StatsigManager()
export default StatsigManager
