import { memo, useCallback, useMemo, useState } from 'react'

import { Dialog } from '@cleartrip/ct-design-components'

import { componentRenderer } from '@/utils'

import { IBaseDialogWrapperProps } from './type'
import { closeDialog } from '@/redux/slices/dialogs'
import { ActionMapper, IDispatcher } from '@/types'

//Custom default styling for all the dialogs.
const DEFAULT_DIALOG_STYLING = {
    maxWidth: '392px',
    margin: '16px'
}

/**
 * Generally, we don't open another dialog on top of a dialog. We can have a use case where we have to show
 * multiple screens on a same dialog. so, modalScreenId state takes care of that
 */

const BaseDialogWrapper = ({ dialog, dispatcher, dialogId }: IBaseDialogWrapperProps) => {
    const { data, ownProps } = dialog || {}
    const { customDispatcher, modalContentCss = DEFAULT_DIALOG_STYLING } = ownProps || {}

    const [modalScreenId, setModalScreenId] = useState(0)

    const localDispatcher: IDispatcher = useCallback(
        action => {
            const { type } = action ?? {}

            switch (type) {
                case ActionMapper.ADD_TO_DIALOG_JOURNEY: {
                    setModalScreenId(id => id + 1)
                    break
                }

                case ActionMapper.REMOVE_FROM_DIALOG_JOURNEY: {
                    setModalScreenId(id => id - 1)
                }

                default:
                    dispatcher(action)
            }
        },
        [dispatcher]
    )

    const memorizedOwnProps = useMemo(() => {
        return {
            ...ownProps,
            modalScreenId
        }
    }, [ownProps, modalScreenId])

    const { pageHeaderSlotNodes, slotNodes, pageFooterSlotNodes } = componentRenderer({
        data,
        dispatcher: customDispatcher || localDispatcher,
        ownProps: memorizedOwnProps
    })

    const onClose = useCallback(() => {
        const isBackOperation = modalScreenId >= 1

        if (isBackOperation) setModalScreenId(id => id - 1)
        else dispatcher(closeDialog())
    }, [dispatcher, modalScreenId])

    return (
        <Dialog
            key={`dialog-${dialogId}`}
            onClose={onClose}
            open={true}
            overlayCloseIcon={{
                isBack: modalScreenId >= 1,
                show: true
            }}
            modalContentCss={modalContentCss}
            allowBackdropClose={modalScreenId < 1}
        >
            {pageHeaderSlotNodes}
            {slotNodes}
            {pageFooterSlotNodes}
        </Dialog>
    )
}

export default memo(BaseDialogWrapper)
