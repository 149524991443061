import React from 'react'

export const DownChevron: React.FC<React.SVGProps<SVGSVGElement>> = ({ height = '24', width = '24', ...restProps }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" {...restProps}>
            <g fill="none" fillRule="evenodd">
                <path d="M0 0h16v16H0z" />
                <path stroke="#FFF" strokeWidth=".5" fill="currentColor" d="M3.41 5L8 9.58 12.59 5 14 6.41l-6 6-6-6z" />
            </g>
        </svg>
    )
}

export const DownChevronNew: React.FC<React.SVGProps<SVGSVGElement>> = ({
    height = '24',
    width = '24',
    ...restProps
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...restProps}>
            <g>
                <path
                    fill="#1A1A1A"
                    d="M12 14.65c-.116 0-.229-.017-.337-.05a.604.604 0 01-.288-.2L6.85 9.9a.766.766 0 01-.2-.525c0-.2.075-.375.225-.525.15-.133.325-.2.525-.2s.375.067.525.2L12 12.925l4.1-4.075c.134-.15.3-.221.5-.213.2.008.375.08.525.213.15.15.225.329.225.537 0 .208-.075.38-.225.513l-4.5 4.5a.605.605 0 01-.287.2c-.108.033-.22.05-.338.05z"
                ></path>
            </g>
        </svg>
    )
}
