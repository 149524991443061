export const CloseIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
    width = 24,
    height = 24,
    className = '',
    stroke = '#1A1A1A'
}) => {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18 6L12 12M12 12L6 18M12 12L6 6M12 12L18 18"
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
