export const SuperCoin = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 12 12">
            <path
                fill="#FFC30D"
                d="M10.508 6.906c0 2.52-2.018 4.44-4.508 4.44s-4.506-1.92-4.506-4.44c0-2.52 2.017-4.562 4.506-4.562 2.489 0 4.508 2.043 4.508 4.562z"
            ></path>
            <path
                fill="#FD0"
                d="M6 12c-3.364 0-6-2.604-6-5.927C0 2.724 2.692 0 6 0s6 2.724 6 6.073C12 9.396 9.364 12 6 12z"
            ></path>
            <mask
                id="mask0_686_2463"
                style={{ maskType: 'alpha' }}
                width="12"
                height="12"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path
                    fill="#fff"
                    d="M6 12c-3.364 0-6-2.604-6-5.927C0 2.724 2.692 0 6 0s6 2.724 6 6.073C12 9.396 9.364 12 6 12z"
                ></path>
            </mask>
            <g mask="url(#mask0_686_2463)">
                <path
                    fill="#fff"
                    d="M6 1.193c-2.78 0-5.042 2.29-5.042 5.103 0 2.776 2.216 4.95 5.042 4.95 2.826 0 5.042-2.173 5.042-4.95 0-2.814-2.262-5.103-5.042-5.103z"
                ></path>
                <path
                    fill="#F0A700"
                    d="M6.04.918C3.26.918 1 3.208 1 6.021c0 2.775 2.215 4.95 5.042 4.95 2.826 0 5.041-2.175 5.041-4.95.002-2.814-2.26-5.103-5.041-5.103z"
                ></path>
                <path
                    fill="#FFC000"
                    d="M6 1.102C3.22 1.102.958 3.39.958 6.205c0 2.775 2.215 4.95 5.042 4.95 2.826 0 5.041-2.175 5.041-4.95C11.041 3.39 8.78 1.102 6 1.102z"
                ></path>
                <mask
                    id="mask1_686_2463"
                    style={{ maskType: 'alpha' }}
                    width="12"
                    height="11"
                    x="0"
                    y="1"
                    maskUnits="userSpaceOnUse"
                >
                    <path
                        fill="#fff"
                        d="M6 1.102C3.22 1.102.958 3.39.958 6.205c0 2.775 2.215 4.95 5.042 4.95 2.826 0 5.041-2.175 5.041-4.95C11.041 3.39 8.78 1.102 6 1.102z"
                    ></path>
                </mask>
                <g mask="url(#mask1_686_2463)">
                    <path
                        fill="#FFD60D"
                        fillRule="evenodd"
                        d="M7.801.52l3.771 3.819a2.436 2.436 0 010 3.418L7.8 11.575a2.366 2.366 0 01-3.377 0L.651 7.757a2.44 2.44 0 010-3.418L4.423.52a2.368 2.368 0 013.378 0zm-2.59 10.266l3.994-4.47c.452-.504.052-1.256-.668-1.254H6.31c-.132 0-.234-.088-.252-.198l.241-.834c.232-.798.56-1.929.785-2.716L4.44 4.256a4090.977 4090.977 0 00-1.47 1.637c-.372.502.023 1.198.715 1.198h2.228c.132 0 .234.088.252.197-.127.441-.61 2.214-.955 3.498z"
                        clipRule="evenodd"
                    ></path>
                    <path
                        fill="#F9B110"
                        d="M3.686 7.089c-.692 0-1.087-.696-.715-1.199l.318-.354c.485-.54 2.602-2.896 3.793-4.224-.33 1.156-.882 3.054-1.025 3.55.018.11.12.197.252.197h2.228c2.549 0 2.933 2.342 1.282 4.472l1.752-1.773a2.44 2.44 0 000-3.418L7.8.52a2.368 2.368 0 00-3.378 0L2.611 2.356C.631 4.359 1.094 7.089 3.686 7.089z"
                        opacity="0.91"
                    ></path>
                </g>
                <path
                    fill="#D89309"
                    fillRule="evenodd"
                    d="M1.032 6.097c-.014 2.81 2.225 5.101 5.003 5.115a4.96 4.96 0 001.961-.4c2.566-1.092 3.771-4.081 2.692-6.678a5.026 5.026 0 00-4.601-3.1c-2.778-.015-5.04 2.252-5.055 5.063zm1.51-3.5a4.88 4.88 0 013.493-1.46 4.88 4.88 0 013.491 1.458 4.97 4.97 0 011.446 3.523A4.975 4.975 0 019.526 9.64a4.88 4.88 0 01-3.491 1.46 4.88 4.88 0 01-3.492-1.46 4.978 4.978 0 01-1.446-3.523c0-1.33.514-2.58 1.446-3.522z"
                    clipRule="evenodd"
                    opacity="0.72"
                ></path>
            </g>
            <path
                fill="#fff"
                d="M7.083 1.313L3.091 5.728c-.514.568-.211 1.371.55 1.351l2.303.021a.21.21 0 01.209.215.25.25 0 01-.007.055l-.925 3.42 3.96-4.346c.496-.545.115-1.427-.618-1.427L6.29 5.06a.211.211 0 01-.206-.273c.243-.862.969-3.443 1-3.474z"
            ></path>
        </svg>
    )
}
