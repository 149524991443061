import { REQUEST_METHODS } from '@/constants/network'

export interface IFetchRequestConfig<
    R = unknown,
    T = FetchResponseFormat.JSON | FetchResponseFormat.RAW | FetchResponseFormat.TEXT
> {
    method?: REQUEST_METHODS
    body?: BodyInit | null | undefined
    headers?: HeadersInit
    /**
     * Response data type can be three types JSON,TEXT or RAW
     * since response can be of multiple type like ArrayBUffer for image or PDF raw data
     */
    responseType?: T
    /**
     * based on init we can determine if we want to make call initially or not
     */
    init?: boolean

    domain?: string
    /**
     *
     * @param previousState
     * @param currentState
     * @returns
     */
    onSuccess?: (currentState: R, previousState?: R) => void
    onError?: (error: HTTPResponseError | undefined | unknown) => void
}

export enum FetchResponseFormat {
    JSON = 'JSON',
    TEXT = 'TEXT',
    RAW = 'RAW'
}

export interface IBaseFetchRequestConfig<T = FetchResponseFormat> extends Omit<IFetchRequestConfig<T>, 'init'> {
    url: string
}

export interface IBaseResponse<T = unknown> {
    response: T
    errorMessage: string | null
    statusCode: number
    ttl?: string | null
    datasource: string | null
}

export interface IBaseFetchType {
    <R, T extends FetchResponseFormat.JSON = FetchResponseFormat.JSON>(
        requestConfig: IBaseFetchRequestConfig<T>
    ): Promise<R>
    <R = string, T extends FetchResponseFormat.TEXT = FetchResponseFormat.TEXT>(
        requestConfig: IBaseFetchRequestConfig<T>
    ): Promise<R>
    <R = Response, T extends FetchResponseFormat.RAW = FetchResponseFormat.RAW>(
        requestConfig: IBaseFetchRequestConfig<T>
    ): Promise<R>
    <R = unknown, T = unknown>(requestConfig: IBaseFetchRequestConfig<T>): Promise<R>
}

export class HTTPResponseError implements Error {
    errorResponse?: unknown
    errorCode: number
    name: string
    message: string
    constructor(response: Response, errorResponse?: unknown) {
        this.name = 'HTTPResponseError'
        this.errorResponse = errorResponse
        this.errorCode = response.status
        this.message = response.statusText
    }
}

export interface IBaseResponse<T> {
    response: T
    errorMessage: string | null
    statusCode: number
    ttl?: string | null
    datasource: string | null
}

export interface IApiErrorResponse {
    errorResponse: {
        message?: string
    }
}
