import { useCallback } from 'react'

import { ActionMapper, IComponent, IDispatcher, IOwnProps } from '@/types'
import { getNestedField, isServer } from '@/utils/general'

interface IObservableProps {
    dispatcher?: IDispatcher
    idForRootMargin: string
    data?: IComponent
    ownProps?: IOwnProps
}

const DEFAULT_HEIGHT = 72 + 56
const OFFSET_HEIGHT = 20

function useObservable({ dispatcher, idForRootMargin, data, ownProps }: IObservableProps) {
    const getDispatchMetaData = useCallback(() => {
        return {
            key: getNestedField(['data', 'key'], data),
            pageName: ownProps?.pageName
        }
    }, [data, ownProps])

    const handleIntersection = useCallback(
        ([entry]: IntersectionObserverEntry[]) => {
            const { isIntersecting } = entry ?? {}

            if (isIntersecting) {
                dispatcher && dispatcher({ type: ActionMapper.SET_SELECTED_NAVTAB, payload: getDispatchMetaData() })
            }
        },
        [getDispatchMetaData, dispatcher]
    )

    const getIntersectionOberserverOptions = () => {
        if (!isServer()) {
            const rootHeight = document.getElementById(idForRootMargin)?.offsetHeight ?? DEFAULT_HEIGHT
            const bottomMargin = window.innerHeight - (rootHeight ?? 0) - OFFSET_HEIGHT

            return {
                rootMargin: `-${rootHeight}px 0px -${bottomMargin}px 0px`,
                threshold: 0
            }
        }
    }

    return {
        getIntersectionOberserverOptions,
        handleIntersection
    }
}

export default useObservable
