export const Landing = ({ stroke, ...props }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={stroke} viewBox="0 0 24 24">
            <mask
                id="mask0_160_1644"
                style={{ maskType: 'alpha' }}
                width="24"
                height="24"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#808080" d="M0 0H24V24H0z"></path>
            </mask>
            <g mask="url(#mask0_160_1644)">
                <path d="M3.5 20.5v-1h17v1h-17zm15.475-5.1L3.5 11.125V6.7l.8.225L5 9.05l5.5 1.55V2.65l1.125.275 2.75 8.75 5.25 1.475c.267.067.48.209.638.425.158.217.237.459.237.725 0 .384-.162.692-.487.925-.325.234-.671.292-1.038.175z"></path>
            </g>
        </svg>
    )
}
