import React from 'react'

export function DealOfTheDayTransparent(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_2639_3467)">
                <path
                    d="M1.60339 13.5873L1.60339 4.00954C1.60339 3.11975 2.31112 2.39844 3.18416 2.39844L12.8223 2.39844C13.6953 2.39844 14.4031 3.11975 14.4031 4.00954L14.4031 13.5873C14.4031 14.4771 13.6953 15.1984 12.8223 15.1984L3.18416 15.1984"
                    stroke="#1A1A1A"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M4.8031 5.60156L14.4029 5.60156"
                    stroke="#1A1A1A"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M4.75647 0.800781L4.75647 3.86492"
                    stroke="#1A1A1A"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M11.2773 0.800781L11.2774 3.86492"
                    stroke="#1A1A1A"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M8.02183 7.19922L6.98494 8.97559L4.80298 9.26792L6.41267 10.7515L5.99078 12.7992L8.04184 11.7339L10.0875 12.7992L9.65154 10.7305L11.2028 9.35138L9.02086 8.93363L8.02183 7.19922Z"
                    fill="#61C149"
                />
                <path
                    d="M8.02183 7.19922L6.98494 8.97559L4.80298 9.26792L6.41267 10.7515L5.99078 12.7992L8.04184 11.7339L10.0875 12.7992L9.65154 10.7305L11.2028 9.35138L9.02086 8.93363L8.02183 7.19922Z"
                    stroke="#1A1A1A"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2639_3467">
                    <rect
                        width="15.9596"
                        height="16"
                        fill="white"
                        transform="matrix(1 -1.45759e-08 1.41891e-05 1 0.00305176 0)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
