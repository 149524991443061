import React, { SVGProps } from 'react'

export const EarlyBird = ({ width = 16, height = 16, color = '#FDBA12', ...rest }: SVGProps<SVGSVGElement>) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g clip-path="url(#clip0_893_38342)">
                <path d="M4.0682 1.5957L8.40081 4.7957L8.80089 5.1957L4.06812 4.7957L4.0682 1.5957Z" fill={color} />
                <path
                    d="M7.73394 8.70067C2.32153 10.7364 1.59839 4.63356 1.59839 4.63356C1.59839 4.63356 5.31749 5.20021 10.4476 5.68701C10.1683 3.64071 11.5992 1.34957 13.3789 2.93119C14.447 3.88027 12.9981 7.33756 12.9981 7.33756C12.9981 7.33756 13.3789 12.8043 8.3982 12.8043C8.3982 12.8043 7.4919 12.0996 6.08537 12.394C5.50188 12.5162 4.84384 13.0367 4.27328 13.6043"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M5.19951 9.20898C5.19951 9.20898 4.98404 10.226 1.59961 11.2484C2.80225 14.0992 2.99625 14.409 2.99625 14.409"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M4.25873 4.7957C4.25873 4.7957 3.82721 3.92566 4.08085 1.5957C5.44506 2.78474 5.60093 2.89915 5.60093 2.89915"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M7.19995 3.98828L10.3999 5.58828"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M14.6662 4L13.9995 3.66667L14.6662 3"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_893_38342">
                    <rect
                        width="15.9596"
                        height="16"
                        fill="white"
                        transform="matrix(1 -1.45753e-08 1.41897e-05 1 0 0)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
