import { SVGProps } from 'react'

export function CleartripLogoOnly({ height = 20, width = 20, ...rest }: SVGProps<SVGSVGElement>) {
    return (
        <svg {...rest} width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.005 5.91433V14.0857V14.4815C20.005 15.3973 19.7745 16.2659 19.3784 17.0281C19.3725 17.0399 19.3666 17.0517 19.3607 17.0576C19.2542 17.2644 19.1301 17.4594 18.9941 17.6485C18.9823 17.6642 18.9725 17.68 18.9646 17.6957C18.8306 17.8808 18.6867 18.0561 18.533 18.2216C18.5212 18.2334 18.5113 18.2432 18.5035 18.2511C18.1724 18.5997 17.8 18.9069 17.3921 19.161C17.3763 19.1728 17.3586 19.1846 17.3389 19.1965C17.1438 19.3146 16.9369 19.421 16.7241 19.5155C16.7201 19.5194 16.7142 19.5234 16.7063 19.5273C16.4896 19.6219 16.2649 19.7046 16.0324 19.7755C16.0028 19.7814 15.9674 19.7873 15.9319 19.7991C15.7427 19.8503 15.5516 19.8917 15.3585 19.9232C15.2993 19.9311 15.2402 19.9389 15.1811 19.9468C14.9506 19.9764 14.72 20 14.4835 20H5.54513C2.47107 20 0 17.5244 0 14.4815V5.52437C0 2.47563 2.47107 0 5.54513 0H14.4835C14.72 0 14.9506 0.0236333 15.1811 0.0531754C15.2402 0.0610533 15.2993 0.0689308 15.3585 0.0768087C15.5516 0.10832 15.7427 0.149681 15.9319 0.200888C15.9674 0.212705 16.0028 0.218613 16.0324 0.23043C16.2649 0.297392 16.4896 0.378139 16.7063 0.472674C16.7142 0.476613 16.7201 0.480552 16.7241 0.484491C16.9369 0.579026 17.1438 0.685375 17.3389 0.803544C17.3586 0.81536 17.3763 0.827179 17.3921 0.838996C17.8 1.09306 18.1724 1.40029 18.5035 1.74889C18.5113 1.76071 18.5212 1.77055 18.533 1.77843C18.6867 1.94781 18.8306 2.12506 18.9646 2.31019C18.9725 2.32201 18.9823 2.33579 18.9941 2.35155C19.1301 2.54062 19.2542 2.7356 19.3607 2.94239C19.3666 2.95421 19.3784 2.97193 19.3784 2.97193C15.6008 5.15805 11.1494 8.72674 7.52553 12.0827C7.34227 11.4092 6.74519 9.08715 6.74519 9.08715C6.43188 7.76366 5.13131 7.84047 3.95489 8.30724C2.78439 8.80354 2.52427 9.76662 3.02085 10.9365C3.64158 12.3722 5.0249 15.6514 5.9353 17.0044C6.24861 17.4712 6.58558 17.7312 7.02895 17.7312C7.39547 17.7312 7.73244 17.5539 7.99255 17.2939C11.51 13.6189 15.4471 9.24668 20.005 5.91433Z"
                fill="#F05325"
            />
        </svg>
    )
}
