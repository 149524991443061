import { CSSProperties } from 'react'

export const flight_UI_ACTION = 'flight_UI_Action'

export const CLEVERTAP_SCRIPT_HTTPS = 'https://d2r1yp2w7bby2u.cloudfront.net/js/a.js'

export const CLEVERTAP_SCRIPT_HTTP = 'http://static.clevertap.com/js/a.js'

export const ACTION_NAMES = {
    searchFlightHomeClicked: 'search_flight_home_clicked',
    ptcSelected: 'ptc_selected',
    hpRadioOW: 'hp_radio_ow',
    hpRadioRT: 'hp_radio_rt',
    hpReturnOnClicked: 'hp_return_on_clicked',
    ptcIconHover: 'ptc_icon_hover',
    ptcFareSelectionError: 'ptc_fare_selection_error',
    hiFiveBannerClick: 'Hi5_banner_click',
    hpWalletClick: 'hp_wallet_click',
    bannerHomepageBBD: 'banner_homepage_bbd',
    flightSearchClick: 'flight_search_click',
    wrHomepageLoad: 'wr_homepage_load',
    popularDestinationsClick: 'popular_destinations_click',
    searchesClick: 'your_searches_click',
    loginVerifyClicked: 'login_verify_clicked',
    loginMobileNoFilled: 'login_mobile_no_filled',
    loginOtpClicked: 'login_otp_clicked',
    accountMenuClicked: 'my_account_menu_clicked',
    offerKnowMore: 'offer_know_more_click',
    loginError: 'Error_Occured_Login_modal',
    loginSuccess: 'Login_succesful',
    otpFetched: 'Get_OTP_entered_on_login modal',
    mobileEntered: 'Mobile_number_entered_on_login_modal',
    invalidValue: 'Invalid_value_entered',
    loginOpened: 'Login_Signup_modal_opened',
    otpOpened: 'login_OTP_modal_opened',
    otpEntered: 'Entered_OTP_on_login_modal',

    hpPageLandGA: 'home_page_land_ga'
}

export const ACTION_ERRORS = {
    otpGenerationFailed: 'OTP generation failed',
    otpVerificationFailed: 'OTP verification failed',
    otpLimit: 'OTP limit exceeded'
}

export const RAVEN_ACTION_NAMES = {
    mobileEntered: 'phone_number_entered',
    getOtpClicked: 'get_otp_clicked',
    otpScreenOpened: 'login_otp_screen_opened',
    otpEntered: 'otp_entered',
    policyClick: 'policy_click',
    loginSuccess: 'login_successful',
    tncClick: 'tnc_click',
    incorrectOtp: 'otp_incorrect',
    resendOtp: 'resend_otp_clicked',
    loginDismiss: 'login_prompt_dismissed',
    invalidNumber: 'invalid_mobile_number_entered',
    loginModalOpened: 'login_prompt_opened'
}

export const RAVEN_PARAMS = {
    signin: 'sign_in',
    signup: 'sign_up',
    manualEntry: 'manual_entry',
    manualClick: 'manual_click',
    autoDetection: 'autodetected',
    autoClick: 'auto_click'
}

export const DEFAULT_THRESHOLD: number[] = [1]

//Case :- When we wrapped elements with div so inner styles distorted so to adjust them created this style object
//used in the baseComponent.
export const PARENT_ELEMENT_INHERIT_STYLES: CSSProperties = {
    display: 'inherit',
    height: 'inherit',
    width: 'inherit',
    position: 'inherit',
    flex: 'inherit',
    flexDirection: 'inherit',
    justifyContent: 'inherit',
    alignItems: 'inherit',
    flexBasis: 'inherit',
    alignSelf: 'inherit',
    verticalAlign: 'inherit',
    zIndex: 'inherit',
    bottom: 'inherit',
    font: 'inherit'
}
