import { DepartureTimeMapping } from '@/types/srp'

export const TRIP_TYPE = 'TRIP_TYPE'
export const DEPARTURE_CITY = 'DEPARTURE_CITY'
export const RETURN_CITY = 'RETURN_CITY'
export const ONWARD_DATE = 'ONWARD_DATE'
export const RETURN_DATE = 'RETURN_DATE'
export const TRAVELLERS = 'TRAVELLERS'
export const CALENDAR = 'CALENDAR'
export const ONEWAY_LABEL = 'One way'
export const ROUND_TRIP_LABEL = 'Round trip'
export const LOGGED_IN = 'LOGGED_IN'
export const tripTypeOptions = [
    { key: 'OW', label: ONEWAY_LABEL },
    { key: 'RT', label: ROUND_TRIP_LABEL }
]
export const ONWARD = 'ONWARD'
export const RETURN = 'RETURN'
export const DEPARTURE = 'DEPARTURE'
export const TUPPLE_BOOK_BTN = 'Book'
export const SAME_CITY_ERROR = 'Departure and Arrival airports/cities cannot be same.'

export const popularCities = [
    { k: 'BLR', v: 'Bangalore, IN - Kempegowda International Airport (BLR)', pr: '' },
    { k: 'BOM', v: 'Mumbai, IN - Chatrapati Shivaji Airport (BOM)', pr: '' },
    { k: 'DEL', v: 'New Delhi, IN - Indira Gandhi Airport (DEL)', pr: '' },
    { k: 'CCU', v: 'Kolkata, IN - Netaji Subhas Chandra Bose Airport (CCU)', pr: '' },
    { k: 'GOI', v: 'Goa, IN - Dabolim Airport (GOI)', pr: '' },
    { k: 'HYD', v: 'Hyderabad, IN - Rajiv Gandhi International (HYD)', pr: '' },
    { k: 'MAA', v: 'Chennai, IN - Chennai Airport (MAA)', pr: '' }
]

export const SEARCH_NO_FLIGHTS_AVAILABLE = 'SEARCH_NO_FLIGHTS_AVAILABLE'
export const SEARCH_FLIGHT_SERVER_ERROR = 'SEARCH_FLIGHT_SERVER_ERROR'
export const DATE_ERROR = 'DATE_ERROR'

export const DEPARTURE_TIME_MAPPING_OBJ: DepartureTimeMapping = {
    '0000': {
        label: 'Early morning',
        subLabel: 'Midnight - 8 am',
        intlLabel: '12am_8am',
        value: '0000',
        time: {
            start: 0,
            end: 800
        }
    },
    '0800': {
        label: 'Morning',
        subLabel: '8 am - Noon',
        intlLabel: '8am_12pm',
        value: '0800',
        time: {
            start: 800,
            end: 1200
        }
    },
    '1200': {
        label: 'Afternoon',
        subLabel: 'Noon - 4 pm',
        intlLabel: '12pm_4pm',
        value: '1200',
        time: {
            start: 1200,
            end: 1600
        }
    },
    '1600': {
        label: 'Evening',
        subLabel: '4 pm - 8 pm',
        intlLabel: '4pm_8pm',
        value: '1600',
        time: {
            start: 1600,
            end: 2000
        }
    },
    '2000': {
        label: 'Night',
        subLabel: '8 pm - Midnight',
        intlLabel: '8pm_12am',
        value: '2000',
        time: {
            start: 2000,
            end: 2400
        }
    }
}

export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

type MonthConfig = Record<string, string>
export const monthConfig: MonthConfig = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec'
}

export const listOfJourneyKeys: ['J1', 'J2'] = ['J1', 'J2']
export const benefitListData = [
    {
        benefitDisplayName: 'Cancellation fee',
        benefitKey: 'CANCEL'
    },
    {
        benefitDisplayName: 'Date change fee',
        benefitKey: 'AMEND'
    },
    {
        benefitDisplayName: 'Seat selection',
        benefitKey: 'SEAT'
    },
    {
        benefitDisplayName: 'Meal selection',
        benefitKey: 'MEAL'
    },
    {
        benefitDisplayName: 'Cabin bag/adult',
        benefitKey: 'HAND_BAGGAGE'
    },
    {
        benefitDisplayName: 'Check-in bag/adult',
        benefitKey: 'CHECK_IN_BAGGAGE'
    }
]

export const filterTypes = {
    STOPS: 'stops',
    AIRLINE_FILTER: 'airlineFilter',
    DEPARTURE_TIME_FROM: 'departureTimeFrom',
    NEARBY_AIRPORT: 'nearbyAirportFilter'
}
