import React, { SVGProps } from 'react'

export const RecentSearch = ({ className = '', ...rest }: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            {...rest}
        >
            <mask id="mask0_1388_40085" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1388_40085)">
                <path
                    d="M11.962 20C9.93124 20 8.16104 19.3369 6.65142 18.0106C5.28617 16.8111 4.44127 15.3304 4.11674 13.5684C4.06132 13.2675 4.30011 13 4.60607 13C4.85756 13 5.06983 13.1835 5.12253 13.4294C5.4505 14.9598 6.20757 16.2468 7.39375 17.2904C8.68927 18.4301 10.212 19 11.962 19C13.912 19 15.5662 18.3208 16.9245 16.9625C18.2829 15.6042 18.962 13.95 18.962 12C18.962 10.05 18.2829 8.39583 16.9245 7.0375C15.5662 5.67917 13.912 5 11.962 5C10.9274 5 9.95497 5.21859 9.0447 5.65578C8.13445 6.09296 7.33125 6.69488 6.6351 7.46155H8.61585C8.89199 7.46155 9.11585 7.68541 9.11585 7.96155C9.11585 8.23769 8.89199 8.46155 8.61585 8.46155H5.55541C5.22768 8.46155 4.962 8.19587 4.962 7.86814V4.8077C4.962 4.53156 5.18586 4.3077 5.462 4.3077C5.73814 4.3077 5.962 4.53156 5.962 4.8077V6.69615C6.73508 5.84743 7.64117 5.18589 8.68027 4.71152C9.71937 4.23717 10.8133 4 11.962 4C13.071 4 14.1104 4.20865 15.0803 4.62595C16.0502 5.04327 16.8976 5.61443 17.6226 6.33943C18.3476 7.06443 18.9187 7.91186 19.336 8.88172C19.7534 9.85159 19.962 10.891 19.962 12C19.962 13.109 19.7534 14.1484 19.336 15.1183C18.9187 16.0881 18.3476 16.9356 17.6226 17.6606C16.8976 18.3856 16.0502 18.9567 15.0803 19.3741C14.1104 19.7914 13.071 20 11.962 20ZM15.5197 15.5C15.3243 15.6954 15.0074 15.6954 14.812 15.5L11.5197 12.2077V7.5C11.5197 7.22386 11.7436 7 12.0197 7C12.2958 7 12.5197 7.22386 12.5197 7.5V11.7923L15.5197 14.7923C15.7151 14.9877 15.7151 15.3046 15.5197 15.5Z"
                    fill="#1A1A1A"
                />
            </g>
        </svg>
    )
}
