import { RootState } from '@/redux/store/configureStore'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: { [key: string]: string } = {}

interface NavTabsPayloadType {
    pageName: string
    key: string
}

export const navTabsStateSlice = createSlice({
    name: 'navtabsState',
    initialState,
    reducers: {
        setSelectedNavTab: (state, action: PayloadAction<NavTabsPayloadType>) => {
            const { payload: { key = '', pageName = '' } = {} } = action || {}

            state[pageName] = key
        }
    }
})

//Only want to get specific page name state so used currying approach.
const getSelectedNavTab = (pageName: string) => (state: RootState) => state.navtabsState[pageName] || ''

const { reducer, actions } = navTabsStateSlice
const { setSelectedNavTab } = actions || {}

export { setSelectedNavTab, getSelectedNavTab }

export default reducer
