import React from 'react'

export const ErrorInfo: React.FC<React.SVGProps<SVGSVGElement>> = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 56 56" {...props}>
            <mask
                id="mask0_9554_48573"
                style={{ maskType: 'alpha' }}
                width="56"
                height="56"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H56V56H0z"></path>
            </mask>
            <g mask="url(#mask0_9554_48573)">
                <path
                    fill="#DF9641"
                    d="M28 38.41a1.39 1.39 0 001.023-.412 1.39 1.39 0 00.413-1.024 1.39 1.39 0 00-.413-1.023A1.39 1.39 0 0028 35.538a1.39 1.39 0 00-1.023.413 1.39 1.39 0 00-.413 1.023c0 .407.138.748.413 1.024A1.39 1.39 0 0028 38.41zm.002-7.718c.33 0 .608-.111.83-.335.223-.224.335-.5.335-.831V17.859c0-.33-.112-.608-.336-.831a1.131 1.131 0 00-.833-.336c-.33 0-.608.112-.83.336-.223.223-.335.5-.335.831v11.667c0 .33.112.607.336.83.224.224.502.336.833.336zM28.008 49c-2.904 0-5.634-.551-8.19-1.653-2.557-1.102-4.781-2.598-6.672-4.487-1.891-1.89-3.388-4.111-4.491-6.665C7.552 33.64 7 30.912 7 28.008c0-2.904.551-5.634 1.653-8.19 1.102-2.557 2.598-4.781 4.487-6.672 1.89-1.891 4.111-3.388 6.665-4.491C22.36 7.552 25.088 7 27.992 7c2.904 0 5.634.551 8.19 1.653 2.557 1.102 4.781 2.598 6.672 4.487 1.891 1.89 3.388 4.111 4.491 6.665C48.448 22.36 49 25.088 49 27.992c0 2.904-.551 5.634-1.653 8.19-1.102 2.557-2.598 4.781-4.487 6.672-1.89 1.891-4.111 3.388-6.665 4.491C33.64 48.448 30.912 49 28.008 49z"
                ></path>
            </g>
        </svg>
    )
}
