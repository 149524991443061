import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store/configureStore'
import { IModifyComponentState, IModifySearch } from './type'
import { getDefaultRoomState } from '@/utils/pages/common/modifySearch'
import { IRoomTraveller } from '@/components/common/HeaderWithModifySearch/type'
import { OPEN_EDIT_MODIFY_SECTION } from '@/constants/components'

export const MODIFY_DEFAULT_REDUX_STATE: IModifySearch = {
    data: {
        location: {
            text: '',
            isflightSearch: false,
            placeDetails: null
        },
        searchedDate: {
            checkInDate: null,
            checkOutDate: null,
            checkInDateText: '',
            checkOutDateText: ''
        },
        paxDetails: {
            text: '',
            roomDetails: getDefaultRoomState(true) as IRoomTraveller[] //set alis as we are aware it will always be a IRoomTraveller[]
        }
    },
    modifyComponentState: {
        show: false,
        hasError: false
    }
}

export const modifySlice = createSlice({
    name: 'modifySearch',
    initialState: MODIFY_DEFAULT_REDUX_STATE,
    reducers: {
        setModifySearchData: (state, action: PayloadAction<IModifySearch>) => {
            const { payload } = action || {}
            state.data = payload.data
        },
        toogleModifySearchComponent: (state, action: PayloadAction<IModifyComponentState>) => {
            // @ts-ignore
            state.modifyComponentState = {
                ...state.modifyComponentState,
                ...action.payload
            }
        },
        toggleModifySearchSection: (state, action: PayloadAction<OPEN_EDIT_MODIFY_SECTION | null>) => {
            state.modifyComponentState.openedSection = {
                ...state.modifyComponentState.openedSection,
                section: action.payload || null
            }
        },
        toggleModifySearchError: (state, action: PayloadAction<boolean>) => {
            state.modifyComponentState.hasError = action.payload
        }
    }
})

const getModifyState = (state: RootState) => state.modifySearch.data
const getModifyComponentState = (state: RootState) => state.modifySearch.modifyComponentState

const { reducer, actions } = modifySlice
const { setModifySearchData, toogleModifySearchComponent, toggleModifySearchError, toggleModifySearchSection } =
    actions || {}

export {
    setModifySearchData,
    getModifyState,
    getModifyComponentState,
    toogleModifySearchComponent,
    toggleModifySearchError,
    toggleModifySearchSection
}

export default reducer
