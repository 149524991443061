import React, { PropsWithChildren, useEffect, useId, useRef, useState } from 'react'
import { Container } from '@cleartrip/ct-design-components'
import { BaseComponentProps } from '../type'
import { getModifyComponentState } from '@/redux/slices/modifySearch'
import { useAppSelector } from '@/redux/store/hooks'
import { getDropDownState } from '@/redux/slices/dropdown'

function FixedTopComponentHOC({ children, ownProps, data, pageName }: PropsWithChildren<BaseComponentProps>) {
    const { headerSlotClassName = '' } = ownProps || {}
    const id = useId()
    // Toggle fixed and sticky style based on this.
    const [isElementFixed, setElementFixed] = useState(false)

    // Top value after sticky
    const [fixedTopValue, setFixedTopValue] = useState(0)

    // Total distance from top i.e parent start point to the element
    const distanceFromTopRef = useRef(0)
    // Parent Container height
    const parentHeightRef = useRef(0)
    const containerHeight = useRef(0)

    const elementIdPrefix = `${pageName}-fixed-top`
    // Adding id so that each element is unique
    const elementId = `${elementIdPrefix}-${id}`
    const { parentContainerId = 'header-container' } = ownProps || {}

    const { show: isModifySearchOpen } = useAppSelector(getModifyComponentState) || {}
    const { dropdownId, isOpen } = useAppSelector(getDropDownState)

    useEffect(() => {
        let fixedHeightFromTop = 0
        let totalHeightFromTop = 0

        // Added to handle the case of merch srp
        // Height of child element was coming 0.
        // Reduced the priority of the following code  to ensure element is mounted
        setTimeout(() => {
            // Calculate height from top check elements with fixed key and then calculate above element height
            const parentElement = document.getElementById(parentContainerId as string)
            if (parentElement) {
                const parentElementHeight = parentElement.clientHeight || 0
                parentHeightRef.current = parentElementHeight
                const childrenCount = parentElement.childElementCount
                for (let i = 0; i < childrenCount; i++) {
                    const childElement = parentElement.children.item(i)
                    const childElementId = childElement?.id || ''
                    const childElementHeight = childElement?.getBoundingClientRect()?.height || 0

                    if (i === 0 && childElementId === elementId) {
                        containerHeight.current = childElementHeight
                        totalHeightFromTop = childElementHeight
                        break
                    }

                    if (childElementId === elementId) {
                        containerHeight.current = childElementHeight
                        break
                    }

                    totalHeightFromTop += childElementHeight

                    if (childElement?.id.includes(elementIdPrefix)) {
                        fixedHeightFromTop += childElementHeight
                    }
                }
            }
            // Total distance from top
            distanceFromTopRef.current = totalHeightFromTop
            /**
             * Total fixed height is sum of all elements with fixed position before current element.
             */
            fixedHeightFromTop && setFixedTopValue(fixedHeightFromTop)
        }, 0)
    }, [children])

    useEffect(() => {
        const handleWindowScroll = (_e: Event) => {
            if (window.pageYOffset >= distanceFromTopRef.current) {
                setElementFixed(true)
            } else {
                setElementFixed(false)
            }
        }

        window.addEventListener('scroll', handleWindowScroll)
        return () => {
            window.removeEventListener('scroll', handleWindowScroll)
        }
    }, [])

    const componentType = data?.type || ''

    return (
        <>
            <Container
                id={elementId}
                className={`${isElementFixed ? 'p-fixed w-100vw l-0' : 'p-sticky'} bg-white`}
                css={{
                    top: fixedTopValue,
                    zIndex:
                        componentType === 'ADJACENT' &&
                        (isModifySearchOpen ||
                            (['USER_ACCOUNT_DROPDOWN', 'navigationChip'].includes(dropdownId) && isOpen))
                            ? 2
                            : 12,
                    animation: isElementFixed && fixedTopValue > 0 ? 'slideInDown 0.2s cubic-bezier(0.4, 0, 1, 1);' : ''
                }}
            >
                <Container className={`${isElementFixed ? headerSlotClassName : ''}`}>{children}</Container>
            </Container>
            {isElementFixed && <Container height={`${containerHeight.current}px`}></Container>}
        </>
    )
}

export default FixedTopComponentHOC
