import { useEffect, useMemo, useState } from 'react'

export type useResizeObservableRect = Pick<
    DOMRectReadOnly,
    'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'
>
export type useResizeObservableRef<E extends Element = Element> = (element: E) => void

type Props = {
    callback?: (rect: DOMRectReadOnly) => void
}

export function useResizeObservable<E extends Element = Element>({ callback }: Props) {
    const [element, ref] = useState<E | null>(null)

    const observer = useMemo(
        () =>
            new ResizeObserver(entries => {
                if (entries[0]) {
                    const rect = entries[0].contentRect
                    callback && callback(rect)
                }
            }),
        [callback]
    )

    useEffect(() => {
        if (!element) return
        observer.observe(element, { box: 'border-box' })
        return () => {
            observer.disconnect()
        }
    }, [element, observer])

    return {
        ref
    }
}
