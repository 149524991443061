import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store/configureStore'
import { IOwnProps, IPageResponse } from '@/types'

export interface ModalPayload {
    data: IPageResponse
    ownProps?: IOwnProps
}

export interface ModalsData {
    data: Array<ModalPayload>
}

const initialState: ModalsData = {
    data: []
}

export const ModalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        openModal: (state, action: PayloadAction<ModalPayload>) => {
            state.data.push(action.payload)
        },
        closeModal: state => {
            state.data.pop()
        },
        clearModal: state => {
            state.data = []
        }
    }
})

export const { openModal, closeModal, clearModal } = ModalsSlice.actions
export const getModals = (state: RootState): Array<ModalPayload> => state.modals.data
const ModalsReducer = ModalsSlice.reducer
export default ModalsReducer
