import { fireLogger } from '@/utils/general/logger'
import { Component, ErrorInfo } from 'react'

import { IErrorBoundaryProps, IErrorState } from './type'

/**
 * This will be used for client side error.
 */

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorState> {
    public state: IErrorState = {
        hasError: false
    }

    public static getDerivedStateFromError(_: Error): IErrorState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        //TODO: Log the client side error here.

        fireLogger('error Caught by errorBoundary ', {
            message: `Uncaught Javascript error error: ${errorInfo.componentStack}`,
            info: `${error}`
        })
    }

    public render() {
        if (this.state.hasError) {
            return this.props.fallback
        }

        return this.props.children
    }
}

export default ErrorBoundary
