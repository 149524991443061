import querystring from 'querystring'
import { isEmpty } from '@/utils/general/browserHelper'
import { deleteCookie, getCookie, setCookie } from '@/utils/general/cookie'

export const fromQueryString = (
    queryString: string,
    sep: string = '&',
    eq: string = '='
): querystring.ParsedUrlQuery => {
    return querystring.parse(queryString, sep, eq)
}

export const paidAffiliateTracker = (): void => {
    let searchString = window.location.search.split('?')
    let params = fromQueryString(searchString[1] ? searchString[1] : '')

    let affiliateutmExpiryDays = 1
    let _lastStepAttribution = true

    if (!isEmpty(params)) {
        let utmSource = (params['tduid'] as string) ?? (params['utm_source'] as string)
        let utmCampaign = params['utm_campaign'] as string
        let utmMedium = params['utm_medium'] as string
        let utmTerm = params['utm_term'] as string
        let utmUid = params['uid'] as string

        const storeAffiliateInformation = (): void => {
            if (utmSource) {
                setCookie({
                    name: 'source_affiliatefirstsource',
                    value: utmSource,
                    expiryInDay: affiliateutmExpiryDays
                })
                // Hard coded as requirement comes from marketing to add one cookie for 30 days -
                // CON-123 - Cookie settings - Meta channels
                setCookie({ name: 'source_Meta', value: utmSource, expiryInDay: 30 })
            }
            if (utmCampaign)
                setCookie({
                    name: 'campaign_affiliatefirstsource',
                    value: utmCampaign,
                    expiryInDay: affiliateutmExpiryDays
                })
            if (utmMedium)
                setCookie({
                    name: 'medium_affiliatefirstsource',
                    value: utmMedium,
                    expiryInDay: affiliateutmExpiryDays
                })
            if (utmTerm)
                setCookie({ name: 'term_affiliatefirstsource', value: utmTerm, expiryInDay: affiliateutmExpiryDays })
            if (utmUid)
                setCookie({ name: 'uid_affiliatefirstsource', value: utmUid, expiryInDay: affiliateutmExpiryDays })
        }

        // Extract expiryDate of cookie
        let affiliateutmExpiryDate = getCookie('affiliate_expdate')
        if (!affiliateutmExpiryDate) {
            storeAffiliateInformation()
            setCookie({
                name: 'affiliate_expdate',
                value: new Date().toDateString(),
                expiryInDay: affiliateutmExpiryDays
            })
        } else {
            if (_lastStepAttribution) {
                storeAffiliateInformation()
            }
        }
    }
}
export const paidChannelTracker = (): void => {
    let searchString = window.location.search.split('?')
    let urlParams = fromQueryString(searchString[1] ? searchString[1] : '')

    if (!isEmpty(urlParams)) {
        let utmSource = urlParams['utm_source'] as string
        let utmCampaign = urlParams['utm_campaign'] as string
        let utmMedium = urlParams['utm_medium'] as string
        let utmContent = urlParams['utm_content'] as string
        let utmTerm = urlParams['utm_term'] as string
        let utmUid = urlParams['utm_uid'] as string
        let cookiesArray = [
            'source_firstpaidsource',
            'medium_firstpaidsource',
            'campaign_firstpaidsource',
            'content_firstpaidsource',
            'term_firstpaidsource',
            'uid_firstpaidsource',
            'lastchannel'
        ]

        if (utmSource) {
            setCookie({ name: 'source_firstsource', value: utmSource, expiryInDay: 30 })
        }
        if (utmCampaign) {
            setCookie({ name: 'campaign_firstsource', value: utmCampaign, expiryInDay: 30 })
        }
        if (utmMedium) {
            setCookie({ name: 'medium_firstsource', value: utmMedium, expiryInDay: 30 })
        }
        if (utmContent) {
            setCookie({ name: 'content_firstsource', value: utmContent, expiryInDay: 30 })
        }
        if (utmTerm) {
            setCookie({ name: 'term_firstsource', value: utmTerm, expiryInDay: 30 })
        }
        if (utmUid) {
            setCookie({ name: 'uid_firstsource', value: utmUid, expiryInDay: 30 })
        }

        if (utmSource || utmCampaign || utmMedium || utmContent || utmTerm || utmUid) {
            for (let i = 0; i < cookiesArray.length; i++) {
                deleteCookie(cookiesArray[i])
            }
        }

        if (utmSource) {
            setCookie({ name: 'source_firstpaidsource', value: utmSource, expiryInDay: 1 })
        }
        if (utmMedium) {
            setCookie({ name: 'medium_firstpaidsource', value: utmMedium, expiryInDay: 1 })
        }
        if (utmCampaign) {
            setCookie({ name: 'campaign_firstpaidsource', value: utmCampaign, expiryInDay: 1 })
        }
        if (utmContent) {
            setCookie({ name: 'content_firstpaidsource', value: utmContent, expiryInDay: 1 })
        }
        if (utmTerm) {
            setCookie({ name: 'term_firstpaidsource', value: utmTerm, expiryInDay: 1 })
        }
        if (utmUid) {
            setCookie({ name: 'uid_firstpaidsource', value: utmUid, expiryInDay: 1 })
        }

        setCookie({ name: 'noncleartrip', value: 'true', expiryInDay: 1 })
    } else {
        setCookie({ name: 'noncleartrip', value: 'false', expiryInDay: 1 })
    }
}
