import React from 'react'

// Filled star
export const Star: React.FC<React.SVGProps<SVGSVGElement>> = props => {
    const { width = 10, height = 10, color = '#000000', ...rest } = props || {}
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            color={color}
            {...rest}
        >
            <path
                d="M7.99998 1.33301L10.06 5.50634L14.6666 6.17968L11.3333 9.42634L12.12 14.013L7.99998 11.8463L3.87998 14.013L4.66665 9.42634L1.33331 6.17968L5.93998 5.50634L7.99998 1.33301Z"
                fill={color}
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export const StarOutline = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask id="mask0_4837_11742" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                <rect width="16" height="16" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4837_11742)">
                <path
                    d="M4.72222 13.9104C4.55541 14.0258 4.3886 14.0297 4.2218 13.9223C4.05499 13.8156 4.00494 13.6633 4.07167 13.4657L5.19328 9.78092C5.2557 9.57585 5.18046 9.35381 5.00624 9.22893L2.19508 7.21391C2.01159 7.08212 1.95754 6.92134 2.03294 6.73156C2.10767 6.54244 2.23678 6.44788 2.42027 6.44788H5.95418C6.17374 6.44788 6.3676 6.30466 6.43211 6.09479L7.59966 2.29653C7.63302 2.19768 7.68707 2.12355 7.7618 2.07413C7.8372 2.02471 7.9166 2 8 2C8.08341 2 8.16281 2.02471 8.2382 2.07413C8.31293 2.12355 8.36698 2.19768 8.40034 2.29653L9.56789 6.09479C9.6324 6.30466 9.82626 6.44788 10.0458 6.44788H13.5797C13.7632 6.44788 13.8923 6.54244 13.9671 6.73156C14.0425 6.92134 13.9884 7.08212 13.8049 7.21391L10.9938 9.22893C10.8195 9.35381 10.7443 9.57585 10.8067 9.78091L11.9283 13.4657C11.9951 13.6633 11.945 13.8156 11.7782 13.9223C11.6114 14.0297 11.4446 14.0258 11.2778 13.9104L8.29906 11.6873C8.12167 11.5549 7.87833 11.5549 7.70094 11.6873L4.72222 13.9104ZM5.68254 11.7437C5.6555 11.836 5.76164 11.9095 5.83855 11.8518L7.69975 10.4541C7.87763 10.3205 8.12237 10.3205 8.30025 10.4541L10.1614 11.8518C10.2384 11.9095 10.3445 11.836 10.3175 11.7437L9.62566 9.38175C9.56611 9.17841 9.64118 8.95962 9.81301 8.83568L11.5021 7.61741C11.5808 7.56063 11.5406 7.4363 11.4436 7.4363H9.31917C9.10011 7.4363 8.90656 7.2937 8.84164 7.08448L8.09551 4.67999C8.0664 4.58617 7.93361 4.58617 7.90449 4.67999L7.15837 7.08448C7.09344 7.2937 6.89989 7.4363 6.68083 7.4363H4.55641C4.45936 7.4363 4.4192 7.56064 4.49791 7.61741L6.18699 8.83568C6.35883 8.95962 6.43389 9.17841 6.37434 9.38175L5.68254 11.7437Z"
                    fill="#1A1A1A"
                />
            </g>
        </svg>
    )
}

export const CircularStarOutlined = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="8" cy="8" r="7.1" stroke="#1A1A1A" stroke-width="0.8" />
            <path
                d="M6.54626 10.6369C6.49483 10.6695 6.42957 10.6239 6.44262 10.5644L6.80371 8.91793C6.81656 8.85936 6.79731 8.79837 6.75317 8.75778L5.53079 7.63381C5.48709 7.59363 5.51168 7.52069 5.57077 7.51515L7.16572 7.36572C7.22822 7.35986 7.28228 7.31975 7.30601 7.26164L7.93616 5.71834C7.95939 5.66144 8.04001 5.66154 8.0631 5.71851L8.69403 7.27518C8.71767 7.3335 8.77183 7.37379 8.83449 7.37966L10.4274 7.5289C10.4866 7.53445 10.5111 7.60773 10.4671 7.64779L9.24747 8.75767C9.20296 8.79818 9.18343 8.85936 9.19624 8.91817L9.55739 10.5764C9.57038 10.636 9.50474 10.6816 9.45338 10.6486L8.09073 9.77238C8.03551 9.73688 7.96471 9.73667 7.90928 9.77184L6.54626 10.6369ZM5.63153 11.2922C5.56475 11.5931 5.89497 11.8248 6.15523 11.6595L7.96327 10.5115C7.98569 10.4972 8.01431 10.4972 8.03673 10.5115L9.84477 11.6595C10.105 11.8248 10.4352 11.5931 10.3685 11.2922L9.8815 9.09716C9.87618 9.07319 9.88409 9.04821 9.90224 9.03168L11.5367 7.54209C11.7592 7.33927 11.6348 6.96866 11.3349 6.94128L9.2023 6.74661C9.17673 6.74427 9.1546 6.72783 9.14499 6.70401L8.32114 4.66226C8.20455 4.3733 7.79546 4.3733 7.67886 4.66226L6.85501 6.70401C6.8454 6.72783 6.82327 6.74427 6.7977 6.74661L4.66509 6.94128C4.36523 6.96866 4.24076 7.33927 4.46331 7.54209L6.09776 9.03168C6.11591 9.04821 6.12382 9.07319 6.1185 9.09716L5.63153 11.2922Z"
                fill="#1A1A1A"
                stroke="#1A1A1A"
                stroke-width="0.1"
            />
        </svg>
    )
}
