import dayjs, { Dayjs } from 'dayjs'
import { CommonDateType } from '@/types/date'

import customParseFormat from 'dayjs/plugin/customParseFormat'
import { isEmpty } from './browserHelper'
import { monthConfig } from '@/constants/Srp'

dayjs.extend(customParseFormat)

/**
 *
 * @param date
 * @param outputFormat
 * @param inputFormat
 * @returns date or invalid date
 */

export const dateToEpoch = (dateString: string) => {
    let separator = '-'
    if (dateString.includes('/')) {
        separator = '/'
    }
    const [day, month, year] = dateString.split(separator)
    const dateObject = new Date(`${year}-${month}-${day}`)
    const epochTime = dateObject.getTime() - 5.5 * 60 * 60 * 1000
    return epochTime
}

export const getNumberOfDaysBetweenRange = (dateRange: string): number => {
    const [startDateStr, endDateStr] = dateRange.split('_')
    const [day1, month1, year1] = startDateStr.split('-').map(Number)
    const [day2, month2, year2] = endDateStr.split('-').map(Number)
    const startDate: Date = new Date(year1, month1 - 1, day1)
    const endDate: Date = new Date(year2, month2 - 1, day2)
    const differenceInMilliseconds: number = Math.abs(endDate.getTime() - startDate.getTime())
    const oneDayInMilliseconds: number = 24 * 60 * 60 * 1000
    const numberOfDays: number = Math.floor(differenceInMilliseconds / oneDayInMilliseconds)
    return numberOfDays + 1
}

export const formatDateStandardized = (inputDate?: string) => {
    if (!inputDate) return 'N/A'

    const dd_mm_yyyy_regex = /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/
    const yyyy_mm_dd_regex = /^(\d{4})[-\/](\d{1,2})[-\/](\d{1,2})$/

    let match

    match = inputDate.match(dd_mm_yyyy_regex)
    if (match) {
        return `${match[1].padStart(2, '0')}/${match[2].padStart(2, '0')}/${match[3]}`
    }

    match = inputDate.match(yyyy_mm_dd_regex)
    if (match) {
        return `${match[3].padStart(2, '0')}/${match[2].padStart(2, '0')}/${match[1]}`
    }

    return inputDate
}

export function getFormattedDate(date?: CommonDateType, outputFormat?: string, inputFormat?: string) {
    let newDate = 'invalid date'
    try {
        newDate = date
            ? dayjs(date, inputFormat).format(outputFormat ?? 'YYYY-MM-DD')
            : dayjs().format(outputFormat ?? 'YYYY-MM-DD')
    } catch (e) {
        newDate = 'invalid date'
    }
    return newDate
}

export function dayJsDiff(date1: Dayjs, date2: Dayjs, diffType: 'month' | 'year' | 'day' = 'year') {
    const dDate1 = dayjs(date1)
    const dDate2 = dayjs(date2)
    const diff = dDate2.diff(dDate1, diffType, true)
    return diff
}

export function dateDiff(date1: CommonDateType, date2: CommonDateType, format = 'DD-MM-YYYY') {
    const dDate1 = dayjs(date1, format)
    const dDate2 = dayjs(date2, format)
    return dDate2.diff(dDate1, 'day')
}
export function dayDiff(days: CommonDateType, to: CommonDateType) {
    const dDate = dayjs(getFormattedDate(days, 'DD-MM-YYYY'), 'DD-MM-YYYY')
    const toDate = dayjs(getFormattedDate(to, 'DD-MM-YYYY'), 'DD-MM-YYYY')
    const diffDate = dDate.diff(toDate, 'day')
    return diffDate
}
export function getMonthRange(month: string, direction: 'left' | 'right', monthCount: number = 3): string {
    let prevMonth: string
    let nextMonth: string

    const parseMonth = dayjs(getFormattedDate(month, 'DD-MM-YYYY'), 'DD-MM-YYYY')
    const today = dayjs()
    const diffMonth = parseMonth.diff(today, 'month')

    if (direction === 'right') {
        prevMonth = dayjs(parseMonth).add(2, 'month').format('DD-MM-YYYY')
        nextMonth = dayjs(parseMonth.add(monthCount, 'month')).endOf('month').format('DD-MM-YYYY')
    } else {
        prevMonth = !diffMonth ? today.format('DD-MM-YYYY') : parseMonth.format('DD-MM-YYYY')
        nextMonth = parseMonth.add(1, 'month').endOf('month').format('DD-MM-YYYY')
    }

    return `${prevMonth}_${nextMonth}`
}

export function formatDateToString(date: Date, formatOptions?: Intl.DateTimeFormatOptions) {
    /**
     * TODO: @Sidhant
     * check the implementation of localeOption
     * need to revisit
     */
    const dateformatOptions: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        ...formatOptions
    }
    const localeOption = 'en-US'
    // if (getCookie('lp') === 'ar' && appPrefix === '/ar') {
    //   localeOption = 'ar'
    // }
    if (date) {
        return date.toLocaleDateString(localeOption, dateformatOptions)
    }
}

/**
 * @param : date
 * @return:  string in format of 14 Feb’23
 */
export const getDateInApostropheYear = (date: Date): string => {
    const dateInString = formatDateToString(date, { day: '2-digit', year: '2-digit', weekday: undefined })
    const dateArr = dateInString?.replace(',', '')?.split(' ') || []

    return `${dateArr[1]} ${dateArr[0]}'${dateArr[2]}`
}

/**
 * @param : date :  14 Feb’23 format
 * @return:  convert in normal date format and return.
 */
export const getDateFromApostropheYear = (date: string): Date => {
    const splitedDate = date?.split(' ')

    const day = splitedDate?.[0]
    const [month, year] = (splitedDate?.[1] || '').split("'")
    return new Date(`${month} ${day}, ${year}`)
}

/**
 *
 * @param time : 1400
 * @returns : 02:00 PM
 */
export function convert24HrTo12Hr(time: string) {
    return dayjs(time, 'HHmm').format('hh:mm A')
}

export const isLastDayOfMonth = (date: Date | null) => {
    if (!date) return true
    const day = date.getDate()
    const nextDay = new Date(date)
    nextDay.setDate(day + 1)
    return nextDay.getMonth() !== date.getMonth()
}

export const addDays = (date: Date, noOfDays: number) => {
    return dayjs(date).add(noOfDays, 'day').toDate()
}

export function getInitialMonthRange(departDate: string) {
    const departureDate = dayjs(departDate, 'DD-MM-YYYY')
    const today = dayjs()
    const diffMonth = departureDate.diff(today, 'month')
    const sub = !diffMonth ? 0 : 1
    const add = 1
    const prevMonth = !diffMonth
        ? dayjs(dayjs(today, 'DD-MM-YYYY')).subtract(sub, 'month').format('DD-MM-YYYY')
        : dayjs(dayjs(departureDate, 'DD-MM-YYYY')).subtract(sub, 'month').format('DD-MM-YYYY')
    const nextMonth = dayjs(dayjs(departureDate, 'DD-MM-YYYY').add(add, 'month')).endOf('month').format('DD-MM-YYYY')
    const defaultDateFormat = `${prevMonth}_${nextMonth}`
    return defaultDateFormat
}

export const getFormattedDateCfw = (dateString: string) => {
    if (isEmpty(dateString)) {
        return ''
    }
    let [date, month] = dateString.split('/')
    month = monthConfig[month]
    const dateToDisplay = `${date} ${month}`
    return dateToDisplay
}
export const secondsToDateString = (ms)=> {
    try {
        if (!ms || isNaN(Number(ms))) {
            return '';
        }
  
        const date = new Date(ms * 1000);
        const year = date.getFullYear();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
  
        return `${year}-${month}-${day}`;
    } catch (_e) {
        return '';
    }
  };
  export const getDateInYearMonthDayFormat = () => {
    try {
        const date = new Date()
        const year = date.getFullYear()
        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0')
        return `${year}-${month}-${day}`
    } catch (_e) {
        return ''
    }
  }