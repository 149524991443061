import React, { useEffect, PropsWithChildren, forwardRef } from 'react'

import { useForwardRef } from '@/utils/hooks/useForwardRef'

import { IntersectionObserverWrapperData, MetaData } from './type'

// @TODO @ Resolves type issue
const IntersectionObserverWrapper = forwardRef<HTMLDivElement, PropsWithChildren<IntersectionObserverWrapperData>>(
    ({ children, options, metaData = {}, onIntersectionObserverCallback }, ref) => {
        const elementRef = useForwardRef<HTMLDivElement>(ref)

        const { key, pageName, classname, css = {} } = metaData as MetaData
        const sheetHeaderSize = '152px'

        useEffect(() => {
            const observer = new IntersectionObserver(onIntersectionObserverCallback, options)

            if (elementRef?.current) {
                observer.observe(elementRef.current)
            }

            return () => {
                if (elementRef?.current) {
                    observer.unobserve(elementRef.current)
                }
            }
        }, [onIntersectionObserverCallback, elementRef, options])

        return (
            <div
                id={key && pageName ? `${pageName}_${key}` : undefined}
                ref={elementRef}
                style={{
                    scrollMarginTop: sheetHeaderSize,
                    ...css
                }}
                className={classname}
            >
                {children}
            </div>
        )
    }
)

IntersectionObserverWrapper.displayName = 'IntersectionObserverWrapper'

export default IntersectionObserverWrapper
