import { IPageResponse, ISlot } from '@/types'
import {
    IPartialSlotUpdateKeysDataMap,
    ISlotUpdationPageName,
    IUpdateSlotData,
    IUpdateSlotsData,
    SlotOperation
} from '@/types/widget/slots'

const updateData = (currentSlotsData: ISlot[], keysToUpdateMapping: IUpdateSlotData) => {
    currentSlotsData.forEach((slot: ISlot) => {
        // @ts-ignore
        const slotKey = slot.slotData.data.key
        if (slotKey) {
            const isSlotKeyPresentInKeysToUpdate = !!keysToUpdateMapping[slotKey]

            if (isSlotKeyPresentInKeysToUpdate) {
                const updatedSlotInfo = keysToUpdateMapping[slotKey]
                const { operation, data: newSlotData } = updatedSlotInfo

                if (operation === SlotOperation.UPDATE) {
                    // @ts-ignore
                    slot.slotData = newSlotData
                }
            }
        }
    })
}

export const updateSlotsData = (
    currentPageData: Record<ISlotUpdationPageName, IPageResponse>,
    updatedPageData: IUpdateSlotsData
) => {
    const updatedObj = { ...currentPageData }

    let keysToUpdateMapping: IPartialSlotUpdateKeysDataMap = {}
    Object.keys(updatedObj).forEach(pageName => {
        // @ts-ignore
        keysToUpdateMapping = {
            ...keysToUpdateMapping,
            [pageName]: {
                pageDataToUpdate: {
                    pageHeaderSlots: {},
                    pageActions: [],
                    pageFooterSlots: {},
                    ravenTracking: {}
                },
                slotsToUpdate: {}
            }
        }
    })
    // @ts-ignore
    Object.keys(updatedPageData).forEach((pageName: ISlotUpdationPageName) => {
        const pageHeaderSlotsToUpdate = updatedPageData[pageName]?.updatedPageData?.pageHeaderSlots
        const pageFooterSlotsToUpdate = updatedPageData[pageName]?.updatedPageData?.pageFooterSlots
        const slotsToUpdate = updatedPageData[pageName]?.updatedSlotData

        // Need a way to refacto
        pageHeaderSlotsToUpdate &&
            Object.keys(pageHeaderSlotsToUpdate).forEach(slotKey => {
                // @ts-ignore
                keysToUpdateMapping[pageName].pageDataToUpdate.pageHeaderSlots = {
                    ...keysToUpdateMapping[pageName].pageDataToUpdate.pageHeaderSlots,
                    [slotKey]: {
                        operation: pageHeaderSlotsToUpdate[slotKey].operation,
                        data: pageHeaderSlotsToUpdate[slotKey].data.slotData
                    }
                }
            })

        pageFooterSlotsToUpdate &&
            Object.keys(pageFooterSlotsToUpdate).forEach(slotKey => {
                // @ts-ignore
                keysToUpdateMapping[pageName].pageDataToUpdate.pageFooterSlots = {
                    ...keysToUpdateMapping[pageName].pageDataToUpdate.pageFooterSlots,
                    [slotKey]: {
                        operation: pageFooterSlotsToUpdate[slotKey].operation,
                        data: pageFooterSlotsToUpdate[slotKey].data.slotData
                    }
                }
            })

        slotsToUpdate &&
            Object.keys(slotsToUpdate).forEach(slotKey => {
                // @ts-ignore
                keysToUpdateMapping[pageName].slotsToUpdate = {
                    ...keysToUpdateMapping[pageName].slotsToUpdate,
                    [slotKey]: {
                        operation: slotsToUpdate[slotKey].operation,
                        data: slotsToUpdate[slotKey].data.slotData
                    }
                }
            })
    })
    // @ts-ignore
    Object.keys(updatedObj).forEach((pageName: ISlotUpdationPageName) => {
        const currentPageHeaderSlotsData = updatedObj[pageName]?.pageData?.pageHeaderSlots
        const currentPageFooterSlotsData = updatedObj[pageName]?.pageData?.pageFooterSlots
        const currentSlotsData = updatedObj[pageName]?.slotsData
        const pageUpdatedKeyToUpdateMapping = keysToUpdateMapping[pageName]

        currentPageHeaderSlotsData &&
            updateData(currentPageHeaderSlotsData, pageUpdatedKeyToUpdateMapping.pageDataToUpdate.pageHeaderSlots)
        currentPageFooterSlotsData &&
            updateData(currentPageFooterSlotsData, pageUpdatedKeyToUpdateMapping.pageDataToUpdate.pageFooterSlots)
        currentSlotsData && updateData(currentSlotsData, pageUpdatedKeyToUpdateMapping.slotsToUpdate)

        updatedObj[pageName].pageData.pageActions = updatedPageData[pageName]?.updatedPageData?.pageActions
        updatedObj[pageName].pageData.ravenTracking = updatedPageData[pageName]?.updatedPageData?.ravenTracking
        updatedObj[pageName].pageData.tracking = updatedPageData[pageName]?.updatedPageData?.tracking
    })
    return updatedObj
}
