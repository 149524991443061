import React, { SVGProps } from 'react'

const FilterSort: React.FC = () => {
    return (
        <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="a"
                style={{
                    maskType: 'alpha'
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={16}
                height={16}
            >
                <path fill="#D9D9D9" d="M0 0h16v16H0z" />
            </mask>
            <g mask="url(#a)">
                <path
                    d="M7.85 12.667a.499.499 0 0 1-.367-.15.499.499 0 0 1-.15-.367V8.433l-3.6-4.55c-.089-.122-.1-.244-.033-.366a.335.335 0 0 1 .317-.184h7.966c.145 0 .25.061.317.184.067.122.055.244-.033.366l-3.6 4.55v3.717c0 .144-.05.267-.15.367-.1.1-.223.15-.367.15h-.3ZM8 8.2 11.3 4H4.7L8 8.2Z"
                    fill="#1A1A1A"
                />
            </g>
        </svg>
    )
}

const Locality: React.FC = ({ height = 16, width = 16, ...rest }: SVGProps<SVGSVGElement>) => {
    return (
        <svg {...rest} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.8 6.91C12.8 10.726 8 14 8 14s-4.8-3.273-4.8-7.09c0-1.303.506-2.552 1.406-3.472A4.747 4.747 0 0 1 8 2c1.273 0 2.494.517 3.394 1.438.9.92 1.406 2.17 1.406 3.471Z"
                stroke="#1A1A1A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 8.6A1.8 1.8 0 1 0 8 5a1.8 1.8 0 0 0 0 3.6Z"
                stroke="#1A1A1A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

const PriceOffers: React.FC = () => {
    return (
        <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.345 2.569a1 1 0 0 1 1.31 0l.282.244a1 1 0 0 0 .845.226l.366-.07a1 1 0 0 1 1.135.655l.122.352a1 1 0 0 0 .619.619l.352.122a1 1 0 0 1 .655 1.135l-.07.366a1 1 0 0 0 .226.845l.244.282a1 1 0 0 1 0 1.31l-.244.282a1 1 0 0 0-.226.845l.07.366a1 1 0 0 1-.655 1.135l-.352.122a1 1 0 0 0-.619.619l-.122.352a1 1 0 0 1-1.135.655l-.366-.07a1 1 0 0 0-.845.226l-.282.244a1 1 0 0 1-1.31 0l-.282-.244a1 1 0 0 0-.845-.226l-.366.07a1 1 0 0 1-1.135-.655l-.122-.352a1 1 0 0 0-.619-.619l-.352-.122a1 1 0 0 1-.655-1.135l.07-.366a1 1 0 0 0-.226-.845l-.244-.282a1 1 0 0 1 0-1.31l.244-.282a1 1 0 0 0 .226-.845l-.07-.366a1 1 0 0 1 .655-1.135l.352-.122a1 1 0 0 0 .619-.619l.122-.352a1 1 0 0 1 1.135-.655l.366.07a1 1 0 0 0 .845-.226l.282-.244Z"
                stroke="#1A1A1A"
            />
            <path d="m10.4 5.6-4.8 4.8" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M6.229 6.297h.016M9.829 9.897h.016"
                stroke="#1A1A1A"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export { FilterSort, Locality, PriceOffers }
