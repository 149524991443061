import React from 'react'

export const ProfileMini: React.FC<React.SVGProps<SVGSVGElement>> = ({ width = '16', height = '16', ...restProps }) => {
    return (
        <svg viewBox="0 0 103 94" height={height} width={width} fill="#999" {...restProps}>
            <path
                fill-rule="evenodd"
                d="M63.869 3.11c.615 2.336 5.017 2.131 6.55 3.684 1.937 1.962 3.359 6.55 3.372 9.708.007 1.8.09 3.601-.175 5.741-.062.5-.206 4.238-.421 6.56-.104 1.114.088 1.422.923 1.736.95.36 1.285 1.421.966 2.904-.677 3.16-1.535 10.722-2.636 12.758-.29.536-.834.943-1.283 1.048-.777.323-1.274.288-1.572 1.59-1.025 4.704-1.89 8.855-2.81 11.921-.608 1.072-1.06 1.418-1.766 1.91-.497.345-1.406 1.255-1.477 1.919-.181 1.702.313 3.77 1.954 4.561 10.353 3.892 22.675 9.347 30.774 11.252 5.516 1.298 6.503 7.34 6.503 12.871H0C0 87.742.987 81.7 6.503 80.402c8.099-1.905 20.42-7.36 30.773-11.252 1.642-.792 2.136-2.86 1.954-4.561-.07-.664-.98-1.574-1.477-1.92-.706-.49-1.157-.837-1.766-1.909-.92-3.066-1.785-7.217-2.809-11.921-.299-1.302-.796-1.267-1.573-1.59-.448-.105-.993-.512-1.282-1.048-1.1-2.036-1.96-9.598-2.637-12.758-.318-1.483.016-2.545.966-2.904.836-.314 1.027-.622.924-1.736-.216-2.322-.36-6.06-.421-6.56-.266-2.14-.337-3.95-.175-5.74.273-3.017 1.6-6.19 3.628-7.925 4.034-3.451 9.842-6.096 15.157-7.48 2.027-.53 5.15-1.022 8.08-1.086 2.482-.053 7.188-.078 8.024 3.097"
            ></path>
        </svg>
    )
}
