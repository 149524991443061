import React from 'react'

export function SpecialDealIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect
                width="32.0011"
                height="32.0001"
                rx="7.99994"
                transform="matrix(1 -1.45756e-08 1.41894e-05 1 0.000976562 -0.000976562)"
                fill="#FFF9EC"
            />
            <g clip-path="url(#clip0_2401_22967)">
                <path
                    d="M11.8138 18.7632L13.0392 17.7748L13.8512 18.4842L15.0766 17.4958L16.3019 16.5075L16.3546 18.0241L16.287 20.6122L15.7007 21.8647L14.3023 22.4078L12.3853 21.6154L11.6936 19.8346L11.8138 18.7632Z"
                    fill={props.color ?? '#FDBA12'}
                />
                <path
                    d="M10.6052 12.6359L11.7951 10.0943C11.9753 9.70942 12.3043 9.40427 12.7097 9.24679L18.7889 6.88541C19.6328 6.55759 20.5713 6.94511 20.8846 7.75168L23.155 13.5967C23.3054 13.984 23.2888 14.4179 23.1081 14.803L19.5048 22.4998"
                    stroke="black"
                    stroke-width="1.1428"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M9.04112 15.9857L10.6092 12.6361"
                    stroke="black"
                    stroke-width="1.1428"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M13.7496 26.1013L7.29464 23.5546C6.4611 23.2258 6.08869 22.2916 6.4686 21.48L9.04049 15.9864"
                    stroke="black"
                    stroke-width="1.1428"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M18.7137 11.2987C18.9232 11.8381 18.6354 12.4529 18.0707 12.6723"
                    stroke="black"
                    stroke-width="1.1428"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M18.0651 12.6735C17.5004 12.8929 16.873 12.6335 16.6635 12.0942C16.454 11.5548 16.7418 10.9399 17.3065 10.7206C17.8712 10.5012 18.4986 10.7606 18.7081 11.2999"
                    stroke="black"
                    stroke-width="1.1428"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M13.049 18.1459C13.0641 18.1616 13.0798 18.1777 13.0965 18.1945C13.4091 18.5045 13.8788 18.583 14.2822 18.4263C14.4058 18.3783 14.5237 18.3081 14.6283 18.2157C14.7208 18.1337 14.8143 18.0497 14.9039 17.9677C15.098 17.7917 15.2992 17.6095 15.4977 17.4511C15.6313 17.3446 15.7828 17.2488 15.9555 17.1618C15.9171 17.8106 16.0208 18.4115 16.1152 18.9581L16.1366 19.0822C16.244 19.7078 16.2779 20.1767 16.1175 20.5772C15.8506 21.2431 15.4697 21.6645 14.9521 21.8655C14.7985 21.9252 14.6275 21.9677 14.4437 21.9925C13.5703 22.1095 12.9404 21.8409 12.4611 21.146C12.1905 20.7543 12.0653 20.3339 12.0889 19.8967C12.1127 19.4555 12.2936 19.0004 12.6123 18.5798C12.7662 18.3768 12.9045 18.2395 13.0492 18.1464M12.8445 17.0663C12.7835 17.09 12.7225 17.1182 12.6625 17.1478C12.2767 17.3403 11.9844 17.6312 11.7296 17.9666C10.815 19.1728 10.7281 20.5839 11.5377 21.7565C12.2468 22.7839 13.2832 23.2198 14.5622 23.0481C14.8416 23.0107 15.0993 22.9447 15.3356 22.8529C16.1961 22.5186 16.7818 21.8403 17.1443 20.935C17.4155 20.2572 17.3425 19.5636 17.2248 18.8761C17.0999 18.147 16.957 17.4265 17.1302 16.6681C17.2462 16.1608 16.7041 15.7535 16.1986 15.9214C16.1 15.9541 16.0023 15.9892 15.9056 16.0267C15.5131 16.1792 15.1419 16.374 14.8114 16.6371C14.486 16.8965 14.1854 17.1843 13.8972 17.4384C13.7842 17.3266 13.6792 17.1775 13.5343 17.0877C13.3072 16.9474 13.0729 16.977 12.8438 17.066L12.8445 17.0663Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_2401_22967">
                    <rect width="20" height="21" fill="white" transform="translate(6 6)" />
                </clipPath>
            </defs>
        </svg>
    )
}
