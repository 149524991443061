import React from 'react'

export const FacebookOutlined = ({ width = 16, height = 16 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 0.91001C5.87517 0.91001 0.91001 5.87517 0.91001 12C0.91001 18.1248 5.87517 23.09 12 23.09C18.1248 23.09 23.09 18.1248 23.09 12C23.09 5.87517 18.1248 0.91001 12 0.91001ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                fill="#1A1A1A"
            />
            <path
                d="M10.2467 18.9767H13.0556V11.9393H15.0152L15.2214 9.58544H13.0495V8.24469C13.0495 7.68655 13.1587 7.46815 13.6987 7.46815H15.2214V5.02325H13.274C11.181 5.02325 10.2406 5.9454 10.2406 7.70475V9.58544H8.77856V11.9697H10.2406V18.9767H10.2467Z"
                fill="#1A1A1A"
            />
        </svg>
    )
}
