import React from 'react'

export function SuperSaverIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect
                width="32.0011"
                height="32.0001"
                rx="7.99994"
                transform="matrix(1 -1.45756e-08 1.41894e-05 1 0.00195312 0.000488281)"
                fill="#FFF9EC"
            />
            <g clip-path="url(#clip0_2401_22991)">
                <path
                    d="M8.60254 6.20361L23.0022 6.32091V9.91491L21.8022 11.8037H9.80246L8.60254 9.98992V6.20361Z"
                    fill={props.color ?? '#FDBA12'}
                />
                <path
                    d="M12.2021 6.20361H23.0019V10.4207L21.5158 11.9012L22.9343 13.3139L22.9803 23.0036"
                    stroke="black"
                    stroke-width="1.19998"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M23.0022 26.6036H8.60254V13.0896L10.2088 11.7546L8.62622 10.27V6.20361"
                    stroke="black"
                    stroke-width="1.19998"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M14.2316 11.665L12.1855 11.665"
                    stroke="black"
                    stroke-width="0.999987"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M19.5822 11.6646L17.5361 11.6646"
                    stroke="black"
                    stroke-width="0.999987"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M13.5781 22.0297L18.4357 16.2847"
                    stroke="black"
                    stroke-width="1.19998"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M14.1224 18.0154C14.6275 18.0154 15.0369 17.606 15.0369 17.101C15.0369 16.5959 14.6275 16.1865 14.1224 16.1865C13.6174 16.1865 13.208 16.5959 13.208 17.101C13.208 17.606 13.6174 18.0154 14.1224 18.0154Z"
                    stroke="black"
                    stroke-width="0.999987"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M18.0334 22.0287C18.5475 22.0287 18.9642 21.612 18.9642 21.0979C18.9642 20.5838 18.5475 20.167 18.0334 20.167C17.5193 20.167 17.1025 20.5838 17.1025 21.0979C17.1025 21.612 17.5193 22.0287 18.0334 22.0287Z"
                    stroke="black"
                    stroke-width="0.999987"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2401_22991">
                    <rect
                        width="23.9394"
                        height="24"
                        fill="white"
                        transform="matrix(1 -1.45759e-08 1.41891e-05 1 3.80273 3.80273)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
