import { ITraveller } from '@/types/common'

export enum SUGGESTION_TYPE {
    flight = 'flight',
    STATE = 'STATE',
    CITY = 'CITY',
    COUNTRY = 'COUNTRY',
    LOCALITY = 'LOCALITY',
    POI = 'POINT_OF_INTEREST',
    REGION = 'REGION',
    ROAD = 'ROAD',
    AREA = 'AREA'
}

export const SUGGESTION_TYPE_TITLE_MAP = {
    [SUGGESTION_TYPE.COUNTRY]: 'Country',
    [SUGGESTION_TYPE.STATE]: 'State',
    [SUGGESTION_TYPE.REGION]: 'Region',
    [SUGGESTION_TYPE.CITY]: 'City',
    [SUGGESTION_TYPE.LOCALITY]: 'Locality',
    [SUGGESTION_TYPE.POI]: 'Landmark',
    [SUGGESTION_TYPE.flight]: 'flight',
    [SUGGESTION_TYPE.ROAD]: 'Road',
    [SUGGESTION_TYPE.AREA]: 'Area'
}

export const SEARCH_PLACEHOLDER = 'Enter locality, landmark, city or flight'

export const HOME_PAGE_MESSAGES = {
    AUTO_SUGGEST_ERROR_MESSAGE: 'Please enter valid destination'
}

/**
 * Traveller component constants
 */
export const TRAVELLERS_JSON: Record<string, ITraveller[]> = {
    OPTION_1: [{ adults: { count: 1 }, children: { count: 0, metadata: [] } }],
    OPTION_2: [{ adults: { count: 2 }, children: { count: 0, metadata: [] } }],
    OPTION_3: [
        { adults: { count: 2 }, children: { count: 0, metadata: [] } },
        { adults: { count: 2 }, children: { count: 0, metadata: [] } }
    ]
}

export const TRAVELLER_COUNT = {
    OPTION_1: '1 Room, 1 Adult',
    OPTION_2: '1 Room, 2 Adults',
    OPTION_3: '2 Rooms, 4 Adults'
}

export const MAX_ROOM_COUNT = 6

export const MIN_SEARCH_LENGTH = 2

export const MODIFY_SEARCH_CAPTIONS = {
    flight: 'flight'
}

export const categoryType: Record<string, string> = {
    popularDestination: 'popular',
    recentSearch: 'recent',
    regular: 'regular'
}
