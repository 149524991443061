import React from 'react'

export const ChangeFlight: React.FC<React.SVGProps<SVGSVGElement>> = ({
    width = '16',
    height = '16',
    ...restProps
}) => {
    return (
        <svg viewBox="0 0 14 14" className="c-light-blue" height={height} width={width} {...restProps}>
            <g fill="none" fill-rule="evenodd">
                <rect width="14" height="14" fill="#FFF" opacity="0"></rect>
                <path
                    fill="currentColor"
                    fill-rule="nonzero"
                    d="M10.2668078,7.9689266 L12.8037656,10.4358768 C13.0747077,10.6993422 13.0993388,11.1151056 12.8776589,11.4062615 L12.8037656,11.4895931 L10.2668078,13.9565433 L9.21220667,12.902827 L10.3256362,11.8201617 C9.09324498,11.7008095 8.15528162,11.3539959 7.53165946,10.7438555 L7.39333574,10.5983631 L8.49545964,9.59597947 L8.57836232,9.68117546 C8.90069746,9.98312464 9.45117369,10.1985626 10.2448054,10.3004475 L10.4902046,10.3275793 C10.5070205,10.3291543 10.5293571,10.3305626 10.5571255,10.3318005 L9.21220667,9.02264295 L10.2668078,7.9689266 Z M10.2668078,0.0434566847 L12.8037656,2.51040687 C13.0747077,2.77387225 13.0993388,3.18963564 12.8776589,3.48079157 L12.8037656,3.56412322 L10.2668078,6.0310734 L9.21220667,4.97735705 L10.36,3.861 L9.90547203,3.86319783 C7.75690071,3.85907413 6.766746,4.78606842 6.70312299,6.87606591 L6.6998369,7.0964307 C6.6998369,10.1345163 4.79337963,11.76317 1.29936687,11.8611255 L1.02747077,11.8656394 L0.00323858539,11.8679129 L-3.01092484e-13,10.366261 L1.02189677,10.3639964 C3.84757515,10.3486777 5.14036442,9.36504783 5.21612054,7.29380019 L5.21966679,7.0964307 C5.21966679,4.1050435 6.77779721,2.44923727 9.6542424,2.36492217 L10.51,2.358 L9.21220667,1.09717303 L10.2668078,0.0434566847 Z M1.03670265,2.59215268 L1.34961383,2.59595624 C2.88866715,2.63405802 4.04499582,2.95941162 4.81023737,3.61667181 L4.9580687,3.75256249 L3.92748451,4.8304344 L3.82564589,4.73926653 C3.3540282,4.35409048 2.51563444,4.12564382 1.30241769,4.09689616 L1.03670265,4.09380823 L0.0016192927,4.09380823 L0.0016192927,2.59215268 L1.03670265,2.59215268 Z"
                ></path>
            </g>
        </svg>
    )
}
