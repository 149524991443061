import { SVGProps } from 'react'

export function Wallet({ width = 20, height = 16, ...rest }: SVGProps<SVGSVGElement>) {
    return (
        <svg {...rest} width={width} height={height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5 8.2H14.509M2.8 15.4H17.2C18.1941 15.4 19 14.5941 19 13.6V2.8C19 1.80589 18.1941 1 17.2 1H2.8C1.80589 1 1 1.80589 1 2.8V13.6C1 14.5941 1.80589 15.4 2.8 15.4ZM19 5.95H14.05C12.8074 5.95 11.8 6.95736 11.8 8.2C11.8 9.44264 12.8074 10.45 14.05 10.45H19V5.95Z"
                stroke="#1A1A1A"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
