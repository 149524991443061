import { ForwardedRef, RefObject, useEffect, useRef } from 'react'

export const useForwardRef = <T>(ref?: ForwardedRef<T>, initialValue: T | null = null): RefObject<T> | undefined => {
    const targetRef = useRef(initialValue)

    useEffect(() => {
        if (!ref) return

        if (typeof ref === 'function') {
            if (targetRef.current) ref(targetRef.current)
        } else if (ref.current) {
            targetRef.current = ref.current
        }
    }, [ref])
    return targetRef
}
