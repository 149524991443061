import { getSidesheets } from '@/redux/slices/sidesheetSlice'
import { useAppSelector } from '@/redux/store/hooks'
import { Sidesheet } from './Sidesheet'

const GlobalSidesheetComponent = () => {
    const sheets = useAppSelector(getSidesheets)

    return (
        <>
            {sheets.map(({ data, ownProps }, index) => (
                <Sidesheet
                    sidesheetId={index}
                    key={`sidesheet-${index}`}
                    data={data}
                    ownProps={ownProps}
                    isRecent={index === sheets.length - 1}
                />
            ))}
        </>
    )
}
export default GlobalSidesheetComponent
