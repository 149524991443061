import { useEffect, useState, FC, useRef } from 'react'
import { useRouter } from 'next/router'

import { PAGE_NAME, PREFETCH_ROUTE, ROUTE_PATH } from '@/constants/pages/common'

import {
    ResultsPrefetcher,
    DetailsPrefetcher,
    CommonComponentsPrefetcher,
    ItineraryPrefetcher,
    FormPrefetcher
} from '@/components/prefetcher'

interface IPrefetchComponentTypes {
    delay?: number
}

// eslint-disable-next-line react/jsx-key
const EACH_PAGE_REQUIRED_BUNDLE = [<CommonComponentsPrefetcher />]

const getBundle = (pathName: string) => {
    let bundleComp: JSX.Element[] = []
    switch (pathName) {
        case ROUTE_PATH[PAGE_NAME.HOME]: {
            bundleComp.push(<ResultsPrefetcher />)
            break
        }

        case ROUTE_PATH[PAGE_NAME.RESULT]: {
            bundleComp.push(<DetailsPrefetcher />)
            break
        }

        case ROUTE_PATH[PAGE_NAME.DETAIL]: {
            bundleComp.push(<ItineraryPrefetcher />)
            bundleComp.push(<FormPrefetcher />)
            break
        }

        default: {
            bundleComp = []
        }
    }
    return bundleComp
}

/**
 * This is js prefetcher component which is used to download the upcoming js bundle in advance to make transition smooth and fast.
 * You Can add the prefetcher component in the switch case for prefetching.
 * In Next we can prefetch route js bundle using 2 ways.
 * 1. router.prefetch :- down load the page based bundleComp
 * 2. Components Js bundle:- We are prefetching those using a heck.
 */
const PreFetcherComponent: FC<IPrefetchComponentTypes> = props => {
    const { delay = 0 } = props
    const router = useRouter()
    const [isMounted, setIsMounted] = useState<boolean>(false)
    const firstRender = useRef(true)

    //All the js bundles which are required to load on every first load irespective of page
    const [bundledComponents, setBundledComponent] = useState(EACH_PAGE_REQUIRED_BUNDLE)

    useEffect(() => {
        const { pathname } = router

        //TODO: Need to find a way where we can assure that page is laoded succesfully instead of timeout.
        setTimeout(() => {
            //prefetch the route js bundle
            PREFETCH_ROUTE?.[pathname]?.forEach((name: string) => {
                router.prefetch(name)
            })

            /**
             * On first load bundledComponents will always have the value so it will load the required bundle
             * later on it will re-render this page only if we have bundles.
             */
            const bundles = getBundle(pathname)
            if (firstRender.current || bundles.length) {
                setBundledComponent(prev => [...prev, ...bundles])
                setIsMounted(true)
            }

            //set the flag to flase after the first load.
            if (firstRender.current) {
                firstRender.current = false
            }
        }, delay)

        return () => {
            //set the mount to false so that bundle should not be downloaded in initial render.
            setIsMounted(false)
            setBundledComponent([])
        }
    }, [router.pathname])

    return <>{isMounted && bundledComponents}</>
}

export default PreFetcherComponent
