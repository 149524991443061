import React from 'react'

export default function SandClock({ color = '#1A1A1A', ...props }) {
    return (
        <div className="pl-2 flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
                <path
                    fill={color}
                    fillRule="evenodd"
                    d="M6.5 4.585c0-.28.265-.585.691-.585h9.32c.427 0 .691.305.691.585v2.92c0 .098-.03.198-.092.29l-3.16 3.219a1.507 1.507 0 00.037 1.97l3.41 3.225c.07.096.103.203.103.307v2.899c0 .28-.265.585-.692.585H7.191c-.426 0-.691-.305-.691-.585v-2.899c0-.104.034-.211.104-.306l3.389-3.203a1.508 1.508 0 000-2.014L6.603 7.79a.518.518 0 01-.103-.306V4.585zM7.191 3C6.302 3 5.5 3.667 5.5 4.585v2.899c0 .35.123.686.344.958a.5.5 0 00.045.049l3.389 3.202a.506.506 0 010 .614l-3.39 3.202a.491.491 0 00-.044.05c-.22.271-.344.607-.344.957v2.899c0 .918.802 1.585 1.691 1.585h9.617c.89 0 1.692-.667 1.692-1.585v-2.899c0-.35-.123-.686-.344-.958a.493.493 0 00-.045-.049l-3.408-3.226a.504.504 0 01-.012-.596l3.16-3.22a.5.5 0 00.043-.05c.198-.263.308-.582.308-.912v-2.92C18.202 3.667 17.4 3 16.511 3H7.19zM9 17.5a.5.5 0 000 1h6a.5.5 0 000-1H9z"
                    clipRule="evenodd"
                ></path>
            </svg>
        </div>
    )
}
