import { isEmpty } from "../general/browserHelper"

export const serializeItineraryData = (data = []) => {
    const dataObj = {}
    // Todo: Discuss use of element.data check
    data.forEach(element => {
        if (element.data || element.type === 'AGENT_PAYMENT_DETAILS' || element.type === 'GST') {
            if (
                element.type === 'USER' ||
                element.type === 'GST' ||
                element.type === 'VOUCHERS' ||
                element.type === 'AGENT_PAYMENT_DETAILS'
            ) {
                dataObj[element.type] = { ...element }
            } else {
                dataObj[element.type] = element.data
            }
        }
    })
    return dataObj
}

export const getAvailableAncillary = (itineraryData : any) => {
    let ancillaryData = {
        isSeatAvailable: false,
        isMealAvailable: false,
        isBaggageAvailable: false
    }
    if (itineraryData?.ANCILLARY?.hasOwnProperty('tripAncillariesResponse')) {
        const { tripAncillariesResponse } = itineraryData?.ANCILLARY
        if (!isEmpty(tripAncillariesResponse)) {
            tripAncillariesResponse?.forEach((journey: any = {}) => {
                journey?.ancillaryDetails?.journeyAncillaries?.forEach((segment: any) => {
                    if (segment?.baggageAncillary?.baggageDetails?.length > 0) {
                        ancillaryData.isBaggageAvailable = true
                    }
                    segment?.segmentAncillaries?.forEach((ancillaries: any) => {
                        ancillaries?.seatAncillaries?.aircraftLayout?.lowerDeck?.compartments?.forEach(
                            (seats: any) => {
                                if (seats.rows?.length > 0) {
                                    ancillaryData.isSeatAvailable = true
                                }
                            }
                        )
                        if (ancillaries?.mealAncillaries?.mealInfos?.length > 0) {
                            ancillaryData.isMealAvailable = true
                        }
                    })
                })
            })
        }
    }
    return ancillaryData
}
