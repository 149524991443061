import { TypographyColor } from '@cleartrip/ct-design-components'

export enum TA_RATING_TYPE {
    POOR = 'POOR',
    AVERAGE = 'AVERAGE',
    GOOD = 'GOOD'
}

export const TA_RATING_RANGE = {
    [TA_RATING_TYPE.POOR]: {
        min: 0,
        max: 2.4,
        color: 'warning'
    },
    [TA_RATING_TYPE.AVERAGE]: {
        min: 2.5,
        max: 3.9,
        color: 'alert'
    },
    [TA_RATING_TYPE.GOOD]: {
        min: 4,
        max: 5,
        color: 'success'
    }
}

export const COMMON_SEO_TITLE = 'Online flight Booking | Book Luxury, Budget and Cheap flights'

export const META_KEYWORD_FOR_NAME = ['price', 'Rooms', 'Reservations', 'Reviews', 'Address, Book', 'flight']

export const DEFAULT_ERROR_MOCK_CODE = 100

export const flightCardFontColorMap = {
    darkVariant: {
        primary: TypographyColor.NEUTRAL, // name , non striked off price ( middle ) , TA score
        subHeading: TypographyColor.TERTIARY // second line , rhs of middle prices ( tax callout)
    },
    lightVariant: {
        primary: TypographyColor.PRIMARY, // name , ta stuff , main price
        subHeading: TypographyColor.SUBHEADING // second line , strikOffPrice , taxes callout
    }
}

export const V2_PROGRESS_BAR_COLOR = '#FF4F17'
export const V2_BAR_COLOR = '#E6E6E6'

export enum STAR_RATING_PROGRESS_VARIANTS {
    DEFAULT = 'DEFAULT',
    ORANGE = 'ORANGE'
}

export const STAR_RATING_PROGRESS_BAR_VARIANT_STYLE = {
    [STAR_RATING_PROGRESS_VARIANTS.DEFAULT]: {
        progressBarColor: '#1A1A1A',
        barColor: '#E7E7E7',
        countColor: TypographyColor.PRIMARY
    },
    [STAR_RATING_PROGRESS_VARIANTS.ORANGE]: {
        progressBarColor: V2_PROGRESS_BAR_COLOR,
        barColor: V2_BAR_COLOR,
        countColor: TypographyColor.SUBHEADING
    }
}

export enum ReviewCardColor {
    BLUE100 = 'BLUE100',
    RED100 = 'RED100',
    YELLOW100 = 'YELLOW100',
    PURPLE100 = 'PURPLE100',
    GREEN200 = 'GREEN200',
    GREEN100 = 'GREEN100'
}

export const REVIEW_CARD_BACKGROUNDCOLOR_MAP: Record<ReviewCardColor, string> = {
    [ReviewCardColor.BLUE100]: '#ECF9FB',
    [ReviewCardColor.RED100]: '#FFF1ED',
    [ReviewCardColor.YELLOW100]: '#FFF9EC',
    [ReviewCardColor.PURPLE100]: '#F2EDF9',
    [ReviewCardColor.GREEN200]: '#ECF9FB',
    [ReviewCardColor.GREEN100]: '#F2FAF0'
}

export const totalTravellersCount = 9

export const travellersList = [
    {
        type: 'adults',
        label: 'Adult',
        description: '12+ Years',
        minValue: 1
    },
    {
        type: 'childs',
        label: 'Children',
        description: '2 - 12 yrs',
        minValue: 0
    },
    {
        type: 'infants',
        label: 'Infant',
        description: 'Below 2 yrs',
        minValue: 0
    }
]
