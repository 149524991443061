import React from 'react'

export default function Tag({ color = '#1A1A1A', ...props }) {
    return (
        <div className="pl-2 flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
                <path
                    fill={color}
                    fillRule="evenodd"
                    d="M4 3.5a.5.5 0 00-.5.5v7.66a2.5 2.5 0 00.75 1.785l7.565 7.419.002.002a2.169 2.169 0 003.081-.017l5.978-6.479a2.17 2.17 0 000-3.046l-6.568-7.03a2.5 2.5 0 00-1.827-.794H4zm.5 8.16V4.5h7.981a1.5 1.5 0 011.096.476l6.572 7.036.011.011a1.17 1.17 0 01-.013 1.663l-5.976 6.477a1.17 1.17 0 01-1.648-.006l-.004-.004-7.57-7.422a1.5 1.5 0 01-.449-1.07zM8.5 9a1 1 0 112 0 1 1 0 01-2 0zm1-2a2 2 0 100 4 2 2 0 000-4z"
                    clipRule="evenodd"
                ></path>
            </svg>
        </div>
    )
}
