import { TA_RATING_TYPE } from '@/components/details/RatingsAndReviews/AspectRating/type'
import { CURRENCIES, CURRENCY_TO_SYMBOL } from '@/constants/currency'

export const TA_RATING_RANGE = {
    [TA_RATING_TYPE.POOR]: {
        min: 0,
        max: 2.4,
        color: '#C83232'
    },
    [TA_RATING_TYPE.AVERAGE]: {
        min: 2.5,
        max: 3.9,
        color: '#DF9641'
    },
    [TA_RATING_TYPE.GOOD]: {
        min: 4,
        max: 5,
        color: '#11A670'
    }
}

export const getTARatingColor = (rating: number) => {
    for (const key in TA_RATING_RANGE) {
        const objKey = key as TA_RATING_TYPE
        const { min, max, color } = TA_RATING_RANGE[objKey]
        if (rating >= min && rating <= max) {
            return color
        }
    }
}

export function getCurrencySymbol(currency: keyof typeof CURRENCIES) {
    return CURRENCY_TO_SYMBOL[currency] ?? ''
}

export const getTestIdProps = ({
    pageName,
    componentName,
    testId,
    prefix,
    avoidPageName
}: {
    pageName?: string
    componentName?: string
    testId?: string | number
    prefix?: string
    avoidPageName?: boolean
}) => {
    const isTestIdEnabled = process?.env?.NEXT_PUBLIC_ENABLE_TEST_ID === 'true'
    if (!isTestIdEnabled) return {}
    const testIds = []
    if (prefix) testIds.push(prefix)
    if (pageName && !avoidPageName) testIds.push(pageName)
    if (componentName) testIds.push(componentName)
    if (typeof testId !== 'undefined') testIds.push(testId)
    if (testIds.length === 0) return {}
    const dataTestId = testIds.join('-')
    return {
        ['data-test-id']: dataTestId.replaceAll(' ', ''),
        get dataTestId() {
            return this['data-test-id']
        }
    }
}
