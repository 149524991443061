import React from 'react'
import dynamic from 'next/dynamic'

import { ComponentType, IComponentProps, ComponentMapper } from '@/types'

// Below are all the Common components
const LOTTIE = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/Lottie'), {
    ssr: false
})
const STACKED_SLOTS = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/StackedSlots')
)

const ADJACENT_SLOTS = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/AdjacentSlots')
)

const HEADER_WITH_MODIFY_SEARCH = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/HeaderWithModifySearch')
)

const USER_ACCOUNT = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/UserAccount'))

const MASKED_DROPDOWN = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/Dropdown'))

const MASKED_DROPDOWN_HEADER = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/Dropdown/Header')
)

const MASKED_DROPDOWN_MAIN = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/Dropdown/Main')
)

const MASKED_DROPDOWN_FOOTER = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/Dropdown/Footer')
)

const CHIP_WITH_DROPDOWN = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/Dropdown/ChipWIthDropDown')
)

const ICON = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/Icon'))

const VERTICAL_RADIO_GROUP = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/Forms/RadioGroup/VerticalRadioGroup')
)

const VERTICAL_CHECKBOX_GROUP = dynamic(
    () =>
        import(
            /* webpackChunkName: "commonComponent" */ '@/components/common/Forms/CheckboxGroup/VerticalCheckboxGroup'
        )
)
const HORIZONTAL_MULTI_FLATCHIP_SELECTION = dynamic(
    () =>
        import(
            /* webpackChunkName: "commonComponent" */ '@/components/common/Forms/FlatChipGroup/HorizontalMultiSelectFlatChipGroup'
        )
)
const FLAT_CHIP = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/Forms/FlatChip'))

const FLAT_NAV_TABS = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/FlatNavTabs'))

const BULLETED_CONTENT = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/BulletedContent')
)

const HTML_MARKDOWN_COMPONENT = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/MarkupContent')
)

const OFFER_CARD = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/OfferCard'))

const SHEET_HEADER = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/SheetHeader'))

const SIDESHEET_SHIMMER = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/Sidesheet/Shimmer')
)

const HORIZONTAL_SINGLE_SELECT_CHECKBOX_GROUP = dynamic(
    () =>
        import(
            /* webpackChunkName: "commonComponent" */ '@/components/common/Forms/CheckboxGroup/SingleSelect/Horizontal'
        )
)

const VERTICAL_SINGLE_SELECT_CHECKBOX_GROUP = dynamic(
    () =>
        import(
            /* webpackChunkName: "commonComponent" */ '@/components/common/Forms/CheckboxGroup/SingleSelect/Vertical'
        )
)

const BASE_CHIP = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/BaseChip'))

const FOOTER = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/Footer'))

const USER_ACCOUNT_DROPDOWN = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/UserAccount/UserAccountDropdown')
)

const USER_ACCOUNT_DROPDOWN_MAIN = dynamic(
    () =>
        import(
            /* webpackChunkName: "commonComponent" */ '@/components/common/UserAccount/UserAccountDropdown/UserAccountDropdownMain'
        )
)

const ERROR_WIDGET = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/ErrorGeneralState')
)

const LOGIN_MODAL = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/home/Modals/LoginModal')
)

const BANNER = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/Banner'))

const IMAGE_WIDGET = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/ImageWidget'))

const CITY_SELECT_FILTER = dynamic(
    () => import(/* webpackChunkName: "merchComponent" */ '@/components/merchResult/filters/CitySelectFilter')
)
const SELECT_LOCATION_FILTER = dynamic(
    () => import(/* webpackChunkName: "merchComponent" */ '@/components/merchResult/filters/LocationSelectFilter')
)

const ICONED_TEXT = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/IconedTextWidget')
)
const HORIZONTAL_SCROLL_CONTAINER = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/HorizontalScrollContainer')
)

const FULL_HEIGHT_BUTTON = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/FullHeightButton')
)

const HORIZONTAL_SINGLE_CHIP_SELECTION = dynamic(
    () =>
        import(
            /* webpackChunkName: "commonComponent" */ '@/components/common/Forms/FlatChipGroup/HorizontalSingleSelectFlatChipGroup'
        )
)

const BADGE = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/Badge'))

const TYPOGRAPHY = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/TypographyWidget')
)

const LINK_BUTTON = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/LinkButton'))
const ALERT_CALLOUT = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/AlertCallout')
)
const CFNR_HIGHLIGHT = dynamic(
    () => import(/*webpackChunkName: "commonComponent" */ '@/components/common/CfnrHighlight')
)
const CANCELLATION_CRITERIA_BLOCK = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/CancellationPolicy/CancellationBlock')
)
const SECTION_INFO_ITEM_NUMBER = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/SectionInfo/SectionInfoItemNumber')
)
const ACCORDION = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/Accordion'))

const ACCORDION_GROUP = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/AccordionGroup')
)
const MEDIA_TEXT_LIST = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/MediaTextList')
)
const BASE_BUTTON = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/BaseButton'))

const BASE_DIALOG = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/BaseDialog'))

const SPACER = dynamic(() => import(/* webpackChunkName: "commonComponent" */ '@/components/common/SpacerWidget'))

/** FORM COMPONENRS */
const FIELD = dynamic(() => import(/* webpackChunkName: "formComponent" */ '@/components/common/Forms/BaseField'))
const CHECKBOX = dynamic(() => import(/* webpackChunkName: "formComponent" */ '@/components/common/Forms/BaseCheckbox'))
const SINGLE_SELECT_CHIP_GROUP = dynamic(
    () => import(/* webpackChunkName: "formComponent" */ '@/components/common/Forms/SingleSelectChip')
)
const FORM_ELEMENT = dynamic(
    () => import(/* webpackChunkName: "formComponent" */ '@/components/common/Forms/FormElement')
)

const TEXTFIELD = dynamic(
    () => import(/* webpackChunkName: "formComponent" */ '@/components/common/Forms/BaseTextarea')
)

const SELECTION_BOX = dynamic(
    () => import(/* webpackChunkName: "formComponent" */ '@/components/common/Forms/BaseSelectionBox')
)

const CHECKBOX_GROUP = dynamic(
    () => import(/* webpackChunkName: "formComponent" */ '@/components/common/Forms/BaseCheckboxGroup')
)

const SELECT_RADIO_DROPDOWN = dynamic(
    () => import(/* webpackChunkName: "formComponent" */ '@/components/common/Forms/BaseSelectRadioDropdown')
)

const PRICE_BREAKUP_SUMMARY_CANCELLATION = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ '@/components/common/CancellationPolicy/PriceBreakupTooltip')
)

/* SEO PAGE COMPONENTS */

const HEADERS_TAG = dynamic(() => import(/* webpackChunkName: "seoComponent" */ '@/components/seo/HeadersTag'))
const BREADCRUMB = dynamic(() => import(/* webpackChunkName: "seoComponent" */ '@/components/seo/Breadcrumb'))
const FOOTER_LINKS = dynamic(() => import(/* webpackChunkName: "seoComponent" */ '@/components/seo/FooterLinks'))
const FAQ = dynamic(() => import(/* webpackChunkName: "seoComponent" */ '@/components/seo/FAQ'))
const FOOTER_CONTENT = dynamic(() => import(/* webpackChunkName: "seoComponent" */ '@/components/seo/FooterContent'))
const MOTHER_OFFER = dynamic(() => import(/* webpackChunkName: "seoComponent" */ '@/components/seo/MotherOffer'))

// TODO: @Saransh to remove the type alias
// https://github.com/facebook/create-react-app/pull/8177
const componentMapper = (widgetType: ComponentType, isSearchV3 = false): React.FC<IComponentProps> | null => {
    switch (widgetType) {
        // Below are all the Common components
        case ComponentMapper.LOTTIE: {
            return LOTTIE as React.FC<IComponentProps>
        }
        case ComponentMapper.FULL_HEIGHT_BUTTON: {
            return FULL_HEIGHT_BUTTON as React.FC<IComponentProps>
        }
        case ComponentMapper.HORIZONTAL_SCROLL_CONTAINER: {
            return HORIZONTAL_SCROLL_CONTAINER as React.FC<IComponentProps>
        }
        case ComponentMapper.STACK: {
            return STACKED_SLOTS as React.FC<IComponentProps>
        }
        case ComponentMapper.ADJACENT: {
            return ADJACENT_SLOTS as React.FC<IComponentProps>
        }
        case ComponentMapper.ICON: {
            return ICON as React.FC<IComponentProps>
        }
        case ComponentMapper.CHIP_WITH_ICON: {
            return BASE_CHIP as React.FC<IComponentProps>
        }
        case ComponentMapper.HTML_MARKDOWN_COMPONENT: {
            return HTML_MARKDOWN_COMPONENT as React.FC<IComponentProps>
        }
        case ComponentMapper.SHEET_HEADER:
        case ComponentMapper.SUB_PAGE_HEADER: {
            return SHEET_HEADER as React.FC<IComponentProps>
        }
        case ComponentMapper.OFFER_CARD: {
            return OFFER_CARD as React.FC<IComponentProps>
        }
        case ComponentMapper.FLAT_NAV_TABS: {
            return FLAT_NAV_TABS as React.FC<IComponentProps>
        }
        case ComponentMapper.BULLETED_CONTENT: {
            return BULLETED_CONTENT as React.FC<IComponentProps>
        }

        case ComponentMapper.ROOM_TYPE_DROPDOWN:
        case ComponentMapper.MASKED_DROPDOWN:
        case ComponentMapper.USER_ACCOUNT_LIST_DROPDOWN: {
            return MASKED_DROPDOWN as React.FC<IComponentProps>
        }

        case ComponentMapper.MASKED_DROPDOWN_HEADER: {
            return MASKED_DROPDOWN_HEADER as React.FC<IComponentProps>
        }
        case ComponentMapper.MASKED_DROPDOWN_FOOTER: {
            return MASKED_DROPDOWN_FOOTER as React.FC<IComponentProps>
        }

        case ComponentMapper.ROOM_TYPES_STACK_LIST_ITEMS:
        case ComponentMapper.MASKED_DROPDOWN_MAIN: {
            return MASKED_DROPDOWN_MAIN as React.FC<IComponentProps>
        }

        case ComponentMapper.CHIP_WITH_DROPDOWN: {
            return CHIP_WITH_DROPDOWN as React.FC<IComponentProps>
        }
        case ComponentMapper.HEADER_WITH_MODIFY_SEARCH: {
            return HEADER_WITH_MODIFY_SEARCH as React.FC<IComponentProps>
        }
        case ComponentMapper.USER_ACCOUNT: {
            return USER_ACCOUNT as React.FC<IComponentProps>
        }
        case ComponentMapper.MASKED_DROPDOWN_WITH_RADIO_GROUP: {
            return VERTICAL_RADIO_GROUP as React.FC<IComponentProps>
        }
        case ComponentMapper.MASKED_DROPDOWN_WITH_CHECKBOX_GROUP: {
            return VERTICAL_CHECKBOX_GROUP as React.FC<IComponentProps>
        }

        case ComponentMapper.USER_RATING_FILTER_SINGLE_SELECT: {
            return HORIZONTAL_SINGLE_SELECT_CHECKBOX_GROUP as React.FC<IComponentProps>
        }

        case ComponentMapper.MASKED_DROPDOWN_SINGLE_SELECT_GROUP: {
            return VERTICAL_SINGLE_SELECT_CHECKBOX_GROUP as React.FC<IComponentProps>
        }
        case ComponentMapper.PAGE_FOOTER: {
            return FOOTER as React.FC<IComponentProps>
        }

        case ComponentMapper.CANCELLATION_CRITERIA_BLOCK: {
            return CANCELLATION_CRITERIA_BLOCK as React.FC<IComponentProps>
        }

        case ComponentMapper.PRICE_BREAKUP_SUMMARY_CANCELLATION: {
            return PRICE_BREAKUP_SUMMARY_CANCELLATION as React.FC<IComponentProps>
        }

        case ComponentMapper.SIDESHEET_SHIMMER: {
            return SIDESHEET_SHIMMER as React.FC<IComponentProps>
        }

        case ComponentMapper.USER_ACCOUNT_DROPDOWN: {
            return USER_ACCOUNT_DROPDOWN as React.FC<IComponentProps>
        }

        case ComponentMapper.DROPDOWN: {
            return USER_ACCOUNT_DROPDOWN_MAIN as React.FC<IComponentProps>
        }

        case ComponentMapper.ERROR_WIDGET: {
            return ERROR_WIDGET as React.FC<IComponentProps>
        }

        case ComponentMapper.LOGIN_MODAL: {
            return LOGIN_MODAL as React.FC<IComponentProps>
        }

        case ComponentMapper.BANNER: {
            return BANNER as React.FC<IComponentProps>
        }

        case ComponentMapper.SELECT_CITY_FILTER: {
            return CITY_SELECT_FILTER as React.FC<IComponentProps>
        }
        case ComponentMapper.SELECT_LOCATION_FILTER: {
            return SELECT_LOCATION_FILTER as React.FC<IComponentProps>
        }

        case ComponentMapper.CFNR_HIGHLIGHT: {
            return CFNR_HIGHLIGHT as React.FC<IComponentProps>
        }

        case ComponentMapper.ICONED_TEXT:
        case ComponentMapper.ICONED_TEXT_WIDGET: {
            return ICONED_TEXT as React.FC<IComponentProps>
        }

        case ComponentMapper.HORIZONTAL_MULTI_FLATCHIP_SELECTION: {
            return HORIZONTAL_MULTI_FLATCHIP_SELECTION as React.FC<IComponentProps>
        }

        case ComponentMapper.FLAT_CHIP: {
            return FLAT_CHIP as React.FC<IComponentProps>
        }

        case ComponentMapper.HORIZONTAL_SINGLE_CHIP_SELECTION: {
            return HORIZONTAL_SINGLE_CHIP_SELECTION as React.FC<IComponentProps>
        }

        case ComponentMapper.TYPOGRAPHY: {
            return TYPOGRAPHY as React.FC<IComponentProps>
        }

        case ComponentMapper.IMAGE: {
            return IMAGE_WIDGET as React.FC<IComponentProps>
        }

        case ComponentMapper.SECTION_INFO_ITEM_NUMBER: {
            return SECTION_INFO_ITEM_NUMBER as React.FC<IComponentProps>
        }
        case ComponentMapper.LINK_BUTTON: {
            return LINK_BUTTON as React.FC<IComponentProps>
        }
        case ComponentMapper.ALERT_CALLOUT: {
            return ALERT_CALLOUT as React.FC<IComponentProps>
        }
        case ComponentMapper.ACCORDION: {
            return ACCORDION as React.FC<IComponentProps>
        }
        case ComponentMapper.ACCORDION_GROUP: {
            return ACCORDION_GROUP as React.FC<IComponentProps>
        }
        case ComponentMapper.MEDIA_TEXT_LIST: {
            return MEDIA_TEXT_LIST as React.FC<IComponentProps>
        }
        case ComponentMapper.CHECKBOX: {
            return CHECKBOX as React.FC<IComponentProps>
        }
        case ComponentMapper.FIELD: {
            return FIELD as React.FC<IComponentProps>
        }
        case ComponentMapper.SINGLE_SELECT_CHIP_GROUP: {
            return SINGLE_SELECT_CHIP_GROUP as React.FC<IComponentProps>
        }
        case ComponentMapper.FORM_ELEMENT: {
            return FORM_ELEMENT as React.FC<IComponentProps>
        }
        /* Below are all the seo page components  */
        case ComponentMapper.HEADERS_TAG: {
            return HEADERS_TAG as React.FC<IComponentProps>
        }
        case ComponentMapper.BREADCRUMB: {
            return BREADCRUMB as React.FC<IComponentProps>
        }
        case ComponentMapper.FOOTER_LINKS: {
            return FOOTER_LINKS as React.FC<IComponentProps>
        }
        case ComponentMapper.FAQ: {
            return FAQ as React.FC<IComponentProps>
        }
        case ComponentMapper.FOOTER_CONTENT: {
            return FOOTER_CONTENT as React.FC<IComponentProps>
        }
        case ComponentMapper.MOTHER_OFFER: {
            return MOTHER_OFFER as React.FC<IComponentProps>
        }
        case ComponentMapper.SPACER: {
            return SPACER as React.FC<IComponentProps>
        }

        case ComponentMapper.SELECT_RADIO_DROPDOWN: {
            return SELECT_RADIO_DROPDOWN as React.FC<IComponentProps>
        }

        case ComponentMapper.TEXTFIELD: {
            return TEXTFIELD as React.FC<IComponentProps>
        }

        case ComponentMapper.SELECTION_BOX: {
            return SELECTION_BOX as React.FC<IComponentProps>
        }

        case ComponentMapper.CHECKBOX_GROUP: {
            return CHECKBOX_GROUP as React.FC<IComponentProps>
        }

        case ComponentMapper.DIALOG_COMPONENT: {
            return BASE_DIALOG as React.FC<IComponentProps>
        }
        case ComponentMapper.BUTTON: {
            return BASE_BUTTON as React.FC<IComponentProps>
        }

        case ComponentMapper.BADGE: {
            return BADGE as React.FC<IComponentProps>
        }

        default: {
            return null
        }
    }
}

export default componentMapper
