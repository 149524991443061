import { memo, useRef, PropsWithChildren } from 'react'

import IntersectionObserverWrapper from '@/components/common/IntersectionObserverWrapper'
import { getNestedField } from '@/utils/general'

import useObservable from './useObservable'
import { BaseComponentProps } from '../type'

function ObservableComponent({ dispatcher, data, ownProps, children }: PropsWithChildren<BaseComponentProps>) {
    const { pageName = '', idForRootMargin = '' } = ownProps ?? {}

    const elemRef = useRef<HTMLDivElement>(null)

    const metaData = {
        key: getNestedField(['data', 'key'], data),
        pageName: pageName as string
    }

    const { getIntersectionOberserverOptions, handleIntersection } = useObservable({
        dispatcher,
        idForRootMargin: idForRootMargin as string,
        ownProps,
        data
    })

    return (
        <IntersectionObserverWrapper
            ref={elemRef}
            onIntersectionObserverCallback={handleIntersection}
            options={getIntersectionOberserverOptions()}
            metaData={metaData}
        >
            {children}
        </IntersectionObserverWrapper>
    )
}

export default memo(ObservableComponent)
