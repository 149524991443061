import { Toast } from '@cleartrip/ct-design-components'

import { closeToast, getToasts } from '@/redux/slices/toastSlice'
import { useAppDispatch, useAppSelector } from '@/redux/store/hooks'
import { subComponentGenerator } from '@/utils'

export default function ToastContainer() {
    const toasts = useAppSelector(getToasts) || []
    const dispatcher = useAppDispatch()

    return (
        <>
            {toasts.map((toast, index) => {
                const { data, ownProps } = toast || {}

                const { closeTimeout = 2000, isAutoClose = true } = ownProps || {}

                const { text, prefixIcon, toastCSS } = data || {}

                const IconToRender = subComponentGenerator({
                    slot: prefixIcon,
                    ownProps: { stroke: '#fff', width: 24, height: 24 }
                })

                const closeToastCallback = () => {
                    dispatcher(closeToast(index))
                }

                return (
                    <Toast
                        key={'gloabal_toast' + index}
                        show={true}
                        text={text}
                        css={{ zIndex: 201, maxWidth: '396px', ...toastCSS }}
                        prefixComponent={IconToRender}
                        closeTimeout={closeTimeout as number}
                        isAutoClose={isAutoClose as boolean}
                        // @ts-ignore
                        closeToastCb={closeToastCallback}
                    />
                )
            })}
        </>
    )
}
