import React from 'react'

export const Hotels: React.FC<React.SVGProps<SVGSVGElement>> = ({ width = '24', height = '24', ...restProps }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            // stroke="#1a1a1a"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.834 4.667H12.834V21.334000000000003H2.834z"></path>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12.834 9.667H21.167V21.334H12.834z"></path>
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.334 9.667h-5M18.667 13.833h-3.333"></path>
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.334 16.333H18.667V21.333H15.334z"></path>
            <path strokeLinecap="round" strokeLinejoin="round" d="M6.166 16.333H9.499V21.333H6.166z"></path>
        </svg>
    )
}
