import React from 'react'
import { IIconProps } from './type'

export const DownChevronOld: React.FC<React.SVGProps<SVGSVGElement>> = ({
    height = '24',
    width = '24',
    color = '#808080',
    ...restProps
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ transform: 'rotate(-180deg)', color: 'rgb(153, 153, 153)' }}
            {...restProps}
        >
            <path d="M16 14L12 10L8 14" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
