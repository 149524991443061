import { IRoomTraveller } from '@/components/common/HeaderWithModifySearch/type'
import { DEFAULT_ROOM_OCCUPANCY } from '@/constants/components'
import { deepClone, pluralize } from '@/utils/general/browserHelper'
import { getFormattedDate } from '@/utils/general/date'

// @ts-ignore TODO: @ fix type error
export const getDefaultRoomState = (wrapWithArray = false) => {
    const room: IRoomTraveller = deepClone(DEFAULT_ROOM_OCCUPANCY)
    return wrapWithArray ? [room] : room
}

export const getTravelerText = (rooms: IRoomTraveller[]) => {
    const str = `${pluralize(rooms?.length, 'room')}`

    let totalHeadCount = 0
    rooms?.forEach(element => {
        totalHeadCount += element?.adults?.count ?? 0
        totalHeadCount += element?.children?.count ?? 0
    })

    return `${str}, ${pluralize(totalHeadCount, 'guest')}`
}

export const getDetailsPageNameString = (name: string, id: string) => {
    return name?.replace(/\s+/g, '-').toLowerCase() + '-' + id
}

export const getDetailsPageUrlDates = (from: Date, to: Date) => {
    return getFormattedDate(from, 'DDMMYY') + '|' + getFormattedDate(to, 'DDMMYY')
}

export const getDetailsUrlPax = (travellers: IRoomTraveller[]) => {
    let trvlStr = ''
    travellers.map((room, idx, array) => {
        const ageArr = room.children?.metadata?.map(({ age }) => age)
        trvlStr += room.adults.count + ',' + room.children.count
        trvlStr += ageArr?.length ? `-${ageArr}` : ''
        if (idx !== array.length - 1) trvlStr += '|'
    })
    return trvlStr
}

/**
 *
 * @param isFocus if section is focused
 * @param isError true if there is a error in section
 * @returns  style object
 */
export const getModifySearchBorderStyle = (isFocus: boolean, isError?: boolean) => {
    const borderStyle = { border: '1px solid #1A1A1A', borderRadius: '12px' }
    const errorStyle = { borderColor: 'red' }

    const styles = isFocus ? borderStyle : {}
    isError && Object.assign(styles, errorStyle)

    return styles
}
