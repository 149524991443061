export const EditPencil: React.FC<React.SVGProps<SVGSVGElement>> = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
            <path
                stroke="#1A1A1A"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.25"
                d="M12.36 19h7.358m-3.68-13.492a1.735 1.735 0 112.454 2.453L8.271 18.182 5 19l.818-3.271 10.22-10.221z"
            ></path>
        </svg>
    )
}
