import dayjs from 'dayjs'

/**
 * When the page is running from server side then these constant date variables remains build time value
 * for example, if build is genrated on 20 july then these dates will be like today:20 and tommorow: 21 forever.
 * So to get look time date we are using getter function.
 *
 */
export const PREPARED_DATES = {
    get TODAY() {
        return new Date()
    },
    get TOMORROW() {
        const tDay = new Date()
        tDay.setDate(tDay.getDate() + 1)
        return tDay
    },
    get DAY_AFTER_TOMORROW() {
        const tDay = this.TOMORROW
        tDay.setDate(tDay.getDate() + 1)
        return tDay
    },
    get FORMATTED_TODAY() {
        return dayjs().format('DD/MM/YYYY')
    },
    get FORMATTED_TOMORROW() {
        return dayjs().add(1, 'day').format('DD/MM/YYYY')
    },
    get FORMATTED_DAY_AFTER_TOMORROW() {
        return dayjs().add(2, 'day').format('DD/MM/YYYY')
    },
    get CURRENT_YEAR() {
        return dayjs().year()
    }
}

//number of milli seconds in a single hour.
export const MILLI_SEC_IN_A_MINUTE = 60 * 1000

//number of milli seconds in a single hour.
export const MILLI_SEC_IN_A_HOUR = 3600 * 1000

//number of milli seconds in a day of 24hrs.
export const MILLI_SEC_IN_A_DAY = 24 * MILLI_SEC_IN_A_HOUR
