import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { AB_API_MOCK, CUSTOM_COOKIE_NAME } from '@/constants/experiment'
import { getAbConfigData } from '@/network/services/abConfig.service'
import { RootState } from '@/redux/store/configureStore'
import { setCustomExperimentCookie } from '@/utils/experiment'

import { IAbConfigInitialState } from './type'

const initialState: IAbConfigInitialState = {
    data: {},
    loading: false
}

export const fetchAbTestData = createAsyncThunk('abConfig/abTest', async () => {
    try {
        const response = AB_API_MOCK
        // const response = (await getAbConfigData()) as Record<string, any>;
        /**
         * uncommenting above to pass the ab values to BE for ranking exp.
         */
        // response.isSearchV3 = true;
        return response
    } catch (e) {
        return {}
    }
})

export const abConfigSlice = createSlice({
    name: 'abConfig',
    initialState,
    reducers: {
        setAbData: (state, action: PayloadAction<Record<string, string>>) => {
            const { payload } = action || {}
            state.data = (payload || {}) as Record<string, string>
        }
    },
    extraReducers(builder) {
        // TODO: Assign type to action instead of type alias
        builder.addCase(fetchAbTestData.fulfilled, (state, action) => {
            const { payload } = action || {}
            state.data = (payload || {}) as Record<string, string>
            state.loading = false
            for (const key of Object.keys(CUSTOM_COOKIE_NAME)) {
                setCustomExperimentCookie(key)
            }
        }),
            builder.addCase(fetchAbTestData.rejected, state => {
                state.data = {}
                state.loading = false
            })
    }
})

const getAbData = (state: RootState) => state.abConfig.data

const { reducer, actions } = abConfigSlice
const { setAbData } = actions || {}
export { getAbData, setAbData }

export default reducer
