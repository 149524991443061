import React from 'react'

export const Flights: React.FC<React.SVGProps<SVGSVGElement>> = ({ width = '24', height = '24', ...restProps }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="#3366cc" viewBox="0 0 19 19">
            <path
                fill="#36C"
                d="M15.2 1A1.98 1.98 0 1118 3.8l-2.45 2.45 1.75 9.45-1.4 1.4-4.2-7-3.5 3.5v2.8l-1.4 1.4-1.4-4.2-4.2-1.4 1.4-1.4h2.8l3.5-3.5-7-4.2 1.4-1.4 9.45 1.75L15.2 1z"
            ></path>
            <path
                fill="#36C"
                d="M6.8 17.8l-.237.08a.25.25 0 00.414.097L6.8 17.8zm-5.6-5.6l-.177-.176a.25.25 0 00.098.413L1.2 12.2zm4.2-1.4v.25a.25.25 0 00.177-.073L5.4 10.8zm2.8 2.8l-.177-.176a.25.25 0 00-.073.176h.25zm-5.6-2.8v-.25a.25.25 0 00-.177.073l.177.177zm5.6 5.6l.177.177a.25.25 0 00.073-.177H8.2zm-2.8-2.8l.237-.079a.25.25 0 00-.158-.158l-.08.237zM3.3 1.7l.045-.246a.25.25 0 00-.222.07L3.3 1.7zm14 14l.177.177a.25.25 0 00.069-.222l-.246.045zM1.9 3.1l-.177-.177a.25.25 0 00.048.391L1.9 3.1zm7 4.2l.177.177a.25.25 0 00-.048-.391L8.9 7.3zm3.85-3.85l-.046.246a.25.25 0 00.223-.07l-.177-.176zm2.8 2.8l-.177-.177a.25.25 0 00-.069.223l.246-.046zm.35 10.85l-.214.129a.25.25 0 00.39.048L15.9 17.1zm-4.2-7l.214-.128a.25.25 0 00-.39-.049l.176.177zM1.377 12.377l1.4-1.4-.354-.354-1.4 1.4.354.354zM2.6 11.05h2.8v-.5H2.6v.5zm5.35 2.55v2.8h.5v-2.8h-.5zm.073 2.624l-1.4 1.4.354.353 1.4-1.4-.354-.354zm-2.544-2.86l-4.2-1.4-.158.473 4.2 1.4.158-.474zm1.558 4.357l-1.4-4.2-.474.158 1.4 4.2.474-.158zM2.077 3.277l1.4-1.4-.354-.354-1.4 1.4.354.354zm3.5 7.7l3.5-3.5-.354-.354-3.5 3.5.354.354zm3.452-3.891l-7-4.2-.258.428 7 4.2.258-.428zm-5.775-5.14l9.45 1.75.091-.492-9.45-1.75-.09.492zm9.673 1.68l2.45-2.45-.354-.353-2.45 2.45.354.354zm4.896-.003l-2.45 2.45.354.354 2.45-2.45-.354-.354zm-2.519 2.673l1.75 9.45.492-.091-1.75-9.45-.492.09zm1.82 9.227l-1.4 1.4.353.354 1.4-1.4-.354-.354zm-1.01 1.449l-4.2-7-.428.257 4.2 7 .428-.257zm-4.59-7.049l-3.5 3.5.353.354 3.5-3.5-.354-.354zm6.3-8.746a1.73 1.73 0 010 2.446l.353.354a2.23 2.23 0 000-3.154l-.354.354zm.353-.354a2.23 2.23 0 00-3.154 0l.354.354a1.73 1.73 0 012.446 0l.354-.354z"
            ></path>
        </svg>
    )
}
