import { NON_DEV_ENVIRONMENT } from '@/utils/general/environment'

export const FASTUI_DOMAIN = 'https://fastui.cltpstatic.com'
export const SEO_IMAGE_DOMAIN = 'https://ui.cltpstatic.com'

export const IMAGE_DOMAIN = (transformation?: string) =>
    `${FASTUI_DOMAIN}/image/upload/${transformation ? transformation : ''}/${
        NON_DEV_ENVIRONMENT.includes(process.env.NEXT_PUBLIC_CT_ENV || '') ? 'flights' : 'flights_qa'
    }`

export const DEFAULT_IMAGE_URL = 'https://www.cleartrip.com/offermgmt/flightBankIcons/defaultImg.png'

export const FALLBACK_ERROR_PATH = '/offermgmt/flightBankIcons/error.png' //TODO need to change the folder change

export const TRANSFORMATIONS = {
    QUALITY: 'quality',
    FILE: 'file',
    DPR: 'dpr',
    GRAVITY: 'gravity'
}

export const IMAGE_TRANSFORMATIONS = {
    [TRANSFORMATIONS.QUALITY]: 75,
    [TRANSFORMATIONS.FILE]: 'auto',
    [TRANSFORMATIONS.DPR]: 2
}
