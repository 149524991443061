import isEqual from 'lodash.isequal'
import { GetServerSidePropsContext } from 'next'
import { ParsedUrlQuery } from 'querystring'
import UAParser from 'ua-parser-js'
import { v4 as uuid } from 'uuid'

import { setCookie } from '@/utils/general/cookie'
import StatsigManager from '@/analytics/statsig/index.server'
import { formDetailsApiPayload } from './details'
import { formResultsApiPayload } from './results'

import { flightS_SEARCH_CRITERIA, META_KEYS, X_LINEAGE_ID, STATSIG_USER_STABLE_ID } from '@/constants/browser'
import { getUnifiedHeader } from '@/constants/network'
import { AVOID_WORDS_IN_URL, PAGE_NAME } from '@/constants/pages/common'

import { ResultsIntersectionPayload } from '@/types/network/results'
import {
    STATSIG_OVERRIDE_KEY,
    formStatsigExperimentObjectAndUpdateValues,
    getStatsigCookieForServerSide
} from '../experiment/statsig'
import { isEmpty } from '../general/browserHelper'
import { DEFAULT_STATSIG_EXPERIMENTS } from '@/analytics/statsig/statsigConfig'

export const decodeCookie = (cookieString: string) => {
    const parsedCookie: Record<string, string> = {}

    if (cookieString) {
        const cookieSplit = cookieString.split(';')
        cookieSplit.forEach(cookie => {
            if (cookie) {
                const [key, value] = cookie.split('=')
                parsedCookie[key.trim()] = value
            }
        })
    }

    return parsedCookie
}

export const encodeCookie = (cookieObj: Record<string, string>) => {
    let parsedString = ''

    for (const [key, value] of Object.entries(cookieObj)) {
        parsedString += ` ${key}=${value};`
    }

    return parsedString
}

export const updateCookie = (cookie: string, key: string, value: string) => {
    const decodedCookie = decodeCookie(cookie)
    const updateCookie = {
        ...decodedCookie,
        [key]: value
    }
    const encodedCookie = encodeCookie(updateCookie)
    return encodedCookie
}

export function getCookieKeyFromCookieString(cookie: string) {
    return (cookie.split('=')[0] ?? '').trim()
}

export function hasSpecificCookieInCookieString(existingCookie: string, updatedCookieString: string) {
    if (typeof existingCookie !== 'string') return false

    const cookieKey = getCookieKeyFromCookieString(updatedCookieString)

    return existingCookie.split(';').filter(cookie => getCookieKeyFromCookieString(cookie) === cookieKey).length > 0
}

export function updateSpecificCookieInOverallCookieString(existingCookie: string, updatedCookieString: string) {
    const decodedUpdatedCookie = decodeCookie(updatedCookieString)
    const cookieKey = getCookieKeyFromCookieString(updatedCookieString)

    const cookiesArray = existingCookie.split(';')
    const startingIndex = cookiesArray.findIndex(cookie => getCookieKeyFromCookieString(cookie) === cookieKey)

    if (startingIndex > -1) {
        for (let i = startingIndex; i < cookiesArray.length; ++i) {
            const key = getCookieKeyFromCookieString(cookiesArray[i])
            if (decodedUpdatedCookie[key]) {
                // a space is required after before each cookie being concated
                cookiesArray[i] = ` ${key} = ${decodedUpdatedCookie[key]}`
                delete decodedUpdatedCookie[key]
            }

            if (decodedUpdatedCookie && Object.keys(decodedUpdatedCookie).length <= 0) {
                break
            }
        }

        return cookiesArray.join(';')
    }

    return existingCookie
}

/**
 *
 * @param existingCookie : exitisting cookie available on request.
 * @param cookieArr : new cookies array
 * @returns string of cookies.
 */
export const enhanceCookieFromArrayCookie = (existingCookie: string, cookieArr: string[]) => {
    if (!existingCookie) {
        existingCookie = ''
    } else {
        //handle a case where other cookie owner is not adding ; at the end of cookie. ex. WZRK_S_Z8R-KK8-W74Z
        if (existingCookie[existingCookie.length - 1] !== ';') {
            existingCookie += ';'
        }
    }

    cookieArr.map(item => {
        if (hasSpecificCookieInCookieString(existingCookie, item)) {
            existingCookie = updateSpecificCookieInOverallCookieString(existingCookie, item)
        } else {
            // a space is required after before each cookie being concated
            existingCookie = existingCookie?.concat(` ${item};`)
        }
    })

    return existingCookie
}

/**
 *
 * @param context ssr context
 * @returns array of cookies
 * Method:
 * Now There are 8 keys of meta, if we are getting any of them in query parameter then we just need to set all of 8 keys in cookies and request with  either with data or blank string
 *  and along with send that  in cookies.
 * If there is no key in query parameter then we are not going change any cookie.
 *
 */
export const getMetaCookies = (context: Partial<GetServerSidePropsContext>) => {
    const { query } = context
    const utmCookieArray: string[] = []

    //Check and add the keys for the meta from query.
    let hasMetaKeyInQuery = false
    META_KEYS.forEach(metaKey => {
        const cookieValue = query?.[metaKey] || ''

        if (cookieValue) {
            hasMetaKeyInQuery = true
        }

        //This line will always be outside the if block, we will prepare cookie but will set if the hasMetaKeyInQuery condition is true.
        utmCookieArray.push(setCookie({ name: metaKey, value: cookieValue as string, expiryInDay: 1 }))
    })

    return hasMetaKeyInQuery ? utmCookieArray : []
}

/**
 *
 * @param query ParsedUrlQuery
 * This function will set the client side meta cookies after checking if is there any meta cookie available.
 */
export const setMetaCookiesOnClient = (query: ParsedUrlQuery) => {
    let hasMetaKeyInQuery = false
    META_KEYS.forEach(metaKey => {
        if (query[metaKey]) {
            hasMetaKeyInQuery = true
        }
    })

    if (hasMetaKeyInQuery) {
        getMetaCookies({
            query
        } as GetServerSidePropsContext)
    }
}

/**
 *
 * @param context : SSR next context
 * @returns cookie header.
 * We are appending the utm source (mata things ) in qa with cookies.
 */

interface IHeadersForSSRConfig {
    addUtmSourceInCookie?: boolean
    addLinageIdInCookie?: boolean
    statsigConfig?: {
        addStatsigInCookie: boolean
        pageName: PAGE_NAME
        customConfig?: Record<string, string | number | boolean | Array<string> | undefined>
    }
}

export const getHeadersForSSR = async (context: GetServerSidePropsContext, otherConfigs?: IHeadersForSSRConfig) => {
    const { req } = context

    const {
        addUtmSourceInCookie = false,
        addLinageIdInCookie = false,
        statsigConfig: { addStatsigInCookie = false, pageName: statsigPageName = '', customConfig = {} } = {}
    } = otherConfigs || {}

    let xCookie = req.headers.cookie
    const cookieForSettingInHeader: string[] = []
    const userAgent = req.headers['user-agent'] || ''
    const parser = new UAParser(userAgent)

    // sometimes needs to override cookie by manual script for force a particular bucket, there we'll be setting h_override_statsig to true, and then some cookie, in that case it should override the statsig cookie
    const shouldOverride = req.cookies[STATSIG_OVERRIDE_KEY] === 'true'
    if (addUtmSourceInCookie) {
        //get meta related cookies.
        const utmSourceCookie = getMetaCookies(context) || []

        if (utmSourceCookie.length) {
            xCookie = enhanceCookieFromArrayCookie(xCookie ? xCookie : '', utmSourceCookie)
            cookieForSettingInHeader.push(...utmSourceCookie)
        }
    }

    if (addLinageIdInCookie) {
        const lineageCookies = getLineageIdCookie(context)

        if (lineageCookies?.length) {
            xCookie = enhanceCookieFromArrayCookie(xCookie ? xCookie : '', lineageCookies)
            cookieForSettingInHeader.push(...lineageCookies)
        }
    }
    const StatsigManagerInstance = new StatsigManager()
    if (addStatsigInCookie && statsigPageName) {
        const stasigUserId = req?.cookies?.[STATSIG_USER_STABLE_ID] || uuid()
        cookieForSettingInHeader.push(
            setCookie({ name: STATSIG_USER_STABLE_ID, value: stasigUserId, expiryInDay: 365 })
        )
        const latestExperimentValues = await StatsigManagerInstance.getAllStatigAbValues(
            stasigUserId,
            statsigPageName,
            customConfig
        )
        const existingExperimentObject = getStatsigCookieForServerSide(context)

        if (!isEmpty(latestExperimentValues)) {
            const statsigCookieValue = formStatsigExperimentObjectAndUpdateValues({
                latestExperimentValues,
                existingExperimentObject,
                shouldOverride
            })
            xCookie = enhanceCookieFromArrayCookie(xCookie ? xCookie : '', [statsigCookieValue])
            cookieForSettingInHeader.push(statsigCookieValue)
        }
    } else if (!isEmpty(DEFAULT_STATSIG_EXPERIMENTS)) {
        // We'll be using it for default value for some experiment which is 100% i.e. that doesn't require statisg call
        const existingExperimentObject = getStatsigCookieForServerSide(context)
        const statsigCookieValue = formStatsigExperimentObjectAndUpdateValues({
            latestExperimentValues: {},
            existingExperimentObject: existingExperimentObject,
            shouldOverride
        })
        xCookie = enhanceCookieFromArrayCookie(xCookie ? xCookie : '', [statsigCookieValue])
        cookieForSettingInHeader.push(statsigCookieValue)
    }

    const unified_headers = {
        ...getUnifiedHeader(),
        deviceModel: parser?.getDevice()?.model?.toLowerCase()
    }

    let headers = {
        // TODO: @Khushahal we have to uncomment once BE fixes their header size
        // ...req?.headers,
        cookie: xCookie,
        'x-unified-header': JSON.stringify(unified_headers)
    }

    headers = {
        ...headers,
        cookie: xCookie
    }

    if (cookieForSettingInHeader.length) {
        context.res.setHeader('set-cookie', cookieForSettingInHeader)
    }

    return headers
}

export const getSrpPaxInfo = (query: ParsedUrlQuery) => {
    const paxInfo: Record<string, string | number | number[] | string[]> = {}
    const { num_rooms = 1 } = query || {}

    for (let i = 0; i < Number(num_rooms); i++) {
        paxInfo[`adults${i + 1}`] = query[`adults${i + 1}`] || ''
        paxInfo[`children${i + 1}`] = query[`children${i + 1}`] || ''
        paxInfo[`ca${i + 1}`] = query[`ca${i + 1}`] || ''
    }
    return paxInfo
}

export const getLineageIdCookie = (context: GetServerSidePropsContext) => {
    const { req, resolvedUrl, query } = context || {}
    const cookies = req.cookies
    const searchCriteriaCookie = cookies[flightS_SEARCH_CRITERIA]
    const lineageIdCookie = cookies[X_LINEAGE_ID]

    let searchCriteria = {}
    let existingSearchCriteria = JSON.parse(searchCriteriaCookie || '{}')
    const isResultsPage = resolvedUrl.includes('/flights/results')

    if (isResultsPage) {
        const {
            checkInDate,
            checkOutDate,
            roomAllocations,
            cityId,
            localityId,
            flightId: resultsflightId
        } = formResultsApiPayload({ query }) as ResultsIntersectionPayload
        const { flightId, ...rest } = existingSearchCriteria || {}
        searchCriteria = {
            checkInDate,
            checkOutDate,
            paxInfo: roomAllocations,
            destinationCode: localityId || cityId || resultsflightId
        }
        existingSearchCriteria = rest
    } else {
        const { checkInDate, checkOutDate, roomAllocations, flightId } = formDetailsApiPayload(query)
        const { destinationCode, ...rest } = existingSearchCriteria || {}
        searchCriteria = {
            checkInDate,
            checkOutDate,
            paxInfo: roomAllocations,
            flightId: flightId
        }
        existingSearchCriteria = rest
    }

    const setNewLineageIdCookie = () => {
        const flightSearchCriteria = {
            destinationCode: '',
            flightId: '',
            ...searchCriteria
        }
        const lineageId = uuid()
        const cookieArray: string[] = []

        //need to update this method as well.
        cookieArray.push(`${setCookie({ name: flightS_SEARCH_CRITERIA, value: JSON.stringify(flightSearchCriteria) })}`)
        cookieArray.push(`${setCookie({ name: X_LINEAGE_ID, value: lineageId })}`)

        return cookieArray
    }

    if (lineageIdCookie) {
        if (!isEqual(searchCriteria, existingSearchCriteria)) {
            return setNewLineageIdCookie()
        }
    } else {
        return setNewLineageIdCookie()
    }
}

/**
 * @param url url of the page
 * @returns boolean true if we url validation failed.
 * We experienced various attacks by using script words in URLS so to avoid them adding this method here.
 * Right now, it is experimental basis and We will add more keywords or form in this method as we will find things.
 */
export const isURLValid = (url: string) => {
    const lowerString = url?.toLowerCase()
    return AVOID_WORDS_IN_URL.find(word => lowerString.includes(word))
}
