import { PAGE_NAME_TYPE } from '@/constants/pages/common'

export const enum STATSIG_EXPERIMENT_NAMES {
    search_v3 = 'search_v3',
    hpm_hpi_removal = 'hpm_hpi_removal',
    city_mumbai_exp_dt = 'city_mumbai_exp_dt',
    h_exp_dom = 'h_exp_dom'
}

export type STATSIG_EXPERIMENT_NAMES_TYPE = `${STATSIG_EXPERIMENT_NAMES}`
export type STAGSIG_EXPERIMENT_VALUES = Record<STATSIG_EXPERIMENT_NAMES_TYPE, string | null>

export interface IStatsigValueConfig {
    experimentName: string
    experimentValue: string
    defaultValue: string
}

export type StatsigExperimentAbKeysConfigType = {
    [key in PAGE_NAME_TYPE]: Partial<Record<STATSIG_EXPERIMENT_NAMES_TYPE, IStatsigValueConfig>>
}
