import React, { PropsWithChildren } from 'react'

import classNames from 'classnames'
import { Container } from '@cleartrip/ct-design-components'

import { SlotBehaviours } from '@/types'
import { CustomEventTypes, SourceType } from '@/utils/customEvents/type'
import { getNestedField } from '@/utils/general'

import { BaseComponentProps } from './type'
import ObservableComponent from './ObservableComponent'
import CustomEventsComponent from './CustomEventsComponent'
import StickyCalculatedComponent from './StickyCalculatedComponent'
import FixedTopComponentHOC from './FixedTopComponentHOC'
import ResizeableComponent from './ResizeableComponent'
import useInView from '@/utils/hooks/useInView'
import { delayTaskWithIdleCallback } from '@/utils/performance'
import { isEmpty } from '@/utils/general/browserHelper'
import { PARENT_ELEMENT_INHERIT_STYLES } from '@/constants/analytics'
import { trackingDispatcher } from '@/utils/tracking'

const BaseComponent: React.FC<PropsWithChildren<BaseComponentProps>> = ({
    children,
    behaviour,
    data,
    ownProps,
    dispatcher,
    pageName,
    ...rest
}) => {
    const componentClasses: string[] = []
    const key = getNestedField(['data', 'key'], data)
    const { ravenTracking } = data || {}

    const { multiTracking: { onImpression = {} } = {} } = ravenTracking || {}
    const isSkipImpression = isEmpty(onImpression)

    const customEventMeta = getNestedField(['data', 'customEvents'], data)
    const sourceType = getNestedField(
        [`${CustomEventTypes.SHOW_ON_OUT_OF_VIEWPORT}`, 'extraProps', 'sourceType'],
        customEventMeta
    )

    const { ref } = useInView({
        //SRP page is using virtualization So it unmount and mount the component so not working in that.
        triggerOnce: false,
        ...(onImpression?.options || {}),
        ...(ownProps?.customImpressionOptions || {}), //custom options from parent own props
        skip: isSkipImpression,
        pageName,
        onChange: inView => {
            if (inView) {
                const { eventName, eventData } = onImpression
                delayTaskWithIdleCallback(() => {
                    trackingDispatcher?.({ eventName, eventData }, dispatcher)
                })
            }
        }
    })
    if (behaviour === SlotBehaviours.STICKY_TOP) {
        componentClasses.push('p-sticky t-0 w-100p z-50')
        // TODO Remove hard Code
        // Hard Coded to handle the z index of header; There is no differnece between image behaviour and header behaviour
        // for STICKY. And only header is needed to make it stick.
        // Why not done in the  specific header component?. Ans:  http://surl.li/hohzl
        if (data) {
            if ('key' in data.data) {
                if (data.data.key === 'detailsHeader') componentClasses.push('z-100')
            }
        }
    }

    if (behaviour === SlotBehaviours.FIXED_BOTTOM) {
        componentClasses.push('p-absolute b-0 w-100p')
    }

    if (behaviour === SlotBehaviours.OBSERVABLE) {
        return (
            <ObservableComponent
                dispatcher={dispatcher}
                behaviour={behaviour}
                data={data}
                ownProps={ownProps}
                pageName={pageName}
                {...rest}
            >
                {children}
            </ObservableComponent>
        )
    }

    if (behaviour === SlotBehaviours.STICKY_TOP_CALCULATED) {
        return (
            <StickyCalculatedComponent
                dispatcher={dispatcher}
                data={data}
                ownProps={ownProps}
                pageName={pageName}
                {...rest}
            >
                {children}
            </StickyCalculatedComponent>
        )
    }

    if ([SourceType.SENDER, SourceType.RECEIVER].includes(sourceType)) {
        return (
            <CustomEventsComponent
                classname={classNames(componentClasses)}
                dispatcher={dispatcher}
                behaviour={behaviour}
                data={data}
                ownProps={ownProps}
                pageName={pageName}
                {...rest}
            >
                {children}
            </CustomEventsComponent>
        )
    }

    if (behaviour === SlotBehaviours.FIXED_TOP_CALCULATED) {
        return (
            <FixedTopComponentHOC dispatcher={dispatcher} data={data} ownProps={ownProps} pageName={pageName} {...rest}>
                {children}
            </FixedTopComponentHOC>
        )
    }

    if (behaviour === SlotBehaviours.RESIZEABLE) {
        return <ResizeableComponent dispatcher={dispatcher}>{children}</ResizeableComponent>
    }

    if (key || componentClasses.length || ref) {
        return (
            <Container
                ref={ref}
                id={key ? key : null}
                className={classNames(componentClasses)}
                style={isSkipImpression ? undefined : PARENT_ELEMENT_INHERIT_STYLES}
                {...rest}
            >
                {children}
            </Container>
        )
    }

    return <>{children}</>
}

export default BaseComponent
