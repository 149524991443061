import { getCookie } from './cookie'
export function getUserAuthValues() {
    const [email, profileName, gender, photo, userId] = decodeURIComponent(getCookie('userid') || '').split('|')
    return {
        email,
        profileName,
        gender,
        photo,
        userId
    }
}

export const isUserSignedIn = () => {
    const userObject = getUserAuthValues() || {}
    const usermiscVal = decodeURIComponent(getCookie('usermisc') || '').split('|')
    const signedIn =
        usermiscVal.includes('SIGNED_IN') && userObject.userId && userObject.userId.length > 0 ? true : false
    return signedIn
}

export const isUserSignedInServerSide = (cookies: Partial<{ [key: string]: string }>) => {
    const [email, profileName, gender, photo, userId] = decodeURIComponent(cookies['userid'] || '').split('|')
    const userObject = {
        email,
        profileName,
        gender,
        photo,
        userId
    }
    const usermiscVal = decodeURIComponent(cookies['usermisc'] || '').split('|')
    const signedIn =
        usermiscVal.includes('SIGNED_IN') && userObject.userId && userObject.userId.length > 0 ? true : false
    return signedIn
}
