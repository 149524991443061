import { Container, Spinner } from '@cleartrip/ct-design-components'

const SidesheetShimmer = () => {
    return (
        <Container className="flex flex-middle flex-center h-100vh">
            <Spinner />
        </Container>
    )
}

export default SidesheetShimmer
