import { PropsWithChildren, memo, useRef } from 'react'
import classNames from 'classnames'

import { Container } from '@cleartrip/ct-design-components'

import { getNestedField, isServer } from '@/utils/general'
import { allowEventDispatch, performFunctionOnCustomEvents } from '@/utils/customEvents'
import IntersectionObserverWrapper from '@/components/common/IntersectionObserverWrapper'
import { SourceType } from '@/utils/customEvents/type'

import useCustomEvents from './useCustomEvents'
import { BaseComponentProps } from '../type'

interface ICustomEventsComponentProps extends PropsWithChildren<BaseComponentProps> {
    classname: string
}

// TODO @raghav add fix for handling multiple custom events
function CustomEventsComponent({ dispatcher, data, ownProps, children, classname }: ICustomEventsComponentProps) {
    const elemRef = useRef<HTMLDivElement>(null)

    const key = getNestedField(['data', 'key'], data)
    const { customEventMeta, sourceType, options } = useCustomEvents({ dispatcher, data })

    const onIntersectionObserverCallback = ([entry]: IntersectionObserverEntry[]) => {
        if (customEventMeta) {
            performFunctionOnCustomEvents(customEventMeta, (eventName, customEventPayload) => {
                const newEvent = new CustomEvent(eventName, {
                    detail: {
                        senderId: customEventPayload.extraProps.senderId,
                        isVisible: entry.isIntersecting
                    }
                })

                if (!isServer() && allowEventDispatch(eventName, entry, customEventPayload)) {
                    document.dispatchEvent(newEvent)
                }
            })
        }
    }

    const metaData = {
        key,
        pageName: ownProps?.pageName as string,
        classname
    }

    if (sourceType === SourceType.RECEIVER) {
        return (
            <Container id={key ? key : null} className={classNames(classname, 'base-container')}>
                {children}
            </Container>
        )
    }

    return (
        <IntersectionObserverWrapper
            ref={elemRef}
            onIntersectionObserverCallback={onIntersectionObserverCallback}
            options={options}
            metaData={metaData}
        >
            {children}
        </IntersectionObserverWrapper>
    )
}

export default memo(CustomEventsComponent)
