export const errorTypeWidgetResponse: Record<number, any> = {
    400: {
        slotData: {
            type: 'ERROR_WIDGET',
            data: {
                heading: 'Sorry our servers are stumped with your request!',
                subheading: 'Looks like you’ve entered a wrong URL, please check the URL or try searching again.',
                placeholderImage: {
                    type: 'IMAGE',
                    data: {
                        url: '/offermgmt/flightsErrorWidget/serverTakingLong.png',
                        caption: 'Error screen'
                    }
                },
                primaryCTA: {
                    type: 'BUTTON',
                    data: {
                        text: 'Go to home'
                    },
                    action: {
                        type: 'NAVIGATE',
                        payload: {
                            type: 'REPLACE',
                            url: '/flights'
                        }
                    },
                    tracking: {}
                }
            }
        }
    },
    404: {
        slotData: {
            type: 'ERROR_WIDGET',
            data: {
                heading: 'Sorry our servers are stumped with your request!',
                subheading: 'Looks like you’ve entered a wrong URL, please check the URL or try searching again.',
                placeholderImage: {
                    type: 'IMAGE',
                    data: {
                        url: '/offermgmt/flightsErrorWidget/serverTakingLong.png',
                        caption: 'Error screen'
                    }
                },
                primaryCTA: {
                    type: 'BUTTON',
                    data: {
                        text: 'Go to home'
                    },
                    action: {
                        type: 'NAVIGATE',
                        payload: {
                            type: 'REPLACE',
                            url: '/flights'
                        }
                    },
                    tracking: {}
                }
            }
        }
    },
    500: {
        slotData: {
            type: 'ERROR_WIDGET',
            data: {
                heading: 'Something went wrong!',
                subheading: 'Please wait a few minutes before you try again.',
                placeholderImage: {
                    type: 'IMAGE',
                    data: {
                        url: '/offermgmt/flightsErrorWidget/serverTakingLong.png',
                        caption: 'Error screen'
                    }
                },
                primaryCTA: {
                    type: 'BUTTON',
                    data: {
                        text: 'Try again'
                    },
                    action: {
                        type: 'NAVIGATE',
                        payload: {
                            type: 'RELOAD'
                        }
                    },
                    tracking: {}
                }
            }
        }
    },

    //Default error details , we can change the data for default one.
    100: {
        slotData: {
            type: 'ERROR_WIDGET',
            data: {
                heading: 'Something went wrong!',
                subheading: 'Please wait a few minutes before you try again.',
                placeholderImage: {
                    type: 'IMAGE',
                    data: {
                        url: '/offermgmt/flightsErrorWidget/serverTakingLong.png',
                        caption: 'Error screen'
                    }
                },
                primaryCTA: {
                    type: 'BUTTON',
                    data: {
                        text: 'Try again'
                    },
                    action: {
                        type: 'NAVIGATE',
                        payload: {
                            type: 'RELOAD'
                        }
                    },
                    tracking: {}
                }
            }
        }
    }
}
