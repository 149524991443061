/**
 * Store the root margin for the given page name.
 * This class caches the root-margin of every page so that it doesn't have to recalculate every time it's called.
 * In future if we need to change the root margin we can easily change it by calling this method using action dispatcher.
 */
class CachedRootMarginByPage {
    private static instance: CachedRootMarginByPage
    private rootMargins = new Map<string, string>([['default', '0px 0px 0px 0px']])

    static getInstance() {
        if (this.instance) {
            return this.instance
        }
        this.instance = new CachedRootMarginByPage()
        return this.instance
    }

    get(pageName: string): string | undefined {
        return this.rootMargins.get(pageName)
    }

    set(pageName: string, margin: string) {
        this.rootMargins.set(pageName, margin)
    }

    has(pageName: string) {
        return this.rootMargins.has(pageName)
    }
}

const CachedRootMarginByPageInstance = Object.freeze(CachedRootMarginByPage.getInstance())

export default CachedRootMarginByPageInstance
