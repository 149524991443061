import { REQUEST_METHODS } from '@/constants/network'
import baseFetch from '@/network/baseFetch'
import { getLanguageMeta } from '@/utils/general/meta'
import { getMerchandisingQueryFK } from '@/utils/srp'
import { SearchIntentType } from '@/types/srp'

type CouponsPayloadType = {
    dataId: string
    searchIntent: SearchIntentType
    couponIntents: {
        requestedIds: string[][]
        travelOptions: {
            [x: string]: {
                travelOptionId: string
                fareIds: any
            }
        }[]
        travelOptionIdsToFareIdsMap: {}[]
    }
}
export const airportSearch = async (searchValue: string) => {
    try {
        const header = {
            caller: 'UI'
        }
        const response = await baseFetch({
            url: `/places/airports/search?string=${searchValue}`,
            method: REQUEST_METHODS.GET,
            headers: header
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.error(e)
        return Promise.reject()
    }
}

export const getSearchResult = async (query: string) => {
    try {
        const header = {
            caller: 'UI'
        }
        const response = await baseFetch({
            url: `/flight/search/v2?${query}`,
            method: REQUEST_METHODS.GET,
            headers: header
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.error(e)
        return Promise.reject()
    }
}

export const airportSearchBluk = async payload => {
    try {
        const header = {
            caller: 'UI'
        }
        const response = await baseFetch({
            url: '/places/airports/search/v3',
            method: REQUEST_METHODS.POST,
            headers: header,
            body: JSON.stringify(payload)
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.log(e)
        return Promise.reject()
    }
}

export const getRTFlightCouponsData = async (payload: CouponsPayloadType) => {
    try {
        const header = {
            caller: 'UI'
        }
        const response = await baseFetch({
            url: `/flight/fetch-coupon-discount`,
            method: REQUEST_METHODS.POST,
            headers: header,
            body: JSON.stringify(payload)
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.error(e)
        return Promise.reject()
    }
}

export const fetchSrpCoupons = async (action: any, isMetaSource = false) => {
    try {
        const { locale } = getLanguageMeta()
        const { isCfw } = action || {}
        const tripType = action.multiCity ? 'OW' : action.arrivalDate ? 'RT' : 'OW'
        let appAgent = 'pwa'
        const isProdPath = 'prod'
        let offerPath = isMetaSource ? 'itin_meta' : isCfw ? 'cfw_srp_itin' : 'srp_itin'
        const Verticalpath = 'flights_'
        const query = `${getMerchandisingQueryFK(
            action.international,
            tripType
        )}_${locale.toLowerCase()}.json?${new Date().getTime()}`
        let domainForSSr = 'https://ui.cltpstatic.com'
        const url = `/offermgmt/fkMarketing/${isProdPath}/${Verticalpath}${offerPath}_${appAgent}_${query}`
        const headers = {
            'api-version': '3.0'
        }

        const response = await baseFetch({
            url,
            method: REQUEST_METHODS.GET,
            headers
        })
        return response
    } catch (ex) {
        return []
    }
}

export const CFWcreateItineraryApi = async (payload: object, requestHeaders: object) => {
    const url = `/itin/v7/itinerary/create`
    const headers = {
        'api-version': '3.0',
        ...requestHeaders
    }
    const response = await baseFetch({
        url,
        method: REQUEST_METHODS.POST,
        headers,
        body: JSON.stringify(payload)
    })
    return response
}

export const getServicesData = async (payload: object) => {
    try {
        const url = `/flight/benefits`
        const response = await baseFetch({
            url,
            method: REQUEST_METHODS.POST,
            body: JSON.stringify(payload)
        })
        return response
    } catch (e) {
        return {}
    }
}
