import dynamic from 'next/dynamic'

/**
 * @description
 * 1.) Currently when the user navigates from Results to Details page we can observe a blank screen for few seconds.
 * 2.) The blank screen/flicker is causing a bad user experience.
 * 3.) While debugging we identified that the component that are part of details page are part of detailsComponent chunk and
 *     the chunk is getting executed/parsed late.
 * 4.) This issue is not specific to details page as all the page component chunks are dynamically loaded this issue can be observed.
 * 5.) To fix this issue we want to fetch and execute the details page chunk on results page. TO achieve that we have created this
 *     Details Prefetcher component
 * 6.) With this Details Prefetcher component we will be able to fetch and execute the Details Page chunk on results page
 *
 * !NOTE: This is for experiment purpose. We will monitor the impacts on page load time and other important metrics.
 *
 * TODO: If experiment is successfull create a utility so that current page chunks can be prefetched on previous page
 */
export const DetailsPrefetcher = dynamic(() => import(/* webpackChunkName: "detailsComponent" */ './FakePrefetcher'))

export const CommonComponentsPrefetcher = dynamic(
    () => import(/* webpackChunkName: "commonComponent" */ './FakePrefetcher')
)

export const ResultsPrefetcher = dynamic(() => import(/* webpackChunkName: "resultsComponent" */ './FakePrefetcher'))

export const ItineraryPrefetcher = dynamic(
    () => import(/* webpackChunkName: "itineraryComponent" */ './FakePrefetcher')
)

export const FormPrefetcher = dynamic(() => import(/* webpackChunkName: "formComponent" */ './FakePrefetcher'))
