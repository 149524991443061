export enum API_CASE_CONTEXT {
    SRP_PAGE = 'DESKTOP_SRP_PAGE',
    SEO_PAGE = 'DESKTOP_SEO_PAGE',
    flight_SRP_FILTER_BY_LOCALITY = 'flight_SRP_FILTER_BY_LOCALITY',
    flight_SRP_FILTER_BY_LOCATION = 'flight_SRP_FILTER_BY_LOCATION',
    flight_SRP_FILTER_BY_flight_NAME = 'flight_SRP_FILTER_BY_flight_NAME',
    DESKTOP_SRP_FILTER = 'DESKTOP_SRP_FILTER',
    DESKTOP_SEO_FILTER = 'DESKTOP_SEO_PAGE_FILTER',
    MERCH_SRP_PAGE = 'MERCH_DESKTOP_SRP_PAGE',
    MERCH_DESKTOP_SRP_PAGE_FILTER = 'MERCH_DESKTOP_SRP_PAGE_FILTER',
    DESKTOP_DETAILS = 'DESKTOP_DETAILS',
    flight_HOME_PAGE = 'flight_HOME_PAGE'
}
