import React from 'react'

export const FeatherLeft = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="6" height="14" viewBox="0 0 6 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4.25363 0C4.29866 0.576171 4.06949 1.75904 2.79288 1.8812C2.73788 0.874892 3.5482 0.169942 4.25363 0ZM2.76845 13.3551C3.15811 13.7839 4.17947 14.4314 5.14752 13.5916C4.45071 12.8598 3.37818 12.9615 2.76845 13.3551ZM5.41498 13.2584C4.90586 12.9783 4.03977 12.1374 4.64834 11.0141C5.52437 11.5227 5.66518 12.5812 5.41498 13.2584ZM0.0534514 6.26204C0.0237928 6.83902 0.284694 8.01442 1.56557 8.1005C1.59374 7.09349 0.763976 6.41195 0.0534514 6.26204ZM1.99546 8.05109C1.83501 7.49543 1.82125 6.29231 3.04981 5.92534C3.30686 6.90042 2.65367 7.74794 1.99546 8.05109ZM1.91224 5.87633C1.88127 5.29942 2.13948 4.12343 3.42018 4.03455C3.45066 5.04148 2.62244 5.72483 1.91224 5.87633ZM0.423071 3.70449C0.263888 4.26052 0.252879 5.46364 1.48229 5.82791C1.73712 4.85225 1.08198 4.0062 0.423071 3.70449ZM1.14546 11.4141C1.40285 11.9327 2.20773 12.8343 3.36813 12.2912C2.89828 11.3974 1.83932 11.2021 1.14546 11.4141ZM3.72006 12.0411C3.30716 11.6328 2.70443 10.5877 3.59672 9.67389C4.29988 10.4022 4.1458 11.4583 3.72006 12.0411ZM2.19965 3.62917C2.40595 3.08837 3.12033 2.11407 4.32746 2.54349C3.9458 3.47808 2.91061 3.77372 2.19965 3.62917ZM1.7197 1.04516C1.34801 1.49102 0.848633 2.58876 1.82457 3.41284C2.45437 2.62075 2.19946 1.58435 1.7197 1.04516ZM0.314337 8.57751C0.339635 9.15489 0.710867 10.3015 1.99341 10.2689C1.92577 9.2633 1.03549 8.66118 0.314337 8.57751ZM2.41768 10.1793C2.20526 9.64066 2.07728 8.44368 3.26474 7.96464C3.61311 8.91207 3.04374 9.81655 2.41768 10.1793Z"
                fill="#FF4F17"
            />
        </svg>
    )
}

export default FeatherLeft
