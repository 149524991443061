import React, { SVGProps } from 'react'

export const NightFree = ({ width = 16, height = 16, color = '#FDBA12', ...rest }: SVGProps<SVGSVGElement>) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.09502 8.70656H12.1086C12.4512 8.70656 12.7671 8.50594 12.891 8.19126C13.0067 7.89645 12.9583 7.52763 12.301 7.23098C12.301 5.53935 10.1252 5.1875 10.1252 5.1875C10.1252 5.1875 8.69777 5.31628 8.71795 6.59491C6.47748 6.59491 7.2434 8.70656 8.09502 8.70656Z"
                fill={color}
            />
            <path
                d="M10.9848 5.44325C10.5718 5.1463 10.1267 5.18972 10.1267 5.18972C10.1267 5.18972 8.63568 5.24966 8.65333 6.52829C6.69303 6.52829 7.25188 8.70602 7.997 8.70602H12.2362C12.536 8.70602 12.8125 8.5054 12.9208 8.19072C13.0221 7.89591 12.9797 7.52709 12.4046 7.23045"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9.81806 13.9482C11.0873 13.6006 12.256 12.8558 13.123 11.7635C13.3262 11.5072 13.4347 11.3395 13.4347 11.3395C13.4347 11.3395 9.69584 12.3994 8.1062 10.1914"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.53359 5.29031C6.53359 5.29031 7.28792 2.48076 8.64534 2.04688C2.38494 2.17903 2.07061 7.51359 2.07061 7.51359C2.07061 7.51359 1.74634 11.5331 5.19985 13.4191"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export default NightFree
