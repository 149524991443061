import { useEffect, useMemo, useRef } from 'react'

import { getRootMarginForPage } from 'src/utils/analytics'
import { DEFAULT_THRESHOLD } from 'src/constants/analytics'

import { type IntersectionOptions, type InViewHookResponse, SCROLLING_TYPE } from './type'

/**
 * React Hooks make it easy to monitor the `inView` state of your components. Call
 * the `useInView` hook with the (optional) [options](#options) you need. It will
 * return an array containing a `ref`.
 * Assign the `ref` to the DOM element you want to monitor.
 *
 * @example
 * ```tsx
 * import React from 'react';
 * import { useInView } from 'src/hooks/useInView';
 *
 * const Component = () => {
 *   const { ref } = useInView({
 *       threshold: 0,
 *   });
 *
 *   return (
 *     <div ref={ref}>
 *       <h2>{`Header inside viewport.`}</h2>
 *     </div>
 *   );
 * };
 * ```
 */
const useInView = function useInView({
    threshold = DEFAULT_THRESHOLD,
    delay = 0,
    rootMargin,
    root,
    triggerOnce,
    skip,
    onChange,
    customIntersectionRatio,
    fireEventOnlyOnEnterInViewPort = true,
    pageName = 'default'
}: IntersectionOptions = {}): InViewHookResponse {
    const ref = useRef<HTMLElement>(null)

    //maintain to fire only on entry of the element
    const prevInViewRef = useRef<{
        x: number | undefined
        y: number | undefined
        intersectionRatio: number | undefined
        triggerCount: number
    }>({ x: undefined, y: undefined, intersectionRatio: undefined, triggerCount: 0 })

    const timeoutRef = useRef<number | null>(null)

    const fireSingleEventOnMultipleThresholds = useMemo(
        () => threshold.length > 1 && customIntersectionRatio,
        [threshold, customIntersectionRatio]
    )

    useEffect(() => {
        // Ensure we have node ref, and skip false otherwise avoid observing
        if (skip || !ref) return

        const calculatedRootMargin = rootMargin || getRootMarginForPage(pageName)
        const observer = new IntersectionObserver(
            entries => {
                const entry = entries?.[0]
                let triggerOnChange = true
                const { isIntersecting, intersectionRatio, boundingClientRect } = entry || {}

                if (!isIntersecting) {
                    timeoutRef?.current && clearTimeout(timeoutRef?.current)

                    //setting the intersecting values to previous reference.
                    prevInViewRef.current = {
                        x: boundingClientRect.x,
                        y: boundingClientRect.y,
                        intersectionRatio,
                        triggerCount: 0
                    }
                    return
                }

                // Check if the element is in the viewport
                const inView = isIntersecting && threshold?.some(t => intersectionRatio >= t)

                // Check if the element is in the viewport as per custom ratio.
                if (customIntersectionRatio) {
                    const { horizontal, vertical } = customIntersectionRatio

                    const { height, width } = boundingClientRect
                    const { height: intersectHeight, width: intersectWidth } = entry.intersectionRect

                    // check if element is in the viewPort for the specific ratio
                    if ((intersectHeight / height) * 100 < vertical || (intersectWidth / width) * 100 < horizontal) {
                        triggerOnChange = false
                    }
                }

                let isElementEntering = false
                let scrollingType = SCROLLING_TYPE.NONE
                if (prevInViewRef?.current?.x === undefined || prevInViewRef?.current?.y === undefined) {
                    isElementEntering = true
                    // if y is same it means user is horizontally scrolling
                } else if (prevInViewRef.current.y === boundingClientRect.y) {
                    scrollingType = SCROLLING_TYPE.HORIZONTAL
                    //if x is in negative then the element is left side element.
                    if (boundingClientRect.x < 0) {
                        isElementEntering = prevInViewRef.current.x < boundingClientRect.x
                    } else if (
                        prevInViewRef.current.intersectionRatio! <= intersectionRatio ||
                        prevInViewRef.current.x >= boundingClientRect.x
                    ) {
                        isElementEntering = true
                    }
                } else {
                    //vertical scrolling case
                    scrollingType = SCROLLING_TYPE.VERTICAL
                    if (boundingClientRect.y < prevInViewRef.current.y) {
                        if (intersectionRatio > prevInViewRef.current.intersectionRatio!) {
                            isElementEntering = true
                        } else {
                            isElementEntering = false
                        }
                    } else if (boundingClientRect.y > prevInViewRef.current.y) {
                        if (intersectionRatio < prevInViewRef.current.intersectionRatio!) {
                            isElementEntering = false
                        } else {
                            isElementEntering = true
                        }
                    }
                }

                prevInViewRef.current = {
                    ...prevInViewRef?.current,
                    x: boundingClientRect.x,
                    y: boundingClientRect.y,
                    intersectionRatio
                }

                // fire only When element is entering in the view port.
                if (fireEventOnlyOnEnterInViewPort) {
                    !isElementEntering && (triggerOnChange = false)
                }

                /**
                 * If there are multiple thresholds and we have to fire the callback at once.
                 * case :- L2 filters will be having [0.5, 1] with custom intersection {50, 100 } threshold.
                 * So to fire vertical event we have to give 1 threshold so to handle the multiple call at horizontal scroll we have to manage with previous event count. .
                 **/
                if (fireSingleEventOnMultipleThresholds && prevInViewRef.current.triggerCount >= 1) {
                    triggerOnChange = false
                }

                if (triggerOnChange) {
                    //Update the trigger count of the event.
                    prevInViewRef.current = {
                        ...prevInViewRef?.current,
                        triggerCount: prevInViewRef.current?.triggerCount + 1
                    }

                    timeoutRef.current = window.setTimeout(() => {
                        // The component is now visible in the viewport and intersecting.
                        onChange?.(inView, entry)

                        // unobserve the observer if you only want to track the first impression
                        if (triggerOnce) {
                            observer.unobserve(ref.current!)
                        }
                    }, delay)
                } else {
                    // Clear the timer if the element becomes invisible
                    timeoutRef?.current && clearTimeout(timeoutRef?.current)
                }
            },
            {
                threshold,
                root,
                rootMargin: calculatedRootMargin,
                //@ts-ignore
                delay // Intersection v2 parameter
            }
        )

        ref?.current && observer.observe(ref.current)

        return () => {
            ref?.current && observer.unobserve(ref.current)
            timeoutRef?.current && clearTimeout(timeoutRef.current) // Clear any pending timer
        }
    }, [])

    const result = [skip ? undefined : ref] as unknown as InViewHookResponse

    // Support object destructuring, by adding the specific values.
    result.ref = result[0]

    return result
}

export default useInView
