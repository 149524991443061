import Router from 'next/router'
import { Middleware, AnyAction } from 'redux'

import { RootState } from '@/redux/store/configureStore'
import { getNestedField, isServer } from '@/utils'
import { ActionMapper, NavigateType } from '@/types'
import { fetchDetailsData } from '../slices/details'
import { openModal } from '../slices/modalSlice'
import { closeDropdown } from '../slices/dropdown'
import { setSelectedNavTab } from '../slices/navTabState'
import { closeSheet, fetchSidesheetData, openSheet } from '../slices/sidesheetSlice'

let isScrollIntoViewInProgress = false
import { getWalletBalance } from '../slices/wallet'
import { isEmpty, scrollIntoViewById } from '@/utils/general/browserHelper'
import { INCLUSIONS } from '@/constants/pages/details'
import { toggleModifySearchSection, toogleModifySearchComponent } from '../slices/modifySearch'
import { IModifyComponentState } from '../slices/modifySearch/type'
import { MODIFY_SEARCH_CONTAINER_ID, OPEN_EDIT_MODIFY_SECTION } from '@/constants/components'
import { INavigatePayload } from '@/types/actions'
import baseFetch from '@/network/baseFetch'
import { IUpdateSlotsData } from '@/types/widget/slots'
import { toggleIsSlotUpdationLoading, updateResultsSlotsData } from '../slices/results'
import { closeDialog, openDialog } from '../slices/dialogs'
import { openToast } from '../slices/toastSlice'

const ActionMiddleware: Middleware =
    ({ getState, dispatch }) =>
    next =>
    async action => {
        const { type, payload, ownProps } = action || {}
        const {
            modifySearch: { modifyComponentState }
        }: RootState = getState()
        switch (type) {
            case ActionMapper.NAVIGATE: {
                const { url, type, query } = (payload || {}) as INavigatePayload
                const navigationPayload = isEmpty(query)
                    ? url
                    : {
                          pathname: url,
                          query
                      }
                switch (type) {
                    case NavigateType.PUSH: {
                        Router.push(navigationPayload)
                        break
                    }
                    case NavigateType.REPLACE: {
                        const decodedCurrentUrl = decodeURIComponent(Router.asPath)
                        if (decodedCurrentUrl !== navigationPayload) Router.replace(navigationPayload)
                        break
                    }
                    case NavigateType.REDIRECT: {
                        window?.open(url, '_blank')
                        break
                    }
                    case NavigateType.URL_REDIRECTION_ACTION:
                    case NavigateType.IN_PAGE_REDIRECT: {
                        if (!isServer()) {
                            window.location.href = url
                        }
                        break
                    }
                    case NavigateType.RELOAD: {
                        Router.reload()
                        break
                    }
                    default:
                        Router.push(navigationPayload)
                }
                dispatch(closeDropdown())
                break
            }
            case ActionMapper.OPEN_MODAL:
            case ActionMapper.OPEN_LOG_IN_MODAL: {
                const { type: payloadType, data } = payload || {}

                //hard coded the login modal for now but will ask from be in future. also same is using on home page.
                if (type === ActionMapper.OPEN_LOG_IN_MODAL || payloadType === ActionMapper.OPEN_LOG_IN_MODAL) {
                    dispatch(
                        //Added blank pageData because it is manadory.
                        openModal({
                            data: { pageData: {}, slotsData: [{ slotData: { type: 'LOGIN_MODAL', data: {} } }] }
                        })
                    )
                } else {
                    dispatch(openModal({ data }))
                }

                event?.stopPropagation()
                break
            }
            case ActionMapper.CLOSE_DROPDOWN: {
                dispatch(closeDropdown())
                break
            }

            case ActionMapper.SCROLL_INTO_VIEW:
            case ActionMapper.SCROLL_TO: {
                if (!isServer()) {
                    const { id = '', ownProps } = payload || {}
                    if (id) {
                        scrollIntoViewById(id, ownProps)

                        isScrollIntoViewInProgress = true
                        setTimeout(() => {
                            isScrollIntoViewInProgress = false
                        }, 1000)
                    }
                }
                break
            }

            case ActionMapper.SET_SELECTED_NAVTAB: {
                if (isScrollIntoViewInProgress) return

                dispatch(setSelectedNavTab(payload))
                break
            }

            case ActionMapper.OPEN_INLINE_SIDESHEET: {
                dispatch(
                    openSheet({
                        data: payload.data,
                        ownProps: ownProps
                    })
                )
                event?.stopPropagation()
                break
            }

            case ActionMapper.OPEN_SIDESHEET: {
                event?.stopPropagation()
                const action = fetchSidesheetData({
                    ownProps: ownProps,
                    payload: payload
                })
                dispatch(action as unknown as AnyAction) //TODO improve this @prakhar
                event?.stopPropagation()
                break
            }
            case ActionMapper.CLOSE_SHEET: {
                event?.stopPropagation()
                dispatch(closeSheet())
                break
            }

            case ActionMapper.FETCH_WALLET: {
                const action = getWalletBalance()
                dispatch(action as unknown as AnyAction)
                break
            }

            case ActionMapper.APPLY_ROOM_FILTER: {
                let inclusion = ''
                const filters = getNestedField(['payload', 'filters'], payload)
                if (filters?.includeOnlyFreeBreakfast) inclusion = inclusion + INCLUSIONS.FREEBREAKFAST
                if (filters?.includeOnlyFreeCancellation) inclusion = inclusion + ',' + INCLUSIONS.FREECANCELLATION
                const newurl = new URL(window.location.href)
                newurl.searchParams.set('inclusion', inclusion)
                window.history.replaceState(window.history.state, '', newurl)
                dispatch(fetchDetailsData(payload) as unknown as AnyAction) //TODO improve this @prakhar
                break
            }

            case ActionMapper.OPEN_MODIFY_SEARCH: {
                const actionPayload: IModifyComponentState = {
                    show: true,
                    openedSection: {
                        section: payload?.type ?? OPEN_EDIT_MODIFY_SECTION.OPEN_EDIT_LOCATION
                    }
                }
                dispatch(closeDropdown())
                dispatch(toogleModifySearchComponent(actionPayload))
                break
            }

            case ActionMapper.CLOSE_MODIFY_SEARCH: {
                const actionPayload: IModifyComponentState = {
                    show: false,
                    openedSection: undefined
                }
                if (!modifyComponentState?.hasError) {
                    if (!isServer()) {
                        const modifyElement = document.getElementById(MODIFY_SEARCH_CONTAINER_ID)
                        modifyElement?.classList.add('fade--out')
                    }
                    setTimeout(() => dispatch(toogleModifySearchComponent(actionPayload)), 250)
                }
                break
            }
            case ActionMapper.TOGGLE_MODIFY_SEARCH_SECTION: {
                if (!payload && !modifyComponentState?.hasError) dispatch(toggleModifySearchSection(payload))
                break
            }

            case ActionMapper.FETCH_AND_UPDATE_SLOT: {
                const { url, payload: apiPayload, method } = payload || {}
                dispatch(toggleIsSlotUpdationLoading())
                try {
                    const response = await baseFetch<IUpdateSlotsData>({
                        url,
                        method,
                        body: JSON.stringify(apiPayload)
                    })
                    if (response?.RESULTS_PAGE) {
                        dispatch(updateResultsSlotsData(response))
                    }
                } catch (err) {
                    // Handle Error
                } finally {
                    dispatch(toggleIsSlotUpdationLoading())
                }
                // Further handle other pages slot updation
                break
            }
            case ActionMapper.OPEN_INLINE_DIALOG:
            case ActionMapper.OPEN_DIALOG_WITH_PAYLOAD: {
                dispatch(
                    openDialog({
                        data: payload.data,
                        ownProps
                    })
                )
                break
            }
            case ActionMapper.CLOSE_DIALOG: {
                dispatch(closeDialog())
                break
            }
            case ActionMapper.SHOW_TOAST: {
                dispatch(openToast({ data: payload, ownProps }))
                break
            }

            default: {
                next(action)
            }
        }
    }

export default ActionMiddleware
