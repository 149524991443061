import React from 'react'

export const SelectedPerson: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, ...restProps }) => {
    return (
        <svg
            width={width || '24'}
            height={height || '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <mask
                id="mask0_239_10933"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_239_10933)">
                <path
                    d="M12.4528 11.2453C11.4465 11.2453 10.5912 10.8931 9.88679 10.1887C9.18239 9.48428 8.83019 8.62893 8.83019 7.62264C8.83019 6.63648 9.18239 5.78596 9.88679 5.07109C10.5912 4.35703 11.4465 4 12.4528 4C13.4591 4 14.3145 4.35703 15.0189 5.07109C15.7233 5.78596 16.0755 6.63648 16.0755 7.62264C16.0755 8.62893 15.7233 9.48428 15.0189 10.1887C14.3145 10.8931 13.4591 11.2453 12.4528 11.2453ZM5.26792 20C4.90566 20 4.60377 19.8792 4.36226 19.6377C4.12075 19.3962 4 19.0943 4 18.7321V18.0075C4 17.5044 4.14611 17.0363 4.43834 16.6032C4.72976 16.1709 5.11698 15.834 5.6 15.5925C6.74717 15.0491 7.88951 14.6413 9.02702 14.3692C10.1637 14.0979 11.3057 13.9623 12.4528 13.9623C13.6 13.9623 14.7423 14.0979 15.8798 14.3692C17.0166 14.6413 18.1585 15.0491 19.3057 15.5925C19.7887 15.834 20.1763 16.1709 20.4685 16.6032C20.76 17.0363 20.9057 17.5044 20.9057 18.0075V18.7321C20.9057 19.0943 20.7849 19.3962 20.5434 19.6377C20.3019 19.8792 20 20 19.6377 20H5.26792Z"
                    fill="#3366CC"
                />
            </g>
        </svg>
    )
}
