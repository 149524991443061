import { IAction, ISlot, ITracking } from '.'
import { IPageMiscTracking } from '../pages/details'

export type IUpdateSlotsData = {
    [key in `${ISlotUpdationPageName}`]: {
        updatedPageData?: {
            pageHeaderSlots: IUpdateSlotData
            pageFooterSlots: IUpdateSlotData
            pageActions: IAction[]
            tracking?: IPageMiscTracking
            ravenTracking?: ITracking
        }
        updatedSlotData?: IUpdateSlotData
    }
}

export interface IUpdateSlotData {
    [slotKey: string]: {
        operation: string
        data: ISlot
    }
}

export enum ISlotUpdationPageName {
    RESULTS_PAGE = 'RESULTS_PAGE',
    DETAILS_PAGE = 'DETAILS_PAGE',
    ITINERARY_PAGE = 'ITINERARY_PAGE'
}

export enum SlotOperation {
    UPDATE = 'UPDATE'
}

export interface IPartialSlotUpdateKeysDataMap {
    [key: string]: {
        pageDataToUpdate: {
            pageHeaderSlots: IUpdateSlotData
            pageActions: IAction[]
            pageFooterSlots: IUpdateSlotData
            ravenTracking: ITracking
        }
        slotsToUpdate: IUpdateSlotData
    }
}
