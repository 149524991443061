import { AnyAction } from '@reduxjs/toolkit'

import { CustomEventTypes } from '@/utils/customEvents/type'

import { ResultsIntersectionPayload } from '../network/results'
import { IPageMiscTracking } from '../pages/details'

export enum ComponentMapper {
    BASE_COMPONENT = 'BASE_COMPONENT',
    LINK = 'LINK',
    flight_CARD = 'flight_CARD',
    ICON = 'ICON',
    PAGE_FOOTER = 'PAGE_FOOTER',
    RATING_AND_REVIEW = 'RATING_AND_REVIEW',
    LINK_TEXT = 'LINK_TEXT',
    flight_CARD_LIST = 'flight_CARD_LIST',
    OFFER_CARD_LIST = 'OFFER_CARD_LIST',
    STACK = 'STACK',
    flight_COUNT = 'flight_COUNT',
    flight_SUMMARY_COUNT = 'flight_SUMMARY_COUNT',
    HEADER_WITH_MODIFY_SEARCH = 'HEADER_WITH_MODIFY_SEARCH',
    DESKTOP_MODIFY_SEARCH = 'DESKTOP_MODIFY_SEARCH',
    USER_ACCOUNT = 'USER_ACCOUNT',
    SHEET_HEADER = 'SHEET_HEADER',
    FLAT_NAV_TABS = 'FLAT_NAV_TABS',
    BULLETED_CONTENT = 'BULLETED_CONTENT',
    AMENITIES_HEADER = 'AMENITIES_HEADER',
    TEXT_SCROLL_GRID = 'TEXT_SCROLL_GRID',
    ADJACENT = 'ADJACENT',
    PROPERTY_RULES = 'PROPERTY_RULES',
    ABOUT_PROPERTY = 'ABOUT_PROPERTY',
    AMENITIES = 'AMENITIES',
    GENERAL = 'GENERAL',
    SUMMARY = 'SUMMARY',
    TRIP_ADVISOR_SUMMARY = 'TRIP_ADVISOR_SUMMARY',
    HIGHLIGHTSV2 = 'HIGHLIGHTSV2',
    MASKED_DROPDOWN_HEADER = 'MASKED_DROPDOWN_HEADER',
    MASKED_DROPDOWN_MAIN = 'MASKED_DROPDOWN_MAIN',
    MASKED_DROPDOWN_FOOTER = 'MASKED_DROPDOWN_FOOTER',
    MASKED_DROPDOWN = 'MASKED_DROPDOWN',
    CHIP_WITH_DROPDOWN = 'CHIP_WITH_DROPDOWN',
    FILTERS = 'FILTERS',
    MASKED_DROPDOWN_WITH_RADIO_GROUP = 'MASKED_DROPDOWN_WITH_RADIO_GROUP',
    MASKED_DROPDOWN_WITH_CHECKBOX_GROUP = 'MASKED_DROPDOWN_WITH_CHECKBOX_GROUP',
    MAP_DETAILS = 'MAP_DETAILS',
    PRE_APPLIED_FILTER = 'PRE_APPLIED_FILTER',
    APPLIED_FILTERS = 'APPLIED_FILTERS',
    CHIP_WITH_ICON = 'CHIP_WITH_ICON',
    PRICE_BREAKUP_BS = 'PRICE_BREAKUP_BS',
    RATING_RADIAL = 'RATING_RADIAL',
    ROOM_TYPE_IMAGE_DETAIL = 'ROOM_TYPE_IMAGE_DETAIL',
    ROOM_TYPE_WITH_INCLUSION_DETAILS = 'ROOM_TYPE_WITH_INCLUSION_DETAILS',
    DETAILS_HEADER_WITH_MODIFY_SEARCH = 'DETAILS_HEADER_WITH_MODIFY_SEARCH',
    HEADER_WITH_IMAGE = 'HEADER_WITH_IMAGE',
    SELECT_ROOM_HEADER = 'SELECT_ROOM_HEADER',
    NAV_TABS_WITH_SELECT_ROOM = 'NAV_TABS_WITH_SELECT_ROOM',
    ALL_FILTER_MAIN = 'ALL_FILTER_MAIN',
    ALL_FILTER_SUB_SECTION = 'ALL_FILTER_SUB_SECTION',
    flight_CLASS_FILTER = 'flight_CLASS_FILTER',
    USER_RATING_FILTER_SINGLE_SELECT = 'USER_RATING_FILTER_SINGLE_SELECT',
    AMENITIES_FILTER = 'AMENITIES_FILTER',
    INCLUSION_FILTER = 'INCLUSION_FILTER',
    PROPERTY_TYPES_FILTER = 'PROPERTY_TYPES_FILTER',
    LOCALITY_SELECT_FILTER = 'LOCALITY_SELECT_FILTER',
    SELECT_LOCALITY_FILTER = 'SELECT_LOCALITY_FILTER',
    flight_SEARCH_DROPDOWN = 'flight_SEARCH_DROPDOWN',
    flight_SEARCH = 'flight_SEARCH',
    IMAGE_CAROUSEL = 'IMAGE_CAROUSEL',
    ROOM_AMENITIES = 'ROOM_AMENITIES',
    INCLUSION = 'INCLUSION',
    BUTTON = 'BUTTON',
    PRICE_RANGE_PICKER = 'PRICE_RANGE_PICKER',
    PRICE_RANGE_FILTER = 'PRICE_RANGE_FILTER',
    DETAILS_HEADER_SECTION = 'DETAILS_HEADER_SECTION',
    ROOM_SELECTION_FILTER_HEADER = 'ROOM_SELECTION_FILTER_HEADER',
    ROOM_TYPE_DROPDOWN = 'ROOM_TYPE_DROPDOWN',
    ROOM_TYPES_STACK_LIST_ITEMS = 'ROOM_TYPES_STACK_LIST_ITEMS',
    SELECT_ROOM_HIGHLIGHTS = 'SELECT_ROOM_HIGHLIGHTS',
    VIEW_OFFER = 'VIEW_OFFER',
    HTML_MARKDOWN_COMPONENT = 'HTML_MARKDOWN_COMPONENT',
    OFFER_CARD = 'OFFER_CARD',
    STACK_OFFER_CARD_LIST = 'STACK_OFFER_CARD_LIST',
    ASPECT_RATING = 'ASPECT_RATING',
    STAR_RATING_AND_ASPECT_RATING = 'STAR_RATING_AND_ASPECT_RATING',
    REVIEW_COMMENT = 'REVIEW_COMMENT',
    RATING_PROGRESS_BAR = 'RATING_PROGRESS_BAR',
    SEARCH_BOX = 'SEARCH_BOX',
    ROOM_TYPE_LIST_ITEM = 'ROOM_TYPE_LIST_ITEM',
    INCLUSION_TYPES = 'INCLUSION_TYPES',
    MASKED_DROPDOWN_SINGLE_SELECT_GROUP = 'MASKED_DROPDOWN_SINGLE_SELECT_GROUP',
    CANCELLATION_CRITERIA_BLOCK = 'CANCELLATION_CRITERIA_BLOCK',
    IMAGE_SCROLL_GRID = 'IMAGE_SCROLL_GRID',
    SUB_PAGE_HEADER = 'SUB_PAGE_HEADER',
    ROOM_INCLUSION_SIDE_SHEET = 'ROOM_INCLUSION_SIDE_SHEET',
    ROOM_INCLUSION_FOOTER = 'ROOM_INCLUSION_FOOTER',
    SIDESHEET_SHIMMER = 'SIDESHEET_SHIMMER',
    STAR_RATING_AND_ASPECT_RATIO = 'STAR_RATING_AND_ASPECT_RATIO',
    ASPECT_RATIO = 'ASPECT_RATIO',
    USER_ACCOUNT_DROPDOWN = 'USER_ACCOUNT_DROPDOWN',
    USER_ACCOUNT_LIST_DROPDOWN = 'USER_ACCOUNT_LIST_DROPDOWN',
    DROPDOWN = 'DROPDOWN',
    USER_WALLET = 'USER_WALLET',
    PRICE_BREAKUP_SUMMARY_CANCELLATION = 'PRICE_BREAKUP_SUMMARY_CANCELLATION',
    ERROR_WIDGET = 'ERROR_WIDGET',
    LOGIN_MODAL = 'LOGIN_MODAL',
    BANNER = 'BANNER',
    SIMILAR_flightS = 'SIMILAR_flightS',
    PROMOTIONAL_SECTION = 'PROMOTIONAL_SECTION',
    SELECT_CITY_FILTER = 'SELECT_CITY_FILTER',
    SELECT_LOCATION_FILTER = 'SELECT_LOCATION_FILTER',
    CFNR_HIGHLIGHT = 'CFNR_HIGHLIGHT',
    RATE_CARD = 'RATE_CARD',
    ICONED_TEXT = 'ICONED_TEXT',
    HORIZONTAL_CHECKBOX_GROUP = 'HORIZONTAL_CHECKBOX_GROUP',
    HORIZONTAL_SCROLL_CONTAINER = 'HORIZONTAL_SCROLL_CONTAINER',
    FULL_HEIGHT_BUTTON = 'FULL_HEIGHT_BUTTON',
    flightS_WIDGET = 'flightS_WIDGET',
    TRIP_ADVISOR_RATING = 'TRIP_ADVISOR_RATING',
    HORIZONTAL_MULTI_FLATCHIP_SELECTION = 'HORIZONTAL_MULTI_FLATCHIP_SELECTION',
    FLAT_CHIP = 'FLAT_CHIP',
    ALL_DEALS_WIDGET = 'ALL_DEALS_WIDGET',
    HORIZONTAL_SINGLE_CHIP_SELECTION = 'HORIZONTAL_SINGLE_CHIP_SELECTION',
    TYPOGRAPHY = 'TYPOGRAPHY',
    IMAGE = 'IMAGE',
    LINK_BUTTON = 'LINK_BUTTON',
    ALERT_CALLOUT = 'ALERT_CALLOUT',
    HEADERS_TAG = 'HEADERS_TAG',
    BREADCRUMB = 'BREADCRUMB',
    FOOTER_LINKS = 'FOOTER_LINKS',
    FAQ = 'FAQ',
    FOOTER_CONTENT = 'FOOTER_CONTENT',
    MOTHER_OFFER = 'MOTHER_OFFER',
    CANCELLATION_POLICY = 'CANCELLATION_POLICY',
    SECTION_INFO = 'SECTION_INFO',
    SECTION_INFO_ITEM_NUMBER = 'SECTION_INFO_ITEM_NUMBER',
    SPECIAL_REQUESTS_FORM = 'SPECIAL_REQUESTS_FORM',
    FIELD = 'FIELD',
    GUEST_FORM = 'GUEST_FORM',
    CHECKBOX = 'CHECKBOX',
    SINGLE_SELECT_CHIP_GROUP = 'SINGLE_SELECT_CHIP_GROUP',
    PHONE_FIELD_PREFIX = 'PHONE_FIELD_PREFIX',
    PRICE_SUMMARY = 'PRICE_SUMMARY',
    NCEMI_CALLOUT = 'NCEMI_CALLOUT',
    FORM_ELEMENT = 'FORM_ELEMENT',
    TEXTFIELD = 'TEXTFIELD',
    SELECTION_BOX = 'SELECTION_BOX',
    CHECKBOX_GROUP = 'CHECKBOX_GROUP',
    NCEMI_PROVIDER_LIST = 'NCEMI_PROVIDER_LIST',
    ACCORDION = 'ACCORDION',
    NCEMI_BREAKUP = 'NCEMI_BREAKUP',
    ITINERARY_REVIEW_CARD = 'ITINERARY_REVIEW_CARD',
    ITINERARY_flight_SUMMARY = 'ITINERARY_flight_SUMMARY',
    ITINERARY_TRIP_DETAIL = 'ITINERARY_TRIP_DETAIL',
    ITINERARY_CHECK_IN_OUT = 'ITINERARY_CHECK_IN_OUT',
    ITINERARY_ROOMS_GUESTS = 'ITINERARY_ROOMS_GUESTS',
    ITINERARY_TRIP_ADVISOR_RATING = 'ITINERARY_TRIP_ADVISOR_RATING',
    ACCORDION_GROUP = 'ACCORDION_GROUP',
    ITINERARY_ROOM_INFO = 'ITINERARY_ROOM_INFO',
    ITINERARY_BOOKING_POLICY = 'BOOKING_POLICY',
    ITINERARY_GUEST_INFO = 'ITINERARY_GUEST_INFO',
    SUPERCOIN = 'SUPERCOIN',
    SELECT_RADIO_DROPDOWN = 'SELECT_RADIO_DROPDOWN',
    COUPON_AND_GV = 'COUPON_AND_GV',
    DIALOG_COMPONENT = 'DIALOG_COMPONENT',
    MEDIA_TEXT_LIST = 'MEDIA_TEXT_LIST',
    ADD_AND_SAVE_NEW_GUEST = 'ADD_AND_SAVE_NEW_GUEST',
    ADD_NEW_GUEST = 'ADD_NEW_GUEST',
    SAVED_GUEST_FORM = 'SAVED_GUEST_FORM',
    EDITABLE_CHECKBOX = 'EDITABLE_CHECKBOX',
    OTHER_GUEST = 'OTHER_GUEST',
    MEDI_CANCEL_BANNER = 'MEDI_CANCEL_BANNER',
    CONTINUE_TO_PAYMENT = 'CONTINUE_TO_PAYMENT',
    CANCELLATION_POLICY_SELECTION_SECTION = 'CANCELLATION_POLICY_SELECTION_SECTION',
    CANCELLATION_POLICY_CARD = 'CANCELLATION_POLICY_CARD',
    SWITCH = 'SWITCH',
    ICONED_TEXT_WIDGET = 'ICONED_TEXT_WIDGET',
    BADGE = 'BADGE',
    LOTTIE = 'LOTTIE',
    TOP_RATED = 'TOP_RATED',
    SPOTLIGHT = 'SPOTLIGHT',
    GREAT_FOR = 'GREAT_FOR',
    SUMMARY_V2 = 'SUMMARY_V2',
    PROMPT_HIGHLIGHT = 'PROMPT_HIGHLIGHT',
    CLEAR_TRIP_RATING_SUMMARY = 'CLEAR_TRIP_RATING_SUMMARY',
    OVERALL_RATING = 'OVERALL_RATING',
    REVIEW_CARDS = 'REVIEW_CARDS',
    REVIEW_CARD = 'REVIEW_CARD',
    SPACER = 'SPACER',
    REVIEW_COMMENTS_V2 = 'REVIEW_COMMENTS_V2',
    REVIEW_V2 = 'REVIEW_V2',
    CLEAR_TRIP_RATING = 'CLEAR_TRIP_RATING',
    SECTION_CONCLUSION = 'SECTION_CONCLUSION'
}

export type ComponentType = `${ComponentMapper}`

export enum ActionMapper {
    NAVIGATE = 'NAVIGATE',
    OPEN_LOG_IN_MODAL = 'OPEN_LOG_IN_MODAL',
    CLOSE_DROPDOWN = 'CLOSE_DROPDOWN',
    TOGGLE_DROPDOWN = 'TOGGLE_DROPDOWN',
    REMOVE_FILTER = 'REMOVE_FILTER',
    REMOVE_PRE_APPLIED_FILTERS = 'REMOVE_PRE_APPLIED_FILTERS',
    REMOVE_SEARCH = 'REMOVE_SEARCH',
    UPDATE_STATE = 'UPDATE_STATE',
    APPLY_FILTER = 'APPLY_FILTER',
    APPLY_SRP_FILTER = 'APPLY_SRP_FILTER',
    OPEN_SIDESHEET = 'OPEN_SIDESHEET',
    OPEN_INLINE_SIDESHEET = 'OPEN_INLINE_SIDESHEET',
    SCROLL_TO = 'SCROLL_TO',
    APPLY_ROOM_FILTER = 'APPLY_ROOM_FILTER',
    CLOSE_SHEET = 'CLOSE_SHEET',
    SCROLL_INTO_VIEW = 'SCROLL_INTO_VIEW',
    RESET_FILTER = 'RESET_FILTER',
    CREATE_ITINERARY = 'CREATE_ITINERARY',
    OBSERVABLE = 'OBSERVABLE',
    FETCH_WALLET = 'FETCH_WALLET',
    UPDATE_INITIAL_FILTER_STATE = 'UPDATE_INITIAL_FILTER_STATE',
    TRACKING = 'TRACKING',
    SET_SELECTED_NAVTAB = 'SET_SELECTED_NAVTAB',
    SEARCH_AMENITIES = 'SEARCH_AMENITIES',
    STICKY_TOP_CALCULATED = 'STICKY_TOP_CALCULATED',
    TOGGLE_MODIFY_SEARCH_COMPONENT = 'TOGGLE_MODIFY_SEARCH_COMPONENT',
    OPEN_MODIFY_SEARCH = 'OPEN_MODIFY_SEARCH',
    CLOSE_MODIFY_SEARCH = 'CLOSE_MODIFY_SEARCH',
    TOGGLE_MODIFY_SEARCH_SECTION = 'TOGGLE_MODIFY_SEARCH_SECTION',
    OPEN_MODAL = 'OPEN_MODAL',
    UPDATE_STATE_AND_FETCH_DATA = 'UPDATE_STATE_AND_FETCH_DATA',
    FETCH_AND_UPDATE_SLOT = 'FETCH_AND_UPDATE_SLOT',
    OPEN_DIALOG = 'OPEN_DIALOG',
    CLOSE_DIALOG = 'CLOSE_DIALOG',
    BASE_FIELD_CHANGE = 'BASE_FIELD_CHANGE',
    CHIP_SELECT = 'CHIP_SELECT',
    CHECKBOX_SELECT = 'CHECKBOX_SELECT',
    RADIO_CHANGE = 'RADIO_CHANGE',
    PHONE_CODE_CHANGE = 'PHONE_CODE_CHANGE',
    CHECKBOX_GROUP_CHANGE = 'CHECKBOX_GROUP_CHANGE',
    OPEN_INLINE_DIALOG = 'OPEN_INLINE_DIALOG',
    SET_INITAL_FORM_ELEMENT_DATA = 'SET_INITAL_FORM_ELEMENT_DATA',
    VALIDATE_AND_SAVE_LOCAL_TRAVELLER = 'VALIDATE_AND_SAVE_LOCAL_TRAVELLER',
    VALIDATE_AND_SAVE_TRAVELLER = 'VALIDATE_AND_SAVE_TRAVELLER',
    SWITCH_TO_ADD_GUEST = 'SWITCH_TO_ADD_GUEST',
    SHOW_BACK_ICON = 'SHOW_BACK_ICON',
    SWITCH_TO_EDIT_GUEST = 'SWITCH_TO_EDIT_GUEST',
    DIALOG_BACK = 'DIALOG_BACK',
    ADD_TO_DIALOG_JOURNEY = 'ADD_TO_DIALOG_JOURNEY',
    REMOVE_FROM_DIALOG_JOURNEY = 'REMOVE_FROM_DIALOG_JOURNEY',
    ADD_GUEST = 'ADD_GUEST',
    SAVE_TRAVELLER_AND_CLOSE_DIALOG = 'SAVE_TRAVELLER_AND_CLOSE_DIALOG',
    EDIT_GUEST = 'EDIT_GUEST',
    MERGE_DISCOUNT_COUPON_DATA = 'MERGE_DISCOUNT_COUPON_DATA',
    MAKE_DISCOUNT_API_CALL = 'MAKE_DISCOUNT_API_CALL',
    MERGE_LOCAL_COUPON_DATA_AND_MAKE_DISCOUNT_API_CALL = 'MERGE_LOCAL_COUPON_DATA_AND_MAKE_DISCOUNT_API_CALL',
    MERGE_DISCOUNT_GV_DATA = 'MERGE_DISCOUNT_GV_DATA',
    VALIDATE_GV_AND_MAKE_DISCOUNT_CALL = 'VALIDATE_GV_AND_MAKE_DISCOUNT_CALL',
    VALIDATE_AND_OPEN_INLINE_DIALOG = 'VALIDATE_AND_OPEN_INLINE_DIALOG',
    APPEND_COUPON_AND_MAKE_DISCOUNT_API_CALL = 'APPEND_COUPON_AND_MAKE_DISCOUNT_API_CALL',
    CONTINUE_TO_PAYMENT = 'CONTINUE_TO_PAYMENT',
    BASE_SELCTION_BOX_CHANGE = 'BASE_SELCTION_BOX_CHANGE',
    UPDATE_FORM_LAZY_STATE = 'UPDATE_FORM_LAZY_STATE',
    SAVE_TO_DRAFT_AND_OPEN_LOG_IN_MODAL = 'SAVE_TO_DRAFT_AND_OPEN_LOG_IN_MODAL',
    OPEN_DIALOG_WITH_PAYLOAD = 'OPEN_DIALOG_WITH_PAYLOAD',
    SHOW_TOAST = 'SHOW_TOAST',
    APPEND_GV_DATA_AND_MAKE_DISCOUNT_API_CALL = 'APPEND_GV_DATA_AND_MAKE_DISCOUNT_API_CALL',
    RESIZE_OBSERVER = 'RESIZE_OBSERVER'
}

export type ActionType = `${ActionMapper}`

export type ActionPayload = {
    hasOwnProperty: (key: PropertyKey) => boolean
    eventData?: Record<string, unknown>
}

export interface IAction<T extends ActionPayload = ActionPayload> {
    type: ActionType | CustomEventTypes
    payload?: T
    ownProps?: IOwnProps
}

export enum NavigateType {
    URL_REDIRECTION_ACTION = 'URL_REDIRECTION_ACTION',
    PUSH = 'PUSH',
    REPLACE = 'REPLACE',
    REDIRECT = 'REDIRECT',
    IN_PAGE_REDIRECT = 'IN_PAGE_REDIRECT',
    RELOAD = 'RELOAD'
}

export interface IMultiAndImpressionTracking extends Omit<ITracking, 'multiTracking'> {
    options?: {
        delay?: number
        threshold?: number[]
        rootMargin?: string
        rootId?: string
        triggerOnce?: boolean
        customIntersectionRatio?: {
            horizontal: number
            vertical: number
        }
    }
}
export interface IMultiTracking {
    [key: string]: IMultiAndImpressionTracking
}

export interface ITracking {
    actionType?: string
    eventName?: string
    multiTracking?: IMultiTracking
    eventData?: Record<string, unknown>
    mergeData?: Record<string, unknown>
}

export type ComponentValue = object

export interface IArrangement {
    arrangement: string
}
export interface IComponent<
    T extends ComponentValue = ComponentValue,
    S extends ComponentType = ComponentType,
    P extends ActionPayload = ActionPayload
> {
    type: S
    data: T & { key?: string }
    action?: IAction<P>
    tracking?: ITracking
    ravenTracking?: ITracking
    layout?: IArrangement
}

export enum SlotBehaviours {
    OBSERVABLE = 'OBSERVABLE',
    STICKY_TOP = 'STICKY_TOP',
    FIXED_BOTTOM = 'FIXED_BOTTOM',
    STICKY_TOP_CALCULATED = 'STICKY_TOP_CALCULATED',
    FIXED_TOP_CALCULATED = 'FIXED_TOP_CALCULATED',
    RESIZEABLE = 'RESIZEABLE'
}

export type SlotBehaviourType = `${SlotBehaviours}`

export interface ISlot<T extends ComponentValue = ComponentValue> {
    behaviour?: SlotBehaviourType
    slotData: IComponent<T>
}

export interface IPageData {
    pageHeaderSlots?: ISlot[]
    pageFooterSlots?: ISlot[]
    tracking?: IPageMiscTracking
    ravenTracking?: ITracking
    pageMisc?: Record<string, unknown>
    metaData?: Record<string, unknown>
    pageQuery?: ResultsIntersectionPayload
    pageActions?: IAction[]
}

export interface ISubPages {
    [key: string]: IPageResponse
}

export interface IPageResponse {
    pageData: IPageData
    slotsData: ISlot[]
    subPages?: ISubPages
    h_exp_searchv3?: 'a' | 'b' | undefined
}

export enum SLOT_OPERATION {
    UPDATE = 'UPDATE',
    INSERT = 'INSERT',
    DELETE = 'DELETE'
}

export interface IUpdatedSlot {
    data: ISlot
    operation: `${SLOT_OPERATION}`
}

export interface IUpdatedPageData {
    pageHeaderSlots?: Record<string, IUpdatedSlot>
    pageFooterSlots?: Record<string, IUpdatedSlot>
    tracking?: ITracking
    ravenTracking?: ITracking
    pageMisc?: Record<string, unknown>
    metaData?: Record<string, unknown>
    pageQuery?: ResultsIntersectionPayload
    pageActions?: IAction[]
}

export enum UPDATED_PAGE_RESPONSE_KEYS {
    PAGE_DATA = 'updatedPageData',
    SLOT_DATA = 'updatedSlotData',
    SUB_PAGES = 'updatedSubPages'
}

export interface IUpdatePageResponse {
    [UPDATED_PAGE_RESPONSE_KEYS.PAGE_DATA]?: IUpdatedPageData
    [UPDATED_PAGE_RESPONSE_KEYS.SLOT_DATA]?: Record<string, IUpdatedSlot>
    [UPDATED_PAGE_RESPONSE_KEYS.SUB_PAGES]?: ISubPages
}

export interface IPageResponseLoading {
    isLoading: boolean
}

export interface IDispatcher<T extends ActionPayload = ActionPayload> {
    (action: IAction<T> | AnyAction): void
}

export type IOwnProps = Record<string, unknown>

export interface IComponentRendererParams {
    data: IPageResponse
    dispatcher?: IDispatcher
    ownProps?: IOwnProps
    pageName?: string
}

export interface IComponentGeneratorParams {
    slot: ISlot
    dispatcher?: IDispatcher
    ownProps?: IOwnProps & { isSearchV3?: boolean }
    pageName?: string
    id?: string | number
    avoidPageNameForTestId?: boolean
}

export interface ComponentInterceptorProps<T extends ComponentValue = ComponentValue> {
    slotData: IComponent<T>
    dispatcher?: IDispatcher
}

export interface ISubComponentGeneratorParams<T extends ComponentValue = ComponentValue> {
    slot: IComponent<T>
    dispatcher?: IDispatcher
    ownProps?: IOwnProps
    pageName?: string
    id?: string | number
    avoidPageNameForTestId?: boolean
    behaviour?: SlotBehaviours
}

export interface IComponentProps<
    T extends ComponentValue = ComponentValue,
    S extends ComponentType = ComponentType,
    P extends ActionPayload = ActionPayload,
    O extends IOwnProps = IOwnProps
> extends ITestIdProps {
    data: IComponent<T, S, P>
    dispatcher?: IDispatcher
    ownProps?: O
    pageName?: string
}

export interface IActionDispatcher {
    action?: IAction<ActionPayload>
    paramAction?: IAction
    tracking?: ITracking
}

export interface ITestIdProps {
    dataTestId?: string
}
