import StatsigNode from 'statsig-js'
import { STATSIG_ENVIRONMENT_TIER, STATSIG_EXPERIMENT_AB_KEYS, getStatsigUserConfig } from './statsigConfig'
import { v4 as uuid } from 'uuid'

import { getCookie, setCookie } from '@/utils/general/cookie'
import { isServer } from '@/utils/general'
import { STATSIG_OVERRIDE_KEY, formStatsigExperimentObjectAndUpdateValues } from '@/utils/experiment/statsig'
import { isEmpty } from '@/utils/general/browserHelper'

import { STATSIG_USER_STABLE_ID } from '@/constants/browser'
import { PAGE_NAME_TYPE } from '@/constants/pages/common'

import { STAGSIG_EXPERIMENT_VALUES, STATSIG_EXPERIMENT_NAMES_TYPE } from './type'
class StatsigManager {
    static statsigInstance: typeof StatsigNode
    static isInitialised: boolean
    abValues: STAGSIG_EXPERIMENT_VALUES = {} as STAGSIG_EXPERIMENT_VALUES

    async initializeStastigInstance() {
        if (isServer()) return
        const statsigToken = process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY
        if (!StatsigManager.statsigInstance) {
            try {
                StatsigManager.statsigInstance = StatsigNode

                // Assigning globally for raven tracking
                window.Statsig = StatsigNode

                const stasigUserId = getCookie(STATSIG_USER_STABLE_ID) || uuid()
                setCookie({ name: STATSIG_USER_STABLE_ID, value: stasigUserId, expiryInDay: 365 })

                await StatsigManager.statsigInstance.initialize(
                    statsigToken || '',
                    getStatsigUserConfig(stasigUserId),
                    {
                        environment: { tier: STATSIG_ENVIRONMENT_TIER },
                        overrideStableID: stasigUserId
                    } // optional, pass options here if needed
                )
                StatsigManager.isInitialised = StatsigManager.statsigInstance.initializeCalled()
            } catch (err) {
                // Incase of error clearing instance;
                StatsigManager.statsigInstance = undefined as unknown as typeof StatsigNode
                StatsigManager.isInitialised = false
                console.log('Failed to initialise statsig', err)
            }
        }
    }

    private async getStatsigAbValue(abKey: string) {
        if (!StatsigManager.statsigInstance && !StatsigManager.isInitialised) {
            await this.initializeStastigInstance()
        }
        return StatsigManager.statsigInstance.getConfig(abKey)
    }
    async getAllStatigAbValues(pageName: PAGE_NAME_TYPE) {
        const experimentPayload = STATSIG_EXPERIMENT_AB_KEYS[pageName]
        // NOTE!: not using foreach since async process was not working in case of foreach
        for (const abValueKey of Object.keys(experimentPayload)) {
            const {
                experimentName = '',
                experimentValue = '',
                defaultValue
            } = experimentPayload[abValueKey as STATSIG_EXPERIMENT_NAMES_TYPE] || {}
            const abConfig = await this.getStatsigAbValue(experimentName)
            const abValue = abConfig.get(experimentValue, defaultValue)
            this.abValues = { ...this.abValues, [abValueKey]: abValue }
        }
        const shouldOverride = getCookie(STATSIG_OVERRIDE_KEY) === 'true'
        if (!isEmpty(this.abValues)) {
            formStatsigExperimentObjectAndUpdateValues({
                latestExperimentValues: this.abValues,
                shouldOverride
            })
        }
        return this.abValues
    }
}

const StatsigManagerInstance = new StatsigManager()
export default StatsigManagerInstance
