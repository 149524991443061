import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store/configureStore'
import { ISelectedFilterInitialState } from './type'

const initialState = {
    data: {} as ISelectedFilterInitialState
}

export const topFiltersSlice = createSlice({
    name: 'topFilters',
    initialState,
    reducers: {
        setResetTopFiltersValue: (state, action) => {
            const { payload } = action || {}
            state.data = payload
        }
    }
})

const getSetTopFilters = (state: RootState) => state.topFilters?.data

const { reducer, actions } = topFiltersSlice
const { setResetTopFiltersValue } = actions || {}

export { getSetTopFilters, setResetTopFiltersValue }

export default reducer
