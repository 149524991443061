export const Location = ({ stroke, ...props }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
            <path
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20 10.182C20 16.546 12 22 12 22s-8-5.454-8-11.818c0-2.17.843-4.251 2.343-5.786A7.91 7.91 0 0 1 12 2c2.122 0 4.157.862 5.657 2.396A8.277 8.277 0 0 1 20 10.182Z"
            />
            <path
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
            />
        </svg>
    )
}

export const HighlightedLocation = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M20 10.1818C20 16.5455 12 22 12 22C12 22 4 16.5455 4 10.1818C4 8.01187 4.84285 5.93079 6.34315 4.3964C7.84344 2.86201 9.87827 2 12 2C14.1217 2 16.1566 2.86201 17.6569 4.3964C19.1571 5.93079 20 8.01187 20 10.1818Z"
                fill="#FF4F17"
                stroke="#FF4F17"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                fill="white"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
