import { getServicesData } from '@/api/srp'
import { SEARCH_FLIGHT_SERVER_ERROR, SEARCH_NO_FLIGHTS_AVAILABLE } from '@/constants/Srp'
import { RootState } from '@/redux/store/configureStore'
import { SearchData, ServicesData } from '@/types/srp'
import { deepClone, isEmpty } from '@/utils/general/browserHelper'
import { getServicesDataCacheKey } from '@/utils/srp/helper'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { stat } from 'fs'

interface InitialState {
    searchData: SearchData
    isLoading: boolean
    srpError: string
    isServicesLoading: boolean
    servicesData: ServicesData
    couponData: Record<string, any>
    baggageData: Record<string, any>
}
const defaultState = {
    cards: {
        J1: []
    },
    sortData: {},
    filterData: {},
    metaData: {
        airlineDetail: {},
        airportDetail: {}
    },
    searchIntent: {},
    subTravelOptions: {},
    fares: {},
    dataId: '',
    travelOptionIdsToFareIdsMap: {},
    searchId: ''
}
const initialState: InitialState = {
    searchData: {
        cards: {
            J1: []
        },
        sortData: {},
        filterData: {},
        metaData: {
            airlineDetail: {},
            airportDetail: {}
        },
        searchIntent: {},
        subTravelOptions: {},
        fares: {},
        dataId: '',
        travelOptionIdsToFareIdsMap: {},
        searchId: ''
    },
    isLoading: true,
    srpError: '',
    isServicesLoading: true,
    servicesData: {
        benefits: {},
        baggageAllowances: {},
        cardIdToBenefitsMap: {}
    },
    couponData: {},
    baggageData: {}
}

export const fetchServicesData = createAsyncThunk('srpStore/services', async (payload: object) => {
    try {
        const response = await getServicesData(payload)
        return response
    } catch (e) {
        return e
    }
})

const mergedCardIdToBenefitsMap = (srpCardIdToBenefitsMap: any, stateCardIdToBenefitsMap: any) => {
    Object.keys(srpCardIdToBenefitsMap).forEach(key => {
        if (stateCardIdToBenefitsMap?.[key]) {
            const { subTravelOptions = [] } = srpCardIdToBenefitsMap[key] || {}
            subTravelOptions.forEach((subTravelOption: any) => {
                const { subTravelOptionId = '', benefits = {} } = subTravelOption || {}
                const existingSubTravelOption = stateCardIdToBenefitsMap[key]?.subTravelOptions?.find(
                    (subTravelOption: any) => subTravelOption.subTravelOptionId === subTravelOptionId
                )
                if (!isEmpty(existingSubTravelOption)) {
                    Object.keys(benefits).forEach(key => {
                        const {
                            penalties: penalty = [],
                            benefits: benefitIds = [],
                            baggageAllowanceId = ''
                        } = benefits[key] || {}
                        if (isEmpty(existingSubTravelOption.benefits?.[key])) {
                            existingSubTravelOption.benefits = {
                                ...existingSubTravelOption.benefits,
                                [key]: { penalty, benefitIds, baggageAllowanceId }
                            }
                        } else {
                            // TODO, remove duplicates
                            existingSubTravelOption.benefits[key] = {
                                penalty: [...(existingSubTravelOption.benefits[key]?.penalty || []), ...penalty],
                                benefitIds: [
                                    ...(existingSubTravelOption.benefits[key]?.benefitIds || []),
                                    ...benefitIds
                                ],
                                baggageAllowanceId
                            }
                        }
                    })
                } else {
                    stateCardIdToBenefitsMap[key].subTravelOptions.push(subTravelOption)
                }
            })
        } else {
            stateCardIdToBenefitsMap[key] = srpCardIdToBenefitsMap[key]
        }
    })
    return stateCardIdToBenefitsMap
}

export const srpManager = createSlice({
    name: 'srpSeachData',
    initialState,
    reducers: {
        setError(state, action) {
            const error = action.payload
            state.isLoading = false
            state.srpError = error
        },
        setCardIdToBenefitsMap(state, action) {
            const srpCardIdToBenefitsMap = action.payload
            const stateCardIdToBenefitsMap = deepClone({ ...state.servicesData.cardIdToBenefitsMap })
            const mergedCardIds = mergedCardIdToBenefitsMap(srpCardIdToBenefitsMap, stateCardIdToBenefitsMap)
            state.servicesData.cardIdToBenefitsMap = mergedCardIds
        },
        setCouponsData(state, action) {
            state.couponData = {
                ...state.couponData,
                ...action.payload
            }
        },
        setBaggageData(state, action) {
            state.baggageData = {
                ...state.baggageData,
                ...action.payload
            }
        },
        setSrpSearchData(state, action) {
            const { response } = action.payload
            const { cards, statusCode } = response || {}
            if (!isEmpty(cards)) {
                const { benefits, baggageAllowances } = response || {}
                if (!isEmpty(cards?.J1)) {
                    state.searchData = response || {}
                    state.servicesData = {
                        cardIdToBenefitsMap: {},
                        benefits: {
                            ...benefits
                        },
                        baggageAllowances: {
                            ...baggageAllowances
                        }
                    }
                } else {
                    state.searchData = defaultState
                    state.srpError = SEARCH_NO_FLIGHTS_AVAILABLE
                }
            } else {
                state.searchData = defaultState
                if (statusCode === 404) {
                    state.srpError = SEARCH_NO_FLIGHTS_AVAILABLE
                } else {
                    state.srpError = SEARCH_FLIGHT_SERVER_ERROR
                }
            }
            state.isLoading = false
        },
        flushData(state) {
            state.searchData = defaultState
            state.isLoading = true
            state.srpError = ''
            state.isServicesLoading = true
            state.couponData = {}
            state.servicesData = {
                benefits: {},
                baggageAllowances: {},
                cardIdToBenefitsMap: {}
            }
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchServicesData.pending, (state, action) => {
            state.isServicesLoading = true
        })
        builder.addCase(fetchServicesData.fulfilled, (state, action) => {
            const data = action?.payload || {}

            const { cardIdToBenefitsMap, benefits, baggageAllowances } = getServicesDataCacheKey(data) || {}
            state.servicesData = {
                ...state.servicesData,
                benefits: {
                    ...state.servicesData.benefits,
                    ...benefits
                },
                baggageAllowances: {
                    ...state.servicesData.baggageAllowances,
                    ...baggageAllowances
                },
                cardIdToBenefitsMap: mergedCardIdToBenefitsMap(
                    cardIdToBenefitsMap,
                    deepClone({ ...state.servicesData.cardIdToBenefitsMap })
                )
            }

            state.isServicesLoading = false
        })
        builder.addCase(fetchServicesData.rejected, (state, action) => {
            state.isServicesLoading = false
        })
    }
})

const { reducer, actions } = srpManager

const { setError, setSrpSearchData, flushData, setCouponsData, setBaggageData, setCardIdToBenefitsMap } = actions

export const getSrpSearchData = (state: RootState) => state.srpSeachData

export { setError, setSrpSearchData, flushData, setCouponsData, setBaggageData, setCardIdToBenefitsMap }

export default reducer
