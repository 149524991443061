import React from 'react'

export const SucessOffer: React.FC<React.SVGProps<SVGSVGElement>> = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
            <path
                fill="#11A670"
                stroke="#11A670"
                d="M9.345 2.236a1 1 0 0 1 1.31 0l.79.684a1 1 0 0 0 .845.226l1.025-.198a1 1 0 0 1 1.135.655l.341.988a1 1 0 0 0 .619.618l.987.341a1 1 0 0 1 .655 1.135l-.198 1.026a1 1 0 0 0 .226.845l.685.789a1 1 0 0 1 0 1.31l-.685.79a1 1 0 0 0-.226.845l.198 1.025a1 1 0 0 1-.655 1.135l-.987.341a1 1 0 0 0-.619.619l-.341.987a1 1 0 0 1-1.135.655l-1.025-.198a1 1 0 0 0-.846.227l-.789.684a1 1 0 0 1-1.31 0l-.79-.684a1 1 0 0 0-.844-.227l-1.026.198a1 1 0 0 1-1.135-.655l-.34-.987a1 1 0 0 0-.62-.619l-.987-.34a1 1 0 0 1-.655-1.136l.198-1.025a1 1 0 0 0-.226-.845l-.685-.79a1 1 0 0 1 0-1.31l.685-.789a1 1 0 0 0 .226-.845l-.198-1.026a1 1 0 0 1 .655-1.135l.987-.34a1 1 0 0 0 .62-.62l.34-.986a1 1 0 0 1 1.135-.656l1.026.198a1 1 0 0 0 .845-.226l.789-.684Z"
            />
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.25}
                d="m14.727 7-6 6L6 10.274"
            />
        </svg>
    )
}
