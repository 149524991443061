import React from 'react'

export const TravellersMini: React.FC<React.SVGProps<SVGSVGElement>> = ({
    width = '16',
    height = '16',
    ...restProps
}) => {
    return (
        <svg viewBox="0 0 14 14" height={height} width={width} className="c-neutral-400" {...restProps}>
            <g fill="none" fill-rule="evenodd">
                <rect width="14" height="14" fill="#FFF" opacity="0"></rect>
                <path
                    fill="currentColor"
                    d="M4.5,7 C6.43299662,7 8,8.56700338 8,10.5 L8,14 L1,14 L1,10.5 C1,8.56700338 2.56700338,7 4.5,7 Z M10.5002944,9 C11.8810062,9 13.0002944,10.1192881 13.0002944,11.5 L13.0002944,14 L9.50029435,13.9997654 L9.50029435,10.5057654 C9.50029435,10.083454 9.48261395,9.6733711 9.38403764,9.28165365 C9.72780691,9.10132075 10.0848802,9 10.5002944,9 Z M10.5242101,4 C11.6287796,4 12.5242101,4.8954305 12.5242101,6 C12.5242101,7.1045695 11.6287796,8 10.5242101,8 C9.41964058,8 8.52421008,7.1045695 8.52421008,6 C8.52421008,4.8954305 9.41964058,4 10.5242101,4 Z M4.5,1 C5.88071187,1 7,2.11928813 7,3.5 C7,4.88071187 5.88071187,6 4.5,6 C3.11928813,6 2,4.88071187 2,3.5 C2,2.11928813 3.11928813,1 4.5,1 Z"
                ></path>
            </g>
        </svg>
    )
}
