import { isEmpty } from '../general/browserHelper'
import { getCookie, setCookie } from '../general/cookie'
import { CT_EXP_COOKIE, CUSTOM_COOKIE_NAME } from '@/constants/experiment'

export const setExperimentCookie = () => {
    const experimentIds: Record<string, string> = {}

    // TODO: Use alternative for google optimize for experiments

    setCookie({ name: CT_EXP_COOKIE, value: JSON.stringify(experimentIds) })
}

export const setCustomExperimentCookie = (experimentId: string) => {
    // const abCookie = isEmpty(getCookie('ct-ab')) ? {} : JSON.parse(decodeURIComponent(getCookie('ct-ab') || '')) || {};

    setCookie({ name: CUSTOM_COOKIE_NAME[experimentId], value: 'true' })
}
