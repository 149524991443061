export const ArrowLeft = ({ height = '24', width = '24', className = '' }) => {
    return (
        <svg className={className} height={height} width={width} viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path fill="#FFF" d="M0 0h24v24H0z" />
                <path fill="#FFF" d="M0 0h24v24H0z" />
                <path fill="currentColor" d="M19 11.125H8.325l4.9-4.9L12 5l-7 7 7 7 1.225-1.225-4.9-4.9H19z" />
            </g>
        </svg>
    )
}
