import React from 'react'

export const RoundtripIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_1265_2365" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1265_2365)">
                <path
                    d="M6.99995 19.3L2.69995 15L6.99995 10.7L7.69995 11.4L4.62495 14.5H12.5V15.5H4.62495L7.69995 18.6L6.99995 19.3ZM17 13.3L16.3 12.6L19.375 9.49995H11.5V8.49995H19.375L16.3 5.39995L17 4.69995L21.2999 8.99995L17 13.3Z"
                    fill="#1A1A1A"
                />
            </g>
        </svg>
    )
}
