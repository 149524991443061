import React from 'react'

export function FlipkartPremium(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="87" height="20" viewBox="0 0 87 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="86.5" height="20" rx="4" fill="url(#paint0_linear_3966_9465)" />
            <path
                d="M11.6428 8.28255C11.6428 8.28255 12.6161 8.75493 13.0963 8.95543C13.5635 9.15005 13.6165 9.87966 13.1023 10.1261C12.5882 10.3726 12.4464 10.4127 12.4464 10.4127C12.4464 10.4127 12.5273 10.2083 12.5163 10.0107C12.4833 9.65861 11.4212 9.37304 10.7174 9.20384C10.7174 9.20384 10.1773 7.54317 9.93269 7.08057C9.68712 6.61503 9.18896 6.42236 9.18896 6.42236L10.366 6.43801C10.366 6.43801 10.7713 6.41845 10.951 6.84487C11.1307 7.2703 11.6428 8.28255 11.6428 8.28255Z"
                fill="url(#paint1_linear_3966_9465)"
            />
            <path
                d="M11.5752 11.9201C11.5752 11.9201 11.098 12.8629 10.8884 13.3451C10.6887 13.8028 9.92898 13.8546 9.67841 13.3509C9.42683 12.8472 9.40088 12.7572 9.40088 12.7572C9.40088 12.7572 9.55262 12.7866 9.79421 12.7758C10.1536 12.7436 10.4621 11.703 10.6348 11.0135C10.6348 11.0135 12.3299 10.4834 12.8021 10.2447C13.2773 10.0042 13.474 9.51611 13.474 9.51611L13.458 10.8413C13.458 10.8413 13.478 11.0672 13.0437 11.2433C12.6084 11.4194 11.5752 11.9201 11.5752 11.9201Z"
                fill="url(#paint2_linear_3966_9465)"
            />
            <path
                d="M7.82927 11.851C7.82927 11.851 6.86092 11.3815 6.37474 11.1781C5.90754 10.9834 5.85463 10.2529 6.36875 10.0074C6.88288 9.76092 7.08953 9.71594 7.08953 9.71594C7.08953 9.71594 6.95576 9.92521 6.9887 10.1609C7.02264 10.514 8.0509 10.7605 8.7527 10.9297C8.7527 10.9297 9.31375 12.5806 9.53737 13.0529C9.8109 13.628 10.2641 13.716 10.2641 13.716L8.9294 13.6965C8.9294 13.6965 8.6988 13.716 8.5191 13.2906C8.3414 12.8632 7.82927 11.851 7.82927 11.851Z"
                fill="url(#paint3_linear_3966_9465)"
            />
            <path
                d="M7.90875 8.20866C7.90875 8.20866 8.38794 7.26096 8.59559 6.78369C8.79425 6.32598 9.53899 6.27512 9.79056 6.77782C10.0421 7.2815 10.0661 7.37246 10.0661 7.37246C10.0661 7.37246 9.87642 7.37539 9.67575 7.38615C9.31537 7.4194 9.02187 8.42676 8.85016 9.11528C8.85016 9.11528 7.16103 9.65612 6.68284 9.88401C6.02496 10.197 6 10.598 6 10.598L6.02696 9.28839C6.02696 9.28839 6.00699 9.06247 6.44125 8.88643C6.87551 8.71039 7.90875 8.20866 7.90875 8.20866Z"
                fill="url(#paint4_linear_3966_9465)"
            />
            <path
                d="M17.0818 13.6058H15.4116L16.5592 6.53406H19.5307C20.4391 6.53406 21.0778 6.69743 21.4467 7.02418C21.8224 7.35093 22.0102 7.81104 22.0102 8.40452C22.0102 8.70459 21.9829 8.98466 21.9283 9.24473C21.8736 9.49812 21.7712 9.75819 21.6209 10.0249C21.4774 10.285 21.2862 10.5084 21.0471 10.6951C20.8148 10.8751 20.504 11.0252 20.1147 11.1452C19.7253 11.2586 19.2745 11.3152 18.7622 11.3152H17.4609L17.0818 13.6058ZM17.6658 10.0249H18.8236C19.7936 10.0249 20.2786 9.57481 20.2786 8.67458C20.2786 8.39452 20.1966 8.18446 20.0327 8.04443C19.8688 7.89773 19.5853 7.82438 19.1823 7.82438H18.0244L17.6658 10.0249Z"
                fill="white"
            />
            <path
                d="M24.793 12.3156L24.67 13.4959C24.2943 13.6425 23.9083 13.7159 23.5122 13.7159C23.0682 13.7159 22.7266 13.6159 22.4875 13.4158C22.2553 13.2158 22.1392 12.929 22.1392 12.5556C22.1392 12.4556 22.1494 12.3456 22.1699 12.2255L23.1228 6.29407H24.7212L23.8298 11.8354C23.823 11.9155 23.8195 11.9721 23.8195 12.0055C23.8195 12.1655 23.8469 12.2722 23.9015 12.3256C23.963 12.3789 24.0825 12.4056 24.2601 12.4056C24.4036 12.4056 24.5812 12.3756 24.793 12.3156Z"
                fill="white"
            />
            <path
                d="M31.0954 8.33447L30.2347 13.6058H28.9847L29.0257 12.4855C28.4587 13.3057 27.7312 13.7158 26.8432 13.7158C26.365 13.7158 26.0064 13.5858 25.7673 13.3257C25.5282 13.0657 25.4087 12.7189 25.4087 12.2854C25.4087 12.1054 25.4224 11.9454 25.4497 11.8053L26.0235 8.33447H27.6117L27.1096 11.4152C27.0891 11.5153 27.0789 11.6219 27.0789 11.7353C27.0789 12.1287 27.2223 12.3255 27.5092 12.3255C27.7346 12.3255 27.9874 12.2021 28.2674 11.9554C28.5475 11.7086 28.8105 11.3986 29.0564 11.0251L29.497 8.33447H31.0954Z"
                fill="white"
            />
            <path
                d="M31.2378 13.4558L31.5144 12.1554C31.5622 12.2088 31.7774 12.2755 32.16 12.3555C32.5425 12.4289 32.9011 12.4655 33.2358 12.4655C33.9257 12.4655 34.2707 12.2988 34.2707 11.9654C34.2707 11.8454 34.1819 11.7487 34.0043 11.6753C33.8335 11.602 33.6218 11.542 33.369 11.4953C33.1231 11.4419 32.8738 11.3753 32.621 11.2953C32.3751 11.2086 32.1634 11.0652 31.9858 10.8651C31.815 10.6584 31.7296 10.3983 31.7296 10.0849C31.7296 9.4848 31.9516 9.02468 32.3956 8.70461C32.8396 8.38453 33.509 8.22449 34.4039 8.22449C34.6976 8.22449 34.9845 8.24449 35.2646 8.2845C35.5446 8.31784 35.7564 8.35452 35.8999 8.39453C36.0501 8.43454 36.1458 8.46788 36.1868 8.49455L35.9203 9.75486C35.8042 9.68151 35.5925 9.61816 35.2851 9.56482C34.9777 9.51147 34.6805 9.4848 34.3937 9.4848C33.6832 9.4848 33.328 9.64482 33.328 9.96493C33.328 10.0783 33.4134 10.1683 33.5842 10.235C33.7618 10.3017 33.9735 10.3617 34.2195 10.415C34.4654 10.4617 34.7113 10.5284 34.9572 10.6151C35.2099 10.6951 35.4217 10.8385 35.5925 11.0452C35.7701 11.2452 35.8589 11.5019 35.8589 11.8154C35.8589 12.4622 35.6198 12.939 35.1416 13.2457C34.6703 13.5525 34.0214 13.7058 33.1948 13.7058C32.8806 13.7058 32.563 13.6858 32.2419 13.6458C31.9209 13.6058 31.6784 13.5691 31.5144 13.5358C31.3573 13.4958 31.2651 13.4691 31.2378 13.4558Z"
                fill="white"
            />
            <path
                d="M39.7482 13.599H38.124L39.24 6.72192H42.1297C43.0132 6.72192 43.6343 6.8808 43.993 7.19856C44.3584 7.51631 44.541 7.96376 44.541 8.54091C44.541 8.83272 44.5145 9.10508 44.4613 9.35799C44.408 9.60441 44.3085 9.85732 44.1625 10.1167C44.0228 10.3696 43.8369 10.5869 43.6045 10.7684C43.3784 10.9435 43.0763 11.0894 42.6976 11.2062C42.319 11.3164 41.8805 11.3715 41.3823 11.3715H40.1169L39.7482 13.599ZM40.3161 10.1167H41.4421C42.3854 10.1167 42.857 9.679 42.857 8.80354C42.857 8.53118 42.7773 8.32691 42.6179 8.19073C42.4585 8.04806 42.1828 7.97673 41.7909 7.97673H40.6649L40.3161 10.1167Z"
                fill="white"
            />
            <path
                d="M44.4673 13.5991L45.2942 8.47284H46.5198L46.4598 9.70819C46.9981 8.8133 47.6689 8.36584 48.4728 8.36584H48.5626L48.2634 9.91248C47.9248 9.91248 47.5892 10.0162 47.2569 10.2237C46.925 10.4248 46.6527 10.6712 46.44 10.963L46.0217 13.5991H44.4673Z"
                fill="white"
            />
            <path
                d="M53.6295 9.91243C53.5762 11.1056 52.6629 11.7022 50.8894 11.7022C50.6368 11.7022 50.391 11.6925 50.1518 11.6731C50.1854 11.9713 50.2915 12.1853 50.4707 12.315C50.6569 12.4447 50.9725 12.5096 51.4176 12.5096C51.9424 12.5096 52.4837 12.4156 53.0417 12.2275L52.8025 13.4628C52.2445 13.6315 51.6467 13.7157 51.009 13.7157C50.1187 13.7157 49.4843 13.5309 49.1059 13.1613C48.7337 12.7917 48.5479 12.2567 48.5479 11.5563C48.5479 10.6614 48.8201 9.90916 49.3647 9.29961C49.916 8.69004 50.6569 8.38525 51.5867 8.38525C52.2844 8.38525 52.7992 8.52468 53.1315 8.80352C53.4634 9.07588 53.6295 9.44552 53.6295 9.91243ZM50.2316 10.7198C50.2982 10.7328 50.4044 10.7392 50.5504 10.7392C51.567 10.7392 52.0751 10.509 52.0751 10.0486C52.0751 9.89945 52.0251 9.77949 51.9257 9.68869C51.8259 9.59142 51.6765 9.54279 51.4772 9.54279C51.1583 9.54279 50.8927 9.64654 50.68 9.85407C50.474 10.0616 50.3247 10.3501 50.2316 10.7198Z"
                fill="white"
            />
            <path
                d="M61.3857 8.36584C61.8506 8.36584 62.1993 8.4923 62.4317 8.7452C62.6645 8.99811 62.7807 9.33532 62.7807 9.75684C62.7807 9.93193 62.7673 10.0876 62.7409 10.2237L62.1829 13.5991H60.6381L61.1265 10.6031C61.1466 10.5058 61.1563 10.4021 61.1563 10.2918C61.1563 9.9092 61.017 9.71793 60.738 9.71793C60.3528 9.71793 59.8842 10.1362 59.3329 10.9727L58.9045 13.5991H57.3601L57.8485 10.6031C57.8682 10.5058 57.8783 10.4021 57.8783 10.2918C57.8783 9.9092 57.7386 9.71793 57.4596 9.71793C57.0744 9.71793 56.6028 10.1394 56.0448 10.9824L55.6165 13.5991H54.062L54.899 8.47284H56.1145L56.0646 9.56229C56.3436 9.16023 56.6393 8.86193 56.9515 8.66739C57.2704 8.46636 57.6555 8.36584 58.1074 8.36584C58.8844 8.36584 59.3396 8.70954 59.4726 9.39692C59.9773 8.70954 60.615 8.36584 61.3857 8.36584Z"
                fill="white"
            />
            <path
                d="M64.812 13.5989H63.2676L64.0945 8.47267H65.6389L64.812 13.5989ZM65.9876 6.93578C65.9876 7.20814 65.9045 7.43186 65.7387 7.60695C65.5726 7.78204 65.3167 7.86959 64.9714 7.86959C64.4667 7.86959 64.2141 7.65235 64.2141 7.21787C64.2141 6.94551 64.2972 6.72178 64.4633 6.54669C64.6291 6.3716 64.885 6.28406 65.2303 6.28406C65.7354 6.28406 65.9876 6.5013 65.9876 6.93578Z"
                fill="white"
            />
            <path
                d="M71.5535 8.47278L70.7164 13.599H69.5006L69.5405 12.5095C68.9892 13.3072 68.2818 13.706 67.418 13.706C66.9531 13.706 66.6044 13.5795 66.372 13.3266C66.1392 13.0737 66.0229 12.7365 66.0229 12.315C66.0229 12.1399 66.0364 11.9843 66.0628 11.8481L66.6208 8.47278H68.1656L67.6772 11.4687C67.6571 11.566 67.6474 11.6698 67.6474 11.78C67.6474 12.1626 67.7867 12.3539 68.0657 12.3539C68.2851 12.3539 68.5306 12.2339 68.8033 11.994C69.0756 11.7541 69.3311 11.4525 69.5703 11.0894L69.999 8.47278H71.5535Z"
                fill="white"
            />
            <path
                d="M79.105 8.36584C79.5702 8.36584 79.9189 8.4923 80.1513 8.7452C80.3838 8.99811 80.5 9.33532 80.5 9.75684C80.5 9.93193 80.487 10.0876 80.4601 10.2237L79.9021 13.5991H78.3577L78.8461 10.6031C78.8658 10.5058 78.8759 10.4021 78.8759 10.2918C78.8759 9.9092 78.7366 9.71793 78.4576 9.71793C78.072 9.71793 77.6038 10.1362 77.0525 10.9727L76.6241 13.5991H75.0794L75.5677 10.6031C75.5878 10.5058 75.5975 10.4021 75.5975 10.2918C75.5975 9.9092 75.4582 9.71793 75.1792 9.71793C74.794 9.71793 74.3221 10.1394 73.7641 10.9824L73.3357 13.5991H71.7812L72.6183 8.47284H73.8341L73.7842 9.56229C74.0632 9.16023 74.3586 8.86193 74.6711 8.66739C74.99 8.46636 75.3751 8.36584 75.827 8.36584C76.604 8.36584 77.0592 8.70954 77.1918 9.39692C77.6969 8.70954 78.3346 8.36584 79.105 8.36584Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_3966_9465"
                    x1="0"
                    y1="0"
                    x2="32.2247"
                    y2="53.0001"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#082395" />
                    <stop offset="1" stop-color="#081C82" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_3966_9465"
                    x1="9.21474"
                    y1="6.39728"
                    x2="13.008"
                    y2="10.2692"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0411" stop-color="#FBED23" />
                    <stop offset="0.2727" stop-color="#FBED23" />
                    <stop offset="0.5022" stop-color="#FFE400" />
                    <stop offset="0.8126" stop-color="#F0A82E" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_3966_9465"
                    x1="9.62787"
                    y1="13.3513"
                    x2="13.428"
                    y2="9.47236"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1874" stop-color="#F0A82E" />
                    <stop offset="0.4978" stop-color="#FFE400" />
                    <stop offset="0.7273" stop-color="#FBED23" />
                    <stop offset="0.9589" stop-color="#FBED23" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_3966_9465"
                    x1="6.376"
                    y1="9.94965"
                    x2="10.1629"
                    y2="13.8151"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.1874" stop-color="#F0A82E" />
                    <stop offset="0.4978" stop-color="#FFE400" />
                    <stop offset="0.7273" stop-color="#FBED23" />
                    <stop offset="0.9589" stop-color="#FBED23" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_3966_9465"
                    x1="5.97928"
                    y1="10.5775"
                    x2="9.77022"
                    y2="6.70798"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0411" stop-color="#FBED23" />
                    <stop offset="0.2727" stop-color="#FBED23" />
                    <stop offset="0.5022" stop-color="#FFE400" />
                    <stop offset="0.8126" stop-color="#F0A82E" />
                </linearGradient>
                <clipPath id="clip0_3966_9465">
                    <rect width="78.5" height="16" fill="white" transform="translate(4 2)" />
                </clipPath>
                <clipPath id="clip1_3966_9465">
                    <rect width="74.5" height="8" fill="white" transform="translate(6 6)" />
                </clipPath>
            </defs>
        </svg>
    )
}
