import { srpQueryObject } from '@/components/oldComponents/Dropdown/HomeSearchContainer'
import { getNestedField, isServer } from '@/utils/general'
import querystring from 'querystring'
import { fromQueryString } from '../pages/home'
import { getCookie } from './cookie'

export const ONWARD_DIR_ARROW = process.env.CONTENT_DIR === 'RTL' ? '←' : '→'

export function isEmpty(value: unknown): boolean {
    if (!value || value === null) {
        return true
    }

    if (Array.isArray(value) || typeof value === 'string' || (typeof value === 'object' && value !== null)) {
        return Object.keys(value).length === 0
    }

    return false
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = (fn: Function, ms = 300) => {
    let timeoutId: ReturnType<typeof setTimeout>
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => fn.apply(this, args), ms)
    }
}

export const resolveKeysInObjectsArrays = (p: string[], o: Record<string, any>): any | null => {
    const reducerFunction = (xs: Record<string, any> | null, x: string): any | null => {
        return xs && xs[x] ? xs[x] : null
    }
    return p.reduce(reducerFunction, o)
}

export const getAppPrefix = () => {
    let langPrefix = ''
    let lang = 'en'
    // const LOCAL_LANG = process.env.LOCALE_LANG
    // const ENGLISH_LANG = 'en'

    const pathname = resolveKeysInObjectsArrays(['location', 'pathname'], window)

    const [, ln = lang] = (pathname || '').split('/')

    if (ln === 'ar') {
        langPrefix = '/ar'
        lang = ln
    }

    return ln === 'ar' ? `${langPrefix}` : ''
}

export const toQueryString = (object = {}, sep = '&', eq = '=') => {
    return querystring.stringify(object, sep, eq)
}

export const pluralize = (value: number | string, text: string, suffix = 's') =>
    +value === 1 ? `${value} ${text}` : `${value} ${`${text}${suffix}`}`

export const labelPluralize = (value: number, text: string, suffix = 's') =>
    +value === 1 ? `${text}` : `${`${text}${suffix}`}`

export const isEqual = (param1: unknown, param2: unknown) => {
    return JSON.stringify(param1) === JSON.stringify(param2)
}
//TODO: @khush will update the type for this clone deep.
/* eslint-disable  */
// @ts-ignore
export const deepClone = <T>(value: T): T => {
    //Check if browser has structured clone then use inbuild method.
    try {
        if (!isServer() && window?.structuredClone?.name === 'structuredClone') {
            return structuredClone(value)
        }
    } catch (ex) {
        console.log(ex)
    }

    const clone = <P>(item: P): P => {
        if (!item) {
            return item
        } // null, undefined values check

        const types = [Number, String, Boolean]
        let result: unknown

        // normalizing primitives if someone did new String('aaa'), or new Number('444');
        types.forEach(function (type) {
            if (item instanceof type) {
                result = type(item)
            }
        })

        if (typeof result == 'undefined') {
            if (Array.isArray(item)) {
                result = []
                item.forEach(function (child: unknown, index: number) {
                    Array.isArray(result) && (result[index] = clone(child))
                })
            } else if (typeof item == 'object') {
                // testing that this is DOM
                // @ts-ignore
                if (item.nodeType && typeof item.cloneNode == 'function') {
                    // @ts-ignore
                    result = item.cloneNode(true)
                    // @ts-ignore
                } else if (!item.prototype) {
                    // check that this is a literal
                    if (item instanceof Date) {
                        result = new Date(item)
                    } else {
                        // it is an object literal
                        result = {}
                        for (const i in item) {
                            // @ts-ignore
                            result[i] = clone(item[i])
                        }
                    }
                } else {
                    result = item
                }
            } else {
                result = item
            }
        }
        // @ts-ignore
        return result
    }
    return clone(value)
}
/* eslint-enable */

export interface IScrollIntoViewOwnProps {
    behavior?: ScrollBehavior
    block?: ScrollLogicalPosition
    inline?: ScrollLogicalPosition
}

export function scrollIntoViewById(id: string, ownProps?: IScrollIntoViewOwnProps) {
    const { behavior = 'smooth', block = 'start', inline = 'nearest' } = ownProps || {}

    if (!isServer()) {
        document.getElementById(id)?.scrollIntoView({
            behavior,
            block,
            inline
        })
    }
}

export const getQueryParams = () => {
    let urlSearchParams = getNestedField(['location', 'search'], window)
    const queryParams: Record<string, string | string[]> = {}
    if (urlSearchParams) {
        urlSearchParams = urlSearchParams.substring(1, urlSearchParams.length)
        const params = urlSearchParams.split('&')
        for (const val in params) {
            const [key, value] = params[val].split('=')
            queryParams[key] = value
        }
    }
    return queryParams
}

export const pageLoadTime = () => {
    if (!isServer()) {
        const startTime = performance.timeOrigin
        const endTime = performance.now()
        return endTime - startTime
    } else {
        return 0
    }
}

export const getURLSearchParam = (param: string) => {
    if (!isServer()) {
        const params = new URLSearchParams(window.location.href)
        return params.get(param)
    } else {
        return
    }
}
export const htmlSanitizer = (value: string): string => {
    var re = /(<([^>]+)>)/gi
    if (value.match(re)) {
        return ''
    } else {
        return value
    }
}
export function getScript(url: string, callback: () => void): void {
    // Create a script element and inject it into the page
    const script: HTMLScriptElement = document.createElement('script')
    script.src = url
    document.getElementsByTagName('head')[0].appendChild(script)

    // When the new script's load event fires, execute the callback
    script.onload = callback
}
interface FlightSearch {
    rnd_one: string
    depart_date: string
    return_date: string
    origin(origin: string): string
    from: string
    sourceCountry: string
    destinationCountry: string
    to: string
    airline(airline: string): string
    id: string
    departure: string
    destination: string
    date: string
    isCfw: boolean
    sft: string
}

interface RecentSearchData {
    en: FlightSearch[]
    ar: FlightSearch[]
}

export function getflightsHistory(): FlightSearch[] {
    try {
        if (typeof window.localStorage !== 'undefined') {
            const storageName = 'fsHistory'
            const dataForm: RecentSearchData = { en: [], ar: [] }
            const lang = 'en'
            const recentSearch: RecentSearchData = JSON.parse(
                localStorage.getItem(storageName) || JSON.stringify(dataForm)
            )
            return recentSearch[lang]
        } else {
            return []
        }
    } catch (e) {
        console.log('getRecentSearch LC Error', e)
        return []
    }
}
interface updateQueryObject {
    adults: string
    childs: string
    infants: string
    class: string
    depart_date?: string
    return_date?: string
    from: string
    to: string
    intl: string
    origin: string
    destination: string
    sft: string
    sd: number
    rnd_one: string
    sourceCountry: string
    destinationCountry: string
    isCfw: boolean
}

export function updateHomeSearch(obj: updateQueryObject): void {
    try {
        if (typeof window.localStorage !== 'undefined') {
            const storageName = 'fsHistory'
            const dataForm = { en: [] as updateQueryObject[], ar: [] as updateQueryObject[] }
            const recentSearch = JSON.parse(localStorage.getItem(storageName) || JSON.stringify(dataForm))
            const lang = 'en'
            let eleFound = false

            if (recentSearch[lang].length) {
                for (let i = 0; i < recentSearch[lang].length; i++) {
                    if (
                        recentSearch[lang][i].from === obj.from &&
                        recentSearch[lang][i].to === obj.to &&
                        recentSearch[lang][i].adults === obj.adults &&
                        recentSearch[lang][i].class === obj.class &&
                        recentSearch[lang][i].depart_date === obj.depart_date &&
                        recentSearch[lang][i].return_date === obj.return_date &&
                        recentSearch[lang][i].isCfw === obj.isCfw
                    ) {
                        eleFound = true
                        break
                    }
                }
            }

            if (!eleFound) {
                recentSearch[lang].unshift({ ...obj })
            }

            if (recentSearch[lang].length >= 3) {
                recentSearch[lang].length = 3
            }

            localStorage.setItem(storageName, JSON.stringify(recentSearch))
        }
    } catch (e) {
        console.log('updateRecentSearch LC Error', e)
    }
}

export const replaceQueryInUrl = (searchQuery: srpQueryObject) => {
    let pathname = `/flights/results`
    if (searchQuery.intl && searchQuery.intl === 'y') {
        pathname = `/flights/international/results`
    }
    const stringify = toQueryString(searchQuery)
    window.location.href = `${pathname}?${decodeURIComponent(stringify)}`
}

export const stringSplitter = (
    inputString: string,
    splitBasis = ' ',
    processSplitString: (value: string, index: number, array: string[]) => void
) => {
    if (isEmpty(inputString) || typeof inputString !== 'string') return ''
    const splitString = inputString.split(splitBasis)
    if (typeof processSplitString === 'function') {
        return splitString.map(processSplitString)
    } else {
        return splitString.join(splitBasis)
    }
}

export const getCurrencyByLocale = () => {
    return { locale: 'IN', currency: 'INR', country: 'IN', symbol: 'Rs.' }
}

export const formatCurrency = (value: string | number) => {
    if (value && typeof value === 'string') {
        value = value.replace(/,/g, '')
    }

    const formatted = parseInt(value, 10).toLocaleString(getCurrencyByLocale('INR'), {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0
    })
    return formatted
}

export const checkIsMultiAirline = (flight = {}) => {
    const multiAirlinePairsExclude = [
        ['I5', 'IX'],
        ['IX', 'I5']
    ]
    let isMultiAirline = false
    let airline = flight?.segments?.[0]?.airline || ''
    flight?.segments?.forEach(segment => {
        if (
            airline !== segment.airline &&
            !multiAirlinePairsExclude.some(pair => pair[0] === airline && pair[1] === segment.airline)
        ) {
            isMultiAirline = true
        }
    })
    return isMultiAirline;
  }
  
  export const readParam = (name, options = {}) => {
    const queryString = window.location.search.split('?')
    const urlHash = fromQueryString(queryString[1] ? queryString[1] : '')
    const params = urlHash ? urlHash : {}

    const cookieValue = getCookie(name || options.alias)
    const value = params[name] || params[options.alias]

    // if value present in param then return that, otherwise check in cookie
    if (value) {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        writeCookieUtm(name, value, options.expiry || 1)
        return value
    } else {
        if (cookieValue) {
            return cookieValue
        }
    }
}
const writeCookieUtm = (k, v, expiryInDays) => {
    if (expiryInDays > 0) {
        const now = new Date()
        const nextDate = now.setDate(now.getDate() + expiryInDays)
        const expiresDate = new Date(nextDate)
        document.cookie = `${k}=${v}; path=/; expires=` + expiresDate
    } else if (!expiryInDays || expiryInDays === '') {
        document.cookie = `${k}=${v};path="/";`
    }
    return null
}