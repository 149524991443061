import { Router, useRouter } from 'next/router'
import { PropsWithChildren, useEffect, useLayoutEffect, useState } from 'react'
import { Container } from '@cleartrip/ct-design-components'
import { useDispatch } from 'react-redux'
import { setResultsDataLoading } from '@/redux/slices/results'
import { RESULTS_BASE_PATH, DETAILS_BASE_PATH, MERCH_RESULTS_BASE_PATH, SEO_BASE_PATH } from '@/constants/paths'
import { getPathname } from '@/utils/general/url'
import { useAppSelector } from '@/redux/store/hooks'
import { setMerchFiltersLoading } from '@/redux/slices/merchResults'
import { isServer } from '@/utils'

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
    // const router = useRouter();
    // const [isLoading, setIsLoading] = useState(false);
    // const [currentPathname, setCurrentPathname] = useState(router.pathname);
    // const dispatch = useDispatch();
    // const {
    //   modals: { data: modalData },
    //   sidesheets: { data: sideSheetData },
    //   dropdown: {
    //     isOpen: isDropDownOpen,
    //     overlayConfig: { showOverlay },
    //   },
    //   modifySearch: {
    //     modifyComponentState: { show: isModifySearchVisible },
    //   },
    // } = useAppSelector((state) => state);

    // const getShimmerComponent = () => {

    //   return null;
    // };

    return <>{children} </>
}

export default Layout
