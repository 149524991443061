export const CURRENCY = 'INR'
export const CURRENCYLOCAL = 'en-IN'

export const CURRENCIES = {
    INR: 'INR'
} as const

export const CURRENCY_TO_SYMBOL = {
    [CURRENCIES.INR]: '₹'
} as const
