import React from 'react'

export const Offers: React.FC<React.SVGProps<SVGSVGElement>> = ({
    width = '22',
    height = '22',
    stroke = '#1a1a1a',
    ...restProps
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill={'none'}
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <path
                stroke={stroke}
                d="M11.3447 2.56856C11.7207 2.24235 12.2793 2.24235 12.6553 2.56856L13.8066 3.56738C14.0386 3.76866 14.3502 3.85216 14.6518 3.79385L16.1482 3.50447C16.6369 3.40996 17.1207 3.6893 17.2833 4.1598L17.7809 5.60042C17.8811 5.89074 18.1093 6.11887 18.3996 6.21915L19.8402 6.71675C20.3107 6.87926 20.59 7.36309 20.4955 7.85182L20.2062 9.34823C20.1478 9.64979 20.2313 9.96142 20.4326 10.1934L21.4314 11.3447C21.7576 11.7207 21.7576 12.2793 21.4314 12.6553L20.4326 13.8066C20.2313 14.0386 20.1478 14.3502 20.2062 14.6518L20.4955 16.1482C20.59 16.6369 20.3107 17.1207 19.8402 17.2833L18.3996 17.7809C18.1093 17.8811 17.8811 18.1093 17.7809 18.3996L17.2833 19.8402C17.1207 20.3107 16.6369 20.59 16.1482 20.4955L14.6518 20.2062C14.3502 20.1478 14.0386 20.2313 13.8066 20.4326L12.6553 21.4314C12.2793 21.7576 11.7207 21.7576 11.3447 21.4314L10.1934 20.4326C9.96142 20.2313 9.64979 20.1478 9.34823 20.2062L7.85182 20.4955C7.36309 20.59 6.87926 20.3107 6.71675 19.8402L6.21915 18.3996C6.11887 18.1093 5.89074 17.8811 5.60042 17.7809L4.1598 17.2833C3.6893 17.1207 3.40996 16.6369 3.50447 16.1482L3.79385 14.6518C3.85216 14.3502 3.76866 14.0386 3.56738 13.8066L2.56856 12.6553C2.24235 12.2793 2.24235 11.7207 2.56856 11.3447L3.56738 10.1934C3.76866 9.96142 3.85216 9.64979 3.79385 9.34823L3.50447 7.85182C3.40996 7.36309 3.6893 6.87926 4.1598 6.71675L5.60042 6.21915C5.89074 6.11887 6.11887 5.89074 6.21915 5.60042L6.71675 4.1598C6.87926 3.6893 7.36309 3.40996 7.85182 3.50447L9.34823 3.79385C9.64979 3.85216 9.96142 3.76866 10.1934 3.56738L11.3447 2.56856Z"
            />
            <path d="M16 8L8 16" strokeLinecap="round" strokeLinejoin="round" stroke={stroke} />
            <path d="M9.04688 9.16211H9.07504" strokeLinecap="round" strokeLinejoin="round" stroke={stroke} />
            <path d="M15.0469 15.1621H15.075" strokeLinecap="round" strokeLinejoin="round" stroke={stroke} />
        </svg>
    )
}
