import { memo, PropsWithChildren, useCallback } from 'react'

import { Container } from '@cleartrip/ct-design-components'

import { ActionMapper } from '@/types'

import { useResizeObservable } from './useResizeObservable'
import { BaseComponentProps } from '../type'

function ResizeableComponent({ dispatcher, children }: PropsWithChildren<BaseComponentProps>) {
    const callback = useCallback(
        (rect: DOMRectReadOnly) => {
            dispatcher?.({
                type: ActionMapper.RESIZE_OBSERVER,
                payload: rect
            })
        },
        [dispatcher]
    )

    const { ref } = useResizeObservable({ callback })

    return <Container ref={ref}>{children}</Container>
}

export default memo(ResizeableComponent)
