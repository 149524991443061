import { isServer } from './index'
import { ISetCookieParams } from '@/types/browser'
import { MILLI_SEC_IN_A_DAY, MILLI_SEC_IN_A_HOUR, MILLI_SEC_IN_A_MINUTE } from '@/constants/date'

export function getCookie(name: string) {
    if (!isServer() && document.cookie) {
        const nameEQ = name + '='
        const ca = document.cookie.split(';')
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) == ' ') c = c.substring(1, c.length)
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
        }
    }
    return ''
}

/**
 *
 * @param name :name of cookie
 * @param value : value of cookie
 * @param expiryInDay, expiryInHours, expiryInMinutes : expiry date.
 * @returns string or set cookie if it is executed on browser
 */
export const setCookie = ({
    name,
    value,
    expiryInDay,
    expiryInHours,
    expiryInMinutes,
    path = '/'
}: ISetCookieParams) => {
    let expires: string
    const date = new Date()
    if (expiryInDay) {
        date.setTime(date.getTime() + expiryInDay * MILLI_SEC_IN_A_DAY)
        expires = date.toUTCString()
    } else if (expiryInHours) {
        date.setTime(date.getTime() + expiryInHours * MILLI_SEC_IN_A_HOUR)
        expires = date.toUTCString()
    } else if (expiryInMinutes) {
        date.setTime(date.getTime() + expiryInMinutes * MILLI_SEC_IN_A_MINUTE)
        expires = date.toUTCString()
    } else {
        expires = ''
    }

    //send only value for server.
    if (isServer()) {
        return `${name ? `${name}=` : ''}${value}; expires=${expires}; path=${path}`
    } else {
        document.cookie = `${name}=${value}; expires=${expires}; path=${path}`
    }

    return ''
}
export function deleteCookie(name: string) {
    setCookie({ name: name, value: '', expiryInDay: -1 })
}
