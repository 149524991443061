import { NextRouter } from 'next/router'
import { isServer } from '..'
import { isEmpty, toQueryString } from '../general/browserHelper'
import { ProcessedData } from './types'
import { QueryParamsData } from '@/types/srp'
import { getFormattedDate } from '../general/date'
import { srpQueryObject } from '@/components/oldComponents/Dropdown/HomeSearchContainer'
import Router from 'next/router'


export function updateRecentSearch(k: string, v: string) {
    try {
        if (!isServer() && typeof window.localStorage != 'undefined') {
            let storageName = 'recentAirport'
            let recentSearch = JSON.parse(localStorage.getItem(storageName) || '{}')
            if (isEmpty(recentSearch)) {
                recentSearch = []
            }

            let eleFound = false
            if (recentSearch.length) {
                for (let i = 0; i < recentSearch.length; i++) {
                    if (recentSearch[i].k == k) {
                        eleFound = true
                        break
                    }
                }
            }
            if (!eleFound) {
                recentSearch.unshift({ k, v })
            }
            if (recentSearch.length >= 10) {
                recentSearch.length = 10
            }
            localStorage.setItem(storageName, JSON.stringify(recentSearch))
        }
    } catch (e) {
        console.log('updateRecentSearch LC Error', e)
    }
}

export function getRecentSearch() {
    try {
        if (!isServer() && typeof window.localStorage != 'undefined') {
            let storageName = 'recentAirport'
            let recentSearch = JSON.parse(localStorage.getItem(storageName) || '{}')
            if (isEmpty(recentSearch)) {
                recentSearch = []
            }
            return recentSearch
        } else {
            return []
        }
    } catch (e) {
        console.log('getRecentSearch LC Error', e)
    }
}

export const formAirportList = (airportList, code) => {
    let intl = true
    let label = ''
    for (let i = 0; i < airportList.length; i++) {
        if (airportList[i].airportCode === code) {
            const { airportCity, countryCode } = airportList[i] || {}
            intl = airportList[i].intl
            label = `${code} - ${airportCity}, ${countryCode}`
            break
        }
    }
    return { intl, label }
}

export const pushSearchQueryUrl = (searchQuery: srpQueryObject) => {
    let pathname = `/flights/results`
    if (searchQuery.intl && searchQuery.intl === 'y') {
        pathname = `/flights/international/results`
    }
    Router.push({ pathname, query: { ...searchQuery } })
}

export const processQueryParams = (router: NextRouter, data: QueryParamsData): ProcessedData => {
    const processedData: ProcessedData = {
        isIntl: false,
        srpFlow: '',
        departDate: '',
        returnDate: '',
        source: '',
        destination: '',
        sourceHeader: '',
        destinationHeader: '',
        travellers: {
            adults: 0,
            children: 0,
            infants: 0
        },
        tripType: '',
        flightClass: '',
        dx: '',
        isCfw: false,
        carrier: '',
        sft: ''
    }

    const isIntl = router?.route?.indexOf('international') > 0 || data.intl == 'y' ? true : false
    processedData.isIntl = isIntl
    processedData.srpFlow = data.srpFlow
    processedData.departDate = decodeURIComponent(getFormattedDate(data.depart_date, 'DD/MM/YYYY', 'D/M/YYYY'))
    processedData.returnDate = data.return_date
        ? decodeURIComponent(getFormattedDate(data.return_date, 'DD/MM/YYYY', 'D/M/YYYY'))
        : ''
    processedData.source = data.from
    processedData.destination = data.to
    processedData.sourceHeader = data.from_header || data.from
    processedData.destinationHeader = data.to_header || data.to

    processedData.travellers = {
        adults: Number(data.adults),
        children: Number(data.childs),
        infants: Number(data.infants)
    }

    processedData.tripType = processedData.returnDate ? 'roundtrip' : 'oneway'

    if (data.carrier) {
        processedData.carrier = data.carrier
    }
    if (data.sft) {
        processedData.sft = data.sft
    }
    processedData.flightClass = data.class
    processedData.dx = data.dx || ''
    processedData.isCfw = data.isCfw === 'true' ? true : false

    return processedData
}

export const getSearchQueryObj = (query: ProcessedData) => {
    const {
        source,
        destination,
        departDate,
        returnDate,
        flightClass = 'Economy',
        travellers,
        sourceHeader,
        destinationHeader,
        isIntl,
        carrier,
        sft,
        isCfw
    } = query
    const { adults = 1, children = 0, infants = 0 } = travellers
    // let returnDateQuery = ''
    // let carrierQuery = ''
    const queryObj = {
        from: source,
        source_header: sourceHeader,
        to: destination,
        destination_header: destinationHeader,
        depart_date: departDate,
        class: flightClass,
        adults,
        childs: children,
        infants,
        mobileApp: true,
        intl: isIntl ? 'y' : 'n',
        responseType: 'jsonV3',
        source: 'DESKTOP',
        utm_currency: 'INR',
        sft: '',
        return_date: '',
        carrier: '',
        cfw: true
    }
    if (sft) {
        queryObj.sft = query.sft
    }
    if (returnDate) {
        queryObj.return_date = returnDate
    }
    if (carrier) {
        queryObj.carrier = carrier
    }
    if (isCfw) {
        queryObj.cfw = true
    }
    return queryObj
}

export const searchQueryUrl = (query: ProcessedData) => {
    const queryObj = getSearchQueryObj(query)
    return toQueryString(queryObj)
}

export const getMerchandisingQueryFK = (isIntl: boolean, tripType: string): string => {
    const type = isIntl ? 'intl' : 'dom'
    return `${type}_${tripType.toLowerCase()}`
}
