import { IComponentRendererParams, ISlot } from '@/types'
import componentGenerator from '../componentGenerator'

export const componentRenderer = ({ data, dispatcher, ownProps, pageName }: IComponentRendererParams) => {
    const { pageData: { pageHeaderSlots = [], pageFooterSlots = [] } = {}, slotsData = [] } = data || {}

    const slotsMapper = (slots: ISlot[]) => {
        return slots.map(slot => {
            return componentGenerator({
                slot,
                dispatcher,
                ownProps,
                pageName: pageName || (ownProps?.pageName as string)
            })
        })
    }

    const pageHeaderSlotsList = slotsMapper(pageHeaderSlots)
    const slotsList = slotsMapper(slotsData)
    const pageFooterSlotsList = slotsMapper(pageFooterSlots)

    return {
        pageHeaderSlotNodes: [...pageHeaderSlotsList],
        slotNodes: [...slotsList],
        pageFooterSlotNodes: [...pageFooterSlotsList]
    }
}

export default componentRenderer
