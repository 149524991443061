import React from 'react'

export const SliderRightChevron: React.FC<React.SVGProps<SVGSVGElement>> = ({
    width = '20',
    height = '20',
    fill = '#1A1A1A',
    ...restProps
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 20 20"
            {...restProps}
        >
            <mask
                id="mask0_1027_112365"
                style={{ maskType: 'alpha' }}
                width="20"
                height="20"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M20 20H40V40H20z" transform="rotate(-180 20 20)"></path>
            </mask>
            <g mask="url(#mask0_1027_112365)">
                <path
                    fill={fill}
                    d="M8.736 5.607l3.857 3.857a.706.706 0 01.161.247.8.8 0 010 .578.707.707 0 01-.161.246l-3.857 3.858a.617.617 0 01-.45.192.617.617 0 01-.45-.192.617.617 0 01-.193-.45c0-.172.064-.322.193-.45L11.329 10 7.836 6.507a.617.617 0 01-.193-.45c0-.172.064-.322.193-.45a.617.617 0 01.45-.193c.171 0 .321.064.45.193z"
                ></path>
            </g>
        </svg>
    )
}
