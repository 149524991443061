import * as React from 'react'

export const SearchIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
    <svg width={32} height={32} viewBox="0 0 24 24" fill="none" {...props}>
        <mask
            id="mask0_9675_14983"
            style={{
                maskType: 'alpha'
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={24}
            height={24}
        >
            <rect width={24} height={24} fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_9675_14983)">
            <path
                d="M19.1248 19.7999L13.2248 13.8999C12.7248 14.3166 12.1498 14.6416 11.4998 14.8749C10.8498 15.1082 10.1998 15.2249 9.5498 15.2249C7.93314 15.2249 6.5708 14.6706 5.4628 13.5619C4.35414 12.4539 3.7998 11.0999 3.7998 9.4999C3.7998 7.8999 4.35414 6.54557 5.4628 5.4369C6.5708 4.3289 7.9248 3.7749 9.5248 3.7749C11.1248 3.7749 12.4831 4.3289 13.5998 5.4369C14.7165 6.54557 15.2748 7.8999 15.2748 9.4999C15.2748 10.1999 15.1498 10.8749 14.8998 11.5249C14.6498 12.1749 14.3248 12.7249 13.9248 13.1749L19.8748 19.1249C19.9581 19.2082 19.9998 19.3126 19.9998 19.4379C19.9998 19.5626 19.9415 19.6832 19.8248 19.7999C19.7248 19.8999 19.6125 19.9499 19.4878 19.9499C19.3625 19.9499 19.2415 19.8999 19.1248 19.7999ZM9.5498 14.2249C10.8665 14.2249 11.9831 13.7709 12.8998 12.8629C13.8165 11.9542 14.2748 10.8332 14.2748 9.4999C14.2748 8.16657 13.8165 7.04557 12.8998 6.1369C11.9831 5.2289 10.8665 4.7749 9.5498 4.7749C8.21647 4.7749 7.09147 5.2289 6.1748 6.1369C5.25814 7.04557 4.7998 8.16657 4.7998 9.4999C4.7998 10.8332 5.25814 11.9542 6.1748 12.8629C7.09147 13.7709 8.21647 14.2249 9.5498 14.2249Z"
                fill="#1A1A1A"
            />
        </g>
    </svg>
)
