import { ParsedUrlQuery } from 'querystring'

import { IAppliedFilterListItemAction } from '@/components/results/PreAppliedFilterList/type'
import { PREPARED_DATES } from '@/constants/date'
import { API_CASE_CONTEXT } from '@/constants/network'
import {
    ADULTS,
    CHILDREN,
    CHILD_AGE,
    DEFAULT_ROOM_ALLOCATION,
    SORT_FILTER_TYPES,
    STAR_RATING_MAPPER,
    amenitiesPreFix
} from '@/constants/network/results'
import { getPageQuery } from '@/redux/slices/results'
import {
    IPaxInfoMetaData,
    IResultsPayload,
    IRoomAllocation,
    IRequestSortAndFilters,
    ISortBy,
    IMerchResultPayload,
    ResultsUnionPayload
} from '@/types/network/results'
import { deepClone, isEmpty } from '@/utils/general/browserHelper'
import { dateDiff } from '@/utils/general/date'

const PAGE_SIZE = 16

export const getChildMetaData = (query: ParsedUrlQuery, idx: number) => {
    const childMetaData: IPaxInfoMetaData[] = []
    const childAge = query[`${CHILD_AGE}${idx + 1}`]
    if (childAge) {
        if (typeof childAge === 'string') {
            childMetaData.push({ age: Number(childAge) })
        } else {
            childAge.forEach(age => {
                childMetaData.push({ age: Number(age) })
            })
        }
    }
    return childMetaData
}

export const getSrpRoomAllocation = (query: ParsedUrlQuery) => {
    const { num_rooms, childs } = query || {}

    const roomAllocations: IRoomAllocation[] = []
    const totalRoomCount = Number(num_rooms)
    const totalChilds = typeof childs === 'string' && Number(childs)

    if (!totalRoomCount) {
        return DEFAULT_ROOM_ALLOCATION
    }

    for (let i = 0; i < totalRoomCount; i++) {
        const adultCount = Number(query[`${ADULTS}${i + 1}`])
        // TODO(@): Remove this logic once URLs are corrected
        const childCount = totalChilds ? Number(query[`${CHILDREN}${i + 1}`]) : 0

        const paxInfo = {
            adults: {
                count: Number(adultCount),
                metadata: []
            },
            children: {
                count: childCount,
                metadata: childCount ? getChildMetaData(query, i) : []
            }
        }

        roomAllocations.push(paxInfo)
    }

    return roomAllocations
}

export const getSrpFilters = (query: ParsedUrlQuery): Omit<IRequestSortAndFilters, 'sortBy'> => {
    const {
        taStarRating,
        starFilter,
        price,
        propertyType,
        amenity,
        freeCancellation,
        inclusion,
        localities,
        cityId,
        deals,
        filterLocation
    } = query || {}
    const appliedFilters: Omit<IRequestSortAndFilters, 'sortBy'> = {}

    if (typeof taStarRating === 'string' && !isEmpty(taStarRating)) {
        const taRating = taStarRating.split('-')
        if (taRating.length > 1) {
            appliedFilters.tripAdvisorRating = taRating[0]
        }
    }

    if (starFilter && !isEmpty(starFilter)) {
        if (typeof starFilter === 'string') {
            appliedFilters.starRating = [STAR_RATING_MAPPER[starFilter]]
        } else {
            appliedFilters.starRating = []
            starFilter.forEach(star => {
                if (star === '2') {
                    appliedFilters.starRating = [
                        ...(appliedFilters.starRating as string[]),
                        STAR_RATING_MAPPER['1'],
                        STAR_RATING_MAPPER['2']
                    ]
                } else {
                    ;(appliedFilters.starRating as string[]).push(STAR_RATING_MAPPER[star])
                }
            })
        }
    }

    if (typeof price === 'string' && !isEmpty(price)) {
        const priceRange = price.split('-')
        if (priceRange.length === 2) {
            appliedFilters.priceRange = {
                min: Number(priceRange[0]),
                max: Number(priceRange[1])
            }
        }
    }

    if (typeof propertyType === 'string' && !isEmpty(propertyType)) {
        appliedFilters.properties = propertyType.split('-')
    }

    if (typeof amenity === 'string' && !isEmpty(amenity)) {
        const amenityWithPrefix = '-' + amenity
        /**
         * For the case fo am24-hour-security the delimeter '-' won't work.
         * So have added '-' in the beginning and splitting the query string with '-am'
         * After splitting the string the array formed will be ["", 24-hour-security] and this will break the filters
         * Sliced the array from 1st index
         */
        let selectedAmenities = amenityWithPrefix.split(`-${amenitiesPreFix}`).slice(1)

        const amenities: string[] = []
        /**
         * We are adding this logic for backward compatibility
         * Backend is adding am in URL query for backward compatibility, we need to solution this properly.
         * TODO: @Khushahal @Saransh @
         */

        /* Empty selectedAmenities means that its possibly a new case where
         * -am is not prefixed in the amenities & only one amenity is selected
         */
        if (!selectedAmenities.length) {
            selectedAmenities = [amenity]
        }

        selectedAmenities.forEach(amenity => {
            amenities.push(amenity)
        })
        appliedFilters.amenities = amenities
    } else if (Array.isArray(amenity)) {
        // -am is not prefixed in the amenities & multiple amenities are selected
        appliedFilters.amenities = amenity
    }

    if (inclusion && !isEmpty(inclusion)) {
        if (typeof inclusion === 'string') {
            appliedFilters.inclusions = [inclusion]
        } else {
            appliedFilters.inclusions = []
            inclusion.forEach(inclusionItem => {
                ;(appliedFilters.inclusions as string[]).push(inclusionItem)
            })
        }
    }

    if (localities && !isEmpty(localities)) {
        if (typeof localities === 'string') {
            appliedFilters.localities = [localities]
        } else {
            appliedFilters.localities = []
            localities.forEach(locality => {
                ;(appliedFilters.localities as string[]).push(locality)
            })
        }
    }
    if (filterLocation && typeof filterLocation === 'string') {
        const [type, id, name = ''] = filterLocation.split('-')
        // [TODO] : Ashutosh - visit for multi search
        appliedFilters.locations = [
            {
                id,
                type,
                name
            }
        ]
    }

    if (freeCancellation === 'fc') {
        const { inclusions = [] } = appliedFilters || {}
        if (Array.isArray(inclusion) && !inclusion.includes('freeCancellation')) {
            appliedFilters.inclusions = [...inclusions, 'freeCancellation']
        }
    }

    if (cityId && typeof cityId === 'string') {
        appliedFilters.cities = cityId.split('-')
    }

    if (deals && !isEmpty(deals)) {
        const appliedDeals = []
        if (typeof deals === 'string') {
            appliedDeals.push(deals)
        } else {
            deals.forEach(deal => {
                deal !== '' && appliedDeals.push(deal)
            })
        }

        if (appliedDeals.length > 0) {
            appliedFilters.deals = appliedDeals
        }
    }

    return appliedFilters
}

export const getSortFilters = (query: ParsedUrlQuery): ISortBy => {
    const { sort } = query || {}

    const defaultSortFilter: ISortBy = {
        key: 'recommended',
        metadata: null,
        order: 'asc'
    }

    if (typeof sort === 'string') {
        switch (sort) {
            case SORT_FILTER_TYPES.TOP_RATED:
                return {
                    key: 'topRated',
                    metadata: null,
                    order: 'desc'
                }
            case SORT_FILTER_TYPES.PRICE_HIGH_TO_LOW:
                return {
                    key: 'priceHighToLow',
                    metadata: null,
                    order: 'desc'
                }
            case SORT_FILTER_TYPES.PRICE_LOW_TO_HIGH:
                return {
                    key: 'priceLowToHigh',
                    metadata: null,
                    order: 'asc'
                }
            default:
                return defaultSortFilter
        }
    }

    return defaultSortFilter
}

export const formResultsApiPayload = ({
    query = {} as ParsedUrlQuery,
    pageNo = 0,
    pageQuery,
    isMerchSrp = false,
    isSEO = false
}: {
    query: ParsedUrlQuery
    pageNo?: number
    isMerchSrp?: boolean
    isSEO?: boolean
    pageQuery?: ReturnType<typeof getPageQuery>
}) => {
    let { city, state, country, localityId, flightId } = { ...pageQuery, ...query }

    let { dest_code: destCode, poi, chk_in: checkInDate, chk_out: checkOutDate } = query

    const { name, version, baseLocation, filterLocation } = query || {}

    const { FORMATTED_TODAY, FORMATTED_TOMORROW, FORMATTED_DAY_AFTER_TOMORROW } = PREPARED_DATES

    if (!checkInDate || !checkOutDate) {
        checkInDate = FORMATTED_TOMORROW
        checkOutDate = FORMATTED_DAY_AFTER_TOMORROW
    }

    if (typeof checkInDate === 'string' && typeof checkOutDate === 'string') {
        const dateDiffWithCheckIn = dateDiff(FORMATTED_TODAY, checkInDate, 'DD/MM/YYYY')
        const dateDiffWithCheckOut = dateDiff(FORMATTED_TODAY, checkOutDate, 'DD/MM/YYYY')

        /**
         * case:- if the ndate is not in correct format then dateDiffWithCheckIn / dateDiffWithCheckOut will be NaN so added that check as well.
         * Checkin and checkout should not be equal.
         */
        if (
            dateDiffWithCheckIn < 0 ||
            dateDiffWithCheckOut < 0 ||
            dateDiffWithCheckIn >= dateDiffWithCheckOut ||
            isNaN(dateDiffWithCheckIn) ||
            isNaN(dateDiffWithCheckOut)
        ) {
            checkInDate = FORMATTED_TOMORROW
            checkOutDate = FORMATTED_DAY_AFTER_TOMORROW
        }
    }

    const roomAllocations = getSrpRoomAllocation(query)
    const filters = getSrpFilters(query)
    const sortFilters = getSortFilters(query)

    const sortAndFilters = {
        ...pageQuery?.sortAndFilters,
        sortBy: sortFilters,
        ...filters
    }

    if (typeof city !== 'string') {
        city = ''
    }

    if (typeof state !== 'string') {
        state = ''
    }

    if (typeof country !== 'string') {
        country = ''
    }

    if (typeof destCode !== 'string') {
        destCode = ''
    }

    /**
     * If user is redirecting from coding mart then localityId is coming the as undefined string so as a temp check
     * In future will fix in coding mart url.
     */
    if (typeof localityId !== 'string' || localityId === 'undefined') {
        localityId = ''
    }

    if (typeof poi !== 'string' || poi === 'undefined') {
        poi = ''
    }

    if (typeof flightId !== 'string') {
        flightId = ''
    }

    if (typeof checkInDate !== 'string' || typeof checkOutDate !== 'string') {
        checkInDate = FORMATTED_TODAY
        checkOutDate = FORMATTED_DAY_AFTER_TOMORROW
    }

    if (isMerchSrp) {
        const merchPayload: IMerchResultPayload = {
            merchName: typeof name === 'string' ? name : '',
            useCaseContext: API_CASE_CONTEXT.MERCH_SRP_PAGE,
            checkInDate,
            checkOutDate,
            pageNo,
            pageSize: PAGE_SIZE,
            roomAllocations,
            sortAndFilters
        }

        if (baseLocation && typeof baseLocation === 'string') {
            const [type, id, name = ''] = baseLocation.split('-')
            // [TODO] : Ashutosh - visit for multi search
            if (type && id)
                merchPayload.location = {
                    id,
                    type,
                    name
                }
        }
        if (filterLocation && typeof filterLocation === 'string') {
            const [type, id, name = ''] = filterLocation.split('-')
            // [TODO] : Ashutosh - visit for multi search
            if (type && id)
                merchPayload.sortAndFilters.locations = [
                    {
                        id,
                        type,
                        name
                    }
                ]
        }

        if (typeof version === 'string') {
            merchPayload.version = version
        }
        return merchPayload
    }

    let payload: ResultsUnionPayload
    if (flightId) {
        payload = {
            useCaseContext: API_CASE_CONTEXT.SRP_PAGE,
            checkInDate,
            checkOutDate,
            pageNo,
            pageSize: PAGE_SIZE,
            roomAllocations,
            sortAndFilters,
            flightId: flightId
        }
        if (typeof version === 'string') {
            payload.version = version
        }
        if (baseLocation && typeof baseLocation === 'string') {
            const [type, id, name = ''] = baseLocation.split('-')
            // [TODO] : Ashutosh - visit for multi search
            if (type && id)
                payload.location = {
                    id,
                    type,
                    name
                }
        }
        if (filterLocation && typeof filterLocation === 'string') {
            const [type, id, name = ''] = filterLocation.split('-')
            // [TODO] : Ashutosh - visit for multi search
            if (type && id)
                payload.sortAndFilters.locations = [
                    {
                        id,
                        type,
                        name
                    }
                ]
        }
    } else {
        payload = {
            useCaseContext: API_CASE_CONTEXT.SRP_PAGE,
            city,
            state,
            country,
            checkInDate,
            checkOutDate,
            pageNo,
            pageSize: PAGE_SIZE,
            roomAllocations,
            cityId: destCode,
            localityId: localityId || null,
            locality: poi || null,
            sortAndFilters
        }
        if (typeof version === 'string') {
            payload.version = version
        }
        if (baseLocation && typeof baseLocation === 'string') {
            const [type, id, name = ''] = baseLocation.split('-')
            // [TODO] : Ashutosh - visit for multi search
            if (type && id)
                payload.location = {
                    id,
                    type,
                    name
                }
        }
        if (filterLocation && typeof filterLocation === 'string') {
            const [type, id, name = ''] = filterLocation.split('-')
            // [TODO] : Ashutosh - visit for multi search
            if (type && id)
                payload.sortAndFilters.locations = [
                    {
                        id,
                        type,
                        name
                    }
                ]
        }
    }

    if (isSEO) {
        payload = {
            ...pageQuery,
            ...payload,
            useCaseContext: API_CASE_CONTEXT.SEO_PAGE,
            seoUrl: query.seoUrl?.toString()
        }
    }

    return payload
}

export const createSEOPayload = (query: ParsedUrlQuery, seoUrl: string) => {
    const payload = {
        pageSize: PAGE_SIZE,
        pageNo: 0,
        useCaseContext: API_CASE_CONTEXT.SEO_PAGE,
        seoUrl
    }
    // If its the first call without any query params
    // then its not required to add any filters to the payload
    // As the query will contain `seoUrl` as a key, so checking that
    if (Object.keys(query)?.length === 1) return payload

    const resultsPayload = formResultsApiPayload({ query })
    return {
        ...resultsPayload,
        ...payload
    }
}

export const getUpdatedResultsFiltersAfterRemoval = (
    removedFilter: IAppliedFilterListItemAction,
    requestPayload: IResultsPayload
) => {
    const clonedRequestPayload = deepClone(requestPayload)
    const { sortAndFilters } = clonedRequestPayload || {}
    const { key: filterKey, id: filterId } = removedFilter || {}
    const filterToBeUpdated = sortAndFilters[filterId as keyof IRequestSortAndFilters]

    if (Array.isArray(filterToBeUpdated)) {
        const index = filterToBeUpdated.findIndex(filterValue =>
            typeof filterValue === 'string'
                ? filterValue.toLowerCase() === filterKey.toLowerCase()
                : filterValue.id.toLowerCase() === filterKey.toLowerCase()
        )
        if (index > -1) {
            filterToBeUpdated.splice(index, 1)
        }
    } else {
        delete sortAndFilters[filterId as keyof IRequestSortAndFilters]
    }

    return clonedRequestPayload
}
