import { init } from '@elastic/apm-rum'
import { useEffect } from 'react'

const useApmInit = () => {
    useEffect(() => {
        const apm = init({
            serviceName: process.env.NEXT_PUBLIC_SERVICE_NAME,
            serverUrl: process.env.NEXT_PUBLIC_ELASTIC_APM_SERVER_BASE_URL,
            environment: process.env.NEXT_PUBLIC_CT_ENV,
            breakdownMetrics: true
        })
        console.error.prototype = function (...args: string[]) {
            apm.captureError(args?.join(' '))
        }
    }, [])
}

export default useApmInit
