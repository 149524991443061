import React from 'react'

export const Briefcase = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
            <circle cx="16" cy="16" r="16" fill="#F2EDF9"></circle>
            <g stroke="#5F1AB6" strokeLinecap="round" strokeWidth="1.25" clipPath="url(#clip0_4540_29294)">
                <path d="M7.608 22.237v-8.974c0-1.163.882-2.108 1.97-2.108h12.834c1.088 0 1.97.945 1.97 2.108v8.974c0 1.164-.882 2.109-1.97 2.109H9.588c-1.088 0-1.97-.945-1.97-2.109h-.01zM8 17.431h6.294M17.96 17.431h6.295M13.167 11.134V9.092c0-.769.657-1.392 1.47-1.392h2.814c.814 0 1.47.623 1.47 1.392v1.952"></path>
                <path d="M15.235 15.759h1.628c.421 0 .765.363.765.81v2.534c0 .925-.706 1.672-1.579 1.672-.872 0-1.578-.747-1.578-1.672V16.57c0-.447.343-.81.764-.81z"></path>
            </g>
            <defs>
                <clipPath id="clip0_4540_29294">
                    <path fill="#fff" d="M0 0H18V18H0z" transform="translate(7 7)"></path>
                </clipPath>
            </defs>
        </svg>
    )
}
