import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store/configureStore'
import { IPageResponse } from '@/types'
import baseFetch from '@/network/baseFetch'
import { ISideSheetPayload } from '@/types/actions'
import { COMMON_ENDPOINT_PREFIX } from '@/constants/network'
import { IBaseResponse } from '@/types/network/common'

const initialState = {
    data: {} as IPageResponse,
    isLoading: true
}

export const detailsSlice = createSlice({
    name: 'details',
    initialState,
    reducers: {
        setDetailsData: (state, action: PayloadAction<IPageResponse>) => {
            const { payload } = action || {}
            state.data = payload
            state.isLoading = false
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchDetailsData.fulfilled, (state, action) => {
            const { payload: { response } = {} } = action
            state.isLoading = false
            if (response) state.data = response
        })
        builder.addCase(fetchDetailsData.pending, state => {
            state.isLoading = true
        })
    }
})

export const fetchDetailsData = createAsyncThunk('fetchDetailsData', async (payload: ISideSheetPayload) => {
    try {
        const response = await baseFetch<IBaseResponse<IPageResponse>>({
            url: COMMON_ENDPOINT_PREFIX + payload.url,
            body: JSON.stringify(payload.payload),
            method: payload.method,
            headers: {}
        })
        return response
    } catch (e) {
        console.log(e)
    }
})

const getDetailsData = (state: RootState) => state.details.data
const getDetailsLoading = (state: RootState) => state.details.isLoading

const { reducer, actions } = detailsSlice
const { setDetailsData } = actions || {}

export { setDetailsData, getDetailsData, getDetailsLoading }

export default reducer
