import { PropsWithChildren, memo, useRef } from 'react'
import { BaseComponentProps } from '../type'
import IntersectionObserverWrapper from '@/components/common/IntersectionObserverWrapper'
import useStickyTop from './useStickyTop'

function StickyCalculatedComponent({ children, ownProps, dispatcher }: PropsWithChildren<BaseComponentProps>) {
    const elemRef = useRef<HTMLDivElement>(null)

    const { top, isSticky, getIntersectionOberserverOptions, handleIntersection } = useStickyTop({
        dispatcher,
        ownProps
    })

    return (
        <IntersectionObserverWrapper
            ref={elemRef}
            onIntersectionObserverCallback={handleIntersection}
            options={getIntersectionOberserverOptions()}
            metaData={{
                classname: 'p-sticky z-50 bg-white mb-6' + (isSticky ? ' roomSticky' : ''),
                css: { top: `${top}px` }
            }}
        >
            {children}
        </IntersectionObserverWrapper>
    )
}

export default memo(StickyCalculatedComponent)
