import { memo } from 'react'

import { useAppDispatch, useAppSelector } from '@/redux/store/hooks'
import { getDialogs } from '@/redux/slices/dialogs'

import BaseDialogWrapper from './BaseDialogWrapper'

const GlobalDialog = () => {
    const dialogs = useAppSelector(getDialogs)
    const globalDispatcher = useAppDispatch()

    return (
        <>
            {dialogs.map((dialog, index) => {
                return <BaseDialogWrapper dialog={dialog} key={index} dialogId={index} dispatcher={globalDispatcher} />
            })}
        </>
    )
}

export default memo(GlobalDialog)
