import React from 'react'

export const RateCardIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="49" height="53" fill="none" viewBox="0 0 49 53" {...props}>
            <path
                fill="url(#paint0_linear_8893_2548)"
                fillOpacity="0.1"
                d="M.004 28.574c0-.684.594-1.222 1.278-1.207l6.449.116c.698 0 1.248.567 1.233 1.25-.148 8.364 6.687 15.317 15.246 15.463 8.56.16 15.647-6.546 15.796-14.91.163-8.378-6.672-15.316-15.231-15.461-.907-.015-1.828.029-2.705.174l-.074 4.291c0 .51-.327.946-.802 1.135-.49.189-1.01.058-1.382-.291L7.107 10.363c-.49-.48-.476-1.251.03-1.731L20.182.356a1.3 1.3 0 011.382-.262c.46.203.758.669.743 1.163l-.074 3.87a28.46 28.46 0 012.719-.088c13.507.247 24.295 11.2 24.043 24.422-.253 13.222-11.442 23.782-24.935 23.535C10.554 52.748-.234 41.796.004 28.574z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_8893_2548"
                    x1="13.437"
                    x2="43.801"
                    y1="2.541"
                    y2="52.327"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.102" stopColor="#FF4F17"></stop>
                    <stop offset="1" stopColor="#FF4F17" stopOpacity="0.2"></stop>
                </linearGradient>
            </defs>
        </svg>
    )
}

export default RateCardIcon
