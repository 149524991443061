import { REQUEST_METHODS } from '@/constants/network'
import baseFetch from '@/network/baseFetch'
import { isEmpty } from '@/utils/general/browserHelper'

export const fetchComponentsController = async (action: any) => {
    try {
        const { itineraryId, headers = '' } = action
        const URL = `/itin/v7/itinerary/${itineraryId}/fetch/components`
        const response = await baseFetch({
            url: URL,
            method: REQUEST_METHODS.GET,
            headers: headers
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.error(e)
        return e
    }
}
export const fetchUserDataController = async (itineraryId: string) => {
    try {
        const URL = `/itin/v4/itinerary/${itineraryId}/user`

        let headers = { 'grant-token': '' }

        const response = await baseFetch({
            url: URL,
            method: REQUEST_METHODS.GET,
            headers: headers
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.log(e)
        return {}
    }
}
