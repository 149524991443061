import React from 'react'

export const Backpack = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
            <circle cx="16" cy="16" r="16" fill="#F2FAF0"></circle>
            <g fill="#61C149" fillRule="evenodd" clipPath="url(#clip0_4540_29283)" clipRule="evenodd">
                <path d="M14.038 9.874c-2.65 0-4.802 2.163-4.793 4.831v9.185c0 .481.383.86.85.86h11.82c.467 0 .85-.379.85-.86v-9.183c0-2.67-2.153-4.833-4.793-4.833h-3.934zm-6.043 4.834c-.01-3.354 2.696-6.084 6.043-6.084h3.934c3.339 0 6.043 2.731 6.043 6.083v9.183c0 1.163-.934 2.11-2.1 2.11h-11.82c-1.166 0-2.1-.947-2.1-2.11v-9.182z"></path>
                <path d="M13.851 15.85c-.747 0-1.4.641-1.4 1.505v2.377c0 .864.653 1.505 1.4 1.505h4.327c.747 0 1.4-.641 1.4-1.505v-2.377c0-.864-.653-1.505-1.4-1.505h-4.327zm-2.65 1.505c0-1.494 1.155-2.755 2.65-2.755h4.327c1.495 0 2.65 1.26 2.65 2.755v2.377c0 1.494-1.155 2.755-2.65 2.755h-4.327c-1.495 0-2.65-1.26-2.65-2.755v-2.377z"></path>
                <path d="M11.2 18.008c0-.345.28-.625.625-.625h8.35a.625.625 0 110 1.25h-8.35a.625.625 0 01-.625-.625z"></path>
                <path d="M17.51 17.463c.345 0 .625.28.625.625v1.05a.625.625 0 01-1.25 0v-1.05c0-.345.28-.625.625-.625zM16.015 7.249c-.892 0-1.627.728-1.627 1.643v.07a.625.625 0 11-1.25 0v-.07c0-1.6 1.29-2.893 2.877-2.893 1.6 0 2.877 1.306 2.877 2.893v.189a.625.625 0 11-1.25 0v-.189c0-.909-.73-1.643-1.627-1.643z"></path>
            </g>
            <defs>
                <clipPath id="clip0_4540_29283">
                    <path fill="#fff" d="M0 0H16V20H0z" transform="translate(8 6)"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export default Backpack
