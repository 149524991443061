import React, { SVGProps } from 'react'

export const LastMinuteDealsTransparent = ({
    width = 16,
    height = 16,
    color = '#FF4F17',
    ...rest
}: SVGProps<SVGSVGElement>) => {
    return (
        <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g clip-path="url(#clip0_3430_56343)">
                <path
                    d="M4.13721 2.57227H7.83032C7.77564 2.95718 7.64686 3.56147 7.31175 4.20039C6.87135 5.04019 6.30237 5.5055 5.99257 5.72227C5.69026 5.49957 5.18749 5.0658 4.76248 4.32751C4.36439 3.63571 4.20605 2.97402 4.13721 2.57227Z"
                    fill={color}
                />
            </g>
            <path
                d="M1.72559 1.21484H10.2792"
                stroke="black"
                stroke-width="0.666666"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M1.72559 11.2852H10.2792"
                stroke="black"
                stroke-width="0.666666"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9.19868 3.29688C9.20627 3.60752 9.13708 4.44751 8.27152 5.19189C7.67246 5.70696 7.31891 6.10154 7.31958 6.45134C7.32025 6.80113 7.60728 7.16024 8.27152 7.71013C9.5279 8.75001 9.19779 10.0086 9.19779 11.2799"
                stroke="black"
                stroke-width="0.666666"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M2.81186 1.21289V2.34838C2.81186 2.66379 2.81074 2.97919 2.80293 3.29438C2.79534 3.60502 2.86453 4.44501 3.73009 5.18939C4.32916 5.70446 4.6827 6.09904 4.68203 6.44884C4.68136 6.78241 4.39433 7.15774 3.73009 7.70764C2.47371 8.74751 2.80382 10.0061 2.80382 11.2774"
                stroke="black"
                stroke-width="0.666666"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M5.074 4.66471C4.93227 4.51479 4.79031 4.3439 4.65528 4.15007C4.21647 3.51883 4.04171 2.91528 3.96582 2.54883H8.03673C7.97647 2.89992 7.83451 3.45112 7.46512 4.0339C6.97966 4.79991 6.35248 5.22434 6.01098 5.42206"
                stroke="black"
                stroke-width="0.666666"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8.03624 11.2814H3.96533C4.0256 10.9303 4.16755 10.3791 4.53694 9.79636C5.0224 9.03036 5.64958 8.60592 5.99108 8.4082C6.24106 8.5605 6.61536 8.82117 6.98431 9.22613"
                stroke="black"
                stroke-width="0.666666"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <defs>
                <clipPath id="clip0_3430_56343">
                    <rect width="3.69311" height="3.15" fill="white" transform="translate(4.13745 2.57227)" />
                </clipPath>
            </defs>
        </svg>
    )
}
