import React from 'react'

export const Home = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
            <circle cx="16" cy="16" r="16" fill="#FFF9EC"></circle>
            <g fill="#FDBA12" fillRule="evenodd" clipPath="url(#clip0_4540_29258)" clipRule="evenodd">
                <path d="M17.169 7.438v.001l7.23 5.956c.384.355.605.857.605 1.386v8.069c0 1.183-.95 2.163-2.134 2.163H9.11c-1.184 0-2.123-.97-2.123-2.153v-8.118c0-.537.219-1.043.621-1.402l7.287-5.915a.627.627 0 01.099-.074 1.673 1.673 0 012.175.087zm-1.51.981L8.44 14.272a.615.615 0 00-.204.47v8.118c0 .513.4.904.874.904H22.87c.472 0 .884-.4.884-.914V14.78c0-.18-.075-.35-.203-.467l-7.23-5.956-.001-.002a.425.425 0 00-.586.005.622.622 0 01-.075.058z"></path>
                <path d="M13.881 15.636c-.368.132-.542.31-.631.462-.098.164-.133.36-.123.565.018.391.214.796.542 1.134l2.261 2.316 2.405-2.385.001-.002c.3-.295.475-.597.522-.928.078-.569-.126-.809-.29-.936a1.07 1.07 0 00-.412-.191h-.003c-.437-.077-.82.135-1.153.48a3.089 3.089 0 00-.468.636.623.623 0 01-.144.219l-.009.008a.625.625 0 01-1.043-.285c-.381-.755-.793-.983-1.04-1.06a.945.945 0 00-.415-.033zm2.622 1.207zm-.525-1.427c-.414-.52-.873-.804-1.31-.94a2.195 2.195 0 00-.781-.097 1.868 1.868 0 00-.351.055l-.01.003-.005.001h-.002c0 .001-.002.002.183.599l-.185-.598a.462.462 0 00-.02.007c-.627.217-1.061.573-1.322 1.014-.256.43-.314.887-.297 1.26.035.765.407 1.446.896 1.949l2.702 2.768a.625.625 0 00.887.007l2.85-2.827c.429-.422.787-.962.883-1.646.143-1.033-.261-1.709-.762-2.097a2.32 2.32 0 00-.938-.43l-.024-.005-.008-.001c-1.029-.177-1.804.368-2.263.844a3.929 3.929 0 00-.123.134z"></path>
            </g>
            <defs>
                <clipPath id="clip0_4540_29258">
                    <path fill="#fff" d="M0 0H18V18H0z" transform="translate(7 7)"></path>
                </clipPath>
            </defs>
        </svg>
    )
}
