import { SVGProps } from 'react'

export const DealsOfTheDayIcon = ({ width = 32, height = 32, color = '#0FBACB', ...rest }: SVGProps<SVGSVGElement>) => {
    return (
        <svg width={width} height={height} viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect
                width="56.0018"
                height="56"
                rx="13.9999"
                transform="matrix(1 -1.45756e-08 1.41894e-05 1 0.0012207 0)"
                fill="#ECF9FB"
            />
            <path
                d="M12.6007 41.4389L12.6007 18.3923C12.6007 16.2513 14.3037 14.5156 16.4044 14.5156L39.596 14.5156C41.6967 14.5156 43.3997 16.2513 43.3997 18.3923L43.3997 41.4389C43.3997 43.5799 41.6967 45.3156 39.596 45.3156L16.4044 45.3156"
                stroke="black"
                stroke-width="1.74997"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20.3 22.2148L43.3993 22.2148"
                stroke="black"
                stroke-width="1.74997"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20.1877 10.666L20.1877 18.0391"
                stroke="black"
                stroke-width="1.74997"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M35.8783 10.666L35.8783 18.0391"
                stroke="black"
                stroke-width="1.74997"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M28.0441 26.0684L25.5491 30.3427L20.2988 31.0462L24.1721 34.616L23.157 39.5433L28.0923 36.9798L33.0146 39.5433L31.9656 34.5655L35.6983 31.247L30.448 30.2418L28.0441 26.0684Z"
                fill={color}
            />
            <path
                d="M28.0441 26.0684L25.5491 30.3427L20.2988 31.0462L24.1721 34.616L23.157 39.5433L28.0923 36.9798L33.0146 39.5433L31.9656 34.5655L35.6983 31.247L30.448 30.2418L28.0441 26.0684Z"
                stroke="black"
                stroke-width="1.74997"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
