import { ParsedUrlQuery } from 'querystring'

import { getFormattedDate, dateDiff } from '../general/date'
import { PREPARED_DATES } from '@/constants/date'
import { INCLUSIONS } from '@/constants/pages/details'
import { constructImageUrl } from '../general'
import { API_CASE_CONTEXT } from '@/constants/network'
import { IImageTransformations } from '@/components/common/Image/type'

export function getValidDatesFromQuery(dates: string[]) {
    const { FORMATTED_TODAY, FORMATTED_TOMORROW, FORMATTED_DAY_AFTER_TOMORROW } = PREPARED_DATES

    let processedDate = {
        checkIn: FORMATTED_TOMORROW,
        checkOut: FORMATTED_DAY_AFTER_TOMORROW
    }

    if (dates && dates.length > 1) {
        let validCheckIn = getFormattedDate(dates[0], 'DD/MM/YYYY', 'DDMMYYYY'),
            validCheckOut = getFormattedDate(dates[1], 'DD/MM/YYYY', 'DDMMYYYY')

        if (validCheckIn === 'Invalid Date') {
            validCheckIn = getFormattedDate(dates[0], 'DD/MM/YYYY', 'DDMMYY')
        }
        if (validCheckOut === 'Invalid Date') {
            validCheckOut = getFormattedDate(dates[1], 'DD/MM/YYYY', 'DDMMYY')
        }

        const dateDiffWithCheckIn = dateDiff(FORMATTED_TODAY, validCheckIn, 'DD/MM/YYYY')
        const dateDiffWithCheckOut = dateDiff(FORMATTED_TODAY, validCheckOut, 'DD/MM/YYYY')

        /**
         * If any date is invalid or lesser then change the dates to tommorow and Tommorow + 1.
         * In desktop, we have rates and details page in same page so correct the dates.
         */
        if (validCheckIn === 'Invalid Date' || dateDiffWithCheckIn < 0 || dateDiffWithCheckIn > dateDiffWithCheckOut) {
            validCheckIn = FORMATTED_TOMORROW
            validCheckOut = FORMATTED_DAY_AFTER_TOMORROW
        } else if (
            validCheckOut === 'Invalid Date' ||
            dateDiffWithCheckOut < 0 ||
            dateDiffWithCheckIn > dateDiffWithCheckOut ||
            validCheckIn === validCheckOut //if checkin and chekout is same then checkout will be tommorow.
        ) {
            validCheckIn = FORMATTED_TOMORROW
            validCheckOut = FORMATTED_DAY_AFTER_TOMORROW
        }
        processedDate = { ...processedDate, checkIn: validCheckIn, checkOut: validCheckOut }
    }

    return processedDate
}

export const detailsRoomAllocationDecoder = (travellers: string) => {
    const rooms = travellers ? travellers.split('|') : []
    const roomAllocations = []

    const getChildrenMetaData = (_index: number, childrenAge: string, childrenCount: number) => {
        const metadata = []
        if (childrenCount) {
            const ages = childrenAge.split(',')
            for (let i = 0; i < ages.length; i += 1) {
                metadata.push({
                    age: Number(ages[i])
                })
            }
        }

        return metadata
    }

    for (let i = 0; i < rooms.length; i += 1) {
        const [counts, childrenAge = ''] = rooms[i].split('-')

        let [adultsCount = 0, childrenCount = 0] = counts.split(',')

        adultsCount = Number(adultsCount)
        childrenCount = Number(childrenCount)

        roomAllocations.push({
            adults: {
                count: adultsCount,
                metadata: []
            },
            children: {
                count: childrenCount,
                metadata: getChildrenMetaData(i, childrenAge, childrenCount)
            }
        })
    }
    return roomAllocations
}

export const formDetailsApiPayload = (data: ParsedUrlQuery) => {
    const { flightId = '', c = '', r = '2,0', inclusion = '' } = data || {}

    const inclusionsList = typeof inclusion === 'string' ? inclusion.split(',') : []

    let hId: string[] = [],
        dates: string[] = [],
        roomAllocations = ''

    if (typeof flightId === 'string') {
        hId = flightId?.split('-') || []
    }

    if (typeof c === 'string') {
        dates = c.split('|')
    }

    if (typeof r === 'string') {
        roomAllocations = r
    }

    const travellers = detailsRoomAllocationDecoder(roomAllocations)
    const { checkIn, checkOut } = getValidDatesFromQuery(dates)

    const payload = {
        useCaseContext: API_CASE_CONTEXT.DESKTOP_DETAILS,
        flightId: hId[hId.length - 1],
        checkInDate: checkIn,
        checkOutDate: checkOut,
        roomAllocations: travellers,
        filters: {
            includeOnlyFreeCancellation: inclusionsList.includes(INCLUSIONS.FREECANCELLATION),
            includeOnlyFreeBreakfast: inclusionsList.includes(INCLUSIONS.FREEBREAKFAST)
        }
        //TODO: We are not going to send the meta data but we need to discuss once about it.
        // metaData: {
        //   utm: { ...getUTMData(data) },
        // },
    }

    return payload
}

export function preloadPreviewModalImage(
    url: string,
    width = 1016,
    height = 624,
    transformations: IImageTransformations
) {
    const { dpr, file, quality } = transformations

    let defaultTransformation = `w_${width},h_${height},fl_progressive,e_sharpen:80,c_fill`
    if (dpr) {
        defaultTransformation += `,dpr_${dpr}`
    }
    if (file) {
        defaultTransformation += `,f_${file}`
    }
    if (quality) {
        defaultTransformation += `,q_${quality}`
    }

    new Image().src = constructImageUrl(url, defaultTransformation)
}
