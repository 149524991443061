import React from 'react'

export const ShortcutsMini: React.FC<React.SVGProps<SVGSVGElement>> = ({
    width = '16',
    height = '16',
    ...restProps
}) => {
    return (
        <svg viewBox="0 0 14 14" height={height} width={width} className="c-neutral-400" {...restProps}>
            <g fill="none" fill-rule="evenodd">
                <rect width="14" height="14" fill="#FFF" opacity="0"></rect>
                <path
                    fill="currentColor"
                    fill-rule="nonzero"
                    stroke="currentColor"
                    d="M6.66377804,12.0103667 C6.85467399,12.1862449 7.14840775,12.1868013 7.33996863,12.0116476 L7.52037698,11.846694 C10.4133674,9.18600047 11.3179531,8.24297499 11.9494023,7.05369364 C12.2679269,6.45377943 12.4230769,5.8833705 12.4230769,5.29700272 C12.4230769,3.72548999 11.2121985,2.5 9.66538462,2.5 C8.79968324,2.5 7.94470884,2.90670095 7.38258677,3.57477308 L7,4.02947068 L6.61741323,3.57477308 C6.05529116,2.90670095 5.20031676,2.5 4.33461538,2.5 C2.78780146,2.5 1.57692308,3.72548999 1.57692308,5.29700272 C1.57692308,5.88588466 1.73340796,6.4586853 2.05471743,7.06126617 C2.68799666,8.24891226 3.59889285,9.19694245 6.47994688,11.8409976 L6.66377804,12.0103667 Z"
                ></path>
            </g>
        </svg>
    )
}
