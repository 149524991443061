import { useEffect } from 'react'
import type { AppProps } from 'next/app'
import { ThemeProvider } from '@cleartrip/ct-design-components'
import { Inter } from 'next/font/google'

import { wrapper } from '@/redux/store/configureStore'
import { useAppDispatch } from '@/redux/store/hooks'
import { fetchAbTestData } from '@/redux/slices/abConfig'

import GlobalSidesheetComponent from '@/components/common/Sidesheet'
import GlobalModalComponent from '@/components/common/Modal'
import GlobalDialog from '@/components/common/GlobalDialog'
import GlobalToast from '@/containers/ToastContainer'
import Layout from '@/containers/Layout'
import PreFetcherComponent from '@/components/prefetcher/PreFetcherComponent'

import ErrorBoundary from '@/utils/widget/errorBoundary'
import ErrorFallback from '@/utils/widget/errorBoundary/errorFallback'
import { deviceId } from '@/utils/deviceId'

import { DEFAULT_ERROR_MOCK_CODE } from '@/constants/components/common'

import '@/styles/global.scss'
import StatsigManager from '@/analytics/statsig'
import useApmInit from '@/utils/hooks/useApmInit'

const inter = Inter({
    subsets: ['latin'],
    weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
    preload: true,
    display: 'swap',
    adjustFontFallback: false,
    fallback: ['Inter', 'sans-serif']
})

const App = ({ Component, pageProps, router, ...rest }: AppProps) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        // dispatch(fetchAbTestData())
        deviceId()
        StatsigManager.initializeStastigInstance()
    }, [])

    useApmInit()

    return (
        <>
            <style jsx global>{`
                * {
                    font-family: ${inter.style.fontFamily} !important;
                }
            `}</style>
            <ThemeProvider theme="B2C" platform="desktop">
                <ErrorBoundary fallback={<ErrorFallback statusCode={DEFAULT_ERROR_MOCK_CODE} />}>
                    <Layout>
                        <Component key={router.asPath} router={router} {...pageProps} {...rest} />
                    </Layout>
                </ErrorBoundary>
                <GlobalModalComponent />
                <GlobalToast />
                <GlobalSidesheetComponent />
                <GlobalDialog />
                <PreFetcherComponent />
            </ThemeProvider>
        </>
    )
}

export default wrapper.withRedux(App)
