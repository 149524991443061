export enum CustomEventTypes {
    SHOW_ON_OUT_OF_VIEWPORT = 'SHOW_ON_OUT_OF_VIEWPORT'
}

export enum SourceType {
    RECEIVER = 'RECEIVER',
    SENDER = 'SENDER'
}
export type CustomEventPayload = {
    extraProps: {
        [key: string]: string | boolean
    }
}

export interface IIdForRootMargin {
    isNegative: boolean
    id: string
}

export type CustomEvents = {
    [key in CustomEventTypes]: CustomEventPayload
}

export type CustomEventFn = (eventName: CustomEventTypes, obj: CustomEventPayload) => void
