import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '@/redux/store/configureStore'

import { IDispatcher, IOwnProps, IPageResponse } from '@/types'

export interface IDialogOwnProps extends IOwnProps {
    customDispatcher?: IDispatcher
}

export interface DialogPayload {
    data: IPageResponse
    ownProps?: IDialogOwnProps
}

export interface DialogsData {
    data: Array<DialogPayload>
}

const initialState: DialogsData = {
    data: []
}

export const DialogsSlice = createSlice({
    name: 'dialogs',
    initialState,
    reducers: {
        openDialog: (state, action: PayloadAction<DialogPayload>) => {
            state.data.push(action.payload)
        },
        closeDialog: state => {
            state.data.pop()
        },
        clearDialog: state => {
            state.data = []
        }
    }
})

export const { openDialog, clearDialog, closeDialog } = DialogsSlice.actions
export const getDialogs = (state: RootState): Array<DialogPayload> => state.dialogs.data
const DialogsReducer = DialogsSlice.reducer
export default DialogsReducer
