import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISidesheetProps, SidesheetDirection } from '@/components/common/Sidesheet/type'
import { getSidesheetData } from '@/network/services/abConfig.service'
import { IFetchSidesheetPayload } from '@/types/actions'
import { ComponentMapper } from '@/types'

import { RootState } from '../../store/configureStore'
import { DOCUMENTS_IDS } from '@/constants/pages/details'

export interface SideSheetsData {
    data: Array<ISidesheetProps>
}

const initialState: SideSheetsData = {
    data: []
}

export const fetchSidesheetData = createAsyncThunk('fetchSidesheet', async (payload: IFetchSidesheetPayload) => {
    try {
        const { ownProps, payload: sideSheetPayload } = payload ?? {}
        const response = await getSidesheetData(sideSheetPayload)
        return { ownProps: ownProps, response: response }
    } catch (e) {
        return {}
    }
})

const shimmerResponse = {
    pageData: {},
    slotsData: [
        {
            slotData: {
                type: ComponentMapper.SIDESHEET_SHIMMER,
                data: {}
            }
        }
    ]
}

export const SheetsSlice = createSlice({
    name: 'sidesheets',
    initialState,
    reducers: {
        openSheet: (state, action: PayloadAction<ISidesheetProps>) => {
            state.data.push(action.payload)
        },
        closeSheet: state => {
            state.data.pop()
        },
        clearSheet: state => {
            state.data = []
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchSidesheetData.fulfilled, (state, action) => {
            const { payload } = action
            const { ownProps, response } = payload
            state.data[state.data.length - 1] = {
                data: response?.response || { pageData: {}, slotsData: [] },
                ownProps: {
                    ...ownProps,
                    direction: SidesheetDirection.LEFT,
                    pageName: ownProps?.pageName ?? 'sidesheet',
                    idForRootMargin: DOCUMENTS_IDS.SHEET_HEADER_ID
                }
            }
        })
        builder.addCase(fetchSidesheetData.pending, (state, action) => {
            const { width } = action.meta.arg?.ownProps ?? {}

            state.data.push({
                data: shimmerResponse,
                ownProps: { direction: SidesheetDirection.LEFT, pageName: 'sidesheet', width }
            })
        })
        builder.addCase(fetchSidesheetData.rejected, state => {
            state.data = []
        })
    }
})

export const { openSheet, clearSheet, closeSheet } = SheetsSlice.actions
export const getSidesheets = (state: RootState): Array<ISidesheetProps> => state.sidesheets.data

const SheetsReducer = SheetsSlice.reducer
export default SheetsReducer
