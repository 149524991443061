import React from 'react'

export const WalletMini: React.FC<React.SVGProps<SVGSVGElement>> = ({ width = '16', height = '16', ...restProps }) => {
    return (
        <svg viewBox="0 0 14 14" height={width} width={height} className="c-neutral-400" {...restProps}>
            <g fill="none" fill-rule="evenodd">
                <rect width="14" height="14" fill="#FFF" opacity="0"></rect>
                <path
                    fill="currentColor"
                    d="M10.9938128,1.8893206 C10.9979344,1.92619985 11,1.96327993 11,2.00038878 L11,2.9992998 L2.5,3 C2.25454011,3 2.05039163,3.17687516 2.00805567,3.41012437 L2,3.5 C2,3.74545989 2.17687516,3.94960837 2.41012437,3.99194433 L2.5,4 L12,4 C12.5522847,4 13,4.44771525 13,5 L13,12 C13,12.5522847 12.5522847,13 12,13 L2,13 C1.44771525,13 1,12.5522847 1,12 L1,5 L1.003,4.9462998 L1.00048219,4.89843613 L1,2.89446607 C1,2.38516155 1.38277848,1.95722081 1.88893182,1.90065328 L9.88893182,1.00657599 C10.4377995,0.945234733 10.9324715,1.34045296 10.9938128,1.8893206 Z M11,8 C10.4477153,8 10,8.44771525 10,9 C10,9.55228475 10.4477153,10 11,10 C11.5522847,10 12,9.55228475 12,9 C12,8.44771525 11.5522847,8 11,8 Z"
                ></path>
            </g>
        </svg>
    )
}
