import React from 'react'

export const FeatherRight = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width="6" height="14" viewBox="0 0 6 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1.79813 0C1.7531 0.576171 1.98226 1.75904 3.25888 1.8812C3.31388 0.874892 2.50356 0.169942 1.79813 0ZM3.28331 13.3551C2.89365 13.7839 1.87228 14.4314 0.904238 13.5916C1.60105 12.8598 2.67358 12.9615 3.28331 13.3551ZM0.636774 13.2584C1.1459 12.9783 2.01199 12.1374 1.40342 11.0141C0.527386 11.5227 0.386574 12.5812 0.636774 13.2584ZM5.99831 6.26204C6.02796 6.83903 5.76706 8.01442 4.48619 8.1005C4.45802 7.09349 5.28778 6.41195 5.99831 6.26204ZM4.05629 8.05109C4.21674 7.49543 4.23051 6.29232 3.00195 5.92534C2.7449 6.90042 3.39809 7.74794 4.05629 8.05109ZM4.13951 5.87633C4.17049 5.29942 3.91227 4.12343 2.63157 4.03455C2.6011 5.04148 3.42932 5.72483 4.13951 5.87633ZM5.62869 3.70449C5.78787 4.26052 5.79888 5.46364 4.56946 5.82791C4.31463 4.85225 4.96978 4.0062 5.62869 3.70449ZM4.9063 11.4141C4.64891 11.9327 3.84403 12.8343 2.68363 12.2912C3.15348 11.3974 4.21244 11.2021 4.9063 11.4141ZM2.3317 12.0411C2.7446 11.6328 3.34732 10.5877 2.45504 9.67389C1.75188 10.4022 1.90596 11.4583 2.3317 12.0411ZM3.85211 3.62917C3.64581 3.08837 2.93143 2.11407 1.7243 2.54349C2.10596 3.47808 3.14115 3.77372 3.85211 3.62917ZM4.33206 1.04516C4.70375 1.49102 5.20313 2.58876 4.22718 3.41284C3.59739 2.62075 3.85229 1.58435 4.33206 1.04516ZM5.73742 8.57751C5.71212 9.15489 5.34089 10.3015 4.05835 10.2689C4.12599 9.2633 5.01627 8.66118 5.73742 8.57751ZM3.63408 10.1793C3.8465 9.64066 3.97448 8.44368 2.78702 7.96464C2.43865 8.91207 3.00801 9.81655 3.63408 10.1793Z"
                fill="#FF4F17"
            />
        </svg>
    )
}

export default FeatherRight
