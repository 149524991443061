export const enum PAGE_NAME {
    HOME = 'flights_home',
    RESULT = 'flights_srp',
    SEO = 'flights_seo',
    DETAIL = 'flights_details',
    ITINERARY = 'flights_itinerary',
    ITINERARY_SUPERCOIN = 'flights_itinerary_supercoin',
    MERCH_SRP = 'flights_merch_srp'
}

export type PAGE_NAME_TYPE = `${PAGE_NAME}`

export const AVOID_WORDS_IN_URL = ['<script']

export const EMPTY_BLOCK_FUNCTION = () => {
    return
}

export const ROUTE_PATH = {
    [PAGE_NAME.HOME]: '/flights',
    [PAGE_NAME.RESULT]: '/flights/results',
    [PAGE_NAME.DETAIL]: '/flights/details/[flightId]'
}

export const PREFETCH_ROUTE = {
    [ROUTE_PATH[PAGE_NAME.HOME]]: ['/flights/results'],
    [ROUTE_PATH[PAGE_NAME.RESULT]]: ['/flights/details/[flightId]'],
    [ROUTE_PATH[PAGE_NAME.DETAIL]]: ['/flights/itinerary/[itineraryId]/info']
}
