/* This component is used to render subcompomnent which do not have slotData key but have data, type, action and all 

i.e.   
"userAccount": {
    "type": "USER_ACCOUNT",
    "data": {
        "nonLoggedIn": {
            "action": {
                "type": "OPEN_LOG_IN_MODAL"
            },
            "type": "BUTTON",
            "data": {
                "text": "Log in / Sign up"
            }
        }
    }
}

if we have to render the useAccount kind of component then we should use this component. which is inside the  data component.
*/

import componentGenerator from '../componentGenerator'
import { ISubComponentGeneratorParams } from '@/types'

const subComponentGenerator = ({
    slot,
    dispatcher,
    ownProps,
    pageName,
    id,
    behaviour,
    ...rest
}: ISubComponentGeneratorParams) => {
    //Prepare data to send in componentGenerator also added the aliasing to match 2 types, Will fix later.
    const preparedSlot = { slotData: slot, behaviour }

    const slotNode = componentGenerator({
        slot: preparedSlot,
        dispatcher,
        ownProps,
        pageName,
        id,
        ...rest
    })

    if (slotNode) {
        return slotNode
    }
    return null
}

export default subComponentGenerator
