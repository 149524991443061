import { SVGProps } from 'react'
export function RhombusPolygon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={9} height={12} fill="none" {...props}>
            <path
                fill="#09707A"
                d="M4.34 9.336c-.12-.688-.608-1.552-1.536-2.248-.456-.344-.92-.568-1.376-.664v-.336c.904-.216 1.792-.824 2.36-1.648.288-.416.472-.824.552-1.248h.336c.136.808.76 1.704 1.608 2.304.416.296.848.496 1.288.592v.336c-.888.184-1.92.976-2.432 1.784-.256.408-.408.784-.464 1.128H4.34Z"
            />
        </svg>
    )
}
