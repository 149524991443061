import React from 'react'

export default function Diamond({ color = '#1A1A1A', ...props }) {
    return (
        <div className="pl-2 flex justify-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.8162 9.99969L9.18379 9.99969L12 18.8508L14.8162 9.99969ZM13.3344 17.9551L15.8656 9.99969H19.4818L13.3344 17.9551ZM12.4051 20.7932L20.8956 9.80541C21.0202 9.64426 21.0345 9.42367 20.9319 9.24775L17.4319 3.24775C17.3423 3.09414 17.1778 2.99969 17 2.99969H13.5117C13.504 2.99951 13.4962 2.99951 13.4884 2.99969H10.5116C10.5038 2.99951 10.496 2.99951 10.4883 2.99969H7C6.82216 2.99969 6.65771 3.09414 6.56811 3.24775L3.06811 9.24775C2.96549 9.42367 2.97983 9.64426 3.10435 9.80541L11.5949 20.7932C11.6106 20.8149 11.628 20.8352 11.6468 20.8539C11.6946 20.9017 11.7527 20.9403 11.8191 20.966C11.8762 20.9882 11.9376 21.0001 12.0003 21C12.0631 21 12.1246 20.988 12.1817 20.9657C12.2701 20.9311 12.3482 20.8717 12.4051 20.7932ZM4.51825 9.99969L10.6656 17.9551L8.13439 9.99969H4.51825ZM9.80629 3.99969H7.28718L4.37052 8.99969H8.13962L9.80629 3.99969ZM10.8604 3.99969L9.19371 8.99969L14.8063 8.99969L13.1396 3.99969H10.8604ZM14.1937 3.99969L15.8604 8.99969H19.6295L16.7128 3.99969H14.1937Z"
                    fill={color}
                />
            </svg>
        </div>
    )
}
