import { createPortal } from 'react-dom'
import { OutsideClickHandler, Transition, Container } from '@cleartrip/ct-design-components'

import { ActionMapper, IDispatcher, IPageData, ISlot, ITracking } from '@/types'
import { closeSheet } from '@/redux/slices/sidesheetSlice'
import { useAppDispatch } from '@/redux/store/hooks'
import { getNestedField, isServer } from '@/utils'
import componentRenderer from '@/utils/widget/componentRenderer'
import { deepClone } from '@/utils/general/browserHelper'
import { DOCUMENTS_IDS } from '@/constants/pages/details'

import { ISidesheetProps } from './type'
import SidesheetShimmer from './Shimmer'
import { useEffect, useState } from 'react'
import { triggerTracking } from '@/utils/tracking'

export const Sidesheet: React.FC<ISidesheetProps> = ({ data, ownProps, isRecent, sidesheetId }) => {
    const dispatch = useAppDispatch()

    const [isSheetOpen, setIsSheetOpen] = useState(true)

    const { pageName } = ownProps || {}

    const handleCloseSidesheet = () => {
        if (isRecent) {
            setIsSheetOpen(false)
            setTimeout(() => dispatch(closeSheet()), 350)
        }
    }

    const [pageResponse, setPageReponse] = useState(data)

    useEffect(() => {
        setPageReponse(data)
    }, [data])

    const filterAmenities = (searchKey: string) => {
        const lowerCaseSearch = searchKey.toLowerCase()
        const clonedResponse = deepClone(data)

        if (!searchKey.trim().length) {
            return clonedResponse
        }

        const filteredResult: { pageData: IPageData; slotsData: ISlot<object>[] } = {
            pageData: clonedResponse.pageData,
            slotsData: []
        }

        clonedResponse.slotsData.forEach(slot => {
            const list: { label: string }[] = getNestedField(['slotData', 'data', 'tabScrollItems', 'children'], slot)
            const filteredList = list.filter(({ label }) => {
                return label.toLowerCase().includes(lowerCaseSearch)
            })

            if (filteredList.length) {
                slot.slotData.data = {
                    ...slot.slotData.data,
                    //@ts-ignore
                    tabScrollItems: { ...slot.slotData.data.tabScrollItems, children: filteredList }
                }
                filteredResult.slotsData.push(slot)
            }
        })
        return filteredResult
    }

    const internalDispatcher: IDispatcher = action => {
        const { type, payload } = action || {}
        switch (type) {
            case ActionMapper.SEARCH_AMENITIES: {
                //@ts-ignore
                setPageReponse(filterAmenities(payload?.value))
                break
            }
            case ActionMapper.CLOSE_SHEET: {
                handleCloseSidesheet()
                break
            }
            case ActionMapper.TRACKING: {
                triggerTracking({
                    pageTrackingData: ownProps?.pageTracking as ITracking,
                    componentTrackingData: payload as ITracking
                })
                break
            }
            default: {
                dispatch(action)
            }
        }
    }

    const { pageHeaderSlotNodes, slotNodes, pageFooterSlotNodes } = componentRenderer({
        data: pageResponse,
        dispatcher: internalDispatcher,
        ownProps: {
            ...ownProps,
            sidesheetId,
            idForRootMargin: DOCUMENTS_IDS.SHEET_HEADER_ID,
            navWidth: ownProps?.width ?? '671px'
        },
        pageName
    })

    const {
        direction = 'left',
        width = '671px',
        classname = 'w-100p',
        hasLoader,
        overrideSidesheetSlotCSS = {}
    } = ownProps ?? {}

    if (isServer()) return null
    return createPortal(
        <Container
            className={`${
                direction === 'left' ? 'flex-end' : 'flex-start'
            } overlay-bg oy-scroll p-fixed flex w-100p h-100p t-0 flex-middle z-100 c-h-100vh h-overflow-x`}
        >
            <OutsideClickHandler onOutsideClick={handleCloseSidesheet}>
                <Transition type="slide" slideDirection={direction} in={isSheetOpen} timeout={350}>
                    <Container
                        className="flex flex-column flex-start flex-middle pb-6 min-h-100vh bg-white p-relative"
                        width={width}
                    >
                        {hasLoader ? (
                            <SidesheetShimmer />
                        ) : (
                            <Container className={classname}>
                                {pageHeaderSlotNodes}
                                <Container className="px-6 w-100p bg-white" css={overrideSidesheetSlotCSS}>
                                    {slotNodes}
                                </Container>
                                {pageFooterSlotNodes}
                            </Container>
                        )}
                    </Container>
                </Transition>
            </OutsideClickHandler>
        </Container>,
        document.body
    )
}
