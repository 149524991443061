import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store/configureStore'
import { IPageResponse, IPageResponseLoading } from '@/types'
import { IResultsInitialState } from './type'
import { IUpdateSlotsData } from '../../../types/widget/slots'
import { updateSlotsData } from '@/utils/slots'

const initialState: IResultsInitialState = {
    data: {} as IPageResponse,
    isLoading: true,
    isInfiniteScrollLoading: false,
    isSlotUpdationLoading: false
}

export const resultsSlice = createSlice({
    name: 'results',
    initialState,
    reducers: {
        setResultsData: (state, action: PayloadAction<IPageResponse>) => {
            const { payload } = action || {}
            state.data = payload
            state.isLoading = false
        },
        setResultsDataLoading: (state, action: PayloadAction<IPageResponseLoading>) => {
            const { payload } = action || {}
            state.isLoading = payload.isLoading
        },
        setResultsFetchMoreData: (state, action: PayloadAction<IPageResponse>) => {
            const { payload } = action || {}
            const { pageData: { pageMisc = {} } = {}, slotsData = [] } = payload || {}

            const existingData = state?.data?.slotsData || []
            state.data = {
                ...state.data,
                pageData: {
                    ...state.data.pageData,
                    pageMisc
                },
                slotsData: [...existingData, ...slotsData]
            }
        },
        setInfiniteScrollLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action || {}
            state.isInfiniteScrollLoading = payload
        },
        updateResultsSlotsData: (state, { payload }: PayloadAction<IUpdateSlotsData>) => {
            const { updatedPageData, updatedSlotData } = payload.RESULTS_PAGE || {}
            // @ts-ignore
            const newPayload: IUpdateSlotsData = {
                RESULTS_PAGE: {
                    updatedSlotData: updatedSlotData,
                    updatedPageData
                }
            }

            const stateWithUpdatedSlotsData = updateSlotsData(
                // @ts-ignore
                {
                    RESULTS_PAGE: state.data
                },
                newPayload
            )
            state.data = stateWithUpdatedSlotsData.RESULTS_PAGE
        },
        toggleIsSlotUpdationLoading: state => {
            state.isSlotUpdationLoading = !state.isSlotUpdationLoading
        }
    }
})

const getResultsData = (state: RootState) => state.results?.data
const getResultsLoading = (state: RootState) => state.results.isLoading
const getResultsSlotsData = (state: RootState) => state.results?.data?.slotsData
const getResultsPageMiscData = (state: RootState) => state.results?.data?.pageData?.pageMisc
const getResultSrollLoadingState = (state: RootState) => state.results.isInfiniteScrollLoading
const srpABValueData = (state: RootState) => state.results?.data?.pageData?.pageMisc?.searchV3Key
const getPageQuery = (state: RootState) => {
    const pageQuery = state?.results?.data?.pageData?.pageQuery
    if (pageQuery) {
        const { useCaseContext: _, pageNo: __, pageSize: ___, ...restPageQuery } = pageQuery
        return restPageQuery
    }
}
const getSlotUpdationLoadingState = (state: RootState) => state.results.isSlotUpdationLoading

const { reducer, actions } = resultsSlice
const {
    setResultsData,
    setResultsFetchMoreData,
    setResultsDataLoading,
    setInfiniteScrollLoading,
    updateResultsSlotsData,
    toggleIsSlotUpdationLoading
} = actions || {}

export {
    setResultsData,
    setResultsDataLoading,
    setResultsFetchMoreData,
    getResultsData,
    getResultsLoading,
    getResultsSlotsData,
    getResultsPageMiscData,
    setInfiniteScrollLoading,
    getResultSrollLoadingState,
    getPageQuery,
    updateResultsSlotsData,
    toggleIsSlotUpdationLoading,
    getSlotUpdationLoadingState,
    srpABValueData
}

export default reducer
