import React from 'react'

export const Bus: React.FC<React.SVGProps<SVGSVGElement>> = ({ width = '24', height = '24', ...restProps }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <mask id="mask0_10792_8150" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_10792_8150)">
                <path d="M7.15 20C6.98333 20 6.84167 19.9417 6.725 19.825C6.60833 19.7083 6.55 19.5583 6.55 19.375V17.6C6.21667 17.4667 5.875 17.1707 5.525 16.712C5.175 16.254 5 15.725 5 15.125V6C5 4.96667 5.56267 4.20833 6.688 3.725C7.81267 3.24167 9.58333 3 12 3C14.5 3 16.2917 3.22933 17.375 3.688C18.4583 4.146 19 4.91667 19 6V15.125C19 15.725 18.825 16.254 18.475 16.712C18.125 17.1707 17.7833 17.4667 17.45 17.6V19.375C17.45 19.5583 17.3917 19.7083 17.275 19.825C17.1583 19.9417 17.0167 20 16.85 20C16.6667 20 16.5167 19.9417 16.4 19.825C16.2833 19.7083 16.225 19.5583 16.225 19.375V18H7.775V19.375C7.775 19.5583 7.71267 19.7083 7.588 19.825C7.46267 19.9417 7.31667 20 7.15 20ZM6 11H18V6.55H6V11ZM8.5 15.625C8.81667 15.625 9.08333 15.5123 9.3 15.287C9.51667 15.0623 9.625 14.8 9.625 14.5C9.625 14.1833 9.51267 13.9167 9.288 13.7C9.06267 13.4833 8.8 13.375 8.5 13.375C8.18333 13.375 7.91667 13.4873 7.7 13.712C7.48333 13.9373 7.375 14.2 7.375 14.5C7.375 14.8167 7.48767 15.0833 7.713 15.3C7.93767 15.5167 8.2 15.625 8.5 15.625ZM15.5 15.625C15.8167 15.625 16.0833 15.5123 16.3 15.287C16.5167 15.0623 16.625 14.8 16.625 14.5C16.625 14.1833 16.5127 13.9167 16.288 13.7C16.0627 13.4833 15.8 13.375 15.5 13.375C15.1833 13.375 14.9167 13.4873 14.7 13.712C14.4833 13.9373 14.375 14.2 14.375 14.5C14.375 14.8167 14.4877 15.0833 14.713 15.3C14.9377 15.5167 15.2 15.625 15.5 15.625ZM6.1 5.55H17.925C17.7417 5.03333 17.204 4.64567 16.312 4.387C15.4207 4.129 13.9833 4 12 4C10.05 4 8.625 4.129 7.725 4.387C6.825 4.64567 6.28333 5.03333 6.1 5.55ZM8 17H16C16.55 17 17.021 16.8043 17.413 16.413C17.8043 16.021 18 15.55 18 15V12H6V15C6 15.55 6.196 16.021 6.588 16.413C6.97933 16.8043 7.45 17 8 17Z" />
            </g>
        </svg>
    )
}
