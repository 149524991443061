export const SwapIcon = ({ stroke, ...props }: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
            <rect width="32" height="32" fill="#fff" rx="16"></rect>
            <g
                stroke="#3366CC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.25"
                clipPath="url(#clip0_160_1650)"
            >
                <path d="M24.167 14.833H7.833M7.833 14.833L13.667 9M7.833 18.334h16.334M24.167 18.333l-5.834 5.834"></path>
                <circle cx="16" cy="16" r="13.375"></circle>
            </g>
            <defs>
                <clipPath id="clip0_160_1650">
                    <path fill="#fff" d="M0 0H28V28H0z" transform="translate(2 2)"></path>
                </clipPath>
            </defs>
        </svg>
    )
}
