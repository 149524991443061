import { isServer } from '../general'
import { CustomEventFn, CustomEventPayload, CustomEventTypes, CustomEvents, IIdForRootMargin } from './type'

export const performFunctionOnCustomEvents = (obj: CustomEvents, fn: CustomEventFn) => {
    for (const key in obj) {
        const eventName = key as CustomEventTypes
        fn(eventName, obj[eventName])
    }
}

export const allowEventDispatch = (
    eventName: CustomEventTypes,
    entry: IntersectionObserverEntry,
    customEventPayload: CustomEventPayload
) => {
    switch (eventName) {
        case CustomEventTypes.SHOW_ON_OUT_OF_VIEWPORT: {
            if (customEventPayload.extraProps.onlyAllowFromTop)
                return entry.boundingClientRect.y < (entry.rootBounds?.y ?? Number.MIN_SAFE_INTEGER)
            return true
        }
        default: {
            return true
        }
    }
}

export function getCustomEventsOptions(extraProps: CustomEventPayload['extraProps']) {
    if (!isServer()) {
        const { IntersectingWidgetID, idsForRootMargin = [] } = extraProps || {}

        const rootElement = document.getElementById(String(IntersectingWidgetID))

        // TODO @raghav fix type - remove as
        const rootHeight = (idsForRootMargin as IIdForRootMargin[]).reduce((accumulator, idForRootMargin) => {
            const { isNegative, id } = idForRootMargin ?? {}

            const rootHeight = (isNegative ? -1 : 1) * (document.getElementById(String(id))?.offsetHeight ?? 0)
            return accumulator + rootHeight
        }, 0)

        return {
            root: rootElement,
            rootMargin: typeof rootHeight === 'number' ? `${rootHeight}px 0px 0px 0px` : '0px',
            threshold: 0
        }
    }
}

export const CUSTOM_EVENTS_WITH_INTERSECTION_OBSERVER = [CustomEventTypes.SHOW_ON_OUT_OF_VIEWPORT]
