import React from 'react'

export function RightArrow(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 20 20" {...props}>
            <path
                d="M10.869 2l6.916 7.446a.821.821 0 01.077 1.01l-.077.098L10.869 18l-1.106-1.108 5.67-6.106-13.43.02L2 9.21l13.414-.02-5.65-6.082L10.868 2z"
                fill="currentColor"
                fillRule="nonzero"
            />
        </svg>
    )
}

export function LeftArrow(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 20 20" {...props}>
            <path
                d="M9.131 2L2.215 9.446a.821.821 0 00-.077 1.01l.077.098L9.131 18l1.106-1.108-5.67-6.106 13.43.02L18 9.21 4.586 9.19l5.65-6.082L9.132 2z"
                fill="currentColor"
                fillRule="nonzero"
            />
        </svg>
    )
}

export function UpArrow(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ rotate: '180deg' }}
        >
            <mask
                id="mask0_6383_92460"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="32"
                height="32"
            >
                <rect x="32" y="32" width="32" height="32" transform="rotate(-180 32 32)" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_6383_92460)">
                <path
                    d="M16.0001 12.4665C16.1556 12.4665 16.3054 12.4887 16.4494 12.5331C16.5943 12.5776 16.7223 12.6665 16.8334 12.7998L22.8668 18.7998C23.0445 18.9998 23.1334 19.2331 23.1334 19.4998C23.1334 19.7665 23.0334 19.9998 22.8334 20.1998C22.6334 20.3776 22.4001 20.4665 22.1334 20.4665C21.8668 20.4665 21.6334 20.3776 21.4334 20.1998L16.0001 14.7665L10.5334 20.1998C10.3556 20.3998 10.1334 20.4945 9.86676 20.4838C9.60009 20.4723 9.36676 20.3776 9.16676 20.1998C8.96676 19.9998 8.86676 19.7611 8.86676 19.4838C8.86676 19.2056 8.96676 18.9776 9.16676 18.7998L15.1668 12.7998C15.2779 12.6665 15.4054 12.5776 15.5494 12.5331C15.6943 12.4887 15.8445 12.4665 16.0001 12.4665Z"
                    fill="#1A1A1A"
                />
            </g>
        </svg>
    )
}

export function DownArrow(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_6383_92460"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="32"
                height="32"
            >
                <rect x="32" y="32" width="32" height="32" transform="rotate(-180 32 32)" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_6383_92460)">
                <path
                    d="M16.0001 12.4665C16.1556 12.4665 16.3054 12.4887 16.4494 12.5331C16.5943 12.5776 16.7223 12.6665 16.8334 12.7998L22.8668 18.7998C23.0445 18.9998 23.1334 19.2331 23.1334 19.4998C23.1334 19.7665 23.0334 19.9998 22.8334 20.1998C22.6334 20.3776 22.4001 20.4665 22.1334 20.4665C21.8668 20.4665 21.6334 20.3776 21.4334 20.1998L16.0001 14.7665L10.5334 20.1998C10.3556 20.3998 10.1334 20.4945 9.86676 20.4838C9.60009 20.4723 9.36676 20.3776 9.16676 20.1998C8.96676 19.9998 8.86676 19.7611 8.86676 19.4838C8.86676 19.2056 8.96676 18.9776 9.16676 18.7998L15.1668 12.7998C15.2779 12.6665 15.4054 12.5776 15.5494 12.5331C15.6943 12.4887 15.8445 12.4665 16.0001 12.4665Z"
                    fill="#1A1A1A"
                />
            </g>
        </svg>
    )
}

export function CalendarLeftArrow({ color = '#1A1A1A', ...rest }: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...rest}>
            <mask
                id="a"
                width={24}
                height={24}
                x={0}
                y={0}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'alpha'
                }}
            >
                <path fill="#D9D9D9" d="M0 0h24v24H0z" />
            </mask>
            <g mask="url(#a)">
                <path
                    fill={color}
                    d="m13.475 17.125-4.5-4.5a.822.822 0 0 1-.188-.288.935.935 0 0 1 0-.675.825.825 0 0 1 .188-.287l4.5-4.5A.72.72 0 0 1 14 6.65c.2 0 .375.075.525.225a.72.72 0 0 1 .225.525.72.72 0 0 1-.225.525L10.45 12l4.075 4.075a.72.72 0 0 1 .225.525.72.72 0 0 1-.225.525.72.72 0 0 1-.525.225.72.72 0 0 1-.525-.225Z"
                />
            </g>
        </svg>
    )
}

export function CalendarRightArrow(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
            <mask
                id="a"
                width={24}
                height={24}
                x={0}
                y={0}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'alpha'
                }}
            >
                <path fill="#D9D9D9" d="M0 0h24v24H0z" />
            </mask>
            <g mask="url(#a)">
                <path
                    fill="#1A1A1A"
                    d="M8.875 17.125a.72.72 0 0 1-.225-.525c0-.2.075-.375.225-.525L12.95 12 8.875 7.925A.72.72 0 0 1 8.65 7.4c0-.2.075-.375.225-.525A.72.72 0 0 1 9.4 6.65c.2 0 .375.075.525.225l4.5 4.5a.825.825 0 0 1 .188.287.944.944 0 0 1 0 .675.823.823 0 0 1-.188.288l-4.5 4.5a.72.72 0 0 1-.525.225.72.72 0 0 1-.525-.225Z"
                />
            </g>
        </svg>
    )
}
