import React from 'react'

export const MaskedHeart = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
            <circle cx="16" cy="16" r="16" fill="#FFF1ED"></circle>
            <path
                fill="#FF4F17"
                d="M16.036 24c-.198 0-.396-.044-.567-.142-1.279-.73-7.698-4.632-8.436-10.455-.009-.098-.306-2.507 1.071-4.072.685-.782 1.639-1.218 2.845-1.289 0 0 3.115-.373 5.114 2.738.9-1.404 2.62-2.996 5.159-2.756.045 0 4.186.454 3.745 5.459 0 .178-.162 5.272-8.319 10.348-.18.116-.396.169-.612.169zM11.327 9.127c-.144 0-.234.008-.252.008-.918.063-1.584.356-2.061.89-1.063 1.209-.81 3.218-.81 3.236.675 5.307 6.68 8.96 7.877 9.637 7.509-4.721 7.707-9.433 7.707-9.486.342-3.93-2.566-4.285-2.683-4.303-3.133-.285-4.457 3.05-4.466 3.085a.593.593 0 01-.54.364c-.234-.009-.468-.124-.558-.338-1.297-2.853-3.475-3.111-4.214-3.111v.018z"
            ></path>
            <path
                stroke="#FF4F17"
                strokeLinecap="round"
                strokeWidth="1.25"
                d="M19.5 11.543c.75-.148 2.25 0 2.25 1.779"
            ></path>
        </svg>
    )
}
