import React from 'react'

export const SettingsMini: React.FC<React.SVGProps<SVGSVGElement>> = ({
    width = '16',
    height = '16',
    ...restProps
}) => {
    return (
        <svg viewBox="0 0 14 14" height={height} width={width} className="c-neutral-400" {...restProps}>
            <g fill="none" fill-rule="evenodd">
                <rect width="14" height="14" fill="#FFF" opacity="0"></rect>
                <g transform="translate(-.5)">
                    <path
                        stroke="currentColor"
                        stroke-width="2"
                        d="M7.5,11.7619821 C10.1233526,11.7619821 12.25,9.63533468 12.25,7.01198212 C12.25,4.38862956 10.1233526,2.26198212 7.5,2.26198212 C4.87664744,2.26198212 2.75,4.38862956 2.75,7.01198212 C2.75,9.63533468 4.87664744,11.7619821 7.5,11.7619821 Z"
                    ></path>
                    <g fill="currentColor" transform="translate(6)">
                        <polygon points=".5 11 2.5 11 2.5 14 .5 14"></polygon>
                        <polygon points=".5 0 2.5 0 2.5 3 .5 3"></polygon>
                    </g>
                    <g fill="currentColor" transform="rotate(-45 4.5 -.243)">
                        <polygon points=".5 11 2.5 11 2.5 14 .5 14"></polygon>
                        <polygon points=".5 0 2.5 0 2.5 3 .5 3"></polygon>
                    </g>
                    <g fill="currentColor" transform="rotate(45 4.5 14.243)">
                        <polygon points=".5 11 2.5 11 2.5 14 .5 14"></polygon>
                        <polygon points=".5 0 2.5 0 2.5 3 .5 3"></polygon>
                    </g>
                    <g fill="currentColor" transform="rotate(90 4.5 10)">
                        <polygon points=".5 11 2.5 11 2.5 14 .5 14"></polygon>
                        <polygon points=".5 0 2.5 0 2.5 3 .5 3"></polygon>
                    </g>
                </g>
            </g>
        </svg>
    )
}
