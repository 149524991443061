import {
    ActionMapper,
    ComponentInterceptorProps,
    IComponentGeneratorParams,
    IDispatcher,
    SlotBehaviours
} from '@/types'
import { getTestIdProps } from '@/utils/general/components'

import BaseComponent from '../BaseComponent'
import componentMapper from '../componentMapper'
import { isEmpty } from '@/utils/general/browserHelper'
import { trackingDispatcher } from '@/utils/tracking'

/**
 * Handles an action in the parent interceptor.
 * Purpose of the middleware dispatcher is to merge the action's mergeData if they are used in multilevel approach. i.e  ravenTracking
 * @return {void}
 */
const handleActionInParentInterceptor =
    ({ slotData, dispatcher }: ComponentInterceptorProps): IDispatcher =>
    action => {
        const { ravenTracking } = slotData || {}
        const { type, payload } = action ?? {}
        const { mergeData: parentMergeData = {} } = ravenTracking || {}
        switch (type) {
            case ActionMapper.TRACKING: {
                if (!isEmpty(payload)) {
                    const { eventName, eventData = {} } = payload
                    trackingDispatcher({ eventName, eventData: { ...eventData, ...parentMergeData } }, dispatcher)
                }
                break
            }
            default: {
                dispatcher?.(action)
            }
        }
    }

const componentGenerator = ({
    slot,
    dispatcher,
    ownProps,
    pageName,
    id,
    avoidPageNameForTestId
}: IComponentGeneratorParams) => {
    const { slotData } = slot || {}
    const { type, ravenTracking } = slotData || {}
    const { isSearchV3 } = ownProps || {}
    const ComponentToRender = componentMapper(type, isSearchV3)
    const { dataTestId } = getTestIdProps({
        pageName,
        componentName: type,
        testId: id,
        avoidPageName: avoidPageNameForTestId
    })
    if (slot?.behaviour === SlotBehaviours.FIXED_TOP_CALCULATED) {
        ownProps = {
            ...ownProps,
            isFixedSlot: true
        }
    }

    /**
     * We are using actionMiddle here as it specific to impression tracking to minimize the change effect in future we can update it.
     * !Do not replace the dispatcher cb with the memoizedMiddlewareDispatcher otherwise it will be in infinite loop.
     */
    const { mergeData: parentMergeData = {} } = ravenTracking || {}
    const isParentComponentHavingMergeData = !isEmpty(parentMergeData)
    const middlewareDispatcher: IDispatcher = isParentComponentHavingMergeData
        ? handleActionInParentInterceptor({ slotData, dispatcher: dispatcher as IDispatcher })
        : (dispatcher as IDispatcher)

    if (ComponentToRender) {
        return (
            <BaseComponent
                behaviour={slot?.behaviour}
                dispatcher={middlewareDispatcher}
                data={slotData}
                ownProps={ownProps}
                pageName={pageName}
                dataTestId={dataTestId}
            >
                <ComponentToRender
                    data={slotData}
                    dispatcher={middlewareDispatcher}
                    ownProps={ownProps}
                    pageName={pageName}
                    dataTestId={dataTestId}
                />
            </BaseComponent>
        )
    }

    return null
}

export default componentGenerator
