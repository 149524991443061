import { IDispatcher, IOwnProps } from '@/types'
import { isServer } from '@/utils/general'
import { useCallback, useEffect, useState } from 'react'

interface IStickyTopProps {
    dispatcher?: IDispatcher
    ownProps?: IOwnProps
}

const DEFAULT_TOP = 72

function useStickyTop({ ownProps }: IStickyTopProps) {
    const [isSticky, setIsSticky] = useState(false)
    const [top, setTop] = useState(DEFAULT_TOP)

    const calculateTop = useCallback(() => {
        if (!isServer()) {
            const { headerId = 'headerWrapper' } = ownProps ?? {}
            const header = document.getElementById(headerId as string)

            if (header) {
                return Math.round(header.getBoundingClientRect().bottom) - 0.5
            }

            return DEFAULT_TOP
        }
    }, [ownProps])

    useEffect(() => {
        setTop(calculateTop() ?? DEFAULT_TOP)
    }, [calculateTop])

    const handleIntersection = useCallback(
        ([entry]: IntersectionObserverEntry[]) => {
            const { intersectionRatio, boundingClientRect } = entry ?? {}
            const isStick = intersectionRatio < 1 && boundingClientRect.top - ((top ?? 0) + 0.5) < 0

            setIsSticky(isStick)
        },
        [top]
    )

    const getIntersectionOberserverOptions = () => {
        if (!isServer()) {
            return {
                rootMargin: `-${(top ?? 0) + 0.5}px 0px 0px 0px`,
                threshold: [1]
            }
        }
    }

    return {
        top,
        isSticky,
        handleIntersection,
        getIntersectionOberserverOptions
    }
}

export default useStickyTop
