export const Ncemi: React.FC<React.SVGProps<SVGSVGElement>> = ({ width = '24', height = '24', ...restProps }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 24 24">
            <mask
                id="mask0_7361_33667"
                style={{ maskType: 'alpha' }}
                width={width}
                height={height}
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
            </mask>
            <g mask="url(#mask0_7361_33667)">
                <path
                    fill="#FF4F17"
                    d="M17.396 21.25a.428.428 0 01-.302-.125l-2.51-2.615a.545.545 0 01-.12-.198.655.655 0 01-.047-.229c0-.16.057-.297.171-.411A.562.562 0 0115 17.5h.875c.368 0 .686-.12.953-.36.268-.239.429-.536.485-.89h-2.896a.403.403 0 01-.297-.12.403.403 0 01-.12-.297c0-.118.04-.217.12-.297.08-.08.179-.12.297-.12h2.77a1.506 1.506 0 00-.525-.598 1.38 1.38 0 00-.787-.235h-1.458a.403.403 0 01-.297-.12.403.403 0 01-.12-.296c0-.118.04-.217.12-.297.08-.08.179-.12.297-.12h4.166c.118 0 .217.04.297.12.08.08.12.179.12.297s-.04.217-.12.296a.402.402 0 01-.297.12h-.937c.097.118.184.247.26.386.077.139.136.288.177.448h.5c.118 0 .217.04.297.12.08.08.12.178.12.296s-.04.217-.12.297a.402.402 0 01-.297.12h-.427a2.245 2.245 0 01-.729 1.484c-.43.4-.948.6-1.552.6h-.302l2.125 2.208a.387.387 0 01.078.453.384.384 0 01-.38.255z"
                ></path>
                <path
                    stroke="#FF4F17"
                    strokeWidth="0.12"
                    d="M17.05 21.167h0l-2.51-2.615a.603.603 0 01-.132-.218m2.643 2.833l.161.107m-.162-.107h.001c.047.047.101.083.161.107m-.162-.107l.162.107m-2.804-2.94a.713.713 0 01-.051-.25c0-.177.063-.329.189-.455h0A.622.622 0 0115 17.44h.875c.354 0 .657-.115.913-.344h0c.24-.215.39-.476.453-.786h-2.824a.463.463 0 01-.34-.138.462.462 0 01-.137-.339c0-.133.046-.247.137-.34h0a.464.464 0 01.34-.136h2.671a1.453 1.453 0 00-.46-.49 1.32 1.32 0 00-.753-.224h-1.458a.464.464 0 01-.34-.137.463.463 0 01-.137-.34c0-.133.046-.247.137-.339h0a.464.464 0 01.34-.137h4.166c.134 0 .248.046.34.137a.463.463 0 01.137.34.462.462 0 01-.138.339.462.462 0 01-.339.137h-.814c.069.093.132.192.19.297.072.13.128.27.17.417h.454c.134 0 .248.045.34.137a.463.463 0 01.137.34.462.462 0 01-.138.338.462.462 0 01-.339.138h-.373a2.299 2.299 0 01-.742 1.468c-.442.41-.974.615-1.593.615h-.161L17.74 20.5h0c.07.073.114.154.13.244.015.089 0 .18-.04.274a.482.482 0 01-.171.218.463.463 0 01-.264.074.489.489 0 01-.184-.036m-2.804-2.94s0 0 0 0l.056-.021-.056.02s0 0 0 0zm-.288-4.464h0zm0 0a.404.404 0 00-.12.297m.12-.297l-.12.297m1.875.416c.292 0 .554.078.787.235l-.787-.235zm0 0h-1.458m1.458 0h-1.458m0 0a.403.403 0 01-.297-.12m.297.12l-.297-.12m0 0a.403.403 0 01-.12-.296m.12.296l-.12-.296"
                ></path>
                <path
                    fill="#1C1B1F"
                    d="M5.308 19.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 01-.525-1.283V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h1.384V2.385h1.539V4.5h5.577V2.385h1.5V4.5h1.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v5.156c0 .4-.35.705-.75.705s-.75-.306-.75-.705v-1.156H5v7.384c0 .077.032.148.096.212a.294.294 0 00.212.096h6.084c.416 0 .748.338.798.75.047.377-.22.75-.6.75H5.308zM5 8.808h12v-2.5a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H5.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v2.5z"
                ></path>
            </g>
        </svg>
    )
}
