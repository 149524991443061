import { isServer } from '@/utils'
import { getRavenCommonPayload, lowerCaseRavenData, overrideRavenData } from './ravenUtils'

class RavenManager {
    push({ eventName, eventData }: { eventName: string; eventData: Record<string, unknown> }) {
        try {
            const ravenData = {
                ...getRavenCommonPayload(),
                ...eventData
            }
            const formattedRavenData = { ...lowerCaseRavenData(ravenData), ...overrideRavenData() }

            if (window?.ravenWebManager) {
                window.ravenWebManager.triggerRaven(eventName, formattedRavenData)
            } else {
                if (window?.ravenEarlyEventQueue) {
                    window.ravenEarlyEventQueue.push({
                        name: eventName,
                        payload: formattedRavenData
                    })
                } else if (!isServer()) {
                    window.ravenEarlyEventQueue = []
                    window.ravenEarlyEventQueue.push({
                        name: eventName,
                        payload: formattedRavenData
                    })
                }
                console.log(
                    'Raven: Error in sending data to Raven, SDK not initialized. Event : ',
                    eventName,
                    eventData
                )
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export const Raven = new RavenManager()
