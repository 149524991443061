import { IOwnProps } from '@/types'
import { TypographyVariant } from '@cleartrip/ct-design-components'

export enum TA_RATING_TYPE {
    POOR = 'POOR',
    AVERAGE = 'AVERAGE',
    GOOD = 'GOOD'
}

export interface IAspectRating {
    title: string
    rating: number
}

export interface IAspectRatingProps {
    aspectRatings: IAspectRating[]
}

export interface IAspectRatingOwnProps extends IOwnProps {
    textVariant: TypographyVariant
    columnGap: number
}
