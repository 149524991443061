import React from 'react'

export const Trips: React.FC<React.SVGProps<SVGSVGElement>> = ({
    width = '22',
    height = '22',
    fill = '#1a1a1a',
    ...restProps
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <path d="M15.5 21C15.5 21.2761 15.7239 21.5 16 21.5C16.2761 21.5 16.5 21.2761 16.5 21H15.5ZM14 3V2.5V3ZM10 3V2.5V3ZM8 5H7.5H8ZM7.5 21C7.5 21.2761 7.72386 21.5 8 21.5C8.27614 21.5 8.5 21.2761 8.5 21H7.5ZM16.5 21V5H15.5V21H16.5ZM16.5 5C16.5 4.33696 16.2366 3.70107 15.7678 3.23223L15.0607 3.93934C15.342 4.22064 15.5 4.60218 15.5 5H16.5ZM15.7678 3.23223C15.2989 2.76339 14.663 2.5 14 2.5V3.5C14.3978 3.5 14.7794 3.65804 15.0607 3.93934L15.7678 3.23223ZM14 2.5H10V3.5H14V2.5ZM10 2.5C9.33696 2.5 8.70107 2.76339 8.23223 3.23223L8.93934 3.93934C9.22064 3.65804 9.60218 3.5 10 3.5V2.5ZM8.23223 3.23223C7.76339 3.70107 7.5 4.33696 7.5 5H8.5C8.5 4.60218 8.65804 4.22064 8.93934 3.93934L8.23223 3.23223ZM7.5 5V21H8.5V5H7.5ZM4 7.5H20V6.5H4V7.5ZM20 7.5C20.8284 7.5 21.5 8.17157 21.5 9H22.5C22.5 7.61929 21.3807 6.5 20 6.5V7.5ZM21.5 9V19H22.5V9H21.5ZM21.5 19C21.5 19.8284 20.8284 20.5 20 20.5V21.5C21.3807 21.5 22.5 20.3807 22.5 19H21.5ZM20 20.5H4V21.5H20V20.5ZM4 20.5C3.17157 20.5 2.5 19.8284 2.5 19H1.5C1.5 20.3807 2.61929 21.5 4 21.5V20.5ZM2.5 19V9H1.5V19H2.5ZM2.5 9C2.5 8.17157 3.17157 7.5 4 7.5V6.5C2.61929 6.5 1.5 7.61929 1.5 9H2.5Z" />
        </svg>
    )
}
