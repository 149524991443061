import React, { SVGProps } from 'react'

export function BudgetFriendlyIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect
                width="32.0011"
                height="32.0001"
                rx="7.99994"
                transform="matrix(1 -1.45756e-08 1.41894e-05 1 0.00390625 -0.000976562)"
                fill="#F2FAF0"
            />
            <path
                d="M21.859 25.8603H10.8911C9.65839 25.8603 8.65918 25.2911 8.65918 24.5889V22.2603"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8.65918 20.0361L8.65918 10.2809C8.65918 8.94455 9.60395 7.86133 10.7695 7.86133L22.1486 7.86133C23.3142 7.86133 24.2589 8.94455 24.2589 10.2809L24.2589 23.4613"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M12.2588 5.05469L15.1539 8.97268" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21.191 5.05469L17.3418 10.9497" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M16.7663 13.4027C17.4748 13.4027 18.0491 12.8283 18.0491 12.1198C18.0491 11.4113 17.4748 10.8369 16.7663 10.8369C16.0578 10.8369 15.4834 11.4113 15.4834 12.1198C15.4834 12.8283 16.0578 13.4027 16.7663 13.4027Z"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M19 18.3061L16.9545 17.8587L16.0182 16.0005L15.0455 17.9038L13 18.2168L14.5091 19.8063L14.1138 22.0005L16.0368 20.8584L17.9552 22.0005L17.5459 19.7842L19 18.3061Z"
                fill={props.color ?? '#61C149'}
            />
            <path
                d="M19 18.3061L16.9545 17.8587L16.0182 16.0005L15.0455 17.9038L13 18.2168L14.5091 19.8063L14.1138 22.0005L16.0368 20.8584L17.9552 22.0005L17.5459 19.7842L19 18.3061Z"
                stroke="black"
                stroke-width="0.746592"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
