import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store/configureStore'

export interface IDropdownState {
    isOpen: boolean
    elementRef?: HTMLElement
    dropdownId: string
    isSubmitable?: boolean
    isFooterCTALoading: boolean
    overlayConfig: {
        topOffset?: number
        showOverlay: boolean
    }
}

const initialState: IDropdownState = {
    isOpen: false,
    dropdownId: '',
    isSubmitable: false,
    isFooterCTALoading: false,
    overlayConfig: {
        showOverlay: false,
        topOffset: 0
    }
}

export const DropDownSlice = createSlice({
    name: 'DropDown',
    initialState,
    reducers: {
        openDropdown: (state, { payload }) => {
            const { ref, id, overlayConfig } = payload || {}

            state.isOpen = true
            state.elementRef = ref
            state.dropdownId = id
            if (overlayConfig) {
                state.overlayConfig = {
                    topOffset: overlayConfig.topOffset,
                    showOverlay: !!overlayConfig.showOverlay
                }
            }
        },
        closeDropdown: state => {
            state.elementRef = undefined
            state.isOpen = false
            state.dropdownId = ''
            state.isSubmitable = false
            state.overlayConfig = {
                topOffset: 0,
                showOverlay: false
            }
        },
        toggleDropdownSubmitState: (state, action: PayloadAction<boolean>) => {
            state.isSubmitable = action.payload
        },
        toggleDropdownFooterLoadingState: (state, action: PayloadAction<boolean>) => {
            state.isFooterCTALoading = action.payload
        },
        toggleMask: (state, { payload }) => {
            const { topOffset, showOverlay } = payload
            state.overlayConfig = {
                topOffset,
                showOverlay
            }
        }
    }
})

export const { openDropdown, closeDropdown, toggleDropdownSubmitState, toggleDropdownFooterLoadingState, toggleMask } =
    DropDownSlice.actions
export const getDropDownState = (state: RootState) => state.dropdown
export const getDropdownElementRef = (state: RootState) => state.dropdown?.elementRef
const DropDownReducer = DropDownSlice.reducer
export default DropDownReducer
