import { SVGProps } from 'react'

export function BackIcon({ width = 24, height = 24, ...props }: SVGProps<SVGSVGElement>) {
    return (
        <svg
            {...props}
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M18.2 12H5" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.5 18.5L5 12L11.5 5.5" stroke="#1A1A1A" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
