import { FetchResponseFormat, HTTPResponseError, IBaseFetchRequestConfig, IBaseFetchType } from '@/types/network/common'
import { getApiDomain } from '@/utils/general/url'
import { commonHeaders, getUnifiedHeader } from '@/constants/network'

/**
 *
 * @param url API end point for request
 * @param method Request Method GET,POST,PUT,DELETE
 * @param body Request Body payload
 * @param headers Requst headers
 * @param responseType Response data type can be three types JSON,TEXT or RAW
 *                     since response can be of multiple type like ArrayBUffer for image or PDF raw data,
 *                     Default value is JSON
 * @returns ResponseType
 */
const baseFetch: IBaseFetchType = async <ResponseType = unknown>(requestConfig: IBaseFetchRequestConfig) => {
    const { url, method, body, headers, responseType, domain = getApiDomain(url) } = requestConfig
    const config: RequestInit = {
        method,
        body,
        headers: {
            ...commonHeaders,
            ...headers,
            'x-unified-header': JSON.stringify(getUnifiedHeader()),
            'app-agent': 'DESKTOP'
        }
    }
    const requestUrl = `${domain}${url}`

    const response = await fetch(requestUrl, config)

    if (response.ok) {
        switch (responseType) {
            case FetchResponseFormat.TEXT:
                return response.text()
            case FetchResponseFormat.RAW:
                return response
            case FetchResponseFormat.JSON:
            default:
                return response.json() as ResponseType
        }
    } else {
        const errorResponse = await response.json()
        throw new HTTPResponseError(response, errorResponse)
    }
}

export default baseFetch
