import { DOMAIN, NON_BETA_ENV_API_ENDPOINTS, ENVIRONMENT } from './environment'

export const getUrlWithArgs = (url: string, ...args: string[]) => {
    const ARGS = [...args].join(',')
    return url + ARGS
}

export const getPopularDestinationUrl = (pageUrl?: string) => {
    if (!pageUrl) return null
    const today = new Date()
    const checkInDate = new Date(today.getTime() + 3 * 24 * 60 * 60 * 1000)
    const checkOutDate = new Date(today.getTime() + 4 * 24 * 60 * 60 * 1000)
    const checkInDateDD = String(checkInDate.getDate()).padStart(2, '0')
    const checkOutDateDD = String(checkOutDate.getDate()).padStart(2, '0')
    const checkInMonth = String(checkInDate.getMonth() + 1).padStart(2, '0') //January is 0!
    const checkOutMonth = String(checkOutDate.getMonth() + 1).padStart(2, '0')
    const checkInYear = checkInDate.getFullYear()
    const checkOutYear = checkOutDate.getFullYear()
    return pageUrl
        .replace('{@checkin}', checkInDateDD + '/' + checkInMonth + '/' + checkInYear)
        .replace('{@checkout}', checkOutDateDD + '/' + checkOutMonth + '/' + checkOutYear)
}

export const getApiDomain = (url = '') => {
    const environment = process.env.NEXT_PUBLIC_CT_ENV || ENVIRONMENT.PRODUCTION
    switch (environment) {
        case ENVIRONMENT.PRODUCTION: {
            return DOMAIN.PROD
        }
        case ENVIRONMENT.BETA: {
            const isNonBetaEnvApi = NON_BETA_ENV_API_ENDPOINTS.includes(url)
            if (isNonBetaEnvApi) {
                return DOMAIN.PROD
            }
            return DOMAIN.BETA
        }
        case ENVIRONMENT.DEVELOPMENT: {
            return DOMAIN.LOCAL
        }
        case ENVIRONMENT.STAGING: {
            return DOMAIN.QA
        }
        default: {
            return DOMAIN.PROD
        }
    }
}

/**
 *
 * @param url A string url without domain
 * @returns Pathname extracted from url
 */
export const getPathname = (url: string) => {
    const tempUrl = new URL(`${getApiDomain()}${url}`)
    return tempUrl.pathname
}

export const getSearchParamsFromQuery = (
    searchQuery: string | URLSearchParams | Record<string, string> | string[][] | undefined
) => {
    const searchQueryParams = new URLSearchParams(searchQuery)
    return `?${decodeURIComponent(searchQueryParams?.toString())}`
}

export const extractSearchV3ParamsFromString = (
    inputString = ''
): { baseLocation?: string; filterLocation?: string; version?: string } => {
    // Parse the query string
    const queryParams = new URLSearchParams(inputString)

    // Extract values if present
    const baseLocation = queryParams.get('baseLocation')
    const filterLocation = queryParams.get('filterLocation')
    const version = queryParams.get('version')

    // Create an object with only the present fields
    const result: { [key: string]: string | undefined } = {}
    if (baseLocation) {
        result.baseLocation = baseLocation
    }
    if (filterLocation) {
        result.filterLocation = filterLocation
    }
    if (version) {
        result.version = version
    }

    return result
}
