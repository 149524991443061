import React from 'react'

export const AddBlue: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, ...restProps }) => {
    return (
        <svg
            width={width || '24'}
            height={height || '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <mask
                id="mask0_239_11083"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_239_11083)">
                <path
                    d="M12 18.5C11.85 18.5 11.729 18.4543 11.637 18.363C11.5457 18.271 11.5 18.15 11.5 18V12.5H6C5.85 12.5 5.72933 12.45 5.638 12.35C5.546 12.25 5.5 12.1333 5.5 12C5.5 11.85 5.546 11.729 5.638 11.637C5.72933 11.5457 5.85 11.5 6 11.5H11.5V6C11.5 5.85 11.55 5.729 11.65 5.637C11.75 5.54567 11.8667 5.5 12 5.5C12.15 5.5 12.271 5.54567 12.363 5.637C12.4543 5.729 12.5 5.85 12.5 6V11.5H18C18.15 11.5 18.271 11.55 18.363 11.65C18.4543 11.75 18.5 11.8667 18.5 12C18.5 12.15 18.4543 12.271 18.363 12.363C18.271 12.4543 18.15 12.5 18 12.5H12.5V18C12.5 18.15 12.45 18.271 12.35 18.363C12.25 18.4543 12.1333 18.5 12 18.5Z"
                    fill="#3366CC"
                />
            </g>
        </svg>
    )
}
