import { PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit'

type FormRootState = Record<string, { data: Record<string, any>; lazyUpdates: Record<string, any> }>

type ReducerParams = (state: FormRootState, action: PayloadAction<any>) => void

interface IReducers extends SliceCaseReducers<FormRootState> {
    setInitialFieldDetails: ReducerParams
    setInitialFormState: ReducerParams
    updateFormFieldState: ReducerParams
    updateLazyGuestFormState: ReducerParams
}

const actions: ValidateSliceCaseReducers<FormRootState, IReducers> = {
    setInitialFormState: (state, action) => {
        const { payload } = action ?? {}
        const { formId } = payload ?? {}

        if (formId in state) return

        Object.assign(state, {
            [formId]: {
                data: {},
                lazyUpdates: {}
            }
        })
    },
    setInitialFieldDetails: (state, action) => {
        const { payload } = action ?? {}
        const { formId, fieldId, fieldState } = payload ?? {}

        if (!(formId in state)) actions.setInitialFormState(state, action)

        const formState = state[formId].data
        const existingState = state[formId].data[fieldId]

        Object.assign(formState, {
            [fieldId]: {
                ...existingState?.[fieldId],
                ...fieldState
            }
        })
    },
    updateFormFieldState: (state, action) => {
        const { payload } = action ?? {}
        const { formId, fieldId, fieldState } = payload ?? {}

        const formState = state[formId].data
        const existingState = state[formId].data[fieldId]

        Object.assign(formState, {
            [fieldId]: {
                ...existingState,
                ...fieldState
            }
        })
    },
    updateLazyGuestFormState: (state, action) => {
        const { payload } = action ?? {}
        const { formId, fieldId, fieldState } = payload ?? {}

        const formState = state[formId].lazyUpdates
        const existingState = state[formId].data[fieldId]

        Object.assign(formState, {
            [fieldId]: {
                ...existingState,
                ...fieldState
            }
        })
    }
}

export { actions }
