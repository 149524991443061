import baseFetch from '@/network/baseFetch'
import { getCookie } from '../general/cookie'
import { isEmpty } from '../general/browserHelper'
import { APP_AGENT, APP_AGENT_DEVICE, PERSONALIZATION, REQUEST_METHODS } from '@/constants/network'

export interface IPersonalizationResponse {
    status: number
    headers: Record<string, string>
}

/**
 * This function is getting called at client side so did not add isServer check
 */
export const personalization = async (localStorageDeviceId?: string | null) => {
    let deviceId = {}
    if (localStorageDeviceId) {
        deviceId = {
            dvid: localStorageDeviceId
        }
    }
    try {
        const headers = {
            caller: window?.location?.origin,
            [APP_AGENT]: APP_AGENT_DEVICE,
            ...deviceId
        }

        return baseFetch({
            headers,
            url: PERSONALIZATION,
            method: REQUEST_METHODS.GET
        })
    } catch (e) {
        console.log(e)
    }
}

/**
 * This function is getting called at client side so did not add isServer check
 */
export const deviceId = async () => {
    const localStorageDeviceId = window?.localStorage?.getItem('dvid')
    const getDeviceIdCookie = getCookie('ct-dvId') || ''

    try {
        if (
            (isEmpty(localStorageDeviceId) && isEmpty(getDeviceIdCookie)) ||
            (isEmpty(localStorageDeviceId) && !isEmpty(getDeviceIdCookie))
        ) {
            const req = await personalization()
            const { status, headers } = (req || {}) as IPersonalizationResponse
            if (status === 204) {
                window?.localStorage?.setItem('dvid', headers['dvid'])
            }
        } else if (!isEmpty(localStorageDeviceId) && isEmpty(getDeviceIdCookie)) {
            await personalization(localStorageDeviceId)
        }
    } catch (e) {
        console.error(e)
    }
}
