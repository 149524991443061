import { HomeIcon, Flights, Hotels, Work, Trips, Support, Offers, Bus } from '@/assets/icons'
import {
    TripsMini,
    WalletMini,
    ProfileMini,
    SettingsMini,
    ShortcutsMini,
    TravellersMini,
    Expressway,
    Ticket,
    ChangeFlight,
    Cancellations
} from '@/assets/icons'

export const LOGIN_BANNER_URL =
    'https://fastui.cltpstatic.com/image/upload/f_auto,q_auto,w_410,h_437,dpr_2/offermgmt/bbdJson/Desktop_Banner.png'
export const accoutOptions = [
    {
        text: 'Trips',
        link: '/accounts/trips',
        icon: TripsMini,
        actionName: 'your_trips_trips'
    },
    {
        text: 'Shortlists',
        link: '/account/shortlists',
        icon: ShortcutsMini,
        actionName: 'your_trips_shortlists'
    },
    {
        text: 'Travellers',
        link: '/account/travellers',
        icon: TravellersMini,
        actionName: 'your_trips_travellers'
    },
    {
        text: 'Cleartrip Wallet',
        link: '/accounts/wallet',
        icon: WalletMini,
        actionName: 'your_trips_wallet'
    },
    {
        text: 'Expressway',
        link: '/accounts/expressway',
        icon: Expressway,
        actionName: 'your_trips_expressway'
    },
    {
        text: 'Profile',
        link: '/accounts/profile',
        icon: ProfileMini,
        actionName: 'your_trips_profile'
    },
    {
        text: 'Settings',
        link: '/account/settings',
        icon: SettingsMini,
        actionName: ''
    }
]
export const tools = [
    {
        text: 'Cancellations',
        link: '/accounts/trips',
        icon: Cancellations,
        actionName: 'your_trips_cancellations'
    },
    {
        text: 'Change flight',
        link: '/accounts/trips',
        icon: ChangeFlight,
        actionName: 'your_trips_change_flights'
    },
    {
        text: 'Print ticket',
        link: '/accounts/trips',
        icon: Ticket,
        actionName: 'your_trips_print_ticket'
    },
    {
        text: 'Print flight voucher',
        link: '/vouchers',
        icon: Ticket,
        actionName: 'your_trips_flight_voucher'
    }
]
export const errorConfig = {
    ERROR_IN_SENDING_SMS: 'Failed to send SMS OTP. Please try again.',
    OTP_SEND_LIMIT_EXCEEDED: 'You exceeded the OTP limit. Please try again after 10 minutes.',
    ERROR_IN_SENDING_EMAIL: 'Failed to send email OTP. Please try again.',
    NO_SAVED_OTP_FOUND: 'OTP expired. Please resend OTP and try again.',
    INVALID_OTP: 'Please enter a valid OTP',
    OTP_VALIDATE_LIMIT_EXCEEDED: 'You exceeded the OTP validation limit. Please try again in 10 minutes.',
    INVALID_MOBILE: 'Something went wrong. Please try again.',
    DEFAULT: 'Something went wrong. Please try again.'
}

export const OTP_ERRORS = Object.keys(errorConfig)

export const MOBILE_COUNTRY_CODES = [{ name: 'India', code: '91', min: 10, max: 10 }]
export const HAMBURGER_OPTIONS = [
    {
        icon: HomeIcon,
        text: 'Home',
        link: '/',
        isSelected: false
    },
    {
        icon: Flights,
        text: 'Flights',
        link: '/flights',

        isSelected: true
    },
    {
        icon: Hotels,
        text: 'Hotels',
        link: '/hotels'
    },
    {
        icon: Bus,
        text: 'Bus',
        link: '/bus',
        isSelected: false
    },
    {
        icon: Offers,
        text: 'Offers',
        link: '/all-offers/?categories=flights'
    },
    {
        icon: Trips,
        text: 'My trips',
        link: '/account/trips'
    },
    {
        icon: Work,
        text: 'Cleartrip for Work',
        link: 'https://business.cleartrip.com/'
    },
    {
        icon: Support,
        text: 'Support',
        link: '/support'
    }
]

export const loginBannerImages = [
    {
        url: 'https://rukmini-ct.flixcart.com/f_auto,q_auto,w_350,h_287,dpr_2/offermgmt-prod/offermgmt/images/banner/ct_hotel_pricing_icon_desktop.png',
        backgroundColor: '#FF8057',
    },
    {
        url: 'https://rukmini-ct.flixcart.com/f_auto,q_auto,w_350,h_287,dpr_2/offermgmt-prod/offermgmt/images/banner/ct_flight_pricing_icon_desktop.png',
        backgroundColor: '#FFD468',
    },
    {
        url: 'https://rukmini-ct.flixcart.com/f_auto,q_auto,w_350,h_287,dpr_2/offermgmt-prod/offermgmt/images/banner/ct_cfw_icon_desktop.png',
        backgroundColor: '#5295FF',
    },
    {
        url: 'https://rukmini-ct.flixcart.com/f_auto,q_auto,w_350,h_287,dpr_2/offermgmt-prod/offermgmt/images/banner/uhp_d_login_slideThree.png',
        backgroundColor: '#54BB52',
    },
    {
        url: 'https://rukmini-ct.flixcart.com/f_auto,q_auto,w_350,h_287,dpr_2/offermgmt-prod/offermgmt/images/banner/uhp_d_login_slideFour.png',
        backgroundColor: '#FF8862',
    },
    {
        url: 'https://rukmini-ct.flixcart.com/f_auto,q_auto,w_350,h_287,dpr_2/offermgmt-prod/offermgmt/images/banner/ct_supercoins_discount_icon_desktop.png',
        backgroundColor: '#A06AE5',
    },
];

export const loginHelpText =
    'We no more support email based login. You can now login via mobile number & link email to access your account.'

export const enum HOME_PAGE_COMPONENTS_NAME {
    SEARCH_FIELD = 'searchField',
    SEARCH_BUTTON = 'searchButton',
    TOP_DESTINATION = 'popularDestination',
    TOP_flightS = 'topflights',
    TRENDING_GATEWAYS = 'trendingGateways',
    BSB_BANNER = 'bsbBanner',
    AUTO_SUGGEST_OPTION = 'autoSuggestOption',
    LOGIN_BUTTON = 'loginButton',
    PAX_OPTION = 'paxOption',
    PAX_ADD_MORE = 'paxAddMoreRoom',
    PAX_SHOW_OPTION = 'paxShowOption',
    PAX_ADD_ANOTHER_ROOM = 'paxAddAnotherRoom',
    PAX_BUTTON = 'paxButton',
    PAX_COUNTER = 'paxCounter',
    PAX_REMOVE_ROOM = 'paxRemoveRoom',
    PAX_PERSON_COUNT = 'paxPersonCount',
    CALENDAR = 'calendar',
    MY_ACCOUNT = 'myAccount'
}
