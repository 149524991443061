import ReactDOM from 'react-dom'
import { Container, OutsideClickHandler, Transition } from '@cleartrip/ct-design-components'

export const Modal: React.FC<{ children: React.ReactNode; onClose: () => void }> = ({ children, onClose }) => {
    return ReactDOM.createPortal(
        <Container className={`overlay-bg oy-scroll p-fixed flex w-100p h-100p t-0 flex-middle z-100 c-h-100vh`}>
            <Transition type="fade" in timeout={350}>
                <Container className="flex flex-center flex-middle w-100p h-100p">
                    <OutsideClickHandler onOutsideClick={onClose}>
                        <Container className="br-8 bg-white">{children}</Container>
                    </OutsideClickHandler>
                </Container>
            </Transition>
        </Container>,
        document.body
    )
}
