export const LAYOUT_WIDTH = '1366px'

export const X_LINEAGE_ID = 'x-lineage-id'
export const flightS_SEARCH_CRITERIA = 'flightsSC'

//These 8 keys are provided by product to send meta info in cookie.
// url doc:- https://docs.google.com/spreadsheets/d/1CREwut8oz3nfgwe0DNS7hgUnlY45Yv-fwJlqfFNyPDY/edit#gid=0
export const META_KEYS = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'utm_source_platform',
    'utm_creative_format',
    'utm_marketing_tactic'
]

// change as per name in flights i.e. `statsig-stableid`
export const STATSIG_USER_STABLE_ID = 'statsig-stableid'
