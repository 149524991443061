import React from 'react'

export const PremiumAffordableIcon = (props: React.SVGProps<SVGSVGElement>) => {
    const { height = 32, width = 32, color = '#61C149', ...restProps } = props || {}
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <rect
                width={width}
                height={height}
                rx="8"
                transform="matrix(1 -1.45756e-08 1.41894e-05 1 0.00439453 0)"
                fill="#F2FAF0"
            />
            <path d="M11.8326 13.5197L16.0042 7L20.2361 13.5197" fill={color} />
            <path
                d="M16.0601 24.9998L26.0046 13.5195L6.00488 13.5195L16.0601 24.9998Z"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M26.0043 13.5197L22.6083 7L9.43223 7L6.00449 13.5197L26.0043 13.5197Z"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M18.4045 18.5558L22.6085 7" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.729 10.5234L16.0602 25.0019" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M11.8326 13.5197L16.0042 7L20.2361 13.5197"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
