import React from 'react'

export const LinkedInOutlined = ({ width = 16, height = 16 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 0.91001C5.87517 0.91001 0.91001 5.87517 0.91001 12C0.91001 18.1248 5.87517 23.09 12 23.09C18.1248 23.09 23.09 18.1248 23.09 12C23.09 5.87517 18.1248 0.91001 12 0.91001ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                fill="#1A1A1A"
            />
            <path d="M9.1547 9.4277H6.7644V17.1446H9.1547V9.4277Z" fill="#1A1A1A" />
            <path
                d="M7.94742 8.42064C8.73003 8.42064 9.36097 7.78363 9.36097 6.99496C9.36097 6.20628 8.73003 5.56927 7.94742 5.56927C7.16482 5.56927 6.53387 6.20628 6.53387 6.99496C6.53387 7.78363 7.16482 8.42064 7.94742 8.42064Z"
                fill="#1A1A1A"
            />
            <path
                d="M12.9768 13.0981C12.9768 12.0121 13.4742 11.3691 14.4328 11.3691C15.3125 11.3691 15.7311 11.9879 15.7311 13.0981V17.1507H18.1092V12.2609C18.1092 10.1921 16.9383 9.1911 15.3003 9.1911C13.6623 9.1911 12.9768 10.4651 12.9768 10.4651V9.4277H10.6835V17.1446H12.9768V13.092V13.0981Z"
                fill="#1A1A1A"
            />
        </svg>
    )
}
