export function dePrioritizeBackgroundTask(cb: Function) {
    setTimeout(() => {
        cb()
    }, 0)
}

/**
 * Delays the execution of a task using the Idle Callback API if available,
 * otherwise uses a fallback mechanism with setTimeout to defer the task to a background task.
 *
 * @param {Function} cb - The callback function to be executed after the delay.
 * @return {void} This function does not return anything.
 */
export function delayTaskWithIdleCallback(cb: Function) {
    if ('requestIdleCallback' in window) {
        requestIdleCallback(() => {
            cb()
        })
    } else {
        dePrioritizeBackgroundTask(cb)
    }
}
