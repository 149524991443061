import { IStatsigValueConfig, STATSIG_EXPERIMENT_NAMES, StatsigExperimentAbKeysConfigType } from './type'

// We'll be using it for default value for some experiment which is 100% i.e. that doesn't require statisg call
export const DEFAULT_STATSIG_EXPERIMENTS = {
    [STATSIG_EXPERIMENT_NAMES.search_v3]: 'b'
}
export const HPM_EXPERIMENT_CONFIG: IStatsigValueConfig = {
    experimentName: 'hpm_hpi_removal',
    experimentValue: 'hpm_hpi_removal',
    defaultValue: 'a'
}

export const CITY_MUMBAI_EXPERIMENT_CONFIG: IStatsigValueConfig = {
    experimentName: 'ranking_mumbai_city_desktop',
    experimentValue: 'bucket',
    defaultValue: 'default'
}

export const RANKING_EXPERIMENT_CONFIG: IStatsigValueConfig = {
    experimentName: 'h_ranking_predef_desktop',
    experimentValue: 'rank_model',
    defaultValue: 'data_engg_model'
}

/**
 * Statsig Experiment AB Keys
 * Whatever key is added for config will be set as cookie for statsig
 *
 * Sample: flights_home: {ct_qa_text: { experimentName: 'ct_qa_text', experimentValue: 'color', defaultValue: '#000000' },}
 */

export const STATSIG_EXPERIMENT_AB_KEYS: StatsigExperimentAbKeysConfigType = {
    flights_home: {},
    flights_details: {
        [STATSIG_EXPERIMENT_NAMES.hpm_hpi_removal]: HPM_EXPERIMENT_CONFIG
    },
    flights_itinerary: {},
    flights_merch_srp: {},
    flights_seo: {},
    flights_srp: {
        [STATSIG_EXPERIMENT_NAMES.city_mumbai_exp_dt]: CITY_MUMBAI_EXPERIMENT_CONFIG,
        [STATSIG_EXPERIMENT_NAMES.h_exp_dom]: RANKING_EXPERIMENT_CONFIG
    },
    flights_itinerary_supercoin: {}
}

export const getStatsigUserConfig = (
    id: string,
    customConfig?: Record<string, string | number | boolean | Array<string> | undefined>
) => {
    return { customIDs: { stableID: id }, ...(customConfig && { custom: { ...customConfig } }) }
}

export const STATSIG_ENVIRONMENT_TIER = 'production'
