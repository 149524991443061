import React from 'react'

export const Expressway: React.FC<React.SVGProps<SVGSVGElement>> = ({ width = '16', height = '16', ...restProps }) => {
    return (
        <svg viewBox="0 0 14 14" height={height} width={width} className="c-neutral-400" {...restProps}>
            <g fill="none" fill-rule="evenodd">
                <rect width="14" height="14" fill="#FFF" opacity="0"></rect>
                <path
                    fill="currentColor"
                    fill-rule="nonzero"
                    d="M0.646446609,6.64644661 L0.590530795,6.71219845 C0.362196938,7.03084485 0.584244403,7.5 1,7.5 L4.5,7.5 L4.5,13 C4.5,13.2761424 4.72385763,13.5 5,13.5 L9,13.5 L9.08987563,13.4919443 C9.32312484,13.4496084 9.5,13.2454599 9.5,13 L9.5,7.5 L13,7.5 C13.4454524,7.5 13.6685358,6.96142904 13.3535534,6.64644661 L7.35355339,0.646446609 C7.15829124,0.451184464 6.84170876,0.451184464 6.64644661,0.646446609 L0.646446609,6.64644661 Z"
                ></path>
            </g>
        </svg>
    )
}
