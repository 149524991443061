import { IOwnProps, IPageResponse } from '@/types'
import { CSSProperties } from 'react'

export const enum SidesheetDirection {
    LEFT = 'left',
    RIGHT = 'right'
}
export interface ISidesheetProps {
    data: IPageResponse
    ownProps?: ISideSheetOwnProps
    isRecent?: boolean
    sidesheetId?: number
}

export interface ISideSheetOwnProps extends IOwnProps {
    direction?: SidesheetDirection
    pageName: string
    classname?: string
    width?: string
    hasLoader?: boolean
    cfnrSelected?: boolean
    overrideSidesheetSlotCSS?: CSSProperties
}
