import React from 'react'

export const TwitterOutlined = ({ width = 16, height = 16 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 0.91001C5.87515 0.91001 0.91001 5.87515 0.91001 12C0.91001 18.1248 5.87515 23.09 12 23.09C18.1248 23.09 23.09 18.1248 23.09 12C23.09 5.87515 18.1248 0.91001 12 0.91001ZM0 12C0 5.37257 5.37257 0 12 0C18.6274 0 24 5.37257 24 12C24 18.6274 18.6274 24 12 24C5.37257 24 0 18.6274 0 12Z"
                fill="#1A1A1A"
            />
            <path
                d="M5.84833 15.8645C6.96461 16.5804 8.29322 16.999 9.7189 16.999C14.4024 16.999 17.0536 13.0435 16.8958 9.48837C17.3873 9.13043 17.818 8.68756 18.1577 8.18402C17.7027 8.38422 17.2174 8.51769 16.7078 8.57836C17.2295 8.26895 17.6299 7.77148 17.818 7.18301C17.3327 7.47421 16.7927 7.68048 16.2164 7.79575C15.7553 7.30434 15.1001 7.00101 14.3721 7.00101C12.7401 7.00101 11.545 8.52376 11.9151 10.1011C9.81597 9.99797 7.95955 8.9909 6.7098 7.46208C6.04853 8.59656 6.364 10.0829 7.49241 10.8352C7.07987 10.8231 6.6916 10.7078 6.3458 10.5197C6.32153 11.6906 7.15874 12.7826 8.37209 13.0253C8.01415 13.1223 7.62588 13.1466 7.23154 13.0677C7.55308 14.0688 8.48736 14.8028 9.5915 14.821C8.52982 15.6522 7.19514 16.0222 5.85439 15.8645H5.84833Z"
                fill="#1A1A1A"
            />
        </svg>
    )
}
