import { IPageResponse } from '@/types'
import { ISideSheetPayload } from '@/types/actions'
import { DETAILS_API_ENDPOINTS } from '@/constants/network/details'
import baseFetch from '../baseFetch'
import { AB_CONFIG_ENDPOINT, REQUEST_METHODS } from '@/constants/network'
import { IWalletFetchResponse } from '@/types/common'
import { IBaseResponse } from '@/types/network/common'

export const getAbConfigData = async (headers = {}) => {
    const res = (await baseFetch({
        url: AB_CONFIG_ENDPOINT,
        method: REQUEST_METHODS.GET,
        headers
    })) as { [key: string]: string }
    if (!res['h_exp_searchv3']) res.h_exp_searchv3 = 'a'
    return res
}

export const getSidesheetData = async (payload: ISideSheetPayload) => {
    return baseFetch<IBaseResponse<IPageResponse>>({
        url: '/flight/orchestrator' + payload.url,
        method: payload.method,
        body: JSON.stringify(payload.payload),
        headers: { ...payload?.headers }
    })
}

export async function fetchWalletBalance() {
    return baseFetch<IWalletFetchResponse>({
        url: DETAILS_API_ENDPOINTS.WALLET_SERVICE,
        method: REQUEST_METHODS.GET
    })
}
