import React, { SVGProps } from 'react'

export const LastMinuteDeals = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="-0.000244141" width="32" height="32" rx="8" fill="#ECF9FB" />
            <g clip-path="url(#clip0_906_38201)">
                <path
                    d="M12.3088 9.42969H18.9081C18.8104 10.1176 18.5803 11.1975 17.9815 12.3393C17.1945 13.8401 16.1778 14.6716 15.6242 15.059C15.084 14.661 14.1856 13.8858 13.4261 12.5664C12.7148 11.3301 12.4319 10.1477 12.3088 9.42969Z"
                    fill="#0FBACB"
                />
            </g>
            <path
                d="M7.99951 7.00977H23.2842"
                stroke="black"
                stroke-width="1.15824"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.99951 24.9902H23.2842"
                stroke="black"
                stroke-width="1.15824"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M21.3534 10.709C21.367 11.2641 21.2433 12.7653 19.6966 14.0955C18.6262 15.016 17.9944 15.7211 17.9956 16.3462C17.9968 16.9714 18.5097 17.6131 19.6966 18.5958C21.9417 20.4541 21.3518 22.7033 21.3518 24.9753"
                stroke="black"
                stroke-width="1.15824"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M9.94031 6.98828V9.01749C9.94031 9.58114 9.93832 10.1448 9.92436 10.7081C9.9108 11.2632 10.0344 12.7643 11.5811 14.0946C12.6516 15.0151 13.2834 15.7202 13.2822 16.3453C13.281 16.9414 12.7681 17.6122 11.5811 18.5949C9.33607 20.4532 9.92595 22.7024 9.92595 24.9744"
                stroke="black"
                stroke-width="1.15824"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M13.9829 13.1582C13.7297 12.8903 13.476 12.5849 13.2347 12.2385C12.4506 11.1104 12.1383 10.0318 12.0027 9.37695H19.2771C19.1694 10.0044 18.9157 10.9894 18.2557 12.0309C17.3882 13.3998 16.2675 14.1583 15.6572 14.5116"
                stroke="black"
                stroke-width="1.15824"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M19.2766 24.9823H12.0022C12.1099 24.3549 12.3635 23.3699 13.0236 22.3284C13.8911 20.9595 15.0118 20.201 15.622 19.8477C16.0687 20.1198 16.7376 20.5857 17.3969 21.3094"
                stroke="black"
                stroke-width="1.15824"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <defs>
                <clipPath id="clip0_906_38201">
                    <rect width="6.5993" height="5.62929" fill="white" transform="translate(12.3093 9.42773)" />
                </clipPath>
            </defs>
        </svg>
    )
}
