export const CT_EXP_COOKIE = 'h-exp-dt'

export enum AB_EXPERIMENT_NAME {
    META_REDIRECTION = 'h_exp_meta_redirection'
}

export const QA_AB_EXPERIMENT_ID = {
    [AB_EXPERIMENT_NAME.META_REDIRECTION]: 'yugO7U_tQR2efc3Np1Hhpw'
}

export const PROD_AB_EXPERIMENT_ID = {
    [AB_EXPERIMENT_NAME.META_REDIRECTION]: 'JFA6PwlYTGGt2tiwIF69nQ'
}

/**
 * @deprecated
 * This constant is not being used it was added when we were doing A/B via google optimize.
 * But now we are not using it hence marking it as depricated
 */
export const AB_EXPERIMENT_ID =
    process.env.NEXT_PUBLIC_CT_ENV !== 'production' ? QA_AB_EXPERIMENT_ID : PROD_AB_EXPERIMENT_ID

export const AB_API_MOCK = {
    h_exp16: 'b',
    h_exp15: 'b',
    axisLoyalty: 'b',
    h_exp13: 'b',
    ghFlexMax: 'a',
    EMI_SRP_Exp: 'c',
    h_exp_dom: 'v3_meetbeat',
    freeway_smb: 'b',
    h_exp17: 'b',
    clevertapDTInlineBanner: 'a',
    offer_banner: 'a',
    bus_supercoin: 'b',
    h_exp12: 'b',
    h_exp10: 'b',
    sc_right_rail: 'a',
    ff_Nudges: 'a',
    split: 'a',
    h_exp_7: 'b',
    alternate_refund: 'a',
    vasExp7: 'b',
    saved_vpa: 'a',
    vasExp6: 'b',
    exp: 'c',
    clevertapSrpBanner: 'a',
    bentoSrp: 'a',
    wallet_display: 'b',
    idParameter: 'IP',
    identifier: '130.41.60.0-Thu Mar 07 09:55:01 IST 2024',
    card_tokenization: 'b',
    supercoins: 'a',
    e_srp: 'c',
    h_searchorder: 'b',
    bus_cc_max: 'b',
    New_Coupon_Experience: 'a',
    modify_search: 'a',
    hi_five: 'b',
    room_category_details_page: 'a',
    support_version: 'a',
    ff_Intl: 'c',
    EMI_Itin_Exp: 'a',
    h_exp_intl: 'v3_exploit_gbr',
    sortordermobile: 'a',
    RNIFlowType: 'b',
    h_exp1: 'a',
    h_exp2: 'b',
    h_exp8: 'b',
    trains_pwa: 'b',
    tuple: 'b',
    gh66: 'c',
    per_adult_price: 'b',
    sorting: 'a',
    scV3: 'b',
    sortorder: 'a',
    testGlobal: 'd',
    scV4: 'b',
    'upi-intent': 'b',
    ptb: 'b',
    EMI_SRP_Dom: 'a',
    h_itin: 'b',
    highRps: 'b',
    clevertapTopBanner: 'a',
    intlflexmax: 'a',
    vasExp1: 'a',
    bus_conv_fee: 'b',
    bus_perf_coupon: 'b',
    hashValue: 'c387440f9d6fb797a8a1bcf95fc8e7c8c86ae6fae63fdaba88e7a45db17eb3b1',
    plm: 'b',
    mediCancel: 'b',
    h_itin_dt: 'b',
    tk_home: 'b',
    seatCallout: 'a',
    clevertapPwaInlineBanner: 'a',
    clevertapDThomeBanner: 'a',
    login_init: 'a'
}

export const EXPERIMENT_ID = {
    ITINERARY_REVAMP: 'h_itin_dt'
}

export const CUSTOM_COOKIE_NAME = {
    [EXPERIMENT_ID.ITINERARY_REVAMP]: 'itin_dt_revamp'
}
