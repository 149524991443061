export const GreenTick = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48" {...props}>
            <rect width="48" height="48" fill="#11A670" rx="24"></rect>
            <g clipPath="url(#clip0_969_54489)">
                <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3.6"
                    d="M34.8 16.8L20.4 31.2 13.2 24"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_969_54489">
                    <path fill="#fff" d="M0 0H28.8V28.8H0z" transform="translate(9.6 9.6)"></path>
                </clipPath>
            </defs>
        </svg>
    )
}
