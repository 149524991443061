import { SVGProps } from 'react'

export const LoginUser = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11.1571 14.1216C11.1612 14.1977 11.1502 14.2738 11.1248 14.3456C11.0994 14.4175 11.0602 14.4837 11.0092 14.5403C10.9582 14.597 10.8966 14.6431 10.8279 14.6759C10.7591 14.7087 10.6845 14.7277 10.6084 14.7317C8.15472 14.8607 5.75898 15.5251 3.58942 16.6785C3.54277 16.7034 3.50377 16.7404 3.47657 16.7858C3.44937 16.8311 3.435 16.883 3.435 16.9358V20.46C3.435 20.5608 3.47504 20.6574 3.5463 20.7287C3.61756 20.8 3.71422 20.84 3.815 20.84H10.5772C10.7311 20.84 10.8786 20.9011 10.9874 21.0099C11.0961 21.1186 11.1572 21.2662 11.1572 21.42C11.1572 21.5738 11.0961 21.7214 10.9874 21.8301C10.8786 21.9389 10.7311 22 10.5772 22H3.815C3.40657 22 3.01486 21.8377 2.72606 21.5489C2.43725 21.2601 2.275 20.8684 2.275 20.46V16.9349C2.275 16.3988 2.57082 15.905 3.04519 15.6533C5.36404 14.4208 7.92452 13.7108 10.5469 13.5729L11.1571 14.1216ZM11.1571 14.1216C11.1531 14.0455 11.1341 13.971 11.1013 13.9022C11.0685 13.8334 11.0224 13.7718 10.9657 13.7208C10.9091 13.6699 10.8429 13.6306 10.771 13.6052C10.6992 13.5799 10.6231 13.5689 10.547 13.5729L11.1571 14.1216ZM8.9253 3.65542C9.62669 2.95403 10.578 2.56 11.5699 2.56C12.5618 2.56 13.5131 2.95403 14.2145 3.65542C14.9158 4.35681 15.3099 5.30809 15.3099 6.3C15.3099 7.29191 14.9158 8.24319 14.2145 8.94458C13.5131 9.64597 12.5618 10.04 11.5699 10.04C10.578 10.04 9.62669 9.64597 8.9253 8.94458C8.22391 8.24319 7.82988 7.29191 7.82988 6.3C7.82988 5.30809 8.22391 4.35681 8.9253 3.65542ZM8.10506 2.83518C7.18613 3.75411 6.66988 5.00044 6.66988 6.3C6.66988 7.59956 7.18613 8.8459 8.10506 9.76482C9.02398 10.6838 10.2703 11.2 11.5699 11.2C12.8694 11.2 14.1158 10.6838 15.0347 9.76482C15.9536 8.8459 16.4699 7.59956 16.4699 6.3C16.4699 5.00044 15.9536 3.75411 15.0347 2.83518C14.1158 1.91625 12.8694 1.4 11.5699 1.4C10.2703 1.4 9.02398 1.91625 8.10506 2.83518Z"
                fill="#1A1A1A"
                stroke="#1A1A1A"
                stroke-width="0.2"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.5303 16.6405C17.6203 16.7306 17.6709 16.8526 17.6709 16.9799C17.6709 17.1072 17.6203 17.2292 17.5303 17.3193L15.6103 19.2393C15.5198 19.3267 15.3986 19.3751 15.2727 19.374C15.1469 19.3729 15.0265 19.3224 14.9375 19.2334C14.8485 19.1444 14.798 19.024 14.7969 18.8982C14.7958 18.7723 14.8442 18.6511 14.9316 18.5605L16.8516 16.6405C16.9416 16.5506 17.0637 16.5 17.191 16.5C17.3183 16.5 17.4403 16.5506 17.5303 16.6405Z"
                fill="#0F6BFF"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.5303 17.3116C17.6203 17.2216 17.6709 17.0995 17.6709 16.9722C17.6709 16.8449 17.6203 16.7229 17.5303 16.6329L15.6103 14.7129C15.5198 14.6254 15.3986 14.577 15.2727 14.5781C15.1469 14.5792 15.0265 14.6297 14.9375 14.7187C14.8485 14.8077 14.798 14.9281 14.7969 15.054C14.7958 15.1798 14.8442 15.3011 14.9316 15.3916L16.8516 17.3116C16.9416 17.4016 17.0637 17.4521 17.191 17.4521C17.3183 17.4521 17.4403 17.4016 17.5303 17.3116Z"
                fill="#0F6BFF"
            />
            <path
                d="M15.6766 12.1772L15.6766 12.1772L15.675 12.179C15.5775 12.2951 15.5295 12.4456 15.5409 12.5964C15.5522 12.7474 15.6221 12.8879 15.7357 12.988C15.8493 13.0881 15.9976 13.1399 16.1488 13.1322C16.3 13.1244 16.4422 13.0579 16.545 12.9468L16.545 12.9468L16.5466 12.9449C16.7761 12.6844 17.112 12.5209 17.4853 12.5209H19.317C20.0086 12.5209 20.5687 13.0818 20.5687 13.7736V20.1845C20.5687 20.8761 20.0087 21.4361 19.317 21.4361H17.4853C17.111 21.4361 16.7761 21.2727 16.5466 21.0121L16.5466 21.012C16.4447 20.8967 16.3013 20.8265 16.1477 20.8169C15.9941 20.8074 15.843 20.8592 15.7277 20.961C15.6123 21.0628 15.5421 21.2063 15.5325 21.3599C15.523 21.5135 15.5748 21.6646 15.6766 21.7799C16.1172 22.2796 16.7643 22.5961 17.4853 22.5961H19.317C19.6337 22.5961 19.9473 22.5338 20.2399 22.4126C20.5325 22.2914 20.7984 22.1137 21.0223 21.8898C21.2463 21.6658 21.4239 21.4 21.5451 21.1074C21.6663 20.8148 21.7287 20.5012 21.7287 20.1845V13.7736C21.7287 13.7736 21.7287 13.7736 21.7287 13.7736C21.7288 13.4568 21.6666 13.1431 21.5454 12.8504C21.4243 12.5577 21.2467 12.2917 21.0227 12.0677C20.7987 11.8436 20.5328 11.6659 20.2402 11.5446C19.9475 11.4234 19.6338 11.3609 19.317 11.3609H17.4853C16.7653 11.3609 16.1172 11.6775 15.6766 12.1772ZM11.4996 16.5689C11.3908 16.6777 11.3297 16.8252 11.3297 16.979C11.3297 17.1328 11.3908 17.2804 11.4996 17.3891C11.6083 17.4979 11.7559 17.559 11.9097 17.559H16.7097C16.8635 17.559 17.011 17.4979 17.1198 17.3891C17.2286 17.2804 17.2897 17.1328 17.2897 16.979C17.2897 16.8252 17.2286 16.6777 17.1198 16.5689C17.011 16.4601 16.8635 16.399 16.7097 16.399H11.9097C11.7559 16.399 11.6083 16.4601 11.4996 16.5689Z"
                fill="#0F6BFF"
                stroke="#0F6BFF"
                stroke-width="0.2"
            />
        </svg>
    )
}
