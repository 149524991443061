import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchWalletBalance } from '@/network/services/abConfig.service'
import { IWalletFetchResponse } from '@/types/common'

import { RootState } from '@/redux/store/configureStore'

interface IWalletInitiaState {
    data: IWalletFetchResponse
    hasLoader: boolean
    hasError: boolean
}

const initialState: IWalletInitiaState = {
    data: {} as IWalletFetchResponse,
    hasLoader: false,
    hasError: false
}

export const getWalletBalance = createAsyncThunk('wallet-state', async () => {
    const response = await fetchWalletBalance()
    return response
})

export const WalletSlice = createSlice({
    name: 'wallet_service',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getWalletBalance.fulfilled, function (state, payload) {
            const { payload: walletResponse } = payload ?? {}

            state.data = walletResponse
            state.hasLoader = false
            state.hasError = false
        })
        builder.addCase(getWalletBalance.pending, function (state) {
            state.hasLoader = true
            state.hasError = false
        })
        builder.addCase(getWalletBalance.rejected, function (state) {
            state.hasLoader = false
            state.hasError = true
        })
    }
})

export const getWalletDetails = (state: RootState) => state.wallet

const SheetsReducer = WalletSlice.reducer
export default SheetsReducer
