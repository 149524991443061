import React from 'react'

export default function MoneySack({ color = '#1A1A1A', ...props }) {
    return (
        <div className="pl-2 flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
                <path
                    fill={color}
                    fillRule="evenodd"
                    d="M15.5 2.5a.5.5 0 01.474.658l-1.1 3.3a1.026 1.026 0 00-.013.042H16a.5.5 0 010 1h-1.025c.083.156.195.3.335.425 1.187 1.06 2.623 2.635 3.698 4.441 1.411 2.372.99 4.817-.46 6.637C17.11 20.805 14.672 22 12 22v-1c2.387 0 4.53-1.07 5.765-2.62 1.221-1.532 1.56-3.527.383-5.502-1.011-1.7-2.375-3.198-3.504-4.206a2.515 2.515 0 01-.742-1.172h-3.804c-.13.448-.39.857-.742 1.172-1.13 1.008-2.493 2.505-3.505 4.206-1.175 1.975-.837 3.97.383 5.502C7.47 19.93 9.614 21 12 21v1c-2.673 0-5.112-1.195-6.548-2.997-1.45-1.82-1.871-4.265-.46-6.637 1.075-1.806 2.511-3.38 3.698-4.44.14-.125.252-.27.335-.426H8a.5.5 0 010-1h1.139a1.218 1.218 0 00-.013-.041l-1.1-3.3A.5.5 0 018.5 2.5H12v1H9.194l.88 2.643c.04.118.07.238.088.357h3.676c.019-.12.048-.239.087-.357l.881-2.643H12v-1h3.5zm-5.5 9a.5.5 0 100 1h1.016c.652 0 1.06.316 1.23.694H10a.5.5 0 100 1h2.159a1.22 1.22 0 01-.259.271c-.298.233-.795.424-1.562.424a.5.5 0 00-.353.854l2.711 2.71a.5.5 0 00.707-.706l-1.98-1.981a2.894 2.894 0 001.092-.512c.374-.292.62-.662.74-1.06h.812a.5.5 0 100-1h-.772a2.043 2.043 0 00-.291-.694h1.063a.5.5 0 100-1H10z"
                    clipRule="evenodd"
                ></path>
            </svg>
        </div>
    )
}
