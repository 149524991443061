import { ActionMapper, IDispatcher, IMultiTracking } from '@/types'
import { ITracking } from '@/types'
import { isServer } from '../general'
import { isEmpty } from '../general/browserHelper'

export const handleMultiRavenTracking = (eventType: string, ravenTracking?: ITracking, dispatcher?: IDispatcher) => {
    const { multiTracking = {}, mergeData = {} } = ravenTracking || {}
    let { [eventType]: multiRavenTracking = {} } = multiTracking as IMultiTracking
    const { eventData = {} } = multiRavenTracking as ITracking
    const payload = {
        ...mergeData,
        ...eventData
    }
    if (!isEmpty(multiRavenTracking)) {
        multiRavenTracking = {
            ...multiRavenTracking,
            eventData: payload
        }
        dispatcher?.(formTrackingAction(multiRavenTracking!))
    }
}

export const formTrackingAction = (trackingData: ITracking) => {
    const trackingWIthMergeData = {
        ...trackingData,
        eventData: {
            ...(trackingData.eventData || {}),
            ...(trackingData.mergeData || {})
        }
    }

    return {
        payload: trackingWIthMergeData,
        type: ActionMapper.TRACKING
    }
}

export const getPagePath = (pagePath: string, pathname: string[]) => {
    return pagePath === 'results'
        ? 'SRP'
        : !pagePath
        ? 'HP'
        : pathname.length > 4 && pathname[4] === 'info' && pathname[2] === 'itinerary'
        ? 'Itinerary'
        : 'Details_Page'
}

export const getPageName = () => {
    //Get page name from window
    if (!isServer()) {
        const paths = window.location.pathname.split('/')

        if ((paths || []).length >= 2) {
            return (paths || []).length === 2 ? paths[1] : paths[2]
        }
    }
    return ''
}

/**
 *
 * @param dependentTracking
 * @param selfTracking
 * @returns
 */
export function mergeAndFormTrackingAction(dependentTracking: ITracking, selfTracking?: ITracking) {
    const mergedTrackingEventData = {
        ...dependentTracking?.eventData,
        ...selfTracking?.eventData,
        ...selfTracking?.mergeData
    }

    return formTrackingAction({ eventName: dependentTracking.eventName, eventData: mergedTrackingEventData })
}
