import UAParser from 'ua-parser-js'
import { v4 as uuid } from 'uuid'

import { IUnifiedHeader } from '@/types/common'
import { isServer } from '@/utils'

export enum REQUEST_METHODS {
    GET = 'GET',
    PUT = 'PUT',
    POST = 'POST',
    DELETE = 'DELETE'
}

export const getUnifiedHeader = () => {
    let unifiedHeaders: IUnifiedHeader = {
        platform: 'desktop',
        trackingId: uuid(),
        source: 'CLEARTRIP'
    }
    if (!isServer()) {
        const parser = new UAParser(window?.navigator?.userAgent)
        unifiedHeaders = {
            ...unifiedHeaders,
            deviceModel: parser?.getDevice()?.model?.toLowerCase()
        }
    }
    return unifiedHeaders
}

export const commonHeaders = {
    'x-client-id': 'cleartrip',
    'Content-Type': 'application/json',
    'x-source-type': 'Desktop',
    channel: 'desktop',
    'x-unified-header': JSON.stringify(getUnifiedHeader())
}

export const COMMON_ENDPOINT_PREFIX = '/flight/orchestrator'

export const SRP_DATE_REGEX = /^\d{2}([./-])\d{2}\1\d{4}$/ // Checks for the following format 23/05/2023

export const NUM_REGEX = /^[0-9]*$/ // checks for the numeric values only.

export const MAX_ADULT_COUNT = 24 // total number of rooms a person can book is 6 with 4 adult in each room hence 24.

export const MAX_CHILD_COUNT = 18 // total number of room 6, Each room 1 adults is mandatory for child so 6*3 = 18

export const MAX_ROOM_COUNT = 6 // max number of room allowed to book 6

export const MAX_CHILD_COUNT_PER_ROOM = 3 // max 3 child can be there in room as 1 adult is mandatory

export const MAX_ADULT_COUNT_PER_ROOM = 4 // max 4 adult can be there in each room.

export enum REQUEST_STATUS_CODE {
    BAD_REQUEST = 400,
    NOT_FOUND = 404,
    INTERNAL_SERVER_ERROR = 500,
    CONTINUE = 100
}

export const APP_NAME_FOR_ERROR_LOG = 'node_air-desktop-pwa'

export const APP_AGENT = 'app-agent'
export const APP_AGENT_DEVICE = 'DESKTOP'
