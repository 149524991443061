import { SVGProps } from 'react'

export function ExitIcon({ width = 24, height = 20, ...rest }: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 24 24" {...rest}>
            <path
                stroke="#1A1A1A"
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M18 4a2 2 0 00-2-2H6a2 2 0 00-2 2v16a2 2 0 002 2h10a2 2 0 002-2M11 18h.01M20.5 12h-7m0 0l3.5 3.5M13.5 12L17 8.5"
            ></path>
        </svg>
    )
}
