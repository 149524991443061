import React from 'react'

export const Work: React.FC<React.SVGProps<SVGSVGElement>> = ({ width = '20', height = '20', ...restProps }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...restProps}>
            <mask
                id="mask0_1690_2210"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" />
            </mask>
            <g mask="url(#mask0_1690_2210)">
                <path
                    d="M12 15.1778L15.025 17.4694C15.2083 17.612 15.4071 17.6169 15.6214 17.4841C15.8349 17.3521 15.9009 17.1639 15.8194 16.9194L14.6889 13.1306L17.6222 11.0833C17.8259 10.9204 17.887 10.7216 17.8056 10.4869C17.7241 10.253 17.5611 10.1361 17.3167 10.1361H13.7111L12.4889 6.16389C12.4074 5.91944 12.2444 5.79722 12 5.79722C11.7556 5.79722 11.5926 5.91944 11.5111 6.16389L10.2889 10.1361H6.68333C6.43889 10.1361 6.27593 10.253 6.19444 10.4869C6.11296 10.7216 6.17407 10.9204 6.37778 11.0833L9.31111 13.1306L8.18056 16.9194C8.09907 17.1639 8.16548 17.3521 8.37978 17.4841C8.59326 17.6169 8.79167 17.612 8.975 17.4694L12 15.1778ZM12 23C10.4722 23 9.04141 22.7099 7.70756 22.1298C6.37289 21.5488 5.21178 20.7641 4.22422 19.7758C3.23585 18.7882 2.45119 17.6271 1.87022 16.2924C1.29007 14.9586 1 13.5278 1 12C1 10.4722 1.29007 9.041 1.87022 7.70633C2.45119 6.37248 3.23585 5.21137 4.22422 4.223C5.21178 3.23544 6.37289 2.45119 7.70756 1.87022C9.04141 1.29007 10.4722 1 12 1C13.5278 1 14.959 1.29007 16.2937 1.87022C17.6275 2.45119 18.7886 3.23544 19.777 4.223C20.7646 5.21137 21.5488 6.37248 22.1298 7.70633C22.7099 9.041 23 10.4722 23 12C23 13.5278 22.7099 14.9586 22.1298 16.2924C21.5488 17.6271 20.7646 18.7882 19.777 19.7758C18.7886 20.7641 17.6275 21.5488 16.2937 22.1298C14.959 22.7099 13.5278 23 12 23ZM12 21.7778C14.7093 21.7778 17.0164 20.8257 18.9214 18.9214C20.8257 17.0164 21.7778 14.7093 21.7778 12C21.7778 9.29074 20.8257 6.98359 18.9214 5.07856C17.0164 3.17433 14.7093 2.22222 12 2.22222C9.29074 2.22222 6.984 3.17433 5.07978 5.07856C3.17474 6.98359 2.22222 9.29074 2.22222 12C2.22222 14.7093 3.17474 17.0164 5.07978 18.9214C6.984 20.8257 9.29074 21.7778 12 21.7778Z"
                    fillOpacity="0.9"
                />
            </g>
        </svg>
    )
}
