import React from 'react'
import { SVGProps } from 'react'

export const CalendarTick = (props: SVGProps<SVGSVGElement>) => {
    const { height = 20, width = 20, ...rest } = props || {}

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 20 20" {...rest}>
            <mask
                id="mask0_6882_23684"
                style={{ maskType: 'alpha' }}
                width="20"
                height="20"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
            </mask>
            <g mask="url(#mask0_6882_23684)">
                <path
                    fill="#11A670"
                    d="M9.125 13.484l3.232-3.232a.428.428 0 01.292-.13.397.397 0 01.308.13.418.418 0 01.137.3.418.418 0 01-.137.299l-3.36 3.36a.646.646 0 01-.472.202.646.646 0 01-.471-.202l-1.61-1.61a.428.428 0 01-.13-.292.398.398 0 01.13-.307.418.418 0 01.299-.138c.108 0 .208.046.3.137l1.482 1.483zM4.679 17.5c-.383 0-.703-.128-.96-.386a1.304 1.304 0 01-.386-.96V5.513c0-.384.129-.704.386-.961s.577-.385.96-.385h1.475v-1.41a.436.436 0 01.448-.45.436.436 0 01.45.45v1.41h5.96V2.724c0-.118.04-.217.12-.297.08-.08.179-.12.297-.12.119 0 .218.04.298.12.08.08.12.179.12.297v1.443h1.473c.384 0 .704.128.961.385s.386.577.386.96v10.642c0 .383-.129.704-.386.96a1.304 1.304 0 01-.96.386H4.678zm0-.833H15.32a.49.49 0 00.353-.16.49.49 0 00.16-.353V8.846H4.167v7.308a.49.49 0 00.16.352.49.49 0 00.352.16zm-.512-8.654h11.666v-2.5a.49.49 0 00-.16-.353.49.49 0 00-.353-.16H4.68a.49.49 0 00-.353.16.49.49 0 00-.16.353v2.5z"
                ></path>
            </g>
        </svg>
    )
}
