import { SVGProps } from 'react'

export function CityIcon({ width = 24, height = 24, fill = '#1A1A1A', ...rest }: SVGProps<SVGSVGElement>) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M6.12453 12.1514C5.85944 12.1514 5.64453 11.9365 5.64453 11.6714C5.64453 11.4063 5.85944 11.1914 6.12453 11.1914H7.28348C7.54857 11.1914 7.76348 11.4063 7.76348 11.6714C7.76348 11.9365 7.54857 12.1514 7.28348 12.1514H6.12453Z"
                fill={fill}
            />
            <path
                d="M5.64453 14.1441C5.64453 14.4091 5.85944 14.6241 6.12453 14.6241H7.28348C7.54857 14.6241 7.76348 14.4091 7.76348 14.1441C7.76348 13.8789 7.54857 13.6641 7.28348 13.6641H6.12453C5.85944 13.6641 5.64453 13.8789 5.64453 14.1441Z"
                fill={fill}
            />
            <path
                d="M6.12453 17.0967C5.85944 17.0967 5.64453 16.8818 5.64453 16.6167C5.64453 16.3516 5.85944 16.1367 6.12453 16.1367H7.28348C7.54857 16.1367 7.76348 16.3516 7.76348 16.6167C7.76348 16.8818 7.54857 17.0967 7.28348 17.0967H6.12453Z"
                fill={fill}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.8902 22.0795C22.1553 22.0795 22.3702 21.8646 22.3702 21.5995C22.3702 21.3345 22.1553 21.1195 21.8902 21.1195H20.5338V13.9911C20.5338 13.726 20.3189 13.5111 20.0538 13.5111H17.0355V7.03743C17.0355 6.77234 16.8205 6.55743 16.5555 6.55743H15.8692V4.71953C15.8692 4.45444 15.6543 4.23953 15.3892 4.23953H13.5125V2.39992C13.5125 2.13483 13.2976 1.91992 13.0325 1.91992C12.7673 1.91992 12.5525 2.13483 12.5525 2.39992V4.23953H10.6751C10.41 4.23953 10.1951 4.45444 10.1951 4.71953V6.55743H9.50891C9.24382 6.55743 9.02891 6.77234 9.02891 7.03743V8.87533H3.9472C3.68209 8.87533 3.4672 9.09023 3.4672 9.35533V21.1195H2.11086C1.84577 21.1195 1.63086 21.3345 1.63086 21.5995C1.63086 21.8646 1.84577 22.0795 2.11086 22.0795H21.8902ZM13.0325 5.19982L13.0491 5.19953H14.9092V7.03743C14.9092 7.30252 15.1241 7.51743 15.3892 7.51743H16.0755V13.5111H13.0565C12.7914 13.5111 12.5765 13.726 12.5765 13.9911V21.1195H9.98891V7.51743H10.6751C10.9402 7.51743 11.1551 7.30252 11.1551 7.03743V5.19953H13.0158L13.0325 5.19982ZM13.5365 21.1195V14.4711H19.5738V21.1195H18.2017V15.8801C18.2017 15.615 17.9868 15.4001 17.7217 15.4001C17.4567 15.4001 17.2417 15.615 17.2417 15.8801V21.1195H15.8694V15.8801C15.8694 15.615 15.6545 15.4001 15.3894 15.4001C15.1243 15.4001 14.9094 15.615 14.9094 15.8801V21.1195H13.5365ZM9.02891 21.1195V9.83533H4.4272V21.1195H9.02891Z"
                fill={fill}
            />
        </svg>
    )
}
