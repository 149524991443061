import React from 'react'

export const Cancellations: React.FC<React.SVGProps<SVGSVGElement>> = ({
    width = '16',
    height = '16',
    ...restProps
}) => {
    return (
        <svg viewBox="0 0 14 14" className="c-light-blue" height={height} width={width} {...restProps}>
            <g fill="none" fill-rule="evenodd">
                <rect width="14" height="14" fill="#FFF" opacity="0"></rect>
                <path
                    fill="currentColor"
                    fill-rule="nonzero"
                    d="M7,0.506145606 C10.5898509,0.506145606 13.5,3.41629473 13.5,7.00614561 C13.5,10.5959965 10.5898509,13.5061456 7,13.5061456 C3.41014913,13.5061456 0.5,10.5959965 0.5,7.00614561 C0.5,3.41629473 3.41014913,0.506145606 7,0.506145606 Z M7,2.00614561 C4.23857625,2.00614561 2,4.24472186 2,7.00614561 C2,9.76756936 4.23857625,12.0061456 7,12.0061456 C9.76142375,12.0061456 12,9.76756936 12,7.00614561 C12,4.24472186 9.76142375,2.00614561 7,2.00614561 Z M9.95170499,6.25 L9.95170499,7.75 L4,7.75 L4,6.25 L9.95170499,6.25 Z"
                ></path>
            </g>
        </svg>
    )
}
