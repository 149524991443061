export const DEFAULT_ROOM_OCCUPANCY = {
    adults: {
        count: 1,
        metadata: []
    },
    children: {
        count: 0,
        metadata: []
    }
}

export enum OPEN_EDIT_MODIFY_SECTION {
    OPEN_EDIT_LOCATION = 'LOCATION',
    OPEN_CHECKIN_CALANDER = 'CHECKIN',
    OPEN_CHECKOUT_CALANDER = 'CHECKOUT',
    OPEN_EDIT_PAX = 'EDIT_PAX'
}

export const MODIFY_SEARCH_HEADER_HEIGHT = 72

export const MODIFY_SEARCH_CONTAINER_ID = 'modifyElement'
