export const InfoIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill = '#808080', ...rest }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <mask
                id="mask0_3260_299832"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="16"
                height="16"
            >
                <rect width="16" height="16" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_3260_299832)">
                <path
                    d="M8 11C8.1 11 8.18067 10.9696 8.242 10.9087C8.30289 10.8473 8.33333 10.7667 8.33333 10.6667V7.65C8.33333 7.56111 8.3 7.486 8.23333 7.42467C8.16667 7.36378 8.08889 7.33333 8 7.33333C7.9 7.33333 7.81933 7.36667 7.758 7.43333C7.69711 7.5 7.66667 7.57778 7.66667 7.66667V10.6833C7.66667 10.7722 7.7 10.8473 7.76667 10.9087C7.83333 10.9696 7.91111 11 8 11ZM8 6.38333C8.11111 6.38333 8.20844 6.34444 8.292 6.26667C8.37511 6.18889 8.41667 6.08889 8.41667 5.96667C8.41667 5.85556 8.37511 5.76111 8.292 5.68333C8.20844 5.60556 8.11111 5.56667 8 5.56667C7.88889 5.56667 7.79156 5.60556 7.708 5.68333C7.62489 5.76111 7.58333 5.85556 7.58333 5.96667C7.58333 6.08889 7.62489 6.18889 7.708 6.26667C7.79156 6.34444 7.88889 6.38333 8 6.38333ZM8 14C7.16667 14 6.38333 13.8444 5.65 13.5333C4.91667 13.2222 4.28067 12.7973 3.742 12.2587C3.20289 11.7196 2.77778 11.0833 2.46667 10.35C2.15556 9.61667 2 8.83333 2 8C2 7.16667 2.15556 6.38333 2.46667 5.65C2.77778 4.91667 3.20289 4.28044 3.742 3.74133C4.28067 3.20267 4.91667 2.77778 5.65 2.46667C6.38333 2.15556 7.16667 2 8 2C8.83333 2 9.61667 2.15556 10.35 2.46667C11.0833 2.77778 11.7196 3.20267 12.2587 3.74133C12.7973 4.28044 13.2222 4.91667 13.5333 5.65C13.8444 6.38333 14 7.16667 14 8C14 8.83333 13.8444 9.61667 13.5333 10.35C13.2222 11.0833 12.7973 11.7196 12.2587 12.2587C11.7196 12.7973 11.0833 13.2222 10.35 13.5333C9.61667 13.8444 8.83333 14 8 14ZM8 13.3333C9.47778 13.3333 10.7362 12.814 11.7753 11.7753C12.814 10.7362 13.3333 9.47778 13.3333 8C13.3333 6.52222 12.814 5.26378 11.7753 4.22467C10.7362 3.186 9.47778 2.66667 8 2.66667C6.52222 2.66667 5.264 3.186 4.22533 4.22467C3.18622 5.26378 2.66667 6.52222 2.66667 8C2.66667 9.47778 3.18622 10.7362 4.22533 11.7753C5.264 12.814 6.52222 13.3333 8 13.3333Z"
                    fill={fill}
                />
            </g>
        </svg>
    )
}
