export const SRP_ENDPOINTS = {
    SEARCH_RESULTS: '/flight/orchestrator/v2/search',
    AUTO_SEARCH: '/prefixy/ui/autoSuggest/getSuggestions'
}

export const CONFIRMATION_ENDPOINTS = {
    VIEW: (itineraryId: string) => `/flight/orchestrator/v2/itinerary/${itineraryId}/confirmation`
}

export const ITINERARY_ENDPOINTS = {
    VIEW_ITINERARY: (itineraryId: string) => `/flight/orchestrator/v3/itinerary/desktop/${itineraryId}`,
    DRAFT: (itineraryId: string) => `/flight/orchestrator/v1/itinerary/${itineraryId}/draft`
}

export const AB_CONFIG_ENDPOINT = '/source/abtest/getabtestconfig'

export const PERSONALIZATION = '/personalization'
