import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store/configureStore'
import { IPageResponse, IPageResponseLoading } from '@/types'

const initialState = {
    data: {} as IPageResponse,
    isLoading: true,
    isFiltersLoading: false,
    errorCode: 0
}

export const merchResultsSlice = createSlice({
    name: 'merchResults',
    initialState,
    reducers: {
        setMerchResultsData: (state, action: PayloadAction<IPageResponse>) => {
            const { payload } = action || {}
            state.data = payload
            state.isLoading = false
            state.isFiltersLoading = false
        },
        setMerchResultsDataLoading: (state, action: PayloadAction<IPageResponseLoading>) => {
            const { payload } = action || {}
            state.isLoading = payload.isLoading
        },
        setMerchResultsFetchMoreData: (state, action: PayloadAction<IPageResponse>) => {
            const { payload } = action || {}
            const { pageData: { pageMisc = {} } = {}, slotsData } = payload || {}
            state.data = {
                ...state.data,
                pageData: {
                    ...state.data.pageData,
                    pageMisc
                },
                slotsData: [...state.data.slotsData, ...slotsData]
            }
        },
        setMerchFiltersLoading: (state, action: PayloadAction<boolean>) => {
            const { payload } = action
            state.isFiltersLoading = payload
        },
        setMerchErrorCode: (state, action: PayloadAction<number>) => {
            const { payload } = action
            state.errorCode = payload
        }
    }
})

const { reducer, actions } = merchResultsSlice
export const {
    setMerchResultsData,
    setMerchResultsFetchMoreData,
    setMerchResultsDataLoading,
    setMerchFiltersLoading,
    setMerchErrorCode
} = actions || {}

export default reducer

export const getMerchResultsData = (state: RootState) => state.merchResults?.data
export const getMerchResultsLoading = (state: RootState) => state.merchResults.isLoading
export const getMerchResultsSlotsData = (state: RootState) => state.merchResults?.data?.slotsData
export const getMerchResultsPageMiscData = (state: RootState) => state.merchResults?.data?.pageData?.pageMisc
export const getMerchResultsFilterLoading = (state: RootState) => state.merchResults.isFiltersLoading
export const getMerchResultState = (state: RootState) => state.merchResults
