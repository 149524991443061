import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '@/redux/store/configureStore'
import { IComponent, IIcon } from '@/types'
import { CSSProperties } from 'react'

export interface ToastPayload {
    data: {
        prefixIcon: IComponent<IIcon, 'ICON'>
        text: string
        toastCSS: CSSProperties
    }
    ownProps?: Record<string, unknown>
}

export interface ToastsData {
    data: Array<ToastPayload>
}

const initialState: ToastsData = {
    data: []
}

export const ToastSlice = createSlice({
    name: 'toasts',
    initialState,
    reducers: {
        openToast: (state, action: PayloadAction<ToastPayload>) => {
            state.data.push(action.payload)
        },
        closeToast: (state, action: PayloadAction<number>) => {
            state.data.splice(action.payload, 1)
        }
    }
})

export const { openToast, closeToast } = ToastSlice.actions
export const getToasts = (state: RootState): Array<ToastPayload> => state.toasts.data
const ToastsReducer = ToastSlice.reducer
export default ToastsReducer
