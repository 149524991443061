import React from 'react'

export const OnewayIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_1265_1893" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1265_1893)">
                <path
                    d="M14.45 18.375L13.75 17.65L18.9 12.5H2.80005V11.5H18.9L13.75 6.35L14.45 5.625L20.8251 12L14.45 18.375Z"
                    fill="#1A1A1A"
                />
            </g>
        </svg>
    )
}
