import { AB_CONFIG_ENDPOINT, PERSONALIZATION } from '@/constants/network'
import { HOME_API_ENDPOINTS } from '@/constants/network/home'

export const ENVIRONMENT = {
    DEVELOPMENT: 'development',
    STAGING: 'staging',
    BETA: 'beta',
    PRODUCTION: 'production'
}

export const DOMAIN = {
    PROD: 'https://www.cleartrip.com',
    QA: 'https://qa2new.cleartrip.com',
    LOCAL: `http://localhost:${process.env.PORT || 3000}`,
    BETA: 'https://beta.cleartrip.com'
}

export const NON_DEV_ENVIRONMENT = [ENVIRONMENT.BETA, ENVIRONMENT.PRODUCTION]

export const NON_BETA_ENV_API_ENDPOINTS = [
    HOME_API_ENDPOINTS.HOME_WALLET_INFO,
    HOME_API_ENDPOINTS.HOME_GET_OTP,
    HOME_API_ENDPOINTS.VERIFY_OTP,
    HOME_API_ENDPOINTS.ACCOUNT_DETAILS,
    PERSONALIZATION
]
