import React from 'react'

export const Success: React.FC<React.SVGProps<SVGSVGElement>> = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 56 56" {...props}>
            <path
                fill="#11A670"
                stroke="#11A670"
                d="M26.69 5.137a2 2 0 012.62 0l3.173 2.752a2 2 0 001.69.453l4.123-.797a2 2 0 012.27 1.31l1.372 3.97a2 2 0 001.237 1.237l3.97 1.372a2 2 0 011.31 2.27l-.797 4.123a2 2 0 00.453 1.69l2.752 3.172a2 2 0 010 2.622l-2.752 3.172a2 2 0 00-.453 1.69l.797 4.123a2 2 0 01-1.31 2.27l-3.97 1.372a2 2 0 00-1.237 1.237l-1.372 3.97a2 2 0 01-2.27 1.31l-4.123-.797a2 2 0 00-1.69.453l-3.172 2.752a2 2 0 01-2.622 0l-3.172-2.752a2 2 0 00-1.69-.453l-4.123.797a2 2 0 01-2.27-1.31l-1.372-3.97a2 2 0 00-1.237-1.237l-3.97-1.372a2 2 0 01-1.31-2.27l.797-4.123a2 2 0 00-.453-1.69L5.137 29.31a2 2 0 010-2.622l2.752-3.172a2 2 0 00.453-1.69l-.797-4.123a2 2 0 011.31-2.27l3.97-1.372a2 2 0 001.237-1.237l1.372-3.97a2 2 0 012.27-1.31l4.123.797a2 2 0 001.69-.453l3.172-2.752z"
            ></path>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M36 23L25 34l-5-5"
            ></path>
        </svg>
    )
}
