import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'

import { ActionPayload, IAction, IComponent, IDispatcher } from '@/types'
import { getCustomEventsOptions, performFunctionOnCustomEvents } from '@/utils/customEvents'
import { CustomEventTypes, SourceType } from '@/utils/customEvents/type'
import { getNestedField } from '@/utils/general'

interface ICustomEventsProps {
    dispatcher?: IDispatcher
    data?: IComponent
}

function useCustomEvents({ dispatcher, data }: ICustomEventsProps) {
    const customEventMeta = useMemo(() => getNestedField(['data', 'customEvents'], data), [data])
    const sourceType = useMemo(
        () =>
            getNestedField(
                [`${CustomEventTypes.SHOW_ON_OUT_OF_VIEWPORT}`, 'extraProps', 'sourceType'],
                customEventMeta
            ),
        [customEventMeta]
    )

    const [options, setOptions] = useState(() =>
        getCustomEventsOptions(customEventMeta.SHOW_ON_OUT_OF_VIEWPORT.extraProps)
    )

    const isValidEvent = useCallback(
        () => customEventMeta && sourceType === SourceType.RECEIVER,
        [customEventMeta, sourceType]
    )

    const customEventFn = useCallback(
        (e: Event) => {
            const cstmEvent = e as CustomEvent<{ isVisible: boolean; senderId: string }>
            const payload: {
                [key: string]: unknown
            } = {}

            payload.senderId = cstmEvent.detail.senderId

            if (cstmEvent.type === CustomEventTypes.SHOW_ON_OUT_OF_VIEWPORT) {
                const { isVisible } = cstmEvent.detail
                payload.isVisible = isVisible
            }

            const action = { type: e.type, payload } as IAction<ActionPayload>
            dispatcher?.(action)
        },
        [dispatcher]
    )

    useLayoutEffect(() => {
        setOptions(getCustomEventsOptions(customEventMeta.SHOW_ON_OUT_OF_VIEWPORT.extraProps))
    }, [customEventMeta])

    useEffect(() => {
        if (isValidEvent()) {
            performFunctionOnCustomEvents(customEventMeta, eventName => {
                document.addEventListener(eventName, customEventFn)
            })
        }

        return () => {
            if (isValidEvent()) {
                performFunctionOnCustomEvents(customEventMeta, (eventName: string) =>
                    document.removeEventListener(eventName, customEventFn)
                )
            }
        }
    }, [isValidEvent, customEventMeta, customEventFn])

    return {
        sourceType,
        customEventMeta,
        options
    }
}

export default useCustomEvents
