import { Raven } from '@/analytics/raven'
import { IDispatcher, ITracking } from '@/types'
import { isEmpty } from '../general/browserHelper'
import { formTrackingAction } from '../actions'

export const triggerTracking = ({
    pageTrackingData,
    componentTrackingData
}: {
    pageTrackingData: ITracking
    componentTrackingData: ITracking
}) => {
    const { mergeData = {} } = pageTrackingData || {}
    const { eventName = '', eventData } = componentTrackingData

    const trackingData = {
        ...mergeData,
        ...eventData
    }

    Raven.push({ eventName, eventData: trackingData })
}

export const trackingDispatcher = (ravenTracking?: ITracking, dispatcher?: IDispatcher) => {
    if (!isEmpty(ravenTracking)) {
        dispatcher?.(formTrackingAction(ravenTracking!))
    }
}
