import { SVGProps } from 'react'

export const SliderLeftChevron = ({ className, ...rest }: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
            {...rest}
        >
            <mask
                id="mask0_1027_112362"
                style={{ maskType: 'alpha' }}
                width="20"
                height="20"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
            </mask>
            <g mask="url(#mask0_1027_112362)">
                <path
                    fill="#1A1A1A"
                    d="M11.264 14.393l-3.857-3.857a.705.705 0 01-.161-.247.801.801 0 010-.578.707.707 0 01.161-.246l3.857-3.858a.617.617 0 01.45-.192c.172 0 .322.064.45.192a.617.617 0 01.193.45.617.617 0 01-.193.45L8.671 10l3.493 3.493a.617.617 0 01.193.45.616.616 0 01-.193.45.616.616 0 01-.45.193.617.617 0 01-.45-.193z"
                ></path>
            </g>
        </svg>
    )
}
