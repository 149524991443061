export const SelectedOffer: React.FC<React.SVGProps<SVGSVGElement>> = ({
    width = '24',
    height = '24',
    ...restProps
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
            {...restProps}
        >
            <path
                fill="#11A670"
                stroke="#11A670"
                d="M11.345 2.569a1 1 0 011.31 0l1.152.998a1 1 0 00.845.227l1.496-.29a1 1 0 011.135.656l.498 1.44a1 1 0 00.619.62l1.44.497a1 1 0 01.655 1.135l-.289 1.496a1 1 0 00.227.845l.998 1.152a1 1 0 010 1.31l-.998 1.152a1 1 0 00-.227.845l.29 1.496a1 1 0 01-.656 1.135l-1.44.498a1 1 0 00-.62.619l-.497 1.44a1 1 0 01-1.135.655l-1.496-.289a1 1 0 00-.845.227l-1.152.998a1 1 0 01-1.31 0l-1.152-.998a1 1 0 00-.845-.227l-1.496.29a1 1 0 01-1.135-.656l-.498-1.44a1 1 0 00-.619-.62l-1.44-.497a1 1 0 01-.656-1.135l.29-1.496a1 1 0 00-.227-.845l-.998-1.152a1 1 0 010-1.31l.998-1.152a1 1 0 00.227-.845l-.29-1.496a1 1 0 01.656-1.135l1.44-.498a1 1 0 00.62-.619l.497-1.44a1 1 0 011.135-.656l1.496.29a1 1 0 00.845-.227l1.152-.998z"
            ></path>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.25"
                d="M16.727 9l-6 6L8 12.273"
            ></path>
        </svg>
    )
}
