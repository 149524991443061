import React from 'react'

export const RoundedPlus: React.FC<React.SVGProps<SVGSVGElement>> = ({ width, height, ...restProps }) => {
    return (
        <svg
            width={width || '32'}
            height={height || '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...restProps}
        >
            <path d="M16 9V23M9 16H23" stroke="#3366CC" strokeLinecap="round" strokeLinejoin="round" />
            <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#3366CC" />
        </svg>
    )
}
