import CachedRootMarginByPageInstance from './CachedRootMarginByPage'

/**
 * Conscious call:- We will be having single fixed top and bottom component in any page so we are
 * assigning the id like pageName_fixed_bottom to that element to get the root margin in efficient manner.
 *
 * Gets the root margin for the given page name.
 * This function calculated caches the root-margin of every page so that it doesn't have to recalculate
 * every time it's called.
 * @param pageName The name of the page to get the root margin for.
 * @returns The root margin for the given page name as a string. i.e 0px 0px 0px 0px
 */
export const getRootMarginForPage = (pageName: string): string => {
    if (CachedRootMarginByPageInstance.has(pageName)) {
        return CachedRootMarginByPageInstance.get(pageName)!
    }

    const topPageLikeScreen = `${pageName}-fixed-top-`
    const fixedTopElements = document.querySelectorAll(`[id ^= ${topPageLikeScreen}]`)
    let topMargin = 0

    fixedTopElements?.forEach(element => {
        const rect = element?.getBoundingClientRect() || { height: 0 }
        topMargin += rect.height
    })

    const rootMargin = `-${topMargin}px 0px 0px 0px`
    CachedRootMarginByPageInstance.set(pageName, rootMargin)

    return rootMargin
}
