import { createSlice } from '@reduxjs/toolkit'

import { actions } from './actions'

// TODO @raghav improve typing here
const initialState = {} as Record<string, any>

const formsSlice = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        ...actions
    }
})

const { reducer } = formsSlice

export const { setInitialFieldDetails, setInitialFormState, updateFormFieldState, updateLazyGuestFormState } =
    formsSlice.actions

export default reducer
