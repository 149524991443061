import { SVGProps } from 'react'

export const SuccessTick = (props: React.SVGProps<SVGSVGElement>) => {
    const { height = 12, width = 12, color = '#11A670', ...rest } = props || {}
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 12 12" {...rest}>
            <g clipPath="url(#clip0_1241_155664)">
                <path fill={color} d="M4.5 8.085L2.415 6l-.71.705L4.5 9.5l6-6-.705-.705L4.5 8.085z"></path>
            </g>
            <defs>
                <clipPath id="clip0_1241_155664">
                    <path fill="#fff" d="M0 0H12V12H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}
